import axios from 'axios';
import * as Sentry from '@sentry/react';
import { attachQueryString } from '@tsUtils';
import { createLogic } from 'redux-logic';
import * as Logger from '@common/Logger';
import {
    ActionsType,
    setLeadLocal,
    LEAD_ACTIONS,
    SetLeadRemoteAction,
    SetLeadLocalAction,
    setLeadRemote,
    setGlobalError,
    openDetachedScreen,
} from '../actions';
import { AirToWaterLead, Lead, Store } from '../../types';
import { APP_STATUS, DETACHED_SCREEN_TYPE } from '../../constants';
import Navigator from '../../Navigator';
import {
    getTransitionToStep,
    getFetchLeadPayload,
    getSetLeadRemotePayload,
    isUnauthorizedError,
    isSyncError,
    getPhasing,
    isStepForward,
    isAirToAirMultiRoomLead,
} from '../selectors';

type UpdateResponseType = {
    lead: Partial<Lead>;
    success: boolean;
};

const logAPI = (message: string): void => Logger.log(`[API] ${message}`, Logger.STYLE.API);

type DepObj = { getState: () => Store };
type SetRemoteDepObj = DepObj & { action: SetLeadRemoteAction };
type SetLocalDepObj = DepObj & { action: SetLeadLocalAction };

const fetchLeadLogic = createLogic({
    type: LEAD_ACTIONS.fetchLead,
    name: 'lead.fetchLeadLogic',
    async process({ getState }: DepObj, dispatch, done) {
        const store = getState();
        const payload = await getFetchLeadPayload(store);
        const url = attachQueryString(store.settings.urls.lead, payload);
        axios
            .get<UpdateResponseType>(url)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    // Update reducer w/ new lead-data
                    dispatch(
                        setLeadLocal(data.lead, true, 'fetchLeadLogic', LEAD_ACTIONS.fetchLead),
                    );
                }
            })
            .catch((err) => {
                if (isUnauthorizedError(err)) {
                    // Dispatch setLocal to stop loading & re-check queue
                    dispatch(
                        setLeadLocal(undefined, false, 'fetchLeadLogic', LEAD_ACTIONS.fetchLead),
                    );
                    dispatch(openDetachedScreen(DETACHED_SCREEN_TYPE.unlinkedLead));
                } else {
                    err.name = 'Error fetching lead';
                    Sentry.captureException(err);
                    dispatch(setGlobalError('error_fetching_lead'));
                }
            })
            .finally(() => {
                done();
            });
    },
});

// This logic sends lead-changes to the API
const triggerUpdateLeadLogic = createLogic({
    type: [LEAD_ACTIONS.updateLocation, LEAD_ACTIONS.setSelSoftData],
    name: 'leadLogic.triggerUpdateLeadLogic',
    process(_, dispatch, done) {
        dispatch(setLeadRemote());
        done();
    },
});

const apiQueue: ActionsType[] = [];

// Queue parallel /update calls, lead.version will get out of sync
const enqueueApiLogic = createLogic({
    type: LEAD_ACTIONS.setLeadRemote,
    name: 'lead.enqueueApiLogic',
    validate({ getState, action }: SetRemoteDepObj, allow, reject) {
        if (getState().appState.isSaving) {
            logAPI(`📥 API call already in progress -> enqueue action (${apiQueue.length + 1})`);
            apiQueue.push(action);
            reject({ ...action, type: `📨[${apiQueue.length}] (${action.type})` });
        } else {
            allow(action);
        }
    },
});

let leadHash = 0;

const setLeadRemoteLogic = createLogic({
    type: LEAD_ACTIONS.setLeadRemote,
    name: 'lead.setLeadRemoteLogic',
    async process({ getState, action }: SetRemoteDepObj, dispatch, done) {
        const store = getState();
        // Get payload & hash
        const { payload, hash } = await getSetLeadRemotePayload(store);
        // Add correct transition request - if no transition was given, stay on current app_status
        const targetStatus = action.targetStatus || store.lead.status;
        payload.transition = getTransitionToStep(store, targetStatus);
        // Add phasing if we're advancing from sp_selsoft_result to P4/5
        const { status } = store.lead;
        if (status === APP_STATUS.sp_selsoft_result && isStepForward(status, targetStatus)) {
            payload.phasing ||= getPhasing(store);
        }
        // Add swapCooling if selSoftResult has a solution
        if ((payload as AirToWaterLead).selSoftData?.solution) {
            payload.swapCooling ||= store.appState.swapCooling;
        }
        // Check if backend is already in sync
        if (hash === leadHash && targetStatus === store.lead.status) {
            logAPI('Nothing relevant for update-lead has changed: suppress update-request ✋');
            // Dispatch setLeadLocal to clear the isSaving value
            dispatch(setLeadLocal(undefined, false, 'setLeadRemoteLogic', action.type));
            done();
            return;
        }
        leadHash = hash;
        // Make API-call
        axios
            .post<UpdateResponseType>(store.settings.urls.update, payload)
            .then((res) => {
                const { data } = res;
                if (res.status === 200 && data) {
                    // Update reducer w/ new lead-data
                    dispatch(
                        setLeadLocal(
                            data.lead,
                            false,
                            'setLeadRemoteLogic',
                            action.type,
                            action.toolStatus,
                        ),
                    );
                }
            })
            .catch((err) => {
                if (isUnauthorizedError(err)) {
                    dispatch(openDetachedScreen(DETACHED_SCREEN_TYPE.unlinkedLead));
                } else if (isSyncError(err)) {
                    dispatch(openDetachedScreen(DETACHED_SCREEN_TYPE.versionExpired));
                }
                // Dispatch setLocal to stop loading & re-check queue
                dispatch(setLeadLocal(undefined, false, 'setLeadRemoteLogic', action.type));
            })
            .finally(done);
    },
});

const updateHistoryLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'lead.updateHistoryLogic',
    process({ getState, action }: SetLocalDepObj, dispatch, done) {
        const state = getState();
        const page = action.toolStatus || state.lead.status;
        if (page === APP_STATUS.sft_ra_unsupported_positioning_type) {
            dispatch(openDetachedScreen(DETACHED_SCREEN_TYPE.aemRedirectCCU));
        }
        /* When a multi-room lead don't push quotation tool pages in the browser history */
        if (!(isAirToAirMultiRoomLead(state) && page === APP_STATUS.sp_quotation_tool)) {
            Navigator.setLeadStatus(page);
        }
        done();
    },
});

// Dispatch previously queued actions
const dequeueApiLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'lead.dequeueApiLogic',
    process(_, dispatch, done) {
        if (apiQueue.length) {
            logAPI(`📤 (${apiQueue.length}) API calls in queue -> dispatched queued action`);
            dispatch(apiQueue.shift()!);
        }
        done();
    },
});

// Generate leadHash during initialisation
// This prevents an unneccesary /update api-call after boot because leadHash is undefined
const initialHashLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'lead.initialHashLogic',
    async process({ getState, action }: SetLocalDepObj, dispatch, done) {
        if (!leadHash && action.setAllValues) {
            const { hash } = await getSetLeadRemotePayload(getState());
            leadHash = hash;
        }
        done();
    },
});

export default [
    fetchLeadLogic,
    triggerUpdateLeadLogic,
    enqueueApiLogic,
    setLeadRemoteLogic,
    updateHistoryLogic,
    initialHashLogic,
    dequeueApiLogic,
];
