import { createLogic } from 'redux-logic';
import { MODAL_TYPE, RSN_SECTION } from '../../constants';
import { Store } from '../../types';
import { APP_STATE_ACTIONS, openModal, RedirectAction } from '../actions';
import { getCurrentSection, isPardotLinked } from '../selectors';

type DepObj = { getState: () => Store };
type RedirectDepObj = DepObj & { action: RedirectAction };

const redirectLogic = createLogic({
    type: APP_STATE_ACTIONS.redirect,
    name: 'redirectLogic',
    process({ action, getState }: RedirectDepObj, dispatch, done) {
        const state = getState();
        // Only show Pardot if it's enabled in BU
        if (!state.settings.pardotEnabled) {
            window.open(action.url, action.target);
            return done();
        }
        // Pardot is enabled check the other requirement s before saving to Pardot
        // While the user is on SFT we should not be saving to Pardot yet
        const isSFT = getCurrentSection(state) === RSN_SECTION.section_1_solution_finder_tree;
        // If the user has not entered a postalCode we should not be saving to Pardot yet
        const locationPostalCode = state.lead.location?.postalCode;
        if (
            isSFT ||
            !locationPostalCode ||
            action.target === '_blank' ||
            action.skipPardot ||
            isPardotLinked(state)
        ) {
            window.open(action.url, action.target);
        } else {
            if (action.fromAem) {
                // Close the aem menu when a aem submenu is open, so our pardot modal works correcty
                (document.getElementsByClassName('modal__close')[0] as HTMLButtonElement)?.click();
            }
            dispatch(openModal(MODAL_TYPE.pardot, { url: action.url }));
        }
        return done();
    },
});

const saveProgressLogic = createLogic({
    type: APP_STATE_ACTIONS.saveProgress,
    name: 'redirectLogic',
    process(_: DepObj, dispatch, done) {
        dispatch(openModal(MODAL_TYPE.pardot));
        done();
    },
});

export default [redirectLogic, saveProgressLogic];
