/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import * as Logger from '@common/Logger';
import * as Sentry from '@sentry/react';
import { jsonHash } from '@tsUtils';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { APP_STATUS } from '../../constants';
import { AirToWaterLead, SelSoftResponseData, Store } from '../../types';
import {
    APP_STATE_ACTIONS,
    LEAD_ACTIONS,
    parseSelSoftResponse,
    ParseSelSoftResponseAction,
    SetLeadLocalAction,
    setSelSoftData,
    setSelSoftResults,
    setSwapCooling,
    SetSwapCoolingAction,
} from '../actions';
import { calcRecommendedSolution, getSelSoftPayload, shouldCallSelSoft } from '../selectors';

type SelSoftResponseType = {
    success: boolean;
    responseData: SelSoftResponseData;
};

const logSSI = (message: string): void => Logger.log(`[SSI] ${message}`, Logger.STYLE.SSI_INFO);

let selsoftHash: number | null;
type DepObj = {
    getState: () => Store;
    action: SetLeadLocalAction | SetSwapCoolingAction;
};
// Notify backend of the changes and navigate to next page
const apiLogic = createLogic({
    type: [LEAD_ACTIONS.setLeadLocal, APP_STATE_ACTIONS.setSwapCooling],
    name: 'selsoftLogic.apiLogic',
    warnTimeout: 120000,
    async process({ getState, action }: DepObj, dispatch, done) {
        const state = getState();
        // Check if everything is available to call SelSoft
        if (!shouldCallSelSoft(state)) {
            done();
            return;
        }
        if (action.type === APP_STATE_ACTIONS.setSwapCooling && !action.swapCooling) {
            done();
            return;
        }
        const { payload, hash } = await getSelSoftPayload(state);
        // Check if anything relevant has changed since we last called SelSoft
        if (hash === selsoftHash) {
            logSSI('Nothing relevant for SelectionSoftware has changed: suppress SSI-request.');
            /* Send no-valid-solution true to Aaltra if re-doing the sizing tool but no sizing data changed */
            if (
                action.type === LEAD_ACTIONS.setLeadLocal &&
                action.toolStatus?.startsWith(APP_STATUS.sp_sizing_tool) &&
                state.lead.toolData?.sizingHeating?.['no-solution'] &&
                state.lead.toolData?.sizingHeating['required-capacity']
            ) {
                window._rsnChannel.publish('sizing.heating.selsoft', {
                    'has-valid-solution': false,
                });
            }
            done();
            return;
        }
        selsoftHash = hash;
        // Everything is in order, query SelSoft for solutions
        logSSI('SelSoft JSON complete -> request solutions');
        axios
            .post<SelSoftResponseType>(state.settings.urls.proxyCall, payload)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    // If no selsoft results set swapCooling on true
                    if (!data.responseData.solutions.length && !state.appState.swapCooling) {
                        logSSI('Set swapCooling: true 🔄');
                        dispatch(setSwapCooling(true));
                        selsoftHash = null;
                    } else {
                        const { solutions, ...selsoftData } = data.responseData;
                        dispatch(parseSelSoftResponse(selsoftData, solutions));
                    }
                }
            })
            .catch((err) => {
                err.name = 'Error fetching selSoft';
                Sentry.captureException(err);
                // Notify sizing tool that Selsoft failed fetching selsoft solutions
                window._rsnChannel.publish('sizing.heating.selsoft', {
                    'has-valid-solution': false,
                });
            })
            .finally(() => {
                done();
            });
    },
});

type SelSoftDepObj = {
    getState: () => Store;
    action: ParseSelSoftResponseAction;
};
// Find recommended solution and modify parseSelSoftResponse-action
const preRecommendedSolutionLogic = createLogic({
    type: LEAD_ACTIONS.parseSelSoftResponse,
    name: 'selsoftLogic.preRecommendedSolutionLogic',
    transform({ getState, action }: SelSoftDepObj, next) {
        const solution = calcRecommendedSolution(getState(), action.solutions);
        next(parseSelSoftResponse({ ...action.selsoftData, solution }, action.solutions));
    },
});

// Split parseAction into an updateResults & (if needed) an updateData action
const parseSelSoftLogic = createLogic({
    type: LEAD_ACTIONS.parseSelSoftResponse,
    name: 'selsoftLogic.parse',
    process({ getState, action }: SelSoftDepObj, dispatch, done) {
        const currentData = (getState().lead as AirToWaterLead).selSoftData;
        // Only update lead.selsoftData if anything relevant changed
        const newHash = jsonHash(action.selsoftData);
        const oldHash = currentData?.hash;
        if (newHash !== oldHash) {
            dispatch(setSelSoftData({ ...action.selsoftData, hash: newHash }));
        }
        // Update the selsoft-results regardless of if recommended solution changed or not
        dispatch(setSelSoftResults(action.solutions));
        done();
    },
});

export default [apiLogic, preRecommendedSolutionLogic, parseSelSoftLogic];
