import {
    DHW_COLD_WATER_TEMP,
    DHW_EFFICIENCY,
    DHW_STORAGE_TEMP,
    MAX_SIMULATIONS_PER_SERIES,
} from '@common/data/variables';
import { LiteratureItem } from '@common/types';
import { stableSort } from '@tsUtils';
import { countryCodeToISO3166 } from '@utils';
import { APP_STATUS, PROJECT_TYPE, phasingMap } from '../../constants';
import {
    AirToAirSbmSolution,
    AirToAirSingleRoomSbmSolution,
    AirToWaterLead,
    AirToWaterSbmSolution,
    AppStatus,
    PhasingType,
    SelSoftJsonCooling,
    SelSoftJsonDhw,
    SelSoftJsonHeating,
    SelSoftRequestJson,
    SelSoftSolution,
    Store,
} from '../../types';
import { filterValidSolutions } from '../../utils';
import {
    getPhasing,
    isAirToAirLead,
    isAirToAirMultiRoomLead,
    isAirToWaterProject,
    isGermany,
    isUk,
    selsoftResultsHaveMultiplePhases,
} from './genericSelectors';
import { isCoolingRequired, isDhwRequired } from './leadSelectors';

const getMainSystemOperatingTemperature = (store: Store): number => {
    const lead = store.lead as AirToWaterLead;
    if (lead.projectType === PROJECT_TYPE.renovation) return isGermany(store) ? 15 : 17;
    if (lead.projectType === PROJECT_TYPE.new) return isGermany(store) ? 12 : 16;
    return 0;
};

const getSelSoftHeating = (store: Store): SelSoftJsonHeating => {
    const { toolData } = store.lead;
    const solution = store.lead.solution as AirToWaterSbmSolution;
    const capacityMultiplier = isGermany(store) ? 1.1 : 1;
    const heating: SelSoftJsonHeating = {
        design: { method: 'useImplicitDesignTemperature' },
        capacity: toolData!.sizingHeating!['required-capacity']! * capacityMultiplier,
        mainSystemOperatingTemperature: getMainSystemOperatingTemperature(store),
        leavingWaterTemperature: solution!.heatingLeavingWaterTemperatures,
    };
    if (solution!.heatingBrineTemperatures) {
        heating.brineTemperature = solution!.heatingBrineTemperatures.recommended;
    }
    return heating;
};

const getSelSoftCooling = (store: Store): SelSoftJsonCooling => {
    const { coolingLeavingWaterTemperatures, coolingBrineTemperatures } = store.lead
        .solution as AirToWaterSbmSolution;
    const cooling: SelSoftJsonCooling = {
        design: { method: 'useImplicitDesignTemperature' },
        capacity: 0, // hardcoded for RSN
        mainSystemOperatingTemperature: 20, // hardcoded for RSN
        leavingWaterTemperature: coolingLeavingWaterTemperatures,
    };
    if (coolingBrineTemperatures) {
        cooling.brineTemperature = coolingBrineTemperatures.recommended;
    }
    return cooling;
};

const getEquivalentHotWaterVolume = (state: Store): number => {
    const { householdComposition } = state.lead;
    const people = householdComposition
        ? householdComposition.children + householdComposition.adults
        : 1;
    return 40 * people;
};

export const getTotalVolumeRequired = (store: Store): number => {
    const CWT40ST = (40 - DHW_COLD_WATER_TEMP) / (DHW_STORAGE_TEMP - DHW_COLD_WATER_TEMP);
    return Math.round(getEquivalentHotWaterVolume(store) * CWT40ST);
};

const getSelSoftDhw = (store: Store): SelSoftJsonDhw => {
    if (isDhwRequired(store)) {
        const { instantaneousDhw } = store.lead.solution as AirToWaterSbmSolution;
        return {
            hotWaterRequired: true,
            instantaneousHotWaterRequired: !!instantaneousDhw,
            domesticHotWaterSpecify: {
                minimumFlowRate: 10, // hardcoded for RSN
                storageTemperature: DHW_STORAGE_TEMP,
                coldWaterTemperature: DHW_COLD_WATER_TEMP,
                disinfectionFrequency: 0, // hardcoded for RSN
                disinfectionTemperature: 0, // hardcoded for RSN
                equivalentHotWaterVolume: getEquivalentHotWaterVolume(store),
                efficiencyProvidingDomesticHotWater: DHW_EFFICIENCY,
                totalVolumeRequiredAtStorageTemperature: getTotalVolumeRequired(store),
            },
        };
    } else {
        return {
            hotWaterRequired: false,
            instantaneousHotWaterRequired: false,
        };
    }
};

const allowedSelSoftPages: AppStatus[] = [
    APP_STATUS.sp_sizing_tool,
    APP_STATUS.sp_quotation_tool,
    APP_STATUS.sp_selsoft_result,
];
export const shouldCallSelSoft = (store: Store): boolean => {
    const {
        lead: { toolData, location, status, solution },
    } = store;
    // Only allowed on certain pages
    if (!allowedSelSoftPages.includes(status)) return false;
    // Coordinates are required
    if (!location?.coordinates?.latitude || !location?.coordinates?.longitude) return false;
    // When multi-split flow is chosen no selsoft call needs to be done
    if (isAirToAirMultiRoomLead(store)) return false;
    // Heating Capacity is required
    if (!toolData?.sizingHeating?.['required-capacity']) return false;
    // SBM Solution data is required
    if (!solution) return false;
    // Everything seems to be in order, carry on
    return true;
};

export const getSelSoftRequestJson = (store: Store): SelSoftRequestJson => {
    const {
        lead: { id, location, solution, colorVariation },
        appState: { swapCooling },
        settings,
    } = store;
    const json: SelSoftRequestJson = {
        leadId: id,
        localization: {
            location: location!.coordinates!,
            countryCode: countryCodeToISO3166(settings.country.isoCode),
            languageCode: settings.language,
            energyTariffs: settings.energyPrices,
        },
        heating: getSelSoftHeating(store),
        domesticHotWater: getSelSoftDhw(store),
        optimized: 'Capacity', // hardcoded for RSN
        hybridSelection: { method: 'economical' }, // hardcoded for RSN
        MaxSimulationsPerSeries: MAX_SIMULATIONS_PER_SERIES,
    };
    // COOLING
    // Add cooling only if required on the first selSoft call (swapCooling = false)
    // Invert cooling requirement on the second selSoft call (swapCooling = true)
    const addCoolingToJson = settings.hasDefaultCooling || isCoolingRequired(store);
    // XOR check these booleans
    if (swapCooling !== addCoolingToJson) json.cooling = getSelSoftCooling(store);
    // Add SBM values
    const {
        outdoorUnits,
        indoorUnits,
        boilerUnits,
        tankUnits,
        solutionNumber,
        hasDomesticHeatedWater,
    } = solution as AirToWaterSbmSolution;
    // OUTDOOR UNITS [Required] - Gas and oil units are considered outdoor units
    json.outdoorunits = outdoorUnits || [];
    // INDOOR UNITS [Optional] & BOILER UNITS [Optional]
    json.indoorunits = indoorUnits ? [...indoorUnits] : [];
    // Only include idoor units in the chosen color
    if (colorVariation) {
        json.indoorunits = json.indoorunits.filter((productName) => {
            return productName.split('').pop() === colorVariation;
        });
    }
    // Also add boilerUnits to this list for certain solutions (DAI002-227) (used to be outdoor... DAI002-133)
    if (boilerUnits && ![14, 15, 16].includes(solutionNumber)) {
        json.indoorunits.push(...boilerUnits);
    }
    // WATER TANKS [Optional]
    // If the Solution data from SBM defines there is no Operation Mode 'Domestic Hot Water', we never send
    // any tanks (even if the Product Range has tanks)
    if (hasDomesticHeatedWater) json.waterTanks = tankUnits;

    return json;
};

export const isLegacySorting = (store: Store): boolean => {
    return [5, 6, 7].includes(store.lead.solution!.solutionNumber);
};

// Helper methods for sorting (null & NaN values fallback to 0)
const sortAsc = (v1: number | undefined, v2: number | undefined): number => (v1 || 0) - (v2 || 0);
const sortDesc = (v1: number | undefined, v2: number | undefined): number => (v2 || 0) - (v1 || 0);

// TS-equivalent of ssResultHelper.js
export const calcRecommendedSolution = (
    store: Store,
    solutions: SelSoftSolution[],
    phase?: PhasingType,
): SelSoftSolution | undefined => {
    // Only return valid solutions
    let validSolutions = filterValidSolutions(solutions);
    // Filter solutions based on phasing if applicable
    if (selsoftResultsHaveMultiplePhases(validSolutions)) {
        // Translate SBM phasing to SelSoft phasing via phasingMap
        const phasing = phase ? phasingMap[phase] : phasingMap[getPhasing(store)];
        validSolutions = validSolutions.filter((s) => s.outdoorUnit?.powerSupply.phase === phasing);
    }
    // No valid solutions -> return nothing
    if (!validSolutions.length) return undefined;
    // If legacy -> just return the solution marked as recommended
    if (isLegacySorting(store)) return validSolutions.find((solution) => solution.recommended);

    /* Apply sorting */
    // Sort heatPumpSeasonalEfficiency Descending
    validSolutions = stableSort(validSolutions, (a, b) => {
        return sortDesc(
            a.spaceHeating.performance.heatPumpSeasonalEfficiency,
            b.spaceHeating.performance.heatPumpSeasonalEfficiency,
        );
    });
    // Sort heating spareCapacity Ascending
    validSolutions = stableSort(validSolutions, (a, b) => {
        return sortAsc(
            a.spaceHeating.capacity.spare.atExtremeAmbientTemperature,
            b.spaceHeating.capacity.spare.atExtremeAmbientTemperature,
        );
    });
    // Sort annualBackupHeaterCoverage Ascending
    validSolutions = stableSort(validSolutions, (a, b) => {
        // Solutions with abhc <= 0.01 should be considered equal
        let aC = a.coverage.spaceHeating.backupHeater.annualCoverage;
        let bC = b.coverage.spaceHeating.backupHeater.annualCoverage;
        if (!aC || aC <= 0.01) aC = 0;
        if (!bC || bC <= 0.01) bC = 0;
        return sortAsc(aC, bC);
    });
    // The first item without a waterTank should be recommended
    const recommended = validSolutions.find((v) => !v.waterTank);
    if (recommended) return recommended;
    // If all of them have waterTanks we should check the waterTank.volume sizes
    // Find the volume of the solution marked as recommended
    // (recommended might be a different phase so check solutions)
    const volume = solutions.find((v) => v.recommended)!.waterTank?.volume;
    // The first valid solution matching this volume is the recommended solution
    // Fallback to first solution if the actual recommended has no waterTank
    return volume ? validSolutions.find((v) => v.waterTank.volume === volume) : validSolutions[0];
};

export type SeriesIDsType = {
    indoorUnit?: string;
    outdoorUnit?: string;
    waterTank?: string;
};
// AirToWater SeriesIDs are based on selSoftData.unit.productRangeValue fields
const getAirToWaterSeriesIDs = (store: Store): SeriesIDsType => {
    const seriesIDs = {} as SeriesIDsType;
    const lead = store.lead as AirToWaterLead;
    if (!lead.selSoftData?.solution) return seriesIDs;
    // Get SelSoft unit productRange values
    const { indoorUnit, outdoorUnit, waterTank } = lead.selSoftData.solution;
    if (indoorUnit) seriesIDs.indoorUnit = indoorUnit.productRangeValue?.split(' ')[0];
    if (outdoorUnit) seriesIDs.outdoorUnit = outdoorUnit.productRangeValue?.split(' ')[0];
    if (waterTank) seriesIDs.waterTank = waterTank.productRangeValue?.split(' ')[0];
    return seriesIDs;
};
// AirToAir productnamePrv's are provided by BE
const getAirToAirSeriesIDs = (store: Store): SeriesIDsType => {
    const solution = store.lead.solution as AirToAirSbmSolution;
    return {
        indoorUnit: (solution as AirToAirSingleRoomSbmSolution)?.productNamePrv,
    };
};
export const getSeriesIDs = (store: Store): SeriesIDsType => {
    return isAirToWaterProject(store) ? getAirToWaterSeriesIDs(store) : getAirToAirSeriesIDs(store);
};

// Gets the url to download the product flyer or Brochure literature from
export const getLeaflet = (store: Store): LiteratureItem | null => {
    // Abort early when there is no literature data
    if (!store.lead.literatureData?.documents) return null;
    // Cfr. https://marlon.atlassian.net/wiki/spaces/DAI001/pages/2415559712/Literature+tool+integration+RSN#Questions
    // If the selected Solution has only an Outdoor or Indoor unit, the link will contain the Product flyer for that one unit
    // If the selected Solution has both an Outdoor and Indoor unit, the link will contain the Product flyer of the Indoor unit
    // For UK only: Cfr. https://jira.hosted-tools.com/browse/DAI001-6491
    // If the selected Solution has only an Outdoor or Indoor unit, the link will contain the Brochures for that one unit
    // If the selected Solution has both an Outdoor and Indoor unit, the link will contain the Brochures of the Indoor unit
    const { indoorUnit, outdoorUnit } = getSeriesIDs(store);
    const { documents: litData } = store.lead.literatureData;
    const lang = store.settings.language.toLowerCase();
    const litTitle = isUk(store) ? 'Brochures' : 'Product flyer';
    const indoorFlyer = indoorUnit && litData?.[indoorUnit]?.[litTitle]?.[lang];
    const outdoorFlyer = outdoorUnit && litData?.[outdoorUnit]?.[litTitle]?.[lang];
    return indoorFlyer || outdoorFlyer || null;
};
