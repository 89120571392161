import i18n from '@common/i18n';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../redux';
import { fetchEpackages } from '../../../redux/actions';
import { getEpackages, isExternelToolOpen } from '../../../redux/selectors';
import * as EpackageStyles from '../../../styles/components/Epackage';
import * as TypographyStyles from '../../../styles/components/Typography';

const Epackages: FC = () => {
    const dispatch = useDispatch();
    const epackages = useAppSelector(getEpackages);
    const isToolOpen = useAppSelector(isExternelToolOpen);

    const noEpackages = !epackages.length;
    useEffect(() => {
        if (noEpackages) dispatch(fetchEpackages());
    }, [noEpackages, dispatch]);

    if (noEpackages) return null;

    return (
        <EpackageStyles.Wrapper isToolOpen={isToolOpen}>
            <EpackageStyles.Card>
                <EpackageStyles.Epackages>
                    <TypographyStyles.H3>{i18n('daikins_services')}</TypographyStyles.H3>
                    {epackages.map(({ icon, title, summary }) => (
                        <EpackageStyles.Block key={`${title}`}>
                            <EpackageStyles.Icon url={icon} />
                            <EpackageStyles.Info>
                                <TypographyStyles.H4>{title}</TypographyStyles.H4>
                                <div>{summary}</div>
                            </EpackageStyles.Info>
                        </EpackageStyles.Block>
                    ))}
                    <TypographyStyles.Small>{i18n('epackages_disclaimer')}</TypographyStyles.Small>
                </EpackageStyles.Epackages>
            </EpackageStyles.Card>
        </EpackageStyles.Wrapper>
    );
};

export default Epackages;
