/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import i18n from '@common/i18n';
import { DEVICE_TYPE } from '@common/types';
import React, { useState } from 'react';
import { EVS_OVERVIEW_TAB, MODAL_TYPE } from '../../../../../constants';
import { useEvsAtwModalNavigation } from '../../../../../hooks/evsHooks';
import { useAppSelector } from '../../../../../redux';
import * as EvsStyles from '../../../../../styles/components/Evs';
import * as ModalStyles from '../../../../../styles/components/Modal';
import * as TypographyStyles from '../../../../../styles/components/Typography';
import { EvsAtwData, EvsOverviewTab } from '../../../../../types';
import Modal from '../../Modal';
import Co2AdditionalInfo from '../Co2AdditionalInfo';
import CostCompareToggle from '../CostCompareToggle';
import Navigation from '../Navigation';
import EvsAtwCo2Compare from './Co2Compare';
import Disclaimers from './Disclaimers';
import EvsAtwPriceCompare from './PriceCompare';

type CastedPropsType = { evsData: EvsAtwData };
const EvsAtwCompareView = ({ evsData }: CastedPropsType): JSX.Element => {
    const { priceData, co2Data } = evsData;
    const pricingDisplayStrategy = useAppSelector(
        (state) => state.settings.solutionPricing.pricingDisplayStrategy,
    );

    const [activeTab, setActiveTab] = useState<EvsOverviewTab>(EVS_OVERVIEW_TAB.price);
    const navigation = useEvsAtwModalNavigation(evsData);
    const { visibleItems, itemIndex, deviceType } = navigation;

    const isMobile = deviceType === DEVICE_TYPE.mobile;
    const isDesktop = deviceType === DEVICE_TYPE.desktop;

    const showNavigation = isMobile || priceData.benchmarkSolutions.length > (isDesktop ? 2 : 1);

    return (
        <Modal type={MODAL_TYPE.evsAtwCompare} isWide>
            <ModalStyles.ModalHeader>
                <TypographyStyles.H4 mb={5}>{i18n('compare_daikin_system')}</TypographyStyles.H4>
            </ModalStyles.ModalHeader>
            <ModalStyles.ModalContent isWide width="inherit">
                <CostCompareToggle activeTab={activeTab} onSetActiveTab={setActiveTab} />
                {activeTab === EVS_OVERVIEW_TAB.price ? (
                    <EvsAtwPriceCompare
                        itemIndex={itemIndex}
                        deviceType={deviceType}
                        daikinSolution={priceData.daikinSolution}
                        benchmarkSolutions={priceData.benchmarkSolutions}
                        visibleItems={visibleItems}
                    />
                ) : (
                    <EvsAtwCo2Compare
                        itemIndex={itemIndex}
                        deviceType={deviceType}
                        daikinSolution={co2Data.daikinSolution}
                        benchmarkSolutions={co2Data.benchmarkSolutions}
                        visibleItems={visibleItems}
                    />
                )}
                {showNavigation && <Navigation navigation={navigation} />}
                {activeTab === EVS_OVERVIEW_TAB.co2 && (
                    <Co2AdditionalInfo
                        totalSavings={co2Data.totalSavings}
                        savingsTrees={co2Data.savingsTreesComparison}
                        savingsCarTravel={co2Data.savingsCarTravelComparison}
                        savingsAirplaneTravel={co2Data.savingsAirplaneTravelComparison}
                    />
                )}
            </ModalStyles.ModalContent>
            <ModalStyles.ModalFooter>
                <EvsStyles.Disclaimer>
                    <Disclaimers
                        activeTab={activeTab}
                        pricingDisplayStrategy={pricingDisplayStrategy}
                    />
                </EvsStyles.Disclaimer>
            </ModalStyles.ModalFooter>
        </Modal>
    );
};

// Evs data is fetching from store instead from meta-data, so the view has up to date data
const EvsAtwCompareModal = (): JSX.Element | null => {
    const evsData = useAppSelector((store) => store.appState.evsData) as EvsAtwData;
    return evsData ? <EvsAtwCompareView evsData={evsData} /> : null;
};

export default EvsAtwCompareModal;
