import i18n from '@common/i18n';
import { COLOR_VARIATIONS, COLOR_VARIATION_LABELS } from '@common/types/enums';
import React from 'react';
import { MODAL_TYPE, RSN_SECTION } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { getCurrentSection, getSingleRoomSolution } from '../../../redux/selectors';
import * as GridStyles from '../../../styles/components/Grid';
import * as ModalStyles from '../../../styles/components/Modal';
import * as SolutionStyles from '../../../styles/components/Solution';
import * as TypographyStyles from '../../../styles/components/Typography';
import Description from '../solution/Description';
import EnergyLabels from '../solution/EnergyLabels';
import Highlights from '../solution/Highlights';
import ImageCarousel from '../solution/ImageCarousel';
import RatingCard from '../solution/RatingCard';
import Modal from './Modal';

type PropsType = { metaData: unknown };

type CastedPropsType = { solution: string; colorVariation: COLOR_VARIATIONS | null };

const IndoorSolutionInfoModal = (props: PropsType): JSX.Element => {
    const { solution: solutionId, colorVariation } = props.metaData as CastedPropsType;
    const solution = useAppSelector((state) => getSingleRoomSolution(state, solutionId)!);
    const currentSection = useAppSelector(getCurrentSection);
    const showEnergyLabels = currentSection <= RSN_SECTION.section_2_solution_overview;

    return (
        <SolutionStyles.IndoorSolutionInfoModal>
            <Modal type={MODAL_TYPE.indoorSolutionInfo} isWide>
                <ModalStyles.ModalHeader>
                    <TypographyStyles.H4 mb={colorVariation ? 0 : 5}>
                        {solution.name}
                    </TypographyStyles.H4>
                    {colorVariation && (
                        <TypographyStyles.StrongText mb={2}>
                            {i18n(COLOR_VARIATION_LABELS[colorVariation])}
                        </TypographyStyles.StrongText>
                    )}
                </ModalStyles.ModalHeader>
                <ModalStyles.ModalContent isWide>
                    <GridStyles.Row hasDivider>
                        <GridStyles.Column
                            width={{ xs: 1, lg: 1 / 2 }}
                            py={5}
                            pr={{ xs: 4, md: 5 }}
                        >
                            <ImageCarousel
                                carousel={solution.carousel}
                                spinners={solution.spinners}
                                showActions
                            />
                            <RatingCard
                                energy={solution.energy}
                                comfort={solution.comfort}
                                airQuality={solution.airQuality}
                                connectivity={solution.connectivity}
                                showIcons
                            />
                        </GridStyles.Column>

                        <GridStyles.Column
                            width={{ xs: 1, lg: 1 / 2 }}
                            py={5}
                            pl={{ xs: 4, md: 5 }}
                        >
                            {showEnergyLabels && <EnergyLabels solution={solution} isUpTo />}
                            <Highlights highlights={solution.highlights} />
                            <Description description={solution.description} />
                        </GridStyles.Column>
                    </GridStyles.Row>
                </ModalStyles.ModalContent>
            </Modal>
        </SolutionStyles.IndoorSolutionInfoModal>
    );
};

export default IndoorSolutionInfoModal;
