import { PROJECT_TYPE } from '../../../constants';
import { Store, AirToWaterLead, PlatformIncentiveGermanyData } from '../../../types';
import { isAirToWaterProject } from '../genericSelectors';

const projectTypeMap = Object.freeze({
    [PROJECT_TYPE.new]: 'new',
    [PROJECT_TYPE.renovation]: 'reno',
});

// eslint-disable-next-line import/prefer-default-export
export const getIncentivesGermanyPlatformJson = (store: Store): PlatformIncentiveGermanyData => {
    const lead = store.lead as AirToWaterLead;
    return {
        country: store.settings.country.isoCode,
        language: store.settings.language,
        construction: projectTypeMap[lead.projectType!],
        'consumption-source': lead.previousHeatingSystem || null,
        'energy-sources': lead.solution!.energySources,
        flow: isAirToWaterProject(store) ? 'heating' : 'cooling',
        result: 'percentage',
        'labour-cost': lead.prices?.laborCostRaw || null,
        'material-cost': lead.prices?.smallMaterialCostRaw || null,
        solar: !!lead.solution!.isSolarSolution,
    };
};
