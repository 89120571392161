import styled from 'styled-components';
import { breakpoint } from '@common/components/Breakpoints';
import * as PageStyles from './Page';
import * as TypographyStyles from './Typography';
import { color, space } from '../common/Theme';

export const Title = styled(TypographyStyles.H3)`
    margin: 0;
    padding: 27px 0 33px;
    font-size: 25px;
    line-height: 35px;
    color: ${color.grey500};

    @media ${breakpoint.up('xl')} {
        color: ${color.blue300};
    }
`;

export const Navigation = styled.nav`
    position: relative;
    background-color: ${color.grey000};

    svg {
        color: ${color.blue300};
    }
`;

export const Header = styled(PageStyles.WideContainer)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
    background-color: ${color.grey100};

    @media ${breakpoint.up('xl')} {
        background-color: ${color.grey000};
    }
`;

export const SubHeader = styled.div`
    margin-bottom: ${space[7]}px;
    padding: 25px 0;
    background-color: ${color.grey000};
    border-top: 1px solid ${color.grey300};
    border-bottom: 1px solid ${color.grey300};
`;

export const SubHeaderTitle = styled(TypographyStyles.H4)`
    color: ${color.grey600};
`;
