import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import { UK_INCENTIVE_TYPES } from '@common/types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { MODAL_TYPE } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { openModal } from '../../../redux/actions';
import { getShowPrices } from '../../../redux/selectors';
import * as ButtonStyles from '../../../styles/components/Button';
import * as PreloaderStyles from '../../../styles/components/Preloader';
import * as PricingStyles from '../../../styles/components/Pricing';
import InfoTrigger from '../InfoTrigger';

type PropsType = {
    label: string;
    price?: string;
    isImportant?: boolean;
    isSmall?: boolean;
    note?: string | null;
    mb?: number;
    type?: UK_INCENTIVE_TYPES;
    link?: string;
    tooltip?: string;
    showPriceDetails?: boolean;
};

const PriceItem = (props: PropsType): JSX.Element => {
    const dispatch = useDispatch();
    const { type, label, link, price, tooltip, showPriceDetails } = props;
    const showLink = type
        ? [UK_INCENTIVE_TYPES.hes, UK_INCENTIVE_TYPES.nidirect].includes(type)
        : false;
    const showLoader = !price && !showLink;
    const showPrice = !showLink && price;

    const showPrices = useAppSelector(getShowPrices);

    const onOpenPriceDetails = () => {
        dispatch(openModal(MODAL_TYPE.priceDetails));
    };

    return (
        <PricingStyles.Line isImportant={!!props.isImportant} isSmall={!!props.isSmall}>
            <PricingStyles.Label>
                {label}
                {tooltip && <InfoTrigger label={tooltip} namepace="incentives" />}
            </PricingStyles.Label>
            {showLoader && (
                <PreloaderStyles.Preloader>
                    <Icons.Glyph.LoadingWheel />
                </PreloaderStyles.Preloader>
            )}
            {showLink && (
                <PricingStyles.Link href={link} target="_blank">
                    {i18n('more_info_incentives', true, 'incentives')}
                </PricingStyles.Link>
            )}
            {showPrice && <PricingStyles.Price>{props.price}</PricingStyles.Price>}
            {(props.note || showPriceDetails) && (
                <PricingStyles.Note mb={props.mb ?? 5}>
                    <PricingStyles.Line>
                        <small>{props.note}</small>

                        {showPriceDetails && showPrices && (
                            <ButtonStyles.Link onClick={onOpenPriceDetails}>
                                <small>{i18n('price_details')}</small>
                            </ButtonStyles.Link>
                        )}
                    </PricingStyles.Line>
                </PricingStyles.Note>
            )}
        </PricingStyles.Line>
    );
};

export default PriceItem;
