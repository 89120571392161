import { breakpoint } from '@common/components/Breakpoints';
import styled, { css } from 'styled-components';
import * as CardStyles from './Card';
import * as TypographyStyles from './Typography';
import { color, fontWeight, space } from '../common/Theme';

type BadgeProps = { isHighlighted: boolean };

export const Item = styled(CardStyles.Default)<BadgeProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-block-end: 30px;
    padding: 30px;
    border: 2px solid ${color.grey300};

    @media ${breakpoint.up('xl')} {
        padding: 30px;
        svg {
            height: fit-content !important;
            width: fit-content !important;
            color: ${color.blue300};
        }
    }

    ${({ isHighlighted }) =>
        isHighlighted &&
        css`
            border-color: ${color.blue300};
        `}
`;

export const Badge = styled.div<BadgeProps>`
    position: absolute;
    inset-block-start: -1rem;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    padding: 4px ${space[3]}px 6px;
    font-size: 12px;
    font-weight: ${fontWeight.bold};
    line-height: 18px;
    color: ${color.grey000};
    background-color: ${color.grey300};
    border-radius: 14px;
    white-space: nowrap;

    ${({ isHighlighted }) =>
        isHighlighted &&
        css`
            color: ${color.grey000};
            background-color: ${color.blue300};
        `}
`;

export const Radio = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    pointer-events: none;

    input[type='radio'] {
        position: relative;
        appearance: none;
        width: 1.25rem;
        height: 1.25rem;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid ${color.grey300};
        cursor: pointer;
    }

    input[type='radio']:checked {
        border: 3px solid dodgerblue;
    }
`;

export const Icon = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    > svg {
        width: 100%;
    }
`;

export const Content = styled.div`
    font-size: 1rem;
    color: ${color.grey500};

    ${TypographyStyles.H4} {
        max-width: 80%;
    }
`;
