import styled from 'styled-components';
import { color, fontWeight, space } from '../common/Theme';
import * as TypographyStyles from './Typography';

export const Closed = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: ${space[2]}px ${space[3]}px;
    cursor: pointer;

    svg {
        color: ${color.grey900};
    }
`;

export const Opened = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${color.grey500};
`;

export const Label = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${color.grey500};
`;

export const Value = styled.div`
    margin-left: auto;
    margin-right: ${space[2]}px;
    font-weight: ${fontWeight.semibold};
    color: ${color.grey900};
`;

export const Header = styled.div`
    width: 100%;
    padding: ${space[3]}px;
    border-bottom: 1px solid ${color.grey400};
`;

export const Title = styled(TypographyStyles.H4)`
    font-size: 16px;
    font-weight: ${fontWeight.semibold};
    line-height: 24px;
    color: ${color.blue400};
`;

export const Description = styled.div`
    font-size: 16px;
    color: ${color.grey900};
`;

export const Body = styled.div`
    padding: ${space[3]}px;
`;

export const Options = styled.div`
    padding-top: ${space[5]}px;
    padding-bottom: ${space[4]}px;
`;

export const Option = styled.label`
    display: block;
    margin-bottom: ${space[2]}px;
`;
