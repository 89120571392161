import styled, { css } from 'styled-components';
import { color, fontWeight, space } from '../common/Theme';
import * as TypographyStyles from './Typography';

export const Group = styled.div``;

type LineProps = {
    isImportant?: boolean;
    isSmall?: boolean;
};
export const Line = styled.div<LineProps>`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 24px;
    color: ${color.grey500};

    &:not(:last-child) {
        padding-bottom: ${space[1]}px;
    }

    ${({ isImportant }) =>
        isImportant &&
        css`
            font-weight: ${fontWeight.semibold};
            color: ${color.grey800};
        `};

    ${({ isSmall }) =>
        isSmall &&
        css`
            font-size: 13px;
            line-height: 20px;
        `};
`;

export const Label = styled.div``;

export const Price = styled.div``;

export const Link = styled.a`
    text-decoration: underline !important;
`;

export const Note = styled(TypographyStyles.Small)`
    width: 100%;
    font-weight: ${fontWeight.regular};
    color: ${color.grey600};
`;

export const PriceDetailsContent = styled.div`
    h3 {
        margin: 0 0 ${space[4]}px;
        font-size: 20px;
        font-weight: ${fontWeight.regular};
        line-height: 26px;
    }
    div {
        margin-bottom: 1rem;
    }
`;
