/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { createLogic } from 'redux-logic';
import { Store } from '../../../types';
import { APP_STATUS } from '../../../constants';
import {
    TOOL_SIZING_ATA_COOLING,
    setLeadRemote,
    InitSizingToolAtaCoolingAction,
    UpdateSizingToolAtaCoolingAction,
} from '../../actions';
import { getSizingPlatformJson, getSizingToolsJson } from '../../selectors';

type DepObj = { getState: () => Store };

type InitDepObj = DepObj & { action: InitSizingToolAtaCoolingAction };
const initSizingAtaCoolingLogic = createLogic({
    type: TOOL_SIZING_ATA_COOLING.init,
    name: 'sizing.ataCooling.init',
    validate({ getState, action }: InitDepObj, allow, reject) {
        const state = getState();
        if (state.lead.status === APP_STATUS.sp_sizing_tool) {
            window._rsnChannel.publish('sizing.cooling.init', {
                target: 'div#external-tool-sizing-ata-cooling',
                ctaTarget: 'div#cta-teleport-target',
                platform: getSizingPlatformJson(state),
                tools: getSizingToolsJson(state),
                step: state.lead.toolData?.sizingCoolingPage || null,
            });
            allow(action);
        } else {
            reject({ ...action, type: `🚫 (${action.type})` });
        }
    },
});

type UpdateDepObj = { action: UpdateSizingToolAtaCoolingAction };
const updateSizingAtaCoolingApiLogic = createLogic({
    type: TOOL_SIZING_ATA_COOLING.update,
    name: 'sizing.ataCooling.update',
    process({ action }: UpdateDepObj, dispatch, done) {
        dispatch(
            setLeadRemote(
                APP_STATUS.sp_sizing_tool,
                `${APP_STATUS.sp_sizing_tool}.${action.data.step}`,
            ),
        );
        done();
    },
});

const doneSizingAtaCoolingApiLogic = createLogic({
    type: TOOL_SIZING_ATA_COOLING.done,
    name: 'sizing.ataCooling.done',
    process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        if (state.lead.toolData!.sizingCooling!['no-solution']) {
            dispatch(setLeadRemote(APP_STATUS.dealer_selection));
        } else {
            dispatch(setLeadRemote(APP_STATUS.sp_quotation_tool));
        }
        done();
    },
});

export default [
    initSizingAtaCoolingLogic,
    updateSizingAtaCoolingApiLogic,
    doneSizingAtaCoolingApiLogic,
];
