import { breakpoint } from '@common/components/Breakpoints';
import styled, { css } from 'styled-components';
import { color, space } from '../common/Theme';
import * as CardStyles from './Card';
import * as TypographyStyles from './Typography';

export const Wrapper = styled.div.attrs((props: { isToolOpen: boolean }) => props)`
    margin-top: 20px;
    margin-bottom: 20px;

    ${({ isToolOpen }) =>
        isToolOpen &&
        css`
            @media ${breakpoint.down('lg')} {
                padding: 0px 20px;
            }
        `}
`;

export const Card = styled(CardStyles.Default)`
    @media ${breakpoint.up('lg')} {
        position: static;
        padding: ${space[5]}px ${space[5]}px;
        max-width: auto;
        border-radius: 0;
    }

    @media ${breakpoint.down('lg')} {
        max-width: 100vw;
        max-height: calc(100% - ${space[4]}px);
        box-shadow: 0 -4px 20px 0 rgba(0, 0, 0, 0.1);
    }

    @media ${breakpoint.up('md')} {
        bottom: ${space[4]}px;
        max-width: auto;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 0;
    }
`;

export const Epackages = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    @media ${breakpoint.down('lg')} {
        max-height: calc(100vh - 20px - 100px - 110px);
        overflow-y: auto;
    }

    ${TypographyStyles.H3} {
        color: ${color.grey700};
    }

    ${TypographyStyles.Small} {
        margin-block-start: ${space[5]}px;
        color: ${color.grey600};
    }
`;

export const Block = styled.div`
    display: flex;
    margin-top: 2em;
`;

export const Icon = styled.div.attrs((props: { url: string }) => props)`
    margin-right: 0.5rem;
    padding-right: 2.5rem;
    width: 1rem;

    ${({ url }) => css`
        background-image: url('${url}');
        background-size: 2rem;
        background-repeat: no-repeat;
    `}
`;

export const Info = styled.div`
    min-width: 0;

    ${TypographyStyles.H4} {
        margin-block-end: ${space[1]}px;
        color: ${color.blue300};
        font-size: 1rem;
        font-weight: 600;
    }
`;
