import styled, { css } from 'styled-components';
import { space as spacing, SpaceProps, display, DisplayProps } from 'styled-system';
import { color, space, fontFamily, fontWeight } from '../common/Theme';

type DefaultProps = SpaceProps & {
    isLarge?: boolean;
    isLoading?: boolean;
    isInline?: boolean;
};

export const Default = styled.button<DefaultProps & DisplayProps>`
    display: flex;
    padding: ${space[2]}px ${space[4]}px;
    width: 100%;
    font-size: 14px;
    font-family: ${fontFamily.base};
    line-height: 20px;
    font-weight: ${fontWeight.semibold};
    text-align: left;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    ${spacing};
    ${display};

    svg {
        margin-left: auto;
    }

    ${({ isLarge }) =>
        isLarge &&
        css`
            padding: ${space[3]}px ${space[4]}px;
        `};

    ${({ isInline }) =>
        isInline &&
        css`
            width: auto;

            svg {
                margin-left: ${space[4]}px;
            }
        `};
`;

export const Action = styled.button<SpaceProps>`
    padding: 0;
    font-family: ${fontFamily.base};
    background: transparent;
    border: none;
    cursor: pointer;
    ${spacing};
`;

export const Primary = styled(Default)`
    color: ${color.grey000};
    background-color: ${color.blue300};

    &[disabled] {
        background-color: ${color.grey400};
    }

    &:not([disabled]):hover {
        background-color: ${color.blue400};
    }
`;

export const PrimaryOutline = styled(Default)`
    color: ${color.blue300};
    background-color: transparent;
    border: 2px solid ${color.blue300};
`;

export const Secondary = styled(Action)`
    display: flex;
    font-size: 14px;
    font-weight: ${fontWeight.bold};
    text-transform: uppercase;
    line-height: 20px;
    color: ${color.grey900};

    svg {
        margin-right: ${space[2]}px;
        flex-shrink: 0;
    }
`;

export const Link = styled.a<SpaceProps>`
    display: flex;
    align-items: center;
    font-family: ${fontFamily.base};
    font-size: 16px;
    font-weight: ${fontWeight.semibold};
    line-height: 20px;
    color: ${color.grey500};
    background-color: transparent;
    border: none;
    ${spacing};

    svg {
        margin-right: ${space[2]}px;
        flex-shrink: 0;
    }

    &:hover {
        text-decoration: none;
        color: ${color.grey800};
        cursor: pointer;
    }
`;

export const PrimaryLink = styled(Link)`
    color: ${color.blue300};

    &:hover {
        color: ${color.blue400};
    }
`;

export const Label = styled.span<DisplayProps>`
    ${display};
`;

export const Icon = styled.span<SpaceProps>`
    display: flex;
    align-items: center;

    svg {
        ${spacing};
    }
    &:hover {
        cursor: pointer;
    }
`;
