import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { MODAL_TYPE } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { openModal } from '../../../redux/actions';
import { getRemainingDuration } from '../../../redux/selectors';
import * as ButtonStyles from '../../../styles/components/Button';

const ContactDealerButton: FC = () => {
    const dispatch = useDispatch();
    const remainingDuration = useAppSelector(getRemainingDuration);

    const onClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        dispatch(openModal(MODAL_TYPE.contactADealer, remainingDuration));
    };

    return (
        <ButtonStyles.Link mb={5} onClick={onClick}>
            <Icons.Glyph.User />
            {i18n('contact_a_dealer')}
        </ButtonStyles.Link>
    );
};

export default ContactDealerButton;
