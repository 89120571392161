import * as Logger from '@common/Logger';
import i18n from '@common/i18n';
import { createLogic } from 'redux-logic';
import Navigator, { isRewindingIntoTool } from '../../Navigator';
import { APP_STATUS, DETACHED_SCREEN_TYPE } from '../../constants';
import { Store } from '../../types';
import {
    APP_STATE_ACTIONS,
    NavigationRequestAction,
    SetLeadLocalAction,
    UserNavigationAction,
    closeDetachedScreen,
    openDetachedScreen,
    setLeadRemote,
} from '../actions';
import { LEAD_ACTIONS } from '../actions/leadActions';
import {
    getAreIncentivesAllowed,
    getCanUserToggleAltherma,
    getPojectType,
    getQuotationPlatformJson,
    getQuotationToolsJson,
    getQuotationUnitsJson,
    getSizingPlatformJson,
    getSizingToolsJson,
    isAirToAirMultiRoomLead,
} from '../selectors';

const logHistory = (msg: string): void => Logger.log(`[history] ${msg}`, Logger.STYLE.FILLER);
const logApiCall = (msg: string): void => Logger.log(`[API] ${msg}`, Logger.STYLE.API);

type DepObj = { getState: () => Store };
type NavRequestDepObj = DepObj & { action: NavigationRequestAction };

// Open the altherma-details screen at the appropriate time
type AlthermaOpenChoiceDepObj = DepObj & { action: UserNavigationAction };
const althermaOpenChoiceLogic = createLogic({
    type: APP_STATE_ACTIONS.userNavigation,
    name: 'althermaLogic.openChoice',
    transform({ getState, action }: AlthermaOpenChoiceDepObj, next) {
        const state = getState();
        const navigationPage =
            action.page === APP_STATUS.incentives_tool ||
            action.page === APP_STATUS.sp_quotation_choice;
        if (
            state.lead.status === APP_STATUS.sp_selsoft_result &&
            navigationPage &&
            getCanUserToggleAltherma(state)
        ) {
            // If we're navigating to incentives or quotation choice & altherma can be toggled, open the final selection overlay
            next(openDetachedScreen(DETACHED_SCREEN_TYPE.althermaChoice));
        } else {
            next(action);
        }
    },
});

// Navigate beyond AlthermaChoice after selecting one
type AlthermaChooseChoiceDepObj = DepObj & { action: SetLeadLocalAction };
const althermaChooseChoiceLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'althermaLogic.chooseChoice',
    process({ getState, action }: AlthermaChooseChoiceDepObj, dispatch, done) {
        if (action.logicOrigin === 'althermaChoiceLogic') {
            // Navigation request to P4/5
            const target = getAreIncentivesAllowed(getState())
                ? APP_STATUS.incentives_tool
                : APP_STATUS.dealer_selection;
            dispatch(setLeadRemote(target));
        }
        done();
    },
});

const getPostalNavEvent = (store: Store, page: string): string => {
    switch (page) {
        case APP_STATUS.sp_sizing_tool: {
            const projectType = getPojectType(store);
            return `sizing.${projectType}.navigate`;
        }
        case APP_STATUS.sp_quotation_tool:
            return 'quotation.navigate';
        default:
            return '';
    }
};

// This is triggered when the user clicks the browsers back/forward-buttons

const aemRedirectBackLogic = createLogic({
    type: APP_STATE_ACTIONS.navigationRequest,
    name: 'historyLogic.aemRedirectBackLogic',
    process({ getState, action }: NavRequestDepObj, dispatch, done) {
        const screens = getState().appState.openDetachedScreens;
        if (action.page === APP_STATUS.sft_ra_positioning_type) {
            if (screens.find((s) => s.type === DETACHED_SCREEN_TYPE.aemRedirectCCU)) {
                dispatch(closeDetachedScreen(DETACHED_SCREEN_TYPE.aemRedirectCCU));
            }
        }
        done();
    },
});

const confirmNavigationRequestLogic = createLogic({
    type: APP_STATE_ACTIONS.navigationRequest,
    name: 'historyLogic.confirmNavigationRequest',
    validate({ getState, action }: NavRequestDepObj, allow, reject) {
        const [page] = action.page.split('.');
        const state = getState();
        // Only for ata multi room leads
        if (!isAirToAirMultiRoomLead(state)) return allow(action);
        // Only for when navigating back
        if (page === state.lead.status) return allow(action);
        /* For multiRoom leads when on selsoft result page go back to start of sizing tool,
        Quotation tool has no user interaction */
        if (
            state.lead.status === APP_STATUS.sp_selsoft_result &&
            isRewindingIntoTool(state.lead.status, page, APP_STATUS.sp_sizing_tool)
        ) {
            // eslint-disable-next-line no-alert
            if (window.confirm(i18n('confirm_back'))) {
                logApiCall(`Redirect to sizing tool`);
                return allow(action);
            } else {
                // Cancel the alert-confirmation and add the page back to the browser history
                Navigator.setLeadStatus(APP_STATUS.sp_selsoft_result);
                return reject({ ...action, type: `🚫 (${action.type}) - user canceled redirect` });
            }
        } else {
            return allow(action);
        }
    },
});

const navigationRequestLogic = createLogic({
    type: APP_STATE_ACTIONS.navigationRequest,
    name: 'historyLogic.navigationRequest',
    process({ getState, action }: NavRequestDepObj, dispatch, done) {
        const [page, toolPage] = action.page.split('.');
        const state = getState();
        if (page !== state.lead.status) {
            logApiCall(`Transition via navigation-request API-call to: ${action.page}`);
            dispatch(setLeadRemote(page as APP_STATUS));
        } else if (toolPage) {
            logHistory(`Notify subtool of transition: ${toolPage} (${page})`);
            const payload: Record<string, unknown> = { step: toolPage };
            switch (page) {
                case APP_STATUS.sp_sizing_tool:
                    payload.platform = getSizingPlatformJson(state);
                    payload.tools = getSizingToolsJson(state);
                    break;
                case APP_STATUS.sp_quotation_tool:
                    payload.platform = getQuotationPlatformJson(state);
                    payload.tools = getQuotationToolsJson(state);
                    payload.solution = getQuotationUnitsJson(state);
                    break;
                default:
                    break;
            }
            window._rsnChannel.publish(getPostalNavEvent(state, page), payload);
        }
        done();
    },
});

type UserNavigationDepObj = DepObj & { action: UserNavigationAction };
const userNavigationLogic = createLogic({
    type: APP_STATE_ACTIONS.userNavigation,
    name: 'historyLogic.userNavigation',
    process({ action }: UserNavigationDepObj, _, done) {
        Navigator.navigate(action.page);
        done();
    },
});

export default [
    althermaOpenChoiceLogic,
    althermaChooseChoiceLogic,
    aemRedirectBackLogic,
    confirmNavigationRequestLogic,
    navigationRequestLogic,
    userNavigationLogic,
];
