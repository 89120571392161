import React, { FC } from 'react';
import { MODAL_TYPE } from '../../../constants';
import Modal from './Modal';
import * as TypographyStyles from '../../../styles/components/Typography';
import * as ImageStyles from '../../../styles/components/Image';
import * as VideoStyles from '../../../styles/components/Video';

type PropsType = {
    metaData: unknown;
};

type CastedPropsType = {
    text: string;
    images: string[];
    video: string | null;
};

const IntrusiveModal: FC<PropsType> = (props) => {
    const { text, images, video } = props.metaData as CastedPropsType;

    return (
        <Modal type={MODAL_TYPE.intrusive}>
            <div>
                <TypographyStyles.DefaultText mb={4} pr={5}>
                    {text}
                </TypographyStyles.DefaultText>
                {images &&
                    images.map((image: string) => (
                        <ImageStyles.Fluid key={image} aria-label={image} src={image} />
                    ))}
                {video && (
                    <VideoStyles.Wrapper>
                        <VideoStyles.Player
                            url={video}
                            volume={1}
                            controls
                            playing
                            light
                            width="100%"
                            height="100%"
                        />
                    </VideoStyles.Wrapper>
                )}
            </div>
        </Modal>
    );
};

export default IntrusiveModal;
