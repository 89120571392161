/* eslint-disable operator-linebreak */
import i18n from '@common/i18n';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { APP_STATUS } from '../../constants';
import { Lead, Store } from '../../types';
import { LEAD_ACTIONS, setCustomerDetailsErrors, setLeadLocal } from '../actions';
import {
    CustomerAddressFormPayload,
    CustomerPersonalFormPayload,
    getCustomerAddressFormPayload,
    getCustomerPersonalFormPayload,
} from '../selectors';

type DepObj = {
    getState: () => Store;
};

type SubmitResponseType = {
    lead: Partial<Lead>;
    success: boolean;
};
// Actually does the API request to submit the lead
const submitCustomerDetailsLogic = createLogic({
    type: LEAD_ACTIONS.submitCustomerDetails,
    name: 'submitCustomerDetailsLogic',
    process({ getState }: DepObj, dispatch, done) {
        const {
            lead: { status },
            settings: { urls },
        } = getState();
        let payload: CustomerPersonalFormPayload | CustomerAddressFormPayload | null = null;
        let url: string | null = null;
        switch (status) {
            case APP_STATUS.dealer_selection:
                payload = getCustomerPersonalFormPayload(getState());
                url = urls.dealerSelection;
                break;
            case APP_STATUS.dealer_selection_address_information:
                payload = getCustomerAddressFormPayload(getState());
                url = urls.dealerSelectionCustomerAddress;
                break;
            default:
                return done();
        }
        axios
            .post<SubmitResponseType>(url, payload)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    // Update reducer w/ new lead-data
                    dispatch(
                        setLeadLocal(
                            data.lead,
                            false,
                            'submitCustomerDetailsLogic',
                            LEAD_ACTIONS.submitCustomerDetails,
                        ),
                    );
                } else {
                    if (status > 299) {
                        const error = new Error(
                            `${status} error: Error with customer details when submitting rsn`,
                        );
                        error.name = 'Error submitting rsn';
                        Sentry.captureException(error);
                    }
                    dispatch(setCustomerDetailsErrors({ global: 'unknown' }));
                }
            })
            .catch((err) => {
                const errors = err.response?.data?.errors;
                err.name = 'Error submitting rsn';
                Sentry.captureException(err);
                if (errors && Object.keys(errors).length > 0) {
                    const errorsObj: Record<string, string> = {};
                    Object.entries(errors).forEach(([errKey, errorArray]) => {
                        const translatedErrors = (errorArray as Array<string>).map((error) => {
                            return i18n(error, true, 'validators');
                        });
                        errorsObj[errKey] = translatedErrors.join(' ');
                    });
                    dispatch(setCustomerDetailsErrors(errorsObj));
                }
            })
            .finally(() => {
                done();
            });
    },
});

export default [submitCustomerDetailsLogic];
