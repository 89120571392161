/* eslint-disable operator-linebreak */
import i18n from '@common/i18n';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { gigyaRegister } from '../../hooks';
import { Lead, Store } from '../../types';
import {
    APP_STATE_ACTIONS,
    GIGYA_ACTIONS,
    LEAD_ACTIONS,
    SetLeadLocalAction,
    gigyaRegistrationFlowCompleted,
    setCustomerDetailsErrors,
    setLeadLocal,
    submitRsnRequest,
} from '../actions';
import { getSubmitRsnRequestPayload } from '../selectors';

type DepObj = {
    getState: () => Store;
};

const submitPressedLogic = createLogic({
    type: APP_STATE_ACTIONS.submitPressed,
    name: 'submitPressed',
    warnTimeout: 0, // Wait for user registration
    process({ getState }: DepObj, dispatch, done) {
        const store = getState();
        const { gigyaUser, gigyaUid } = store.appState;
        if (gigyaUser || gigyaUid) {
            // User already logged in, submit
            dispatch(submitRsnRequest());
            done();
        } else {
            // No login yet, prompt the registration screen
            // Fetch value from globalState
            const lang = store.settings.language.toLowerCase();
            gigyaRegister(lang, (complete: boolean) => {
                // If registration or login was completed, notify redux
                if (complete) dispatch(gigyaRegistrationFlowCompleted());
                done();
            });
        }
    },
});

type SubmitAfterLoginDepObj = DepObj & { action: SetLeadLocalAction };
const submitAfterLoginLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'submitAfterLoginLogic',
    process({ getState, action }: SubmitAfterLoginDepObj, dispatch, done) {
        // If setLeadLocal originated from a linkLead & submit previously pressed -> submit
        if (
            action.logicOrigin === 'linkLeadToUserLogic' &&
            getState().appState.submitPressedTime &&
            getState().appState.registrationCompleteTime
        ) {
            dispatch(submitRsnRequest());
        }
        done();
    },
});

// Registration flow was completed
const submitAfterRegisterLogic = createLogic({
    type: GIGYA_ACTIONS.registrationFlowCompleted,
    name: 'submitAfterRegisterLogic',
    async process({ getState }: DepObj, dispatch, done) {
        const { gigyaUser, gigyaUid } = getState().appState;
        if (gigyaUser || gigyaUid) {
            // User already logged in, submit
            dispatch(submitRsnRequest());
            done();
        }
    },
});

type SubmitResponseType = {
    lead: Partial<Lead>;
    success: boolean;
};
// Actually does the API request to submit the lead
const submitApiLogic = createLogic({
    type: LEAD_ACTIONS.submitRsnRequest,
    name: 'submitApiLogic',
    async process({ getState }: DepObj, dispatch, done) {
        const payload = await getSubmitRsnRequestPayload(getState());
        axios
            .post<SubmitResponseType>(getState().settings.urls.dealerSelection, payload)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    // Update reducer w/ new lead-data
                    dispatch(
                        setLeadLocal(
                            data.lead,
                            false,
                            'submitApiLogic',
                            LEAD_ACTIONS.submitRsnRequest,
                        ),
                    );
                } else {
                    if (status > 299) {
                        const error = new Error(
                            `${status} error: Error with customer details when submitting rsn`,
                        );
                        error.name = 'Error submitting rsn';
                        Sentry.captureException(error);
                    }
                    dispatch(setCustomerDetailsErrors({ global: 'unknown' }));
                }
            })
            .catch((err) => {
                const errors = err.response?.data?.errors;
                err.name = 'Error submitting rsn';
                Sentry.captureException(err);
                if (errors && Object.keys(errors).length > 0) {
                    const errorsObj: Record<string, string> = {};
                    Object.entries(errors).forEach(([errKey, errorArray]) => {
                        const translatedErrors = (errorArray as Array<string>).map((error) => {
                            return i18n(error, true, 'validators');
                        });
                        errorsObj[errKey] = translatedErrors.join(' ');
                    });
                    dispatch(setCustomerDetailsErrors(errorsObj));
                }
            })
            .finally(() => {
                done();
            });
    },
});

export default [
    submitPressedLogic,
    submitAfterLoginLogic,
    submitAfterRegisterLogic,
    submitApiLogic,
];
