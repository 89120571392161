////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ARRAY MANIPULATION //////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Copy-pasta from https://medium.com/@fsufitch/is-javascript-array-sort-stable-46b90822543f
// Array.sort is unstable when sorting equal values in Chrome
/* eslint-disable */
Array.prototype.stableSort = function (cmp) {
    cmp = !!cmp ? cmp : (a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    };
    const stabilizedThis = this.map((el, index) => [el, index]);
    const stableCmp = (a, b) => {
        const order = cmp(a[0], b[0]);
        if (order != 0) return order;
        return a[1] - b[1];
    };
    stabilizedThis.sort(stableCmp);
    for (let i = 0; i < this.length; i++) {
        this[i] = stabilizedThis[i][0];
    }
    return this;
};
/* eslint-enable */

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LODASH-Y UTILS //////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const debounceForcer = Symbol('force');

// Debounce a method by a certain amount of milliseconds.
export const debounce = (func, wait, cancellable = false) => {
    let timeout = null;
    const cancel = () => {
        clearTimeout(timeout);
        timeout = null;
    };
    const call = (firstArg, ...args) => {
        cancel();
        if (firstArg === debounceForcer) {
            func.apply(this, args);
        } else {
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(this, [firstArg, ...args]);
            }, wait);
        }
    };
    return cancellable ? { call, cancel } : call;
};

// Cfr.: https://github.com/facebook/react/issues/5465#issuecomment-157888325
export const makePromiseCancelable = (promise) => {
    let hasCanceled_ = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val));
        promise.catch(error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error));
    });
    wrappedPromise.cancel = () => hasCanceled_ = true;
    return wrappedPromise;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BROWSER UTILITIES ///////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const scrollTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

export const submitHiddenForm = (url, data) => {
    // This method creates a filled in text-input
    const createFormInput = ([key, value]) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'text');
        input.setAttribute('name', key);
        input.setAttribute('value', JSON.stringify(value));
        return input;
    };
    // Create a hidden POST-form
    const form = document.createElement('form');
    form.setAttribute('action', url);
    form.setAttribute('method', 'POST');
    form.setAttribute('hidden', 'true');
    // Create a form-field for each data-key and append it to the form
    Object.entries(data).map(createFormInput).forEach(input => form.appendChild(input));
    // Append the form to the body and submit it
    document.body.appendChild(form);
    form.submit();
};

export const preventDefault = (cb, stopPropagation = false) => {
    return (event, ...others) => {
        event.preventDefault();
        if (stopPropagation) event.stopPropagation();
        cb(event, ...others);
    };
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// GEO UTILITIES ///////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const ISO_3166_EXCEPTIONS = { GB: 'UK' };
export const countryCodeToISO3166 = (code) => { return ISO_3166_EXCEPTIONS[code] || code; };

// Create a unique id
export const uuid = () => {
    let d = new Date().getTime();
    if (typeof window !== 'undefined' && window.performance && window.performance.now) d += performance.now();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + (Math.random() * 16)) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};
