import i18n from '@common/i18n';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'usehooks-ts';
import { APP_STATUS } from '../../../../constants';
import { useAppSelector } from '../../../../redux';
import { setManualIncentives, userNavigation } from '../../../../redux/actions';
import { color, fontWeight } from '../../../../styles/common/Theme';
import * as ButtonStyles from '../../../../styles/components/Button';
import * as FormStyles from '../../../../styles/components/Form';
import { AppStatus, EvsSolution } from '../../../../types';

const incentiveStyles = { color: color.green400, fontWeight: fontWeight.semibold };

type IncentivePropsType = {
    status: AppStatus;
    daikinSolution: EvsSolution;
    showIncentivesInput: boolean;
};

const IncentiveDaikinSolution = ({
    status,
    daikinSolution,
    showIncentivesInput,
}: IncentivePropsType): JSX.Element => {
    const dispatch = useDispatch();
    const { incentive: daikinSolutionIncentive, incentiveRaw } = daikinSolution;
    const [incentive, setIncentive] = useState(incentiveRaw || '');
    const country = useAppSelector((store) => store.settings.country);
    const { currency, isCurrencyBeforeAmount, decimalSeparator } = country;

    // Update redux after a 250ms delay
    const debouncedIncentive = useDebounce(incentive, 750);
    useEffect(() => {
        // Ony set manual incentive if the value is different then the value before
        if (debouncedIncentive && debouncedIncentive !== incentiveRaw) {
            dispatch(setManualIncentives(Number(debouncedIncentive)));
        }
    }, [debouncedIncentive]);

    if (showIncentivesInput) {
        return (
            <FormStyles.InputGroup isTextBefore={isCurrencyBeforeAmount}>
                <FormStyles.Input
                    isTextBefore={isCurrencyBeforeAmount}
                    isSmall
                    type="number"
                    value={incentive}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        setIncentive(e.currentTarget.value);
                    }}
                    step={0.01}
                    min={0}
                    placeholder={`1234${decimalSeparator}56`}
                />
                <FormStyles.InputText isSmall isTextBefore={isCurrencyBeforeAmount}>
                    {currency}
                </FormStyles.InputText>
            </FormStyles.InputGroup>
        );
    } else if (status === APP_STATUS.incentives_tool && daikinSolutionIncentive) {
        return <div style={incentiveStyles}>{daikinSolutionIncentive}</div>;
    } else {
        return (
            <div>
                <ButtonStyles.Link
                    onClick={() => dispatch(userNavigation(APP_STATUS.incentives_tool))}
                >
                    {i18n('calculate_incentives')}
                </ButtonStyles.Link>
            </div>
        );
    }
};

export default IncentiveDaikinSolution;
