import { CarouselImages } from '@common/types';
import React from 'react';
import { MODAL_TYPE } from '../../../constants';
import ImageCarousel from '../solution/ImageCarousel';
import Modal from './Modal';

/* --- <ImageCarouselModal/> ------------------------------------------------------------------- */

type PropsType = {
    metaData: unknown;
};

type CastedPropsType = {
    carousel: CarouselImages;
    current: number;
};

const ImageCarouselModal = (props: PropsType): JSX.Element => {
    const { carousel, current } = props.metaData as CastedPropsType;
    return (
        <Modal type={MODAL_TYPE.imageGallery}>
            <ImageCarousel carousel={carousel} startIndex={current} isLarge />
        </Modal>
    );
};

/* --- Exports --------------------------------------------------------------------------------- */

export default ImageCarouselModal;
