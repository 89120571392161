import styled, { css } from 'styled-components';
import { space as spacing, SpaceProps } from 'styled-system';
import { color, fontWeight, space } from '../common/Theme';

export const Toggle = styled.div<SpaceProps>`
    position: relative;
    display: flex;
    justify-content: stretch;
    padding: ${space[1]}px;
    background-color: ${color.grey300};
    border-radius: 3px;
    z-index: 110;
    ${spacing};
`;

type ToggleItemFlags = { isActive?: boolean };
export const ToggleItem = styled.button<ToggleItemFlags>`
    padding: ${space[2]}px ${space[3]}px;
    width: 100%;
    font-weight: ${fontWeight.semibold};
    color: ${color.grey600};
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover {
        background-color: ${color.grey200};
    }

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${color.grey000};
            border-radius: 3px;

            &:hover {
                cursor: default;
                background-color: ${color.grey000};
            }
        `};
`;
