/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
/* eslint-disable indent */
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import { DEVICE_TYPE, UK_INCENTIVE_TYPES } from '@common/types';
import { camelToSnake, transpose } from '@tsUtils';
import React, { useRef } from 'react';
import { APP_STATUS } from '../../../../../constants';
import { useAppSelector } from '../../../../../redux';
import { getPriceTitle, getShowIncentivesRowEvsAtaHeating } from '../../../../../redux/selectors';
import { color } from '../../../../../styles/common/Theme';
import * as ButtonStyles from '../../../../../styles/components/Button';
import * as EvsStyles from '../../../../../styles/components/Evs';
import * as TypographyStyles from '../../../../../styles/components/Typography';
import { EvsAtaHeatingPriceDataDaikinSol, EvsSolution } from '../../../../../types';
import Cell from '../Cell';
import IncentiveDaikinSolution from '../IncentiveDaikinSolution';

type PropsType = {
    itemIndex: number;
    deviceType: DEVICE_TYPE;
    daikinSolution: EvsAtaHeatingPriceDataDaikinSol;
    boilerSolution: EvsSolution;
};
const EvsAtaHeatingPriceCompare = ({
    itemIndex,
    deviceType,
    daikinSolution,
    boilerSolution,
}: PropsType): JSX.Element => {
    const status = useAppSelector((store) => store.lead.status);
    const incentiveParams = useAppSelector(
        (store) => store.lead.incentives?.incentives?.[0]?.parameters,
    );

    const daikinRunningCostRef = useRef<HTMLDivElement>(null);

    const priceTitleRow = useAppSelector(getPriceTitle);
    const showIncentivesRow = useAppSelector(getShowIncentivesRowEvsAtaHeating);

    const showIncentivesInput =
        incentiveParams?.type === UK_INCENTIVE_TYPES.hes ||
        incentiveParams?.type === UK_INCENTIVE_TYPES.nidirect;

    const isDesktop = deviceType === DEVICE_TYPE.desktop;
    const isMobile = deviceType === DEVICE_TYPE.mobile;
    const textAlign = isDesktop ? 'right' : 'left';
    const showInlineHeaders = !isDesktop;

    const titleColumn = [
        <Cell key="name" isTitleColumn isFirstCell />,
        <Cell key="price" isBold textAlign="left" isTitleColumn>
            {i18n(camelToSnake(priceTitleRow))}
        </Cell>,
        <Cell key="running-cost" isBold textAlign="left" isTitleColumn>
            {i18n('running_cost')}
            <EvsStyles.TextGroup renderSideBySide={false}>
                <TypographyStyles.SmallText>
                    {i18n('heatpump_running_cost')}
                </TypographyStyles.SmallText>
            </EvsStyles.TextGroup>
            <EvsStyles.TextGroup renderSideBySide={false}>
                <TypographyStyles.SmallText>{i18n('boiler')}</TypographyStyles.SmallText>
            </EvsStyles.TextGroup>
        </Cell>,
        <Cell key="incentives" isBold textAlign="left" show={showIncentivesRow} isTitleColumn>
            <EvsStyles.TextGroup renderSideBySide={showIncentivesInput}>
                {i18n('incentives')}
                {showIncentivesInput && (
                    <ButtonStyles.Link
                        style={{ width: 'max-content' }}
                        href={incentiveParams?.link as string}
                        target="_blank"
                    >
                        &nbsp;
                        {i18n('more_info')}
                    </ButtonStyles.Link>
                )}
            </EvsStyles.TextGroup>
        </Cell>,
        <Cell key="total-lifecycle-cost" isBold textAlign="left" isTitleColumn>
            <div dangerouslySetInnerHTML={{ __html: i18n('total_lifecycle_cost') }} />
        </Cell>,
        <Cell key="cheaper-expensive" isTitleColumn isLastCell />,
    ];

    // Daikin solution
    const daikinColumn = [
        <Cell
            key="daikin-sol-name"
            isBold
            isDaikin
            isFirstCell
            textAlign="left"
            color={color.blue400}
        >
            {i18n('daikin_solution')}
        </Cell>,
        <Cell key={`daikin-sol-${camelToSnake(priceTitleRow)}`} isDaikin textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong>{i18n(camelToSnake(priceTitleRow))}</strong>
                </EvsStyles.InlineHeader>
            )}
            <div>{daikinSolution[priceTitleRow]}</div>
        </Cell>,
        <Cell key="daikin-sol-running-cost" isDaikin textAlign={textAlign} verticalAlign="bottom">
            <div ref={daikinRunningCostRef}>
                {showInlineHeaders && (
                    <EvsStyles.InlineHeader>
                        <strong>{i18n('running_cost')}</strong>
                    </EvsStyles.InlineHeader>
                )}
                {showInlineHeaders && (
                    <TypographyStyles.SmallText>
                        {i18n('heatpump_running_cost')}
                    </TypographyStyles.SmallText>
                )}
                <EvsStyles.TextGroup renderSideBySide>
                    <TypographyStyles.SmallText className="text-left">
                        {daikinSolution.heatPumpRunningCostPerYear}/{i18n('year')}
                    </TypographyStyles.SmallText>
                    <div className="text-right">{daikinSolution.totalHeatPumpRunningCost}</div>
                </EvsStyles.TextGroup>
                {showInlineHeaders && (
                    <TypographyStyles.SmallText>{i18n('boiler')}</TypographyStyles.SmallText>
                )}
                <EvsStyles.TextGroup renderSideBySide>
                    <TypographyStyles.SmallText className="text-left">
                        {daikinSolution.boilerRunningCostPerYear}/{i18n('year')}
                    </TypographyStyles.SmallText>
                    <div className="text-right">{daikinSolution.totalBoilerRunningCost}</div>
                </EvsStyles.TextGroup>
            </div>
        </Cell>,
        <Cell
            key="daikin-sol-incentives"
            show={showIncentivesRow}
            bgColor={
                status === APP_STATUS.incentives_tool && daikinSolution.incentive
                    ? color.green100
                    : ''
            }
            color={
                status === APP_STATUS.incentives_tool && daikinSolution.incentive
                    ? color.green300
                    : ''
            }
            textAlign={textAlign}
            isDaikin
        >
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong>{i18n('incentives')}</strong>
                </EvsStyles.InlineHeader>
            )}
            <IncentiveDaikinSolution
                status={status}
                daikinSolution={daikinSolution}
                showIncentivesInput={showIncentivesInput}
            />
        </Cell>,
        <Cell key="daikin-sol-total-lifecycle-cost" isDaikin isBold textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong dangerouslySetInnerHTML={{ __html: i18n('total_lifecycle_cost') }} />
                </EvsStyles.InlineHeader>
            )}
            {daikinSolution.totalLifecycleCost}
        </Cell>,
        <Cell
            key="daikin-sol-cheaper-after"
            bgColor={color.blue100}
            isDaikin
            isLastCell
            color={color.blue400}
            textAlign="left"
        >
            <EvsStyles.TextGroup renderSideBySide>
                {!daikinSolution.hasLongReturnInvestment && <Icons.Glyph.ThumbsUp size={40} />}
                <div
                    dangerouslySetInnerHTML={{
                        __html: daikinSolution.hasLongReturnInvestment
                            ? i18n('cheaper_after_10_years')
                            : i18n('cheaper_after_n_years').replace(
                                  '%d',
                                  String(daikinSolution.cheaperAfter),
                              ),
                    }}
                />
            </EvsStyles.TextGroup>
        </Cell>,
    ];

    // benchmark solution (boiler solution)
    const benchmarkColumn = [
        <Cell key="boiler-sol-name" isBold textAlign="left">
            {i18n('boiler_solution')}
        </Cell>,
        <Cell key={`boiler-sol-${camelToSnake(priceTitleRow)}`} textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong>{i18n(camelToSnake(priceTitleRow))}</strong>
                </EvsStyles.InlineHeader>
            )}
            <div>
                {boilerSolution[priceTitleRow]}&nbsp;{i18n('existing_boiler')}
            </div>
        </Cell>,
        <Cell
            key="boiler-sol-running-cost"
            textAlign={textAlign}
            verticalAlign={isDesktop ? 'bottom' : 'top'}
            bgColor={boilerSolution.isBenchmarkRunningCostHigher ? color.red100 : ''}
        >
            <EvsStyles.BoilerRunningCost
                height={
                    !isMobile && daikinRunningCostRef?.current
                        ? `${daikinRunningCostRef.current.offsetHeight}px`
                        : 'inherrit'
                }
                showInlineHeaders={showInlineHeaders}
            >
                {showInlineHeaders && (
                    <EvsStyles.InlineHeader
                        textColor={
                            !isDesktop && boilerSolution.isBenchmarkRunningCostHigher
                                ? color.red300
                                : ''
                        }
                    >
                        <strong>{i18n('running_cost')}</strong>
                    </EvsStyles.InlineHeader>
                )}
                <EvsStyles.TextGroup
                    renderSideBySide
                    textColor={boilerSolution.isBenchmarkRunningCostHigher ? color.red300 : ''}
                >
                    <TypographyStyles.SmallText className="text-left">
                        {boilerSolution.runningCostPerYear}/{i18n('year')}
                    </TypographyStyles.SmallText>
                    <div className="text-right">{boilerSolution.totalRunningCost}</div>
                </EvsStyles.TextGroup>
            </EvsStyles.BoilerRunningCost>
        </Cell>,
        <Cell
            key="boiler-sol-incentives"
            show={showIncentivesRow}
            color={color.grey400}
            textAlign={textAlign}
        >
            <div>{i18n('no_incentives')}</div>
        </Cell>,
        <Cell key="boiler-sol-total-lifecycle-cost" isBold textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong dangerouslySetInnerHTML={{ __html: i18n('total_lifecycle_cost') }} />
                </EvsStyles.InlineHeader>
            )}
            {boilerSolution.totalLifecycleCost}
        </Cell>,
        <Cell key="boiler-sol-percentage-more-expensive" color={color.grey400} textAlign="center">
            <EvsStyles.TextGroup renderSideBySide>
                {boilerSolution.percentageMoreExpensive !== null &&
                    boilerSolution.percentageMoreExpensive >= 0 && (
                        <>
                            <Icons.Glyph.ThumbsDown size={40} />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: i18n('more_expensive').replace(
                                        '%d',
                                        String(boilerSolution.percentageMoreExpensive),
                                    ),
                                }}
                            />
                        </>
                    )}
            </EvsStyles.TextGroup>
        </Cell>,
    ];

    const getVisibleSolutions = (): JSX.Element[][] => {
        switch (deviceType) {
            case DEVICE_TYPE.mobile:
                return [[daikinColumn, benchmarkColumn][itemIndex]];
            case DEVICE_TYPE.tablet:
                return [daikinColumn, benchmarkColumn];
            case DEVICE_TYPE.desktop:
                return [titleColumn, daikinColumn, benchmarkColumn];
            default:
                return [];
        }
    };
    const transposedArray: JSX.Element[][] = transpose(getVisibleSolutions());

    return (
        <EvsStyles.Table>
            <tbody>
                {transposedArray.map((cellArr, rowIndex) => (
                    <tr key={`row-${rowIndex}`}>{cellArr}</tr>
                ))}
            </tbody>
        </EvsStyles.Table>
    );
};

export default EvsAtaHeatingPriceCompare;
