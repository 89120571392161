/* eslint-disable comma-dangle */
import { createLogic } from 'redux-logic';
import {
    APP_STATUS,
    appStatusEventLabels,
    DETACHED_SCREEN_TYPE,
    eventActions,
    INCENTIVES_STATUS,
} from '../../constants';
import { AirToAirLead, Store } from '../../types';
import { getStatusSection } from '../../utils';
import {
    APP_STATE_ACTIONS,
    DoneIncentivesDEActionType,
    INCENTIVES_DE,
    INCENTIVES_DRAPO,
    InitIncentivesDEActionType,
    LEAD_ACTIONS,
    OpenDetachedScreenAction,
    TOOL_QUOTATION_ATA,
    TOOL_QUOTATION_ATW,
    TOOL_SIZING_ATA_COOLING,
    TOOL_SIZING_ATA_HEATING,
    TOOL_SIZING_ATW,
    TRACKING_ACTIONS,
    UpdateIncentivesDEActionType,
    UpdateQuotationToolAtaAction,
    UpdateQuotationToolAtwAction,
    UpdateSizingToolAtaCoolingAction,
    UpdateSizingToolAtwAction,
    UserNavigationAction,
} from '../actions';
import {
    getAreIncentivesAllowed,
    getCurrentSection,
    isAirToAirHeatingLead,
    isAirToWaterLead,
} from '../selectors';

type DepObj = { getState: () => Store };

type AaltraDepObj = DepObj & {
    action:
        | UpdateSizingToolAtwAction
        | UpdateSizingToolAtaCoolingAction
        | UpdateQuotationToolAtaAction
        | UpdateQuotationToolAtwAction;
};

type ActionDepObj = DepObj & { action: UserNavigationAction };

type IncentivesDEDepObj = DepObj & {
    action: InitIncentivesDEActionType | UpdateIncentivesDEActionType | DoneIncentivesDEActionType;
};

const trackingLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'trackingLogic.daikin',
    process({ getState }: DepObj, _dispatch, done) {
        const store = getState();
        const { status } = store.lead;
        const airToAirType = (store.lead as AirToAirLead).airToAirType;

        if (!window.dataLayer) return done();
        if (status === APP_STATUS.sp_sizing_tool || status === APP_STATUS.sp_quotation_tool) {
            return done();
        }
        if (store.lead.country === 'DE' && status === APP_STATUS.incentives_tool) return done();

        const isAirToWater = isAirToWaterLead(store.lead);
        const isAtaHeatingLead = isAirToAirHeatingLead(store);
        const currentSection = getCurrentSection(store);

        const hasIncentives = getAreIncentivesAllowed(store);
        const incentivesStatus = store.lead.incentives?.status;
        const isFR = store.lead.country === 'FR';
        const isInProgress = incentivesStatus === INCENTIVES_STATUS.in_progress;
        const ataTypeEventCategory = isAtaHeatingLead ? 'rsn_airco_winter' : 'rsn_airco_summer';
        const ataEventCategory = airToAirType ? ataTypeEventCategory : 'rsn_airco';

        const getLabel = (): string => {
            if (status === APP_STATUS.sp_selsoft_result && !hasIncentives)
                return 'configure_quotation_screen';
            if (status === APP_STATUS.incentives_tool && isFR && isInProgress)
                return 'calculate_incentives_screen';
            return appStatusEventLabels[status];
        };

        window.dataLayer.push({
            event: 'rsnEvent',
            eventCategory: isAirToWater ? 'rsn_heating' : ataEventCategory,
            eventAction: `configuration_${eventActions[currentSection]}`,
            eventLabel: getLabel(),
        });
        return done();
    },
});

let previousStep = '';
const aaltraTrackingLogic = createLogic({
    type: [
        TOOL_SIZING_ATA_COOLING.update,
        TOOL_SIZING_ATA_HEATING.update,
        TOOL_SIZING_ATW.update,
        TOOL_QUOTATION_ATA.update,
        TOOL_QUOTATION_ATW.update,
    ],
    name: 'trackingLogic.aaltra',
    process({ getState, action }: AaltraDepObj, _dispatch, done) {
        const store = getState();
        const airToAirType = (store.lead as AirToAirLead).airToAirType;
        const step = action.data.step;
        // Ignore the result step
        if (step === 'result') return done();

        const isAirToWater = isAirToWaterLead(store.lead);
        const currentSection = getCurrentSection(store);
        const isAtaHeatingLead = isAirToAirHeatingLead(store);

        const ataTypeEventCategory = isAtaHeatingLead ? 'rsn_airco_winter' : 'rsn_airco_summer';
        const ataEventCategory = airToAirType ? ataTypeEventCategory : 'rsn_airco';

        if (window.dataLayer) {
            const getLabel = (): string => {
                if (step === 'generator-type' && isAtaHeatingLead) {
                    return appStatusEventLabels[`heating-${step}`];
                }
                return appStatusEventLabels[step];
            };
            if (step !== previousStep) {
                previousStep = step;
                window.dataLayer.push({
                    event: 'rsnEvent',
                    eventCategory: isAirToWater ? 'rsn_heating' : ataEventCategory,
                    eventAction: `configuration_${eventActions[currentSection]}`,
                    eventLabel: getLabel(),
                });
            }
        }
        return done();
    },
});

const actionTrackingLogic = createLogic({
    type: APP_STATE_ACTIONS.userNavigation,
    name: 'trackingLogic.action',
    process({ getState, action }: ActionDepObj, _dispatch, done) {
        const store = getState();
        const airToAirType = (store.lead as AirToAirLead).airToAirType;

        if (!window.dataLayer) return done();
        if (!action.canTrack) return done();

        const isAirToWater = isAirToWaterLead(store.lead);
        const targetPage = getStatusSection(action.page);
        const isAtaHeatingLead = isAirToAirHeatingLead(store);

        const ataTypeEventCategory = isAtaHeatingLead ? 'rsn_airco_winter' : 'rsn_airco_summer';
        const ataEventCategory = airToAirType ? ataTypeEventCategory : 'rsn_airco';

        window.dataLayer.push({
            event: 'rsnEvent',
            eventCategory: isAirToWater ? 'rsn_heating' : ataEventCategory,
            eventAction: 'navigation',
            eventLabel: `stepNavigation_${eventActions[targetPage]}`,
        });
        return done();
    },
});

const incentivesDeTrackingLogic = createLogic({
    type: [INCENTIVES_DE.init, INCENTIVES_DE.update, INCENTIVES_DE.done],
    name: 'trackingLogic.incentivesDE',
    process({ getState, action }: IncentivesDEDepObj, _dispatch, done) {
        const store = getState();
        const airToAirType = (store.lead as AirToAirLead).airToAirType;
        const isAirToWater = isAirToWaterLead(store.lead);
        const isAtaHeatingLead = isAirToAirHeatingLead(store);

        const ataTypeEventCategory = isAtaHeatingLead ? 'rsn_airco_winter' : 'rsn_airco_summer';
        const ataEventCategory = airToAirType ? ataTypeEventCategory : 'rsn_airco';

        if (window.dataLayer) {
            const getLabel = (): string | null => {
                if (action.type === INCENTIVES_DE.init) return 'calculate_incentives_start_screen';
                else if (action.type === INCENTIVES_DE.update) {
                    if (action.data.step === 'result') {
                        return 'calculate_incentives_result_screen';
                    } else {
                        return appStatusEventLabels[action.data.step];
                    }
                } else if (action.type === INCENTIVES_DE.done) return 'configure_quotation_screen';
                else return null;
            };

            window.dataLayer.push({
                event: 'rsnEvent',
                eventCategory: isAirToWater ? 'rsn_heating' : ataEventCategory,
                eventAction: 'configuration_incentives',
                eventLabel: getLabel(),
            });
        }
        done();
    },
});

const saveDrapoToolDataTrackingLogic = createLogic({
    type: INCENTIVES_DRAPO.saveDrapoData,
    name: 'trackingLogic.saveDrapooToolData',
    process({ getState }: DepObj, _dispatch, done) {
        const store = getState();
        const airToAirType = (store.lead as AirToAirLead).airToAirType;

        const isAirToWater = isAirToWaterLead(store.lead);
        const isAtaHeatingLead = isAirToAirHeatingLead(store);

        const ataTypeEventCategory = isAtaHeatingLead ? 'rsn_airco_winter' : 'rsn_airco_summer';
        const ataEventCategory = airToAirType ? ataTypeEventCategory : 'rsn_airco';

        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'rsnEvent',
                eventCategory: isAirToWater ? 'rsn_heating' : ataEventCategory,
                eventAction: 'configuration_incentives',
                eventLabel: 'configure_quotation_screen',
            });
        }
        done();
    },
});

const savedLeadsTrackingLogic = createLogic({
    type: APP_STATE_ACTIONS.openDetachedScreen,
    name: 'trackingLogic.savedLeads',
    process({ getState, action }: DepObj & { action: OpenDetachedScreenAction }, _dispatch, done) {
        const store = getState();
        const airToAirType = (store.lead as AirToAirLead).airToAirType;

        if (!window.dataLayer) return done();
        if (action.screenType !== DETACHED_SCREEN_TYPE.account) return done();

        const isAirToWater = isAirToWaterLead(store.lead);
        const isAtaHeatingLead = isAirToAirHeatingLead(store);

        const ataTypeEventCategory = isAtaHeatingLead ? 'rsn_airco_winter' : 'rsn_airco_summer';
        const ataEventCategory = airToAirType ? ataTypeEventCategory : 'rsn_airco';

        window.dataLayer.push({
            event: 'rsnEvent',
            eventCategory: isAirToWater ? 'rsn_heating' : ataEventCategory,
            eventAction: 'navigation',
            eventLabel: 'savedConfiguration_screen',
        });
        return done();
    },
});

const saveLeadTrackingLogic = createLogic({
    type: TRACKING_ACTIONS.trackingSave,
    name: 'trackingLogic.saveLead',
    process({ getState }: DepObj, _dispatch, done) {
        const store = getState();
        const airToAirType = (store.lead as AirToAirLead).airToAirType;

        const isAirToWater = isAirToWaterLead(store.lead);
        const isAtaHeatingLead = isAirToAirHeatingLead(store);

        const ataTypeEventCategory = isAtaHeatingLead ? 'rsn_airco_winter' : 'rsn_airco_summer';
        const ataEventCategory = airToAirType ? ataTypeEventCategory : 'rsn_airco';

        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'rsnEvent',
                eventCategory: isAirToWater ? 'rsn_heating' : ataEventCategory,
                eventAction: 'actions',
                eventLabel: 'configurationSaved',
            });
        }
        done();
    },
});

const compareLeadsTrackinglogic = createLogic({
    type: APP_STATE_ACTIONS.fetchCompareLeads,
    name: 'trackingLogic.compareLeads',
    process({ getState }: DepObj, _dispatch, done) {
        const store = getState();
        const airToAirType = (store.lead as AirToAirLead).airToAirType;

        const isAirToWater = isAirToWaterLead(store.lead);
        const isAtaHeatingLead = isAirToAirHeatingLead(store);

        const ataTypeEventCategory = isAtaHeatingLead ? 'rsn_airco_winter' : 'rsn_airco_summer';
        const ataEventCategory = airToAirType ? ataTypeEventCategory : 'rsn_airco';

        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'rsnEvent',
                eventCategory: isAirToWater ? 'rsn_heating' : ataEventCategory,
                eventAction: 'actions',
                eventLabel: 'configurationCompared',
            });
        }
        done();
    },
});

export default [
    trackingLogic,
    aaltraTrackingLogic,
    actionTrackingLogic,
    incentivesDeTrackingLogic,
    saveDrapoToolDataTrackingLogic,
    savedLeadsTrackingLogic,
    compareLeadsTrackinglogic,
    saveLeadTrackingLogic,
];
