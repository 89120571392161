import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import * as Theme from '../../styles/common/Theme';
import { AppStyle } from '../../styles/components/App';
import * as DetachedStyles from '../../styles/components/Detached';
import * as PageStyles from '../../styles/components/Page';

type PropsType = {
    customMessage?: string;
};

const FullPageLoader: FC<PropsType> = (props) => (
    <ThemeProvider theme={Theme}>
        <AppStyle>
            <PageStyles.MainContent>
                <DetachedStyles.Container>
                    <DetachedStyles.Loader>
                        <Icons.ExtraLarge.Loader />
                    </DetachedStyles.Loader>

                    <DetachedStyles.Title>
                        {props.customMessage ?? i18n('loading', false)}
                    </DetachedStyles.Title>
                </DetachedStyles.Container>
            </PageStyles.MainContent>
        </AppStyle>
    </ThemeProvider>
);

export default FullPageLoader;
