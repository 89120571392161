import React from 'react';
import { useAppSelector } from '../../redux';
import { isAirToWaterProject } from '../../redux/selectors';
import * as GridStyles from '../../styles/components/Grid';
import * as PageStyles from '../../styles/components/Page';
import Basket from '../common/basket/Basket';
import QuotationToolAta from './QuotationTool.ata';
import QuotationToolAtw from './QuotationTool.atw';

const QuotationTool = (): JSX.Element => {
    const isAirToWater = useAppSelector(isAirToWaterProject);
    return (
        <PageStyles.DetailContainer>
            <GridStyles.Row>
                <GridStyles.Column width={{ xs: 1, lg: 8 / 12 }}>
                    {isAirToWater ? <QuotationToolAtw /> : <QuotationToolAta />}
                </GridStyles.Column>
                <GridStyles.Column width={{ xs: 1, lg: 4 / 12 }}>
                    <Basket
                        showSolution
                        showPrices
                        showIncentives
                        showEvsSidePanel
                        showAaltraPortal
                        showEpackages
                        showSave
                    />
                </GridStyles.Column>
            </GridStyles.Row>
        </PageStyles.DetailContainer>
    );
};

export default QuotationTool;
