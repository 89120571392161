/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { APP_STATUS, PROJECT_TYPE, TRANSITION } from '../../constants';
import { AirToWaterLead, AppStatus, TransitionTypes } from '../../types';

export const getNextStep = (lead: AirToWaterLead): AppStatus | null => {
    switch (lead.status) {
        case APP_STATUS.sft_he_project_type:
            return APP_STATUS.sft_he_function_type;
        case APP_STATUS.sft_he_function_type:
            return lead.functionType!.includes('dhw')
                ? APP_STATUS.sft_he_household_composition
                : APP_STATUS.sft_he_emitter_type;
        case APP_STATUS.sft_he_household_composition:
            return APP_STATUS.sft_he_emitter_type;
        case APP_STATUS.sft_he_emitter_type:
            return lead.projectType === PROJECT_TYPE.renovation
                ? APP_STATUS.sft_he_previous_heating_system
                : APP_STATUS.sft_he_building_type;
        case APP_STATUS.sft_he_previous_heating_system:
            return APP_STATUS.sft_he_building_type;
        case APP_STATUS.sft_he_building_type:
            // Stay on this page until a solution is selected
            return lead.solutionId ? APP_STATUS.sp_sizing_tool : APP_STATUS.sft_he_building_type;
        case APP_STATUS.sft_he_preferred_solution:
            return APP_STATUS.sp_sizing_tool;
        default:
            return null;
    }
};

export const getTransitionToStep = (target: AppStatus): TransitionTypes | null => {
    switch (target) {
        case APP_STATUS.sft_he_project_type:
            return TRANSITION.sft_he_choose_project_type;
        case APP_STATUS.sft_he_function_type:
            return TRANSITION.sft_he_choose_function_type;
        case APP_STATUS.sft_he_household_composition:
            return TRANSITION.sft_he_choose_household_composition;
        case APP_STATUS.sft_he_emitter_type:
            return TRANSITION.sft_he_choose_emitter_type;
        case APP_STATUS.sft_he_previous_heating_system:
            return TRANSITION.sft_he_choose_previous_heating_system;
        case APP_STATUS.sft_he_building_type:
            return TRANSITION.sft_he_choose_building_type;
        case APP_STATUS.sft_he_preferred_solution:
            return TRANSITION.sft_he_choose_preferred_solution;
        default:
            return null;
    }
};

export const getSftProgress = (lead: AirToWaterLead): number => {
    const hasDhw = +!!lead.functionType?.includes('dhw');
    const isReno = +(lead.projectType === PROJECT_TYPE.renovation);
    switch (lead.status) {
        case APP_STATUS.sft_he_project_type:
            return 0;
        case APP_STATUS.sft_he_function_type:
            return 1 / (4 + isReno);
        case APP_STATUS.sft_he_household_composition:
            return 2 / (4 + isReno + hasDhw);
        case APP_STATUS.sft_he_emitter_type:
            return (2 + hasDhw) / (4 + isReno + hasDhw);
        case APP_STATUS.sft_he_previous_heating_system:
            return (2 + isReno + hasDhw) / (4 + isReno + hasDhw);
        case APP_STATUS.sft_he_building_type:
            return (3 + isReno + hasDhw) / (4 + isReno + hasDhw);
        case APP_STATUS.sft_he_preferred_solution:
            return 1;
        default:
            return 0;
    }
};
