/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { Lead, Store } from '../../types';
import {
    ChooseAlthermaAction,
    LEAD_ACTIONS,
    setCommerceError,
    setLeadLocal,
    ToggleAlthermaAction,
} from '../actions';
import { getGenericPayload, getShowCommercePrices, getShowPrices } from '../selectors';

type ResponseType = {
    lead: Partial<Lead>;
    success: boolean;
};

enum LOGIC_ORIGIN {
    toggle = 'althermaToggleLogic',
    choice = 'althermaChoiceLogic',
}

type DepObj = { getState: () => Store };
type ApiDepObj = DepObj & { action: ToggleAlthermaAction };
// Notify backend of the changes and navigate to next page
const althermaToggleLogic = createLogic({
    type: LEAD_ACTIONS.toggleAltherma,
    name: 'altherma.apiLogic',
    process({ getState, action }: ApiDepObj, dispatch, done) {
        const store = getState();
        const genericPayload = getGenericPayload(store);
        const payload = { ...genericPayload, solutionVersion: action.solutionVersion };
        axios
            .post<ResponseType>(store.settings.urls.solutionVersion, payload)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    // Update reducer w/ new lead-data
                    dispatch(
                        setLeadLocal(
                            { ...data.lead, prices: null },
                            false,
                            LOGIC_ORIGIN.toggle,
                            action.type,
                        ),
                    );
                }
            })
            .catch((err) => {
                err.name = 'Error fetching altherma version';
                Sentry.captureException(err);
            })
            .finally(() => {
                done();
            });
    },
});

type SelectDepObj = DepObj & { action: ChooseAlthermaAction };
const althermaChoiceLogic = createLogic({
    type: LEAD_ACTIONS.chooseAltherma,
    name: 'altherma.choiceLogic',
    async process({ getState, action }: SelectDepObj, dispatch, done) {
        const { solutionVersion } = action;
        const state = getState();
        const genericPayload = getGenericPayload(state);
        const althermaPayload = { ...genericPayload, solutionVersion };

        try {
            // Call solution-version toggle
            const althermaResponse = await axios.post<ResponseType>(
                state.settings.urls.solutionVersion,
                althermaPayload,
            );
            // Validate response
            if (althermaResponse.status !== 200 || !althermaResponse.data?.lead)
                throw new Error('Invalid solution-version response');
            // Fetch new commerce prices if applicable
            if (getShowPrices(state) && getShowCommercePrices(state)) {
                // Clear any previous errors
                if (state.appState.commerceError) dispatch(setCommerceError(null));
                // Link the current lead to the user
                const pricesPayload = getGenericPayload(state);
                pricesPayload.version = althermaResponse.data.lead.version!;
                const commerceResponse = await axios.post<ResponseType>(
                    state.settings.urls.requestPrices,
                    pricesPayload,
                );
                // Validate response
                if (commerceResponse.status !== 200 || !commerceResponse.data?.lead)
                    throw new Error('Invalid commperce-prices response');
                // Update reducer w/ new lead-data incl. commerce prices
                dispatch(setLeadLocal(commerceResponse.data.lead, false, LOGIC_ORIGIN.choice));
            } else {
                // Update reducer w/ new lead-data excl. commerce prices
                dispatch(setLeadLocal(althermaResponse.data.lead, false, LOGIC_ORIGIN.choice));
            }
        } catch (err: unknown) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            err.name = 'Error fetching altherma version';
            Sentry.captureException(err);
        }
        done();
    },
});

export default [althermaToggleLogic, althermaChoiceLogic];
