/* eslint-disable react/no-array-index-key */
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { useState } from 'react';
import { RSN_SECTION } from '../../../constants';
import { useAppSelector } from '../../../redux';
import {
    getAreIncentivesAllowed,
    getCurrentSection,
    getProgress,
    getTotalSteps,
    isAirToAirMultiRoomLead,
    isAirToWaterProject,
} from '../../../redux/selectors';
import * as Styles from '../../../styles/components/NavigationSteps';
import * as PopoverStyles from '../../../styles/components/Popover';
import PreventScrollHelper from '../PreventScrollHelper';
import Step from './Step';

const StepIndicator = (): JSX.Element => {
    const currentSection = useAppSelector(getCurrentSection);
    const progress = useAppSelector(getProgress);
    const areIncentivesAllowed = useAppSelector(getAreIncentivesAllowed);
    const totalSteps = useAppSelector(getTotalSteps);
    const isAtaMultiRoomLead = useAppSelector(isAirToAirMultiRoomLead);
    const isAirToWater = useAppSelector(isAirToWaterProject);

    // UI handler
    const onBackClicked = (): void => window.history.go(-1);

    const [isOpen, setIsOpen] = useState(false);
    const onToggleIsOpen = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const onClosePopover = (): void => setIsOpen(false);

    return (
        <Styles.Wrapper>
            <Styles.Navigation>
                <Styles.Back type="button" onClick={onBackClicked}>
                    <Icons.Glyph.ChevronLeft />
                </Styles.Back>
                <Styles.StepsMobile>
                    <Step
                        section={currentSection}
                        currentSection={currentSection}
                        totalSteps={totalSteps}
                    />
                    <Styles.StepDividers>
                        {[...Array(totalSteps)].map((_, index) => (
                            <Styles.StepDivider
                                key={`step-divider-${index}`}
                                isCompleted={index + 1 < currentSection}
                            />
                        ))}
                    </Styles.StepDividers>
                </Styles.StepsMobile>
                <PreventScrollHelper enabled={isOpen} />
                <Styles.Steps isOpen={isOpen}>
                    <PopoverStyles.Header>
                        <PopoverStyles.Title>
                            {i18n(isAirToWater ? 'heating' : 'air_to_air_heat_pump')}
                        </PopoverStyles.Title>
                        <PopoverStyles.Toggle onClick={onToggleIsOpen}>
                            <Icons.Glyph.CloseCircle />
                        </PopoverStyles.Toggle>
                    </PopoverStyles.Header>

                    <Step
                        section={RSN_SECTION.section_1_solution_finder_tree}
                        currentSection={currentSection}
                        totalSteps={totalSteps}
                        closePopover={onClosePopover}
                    />
                    <Step
                        section={RSN_SECTION.section_2_sizing_selsoft}
                        currentSection={currentSection}
                        totalSteps={totalSteps}
                        closePopover={onClosePopover}
                    />
                    {areIncentivesAllowed && (
                        <Step
                            section={RSN_SECTION.section_3_incentives}
                            currentSection={currentSection}
                            totalSteps={totalSteps}
                            closePopover={onClosePopover}
                        />
                    )}
                    {!isAtaMultiRoomLead && (
                        <Step
                            section={RSN_SECTION.section_4_quotation}
                            currentSection={currentSection}
                            totalSteps={totalSteps}
                            closePopover={onClosePopover}
                        />
                    )}
                    <Step
                        section={RSN_SECTION.section_5_dealer_selection}
                        currentSection={currentSection}
                        totalSteps={totalSteps}
                    />
                </Styles.Steps>
                <Styles.MobileToggle onClick={onToggleIsOpen}>
                    <Icons.Glyph.HamburgerMenu />
                </Styles.MobileToggle>
                <Styles.Progress>
                    <progress value={progress} />
                </Styles.Progress>
            </Styles.Navigation>
            {isOpen && <Styles.Backdrop />}
        </Styles.Wrapper>
    );
};

export default StepIndicator;
