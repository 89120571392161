/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { Coordinates } from '@common/types';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { APP_STATUS } from '../../constants';
import { Store } from '../../types';
import { LEAD_ACTIONS, SetLeadLocalAction, updateLocation } from '../actions';
import { getGeocodePayload, isAirToAirHeatingLead } from '../selectors';

type GeocodeResponseType = {
    responseData: Coordinates;
    success: boolean;
};

type DepObj = {
    getState: () => Store;
    action: SetLeadLocalAction;
};

const DEBUG_CACHE = {
    daikin: { latitude: 51.013214, longitude: 3.7095232 },
    95000: { latitude: 48.864716, longitude: 2.349014 },
    'BS1 1DD': { latitude: 48.864716, longitude: 2.349014 },
    daikinDE: { latitude: 52.531677, longitude: 13.381777 },
    10115: { latitude: 52.531677, longitude: 13.381777 },
    94012: { latitude: 37.379, longitude: 14.201 }, // IT
};

const geocodeCache: { [postalCode: string]: Coordinates } = __DEBUG ? DEBUG_CACHE : {};

const geocodeLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'geocodeLogic.geocodeLogic',
    async process({ getState }: DepObj, dispatch, done) {
        const store = getState();
        const { location, status, toolData } = store.lead;
        const isAtaHeatingLead = isAirToAirHeatingLead(store);
        const postalCode = isAtaHeatingLead
            ? toolData?.sizingWinterHeating?.postal
            : toolData?.sizingHeating?.postal;
        // Check if we actually need to geo-locate the postalCode
        if (
            status === APP_STATUS.sp_sizing_tool &&
            postalCode &&
            postalCode !== location?.postalCode
        ) {
            if (geocodeCache[postalCode]) {
                dispatch(
                    updateLocation({
                        location: { postalCode, coordinates: geocodeCache[postalCode] },
                    }),
                );
                done();
            } else {
                const payload = await getGeocodePayload(store);
                axios
                    .post<GeocodeResponseType>(getState().settings.urls.proxyCall, payload)
                    .then((res) => {
                        const { data } = res;
                        if (res.status === 200 && data?.success === true) {
                            // Store in cache
                            geocodeCache[postalCode] = data.responseData;
                            // Update
                            dispatch(
                                updateLocation({
                                    location: { postalCode, coordinates: data.responseData },
                                }),
                            );
                        }
                    })
                    .catch((err) => {
                        err.name = 'Error updating the location';
                        Sentry.captureException(err);
                    })
                    .finally(() => {
                        done();
                    });
            }
        } else {
            done();
        }
    },
});

export default [geocodeLogic];
