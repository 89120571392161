import i18n from '@common/i18n';
import React from 'react';
import { useAppSelector } from '../../../redux';
import { getSelectedDealer } from '../../../redux/selectors';
import * as BasketStyles from '../../../styles/components/Basket';

const ServicePartnerInfo = (): JSX.Element | null => {
    const hide = useAppSelector((state) => {
        // Don't render if dealers are being fetched
        if (state.appState.isFetchingDealers) return true;
        // Don't render if a dealer was already selected
        if (getSelectedDealer(state)) return true;
        // Don't render if there are no dealers available
        return state.appState.dealers.length === 0;
    });
    if (hide) return null;
    return (
        <BasketStyles.GreyTextBlock>
            <div>{i18n('click_a_service_partner')}</div>
        </BasketStyles.GreyTextBlock>
    );
};

export default ServicePartnerInfo;
