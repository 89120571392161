/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { createLogic } from 'redux-logic';
import { APP_STATUS } from '../../../constants';
import { Store } from '../../../types';
import {
    InitSizingToolAtaCoolingAction,
    LEAD_ACTIONS,
    TOOL_SIZING_ATA_COOLING,
    UpdateSizingToolAtaCoolingAction,
    redirect,
    setLeadRemote,
} from '../../actions';
import { getSizingPlatformJson } from '../../selectors';

type DepObj = { getState: () => Store };

type InitDepObj = DepObj & { action: InitSizingToolAtaCoolingAction };
const initSizingAtaCoolingLogic = createLogic({
    type: TOOL_SIZING_ATA_COOLING.init,
    name: 'sizing.ataCooling.init',
    validate({ getState, action }: InitDepObj, allow, reject) {
        const state = getState();
        if (state.lead.status === APP_STATUS.sp_sizing_tool) {
            window._rsnChannel.publish('sizing.cooling.init', {
                target: 'div#external-tool-sizing-ata-cooling',
                ctaTarget: 'div#cta-teleport-target',
                platform: getSizingPlatformJson(state),
                tools: null,
                step: null,
            });
            allow(action);
        } else {
            reject({ ...action, type: `🚫 (${action.type})` });
        }
    },
});

type UpdateDepObj = { action: UpdateSizingToolAtaCoolingAction };
const updateSizingAtaCoolingApiLogic = createLogic({
    type: TOOL_SIZING_ATA_COOLING.update,
    name: 'sizing.ataCooling.update',
    process({ action }: UpdateDepObj, dispatch, done) {
        dispatch(
            setLeadRemote(
                APP_STATUS.sp_sizing_tool,
                `${APP_STATUS.sp_sizing_tool}.${action.data.step}`,
            ),
        );
        done();
    },
});

const doneSizingAtaCoolingApiLogic = createLogic({
    type: TOOL_SIZING_ATA_COOLING.done,
    name: 'sizing.ataCooling.done',
    process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        const { pardotEnabled } = state.settings;
        if (state.lead.toolData!.sizingCooling!['no-solution']) {
            // Save the no-solution data to lead but without advancing to sp_selsoft_result.
            // noSolutionLogic will trigger when setLeadRemote has finished
            if (pardotEnabled) {
                dispatch(setLeadRemote());
            } else {
                // Redirect to P5 where we will show a "No solutions found" warning
                dispatch(setLeadRemote(APP_STATUS.dealer_selection));
            }
        } else {
            dispatch(setLeadRemote(APP_STATUS.sp_selsoft_result));
        }
        done();
    },
});

const noSolutionLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'sizing.ataCooling.no-solution',
    process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        const { pardotEnabled } = state.settings;
        if (pardotEnabled && state.lead.toolData?.sizingCooling?.['no-solution']) {
            dispatch(redirect(getState().aemSettings.noSolutionPage, '_self', true));
        }
        done();
    },
});

export default [
    initSizingAtaCoolingLogic,
    updateSizingAtaCoolingApiLogic,
    doneSizingAtaCoolingApiLogic,
    noSolutionLogic,
];
