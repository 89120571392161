/* eslint-disable react/no-array-index-key */
import { useAutoRotateWithHold } from '@common/hooks/tsHooks';
import * as Icons from '@common/icons';
import { CarouselImages, SpinnerImages } from '@common/types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import { MODAL_TYPE } from '../../../constants';
import { openModal } from '../../../redux/actions/appStateActions';
import * as Styles from '../../../styles/components/Carousel';
import CarouselNavigation from './CarouselNavigation';

const swipeConfig = {
    delta: 20,
    preventScrollOnSwipe: false,
    trackTouch: true,
    trackMouse: true,
    rotationAngle: 0,
    swipeDuration: Infinity,
    touchEventOptions: { passive: true },
};

type PropsType = {
    carousel: CarouselImages;
    spinners?: SpinnerImages;
    startIndex?: number;
    showActions?: boolean;
    isLarge?: boolean;
};

const ImageCarousel = ({
    carousel,
    spinners,
    startIndex = 0,
    showActions = false,
    isLarge = false,
}: PropsType): JSX.Element => {
    const images = isLarge ? carousel.largeImages : carousel.images;
    const count = images.length;
    const has360Spin = !!spinners?.[0]?.images?.length;
    const dispatch = useDispatch();
    const [current, setCurrent, onStartHold, onStopHold] = useAutoRotateWithHold(
        count,
        5000,
        startIndex,
    );
    const swipeHandlers = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.dir === 'Right') setCurrent((current + count - 1) % count);
            if (eventData.dir === 'Left') setCurrent((current + 1) % count);
        },
        ...swipeConfig,
    });

    const onOpenLargeCarousel = (): void => {
        dispatch(openModal(MODAL_TYPE.imageGallery, { carousel, current }));
    };

    const onOpen360Spin = (): void => {
        dispatch(openModal(MODAL_TYPE.spin360, spinners));
    };

    const image = images[current] || images[0];

    return (
        <Styles.Carousel>
            {showActions && (
                <Styles.Actions>
                    <Styles.Action type="button" onClick={onOpenLargeCarousel}>
                        <Icons.Glyph.Fullscreen />
                    </Styles.Action>
                    {has360Spin && (
                        <Styles.Action type="button" onClick={onOpen360Spin}>
                            <Icons.Glyph.Spin360 />
                        </Styles.Action>
                    )}
                </Styles.Actions>
            )}

            <Styles.ImageWrapper
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...swipeHandlers}
            >
                <Styles.Image
                    onTouchStart={onStartHold}
                    onTouchEnd={onStopHold}
                    onMouseDown={onStartHold}
                    onMouseUp={onStopHold}
                    role="img"
                    aria-label={image?.split('/').pop()}
                    src={image}
                />
            </Styles.ImageWrapper>

            <CarouselNavigation index={current} itemCount={count} onSelect={setCurrent} />
        </Styles.Carousel>
    );
};

export default ImageCarousel;
