import { COLOR_VARIATIONS, COLOR_VARIATION_HEXES } from '@common/types';
import React from 'react';
import * as SolutionStyles from '../../../styles/components/Solution';

type PropsType = {
    colors: COLOR_VARIATIONS[];
    chosenColor: COLOR_VARIATIONS | undefined;
    setChosenColor: (color: COLOR_VARIATIONS) => void;
};

const ColorPicker = ({ colors, chosenColor, setChosenColor }: PropsType): JSX.Element => (
    <SolutionStyles.ColorPicker>
        {colors.map((colorChar) => (
            <SolutionStyles.ColorPickerIndicator
                key={colorChar}
                isActive={chosenColor === colorChar}
                onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    setChosenColor(colorChar);
                }}
            >
                <SolutionStyles.ColorPickerItem bgColor={COLOR_VARIATION_HEXES[colorChar]} />
            </SolutionStyles.ColorPickerIndicator>
        ))}
    </SolutionStyles.ColorPicker>
);

export default ColorPicker;
