import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { FC } from 'react';
import * as DetachedStyles from '../../../styles/components/Detached';
import * as PageStyles from '../../../styles/components/Page';

const Login: FC = () => {
    return (
        <PageStyles.MainContent>
            <DetachedStyles.Container>
                <DetachedStyles.Icon>
                    <Icons.ExtraLarge.Locked />
                </DetachedStyles.Icon>

                <DetachedStyles.Title>{i18n('login_lead')}</DetachedStyles.Title>

                <DetachedStyles.Actions>
                    <DetachedStyles.Action>
                        <Icons.Glyph.UserCircle />
                        {i18n('login')}
                    </DetachedStyles.Action>

                    <DetachedStyles.Action>
                        <Icons.Glyph.House />
                        {i18n('goto_homepage')}
                    </DetachedStyles.Action>

                    <DetachedStyles.Action>
                        <Icons.Glyph.Back />
                        {i18n('start_again')}
                    </DetachedStyles.Action>
                </DetachedStyles.Actions>
            </DetachedStyles.Container>
        </PageStyles.MainContent>
    );
};

export default Login;
