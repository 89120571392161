/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { color, fontFamily } from '../common/Theme';

export const AppStyle = styled.div`
    font-family: ${fontFamily.base};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    * {
        box-sizing: border-box;
    }

    hr {
        border: none;
        height: 1px;
        background-color: ${color.grey200};
    }

    a {
        text-decoration: none;
    }

    svg {
        width: auto;
        height: auto;
        display: inline;
    }

    /* Reset styles */
    header {
        margin-top: 0;
        min-height: 0;
        background-color: transparent;
    }
`;
