import { EnergyLabels, getEnergyLabelImage } from '@common/data/variables';
import * as Styles from '@common/styles/EnergyTag';
import { DHW_ENERGY_LABELS, ENERGY_LABELS } from '@common/types';
import { formatAsShortEnergyLabel } from '@tsUtils';
import React from 'react';
import { useAppSelector } from '../../redux';

type PropsType = {
    type: keyof EnergyLabels;
    rating: ENERGY_LABELS | DHW_ENERGY_LABELS;
    hasSolar?: boolean;
};
const EnergyTag = ({ rating, type, hasSolar = false }: PropsType): JSX.Element => {
    const cdnPrefix = useAppSelector((state) => state.settings.urls.cdnPrefix);
    const energyLabelPath = getEnergyLabelImage(
        type,
        formatAsShortEnergyLabel(rating),
        hasSolar,
        cdnPrefix,
    );
    return <Styles.EnergyTag src={energyLabelPath} alt={`energy-label-${type}-${rating}`} />;
};

export default EnergyTag;
