import styled from 'styled-components';
import { color, space } from '../common/Theme';

export const Box = styled.div`
    margin-bottom: ${space[5]}px;
    padding: ${space[5]}px;
    text-align: center;
    border: 1px solid ${color.grey300};
`;

export const Graph = styled.div`
    svg {
        max-width: 100%;
        height: auto;
    }
`;
