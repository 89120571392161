import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../redux/actions';
import { MODAL_TYPE } from '../../../../constants';

type PropsType = { label: string; disclaimerModalTitle: string; disclaimer: string };

const CalculationDisclaimerLink = ({
    label,
    disclaimerModalTitle,
    disclaimer,
}: PropsType): JSX.Element => {
    const dispatch = useDispatch();

    const onOpenDisclaimerDialog = (): void => {
        dispatch(
            openModal(
                MODAL_TYPE.evsCalculationDisclaimer,
                {
                    disclaimerModalTitle,
                    disclaimer,
                },
                true,
            ),
        );
    };

    return (
        <a href="#" onClick={onOpenDisclaimerDialog}>
            {label}
        </a>
    );
};

export default CalculationDisclaimerLink;
