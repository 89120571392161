import styled from 'styled-components';

export const Fluid = styled.img`
    max-width: 100%;
    height: auto;
`;

export const Thumb = styled.img`
    max-width: 60px;
    height: auto;
`;
