/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { createLogic } from 'redux-logic';
import { APP_STATUS } from '../../../constants';
import {
    TOOL_QUOTATION_ATA,
    InitQuotationToolAtaAction,
    UpdateQuotationToolAtaAction,
    setLeadRemote,
} from '../../actions';
import { Store } from '../../../types';
import {
    getQuotationPlatformJson,
    getQuotationToolsJson,
    getQuotationUnitsJson,
} from '../../selectors';

type DepObj = { getState: () => Store };

type InitDepObj = DepObj & { action: InitQuotationToolAtaAction };
const initQuotationAtaLogic = createLogic({
    type: TOOL_QUOTATION_ATA.init,
    name: 'quotation.ata.init',
    validate({ getState, action }: InitDepObj, allow, reject) {
        const state = getState();
        if (state.lead.status === APP_STATUS.sp_quotation_tool) {
            window._rsnChannel.publish('quotation.init', {
                target: 'div#external-tool-quotation-ata',
                ctaTarget: 'div#cta-teleport-target',
                platform: getQuotationPlatformJson(state),
                tools: getQuotationToolsJson(state),
                solution: getQuotationUnitsJson(state),
                step: state.lead.toolData?.quotationCoolingPage || null,
            });
            allow(action);
        } else {
            reject({ ...action, type: `🚫 (${action.type})` });
        }
    },
});

type UpdateDepObj = DepObj & { action: UpdateQuotationToolAtaAction };
const updateQuotationAtaApiLogic = createLogic({
    type: TOOL_QUOTATION_ATA.update,
    name: 'quotation.ata.update',
    process({ action }: UpdateDepObj, dispatch, done) {
        dispatch(
            setLeadRemote(
                APP_STATUS.sp_quotation_tool,
                `${APP_STATUS.sp_quotation_tool}.${action.data.step}`,
            ),
        );
        done();
    },
});

const doneQuotationAtaApiLogic = createLogic({
    type: TOOL_QUOTATION_ATA.done,
    name: 'quotation.ata.done',
    process(_, dispatch, done) {
        dispatch(setLeadRemote(APP_STATUS.sp_selsoft_result));
        done();
    },
});

export default [initQuotationAtaLogic, updateQuotationAtaApiLogic, doneQuotationAtaApiLogic];
