import React from 'react';
import { MODAL_TYPE } from '../../../constants';
import i18n from '@common/i18n';
import { useAppSelector } from '../../../redux';
import { getPricesBasedOnStrategy } from '../../../redux/selectors';
import * as ModalStyles from '../../../styles/components/Modal';
import * as PricingStyles from '../../../styles/components/Pricing';
import * as TypographyStyles from '../../../styles/components/Typography';
import Modal from './Modal';

const PriceList = ({
    prices,
    included,
}: {
    prices: ReturnType<typeof getPricesBasedOnStrategy>;
    included: boolean;
}): JSX.Element => (
    <>
        {prices
            ?.filter((price) => price.includedInPrices === included)
            .map(({ label, price, description }) => (
                <React.Fragment key={label}>
                    <b>
                        {i18n(label)}
                        {price ? ` - ${price}` : ''}
                    </b>
                    <div dangerouslySetInnerHTML={{ __html: i18n(description) }} />
                </React.Fragment>
            ))}
    </>
);

const PriceDetailsModal = (): JSX.Element => {
    const prices = useAppSelector(getPricesBasedOnStrategy);
    return (
        <Modal type={MODAL_TYPE.priceDetails} isWide>
            <ModalStyles.ModalHeader>
                <TypographyStyles.H4 mb={5}>{i18n('price_details')}</TypographyStyles.H4>
            </ModalStyles.ModalHeader>
            <ModalStyles.ModalContent isWide width="inherit">
                <PricingStyles.PriceDetailsContent>
                    <h3>{i18n('included_in_prices_title')}</h3>
                    <PriceList prices={prices} included={true} />
                    <h3>{i18n('not_included_in_prices_title')}</h3>
                    <div
                        dangerouslySetInnerHTML={{ __html: i18n('not_included_in_prices_info') }}
                    />
                    <PriceList prices={prices} included={false} />
                </PricingStyles.PriceDetailsContent>
            </ModalStyles.ModalContent>
        </Modal>
    );
};
export default PriceDetailsModal;
