/* eslint-disable default-case */
import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { useAppSelector } from '../redux';
import { doneIncentivesDE, updateIncentivesDE } from '../redux/actions/incentiveActions';
import { isGermany } from '../redux/selectors';
import { IncentiveEventDataTypes } from '../types/external/incentivesDE';

enum EVENTS {
    // INBOUND - Aaltra -> RSN
    update = 'update',
    done = 'done',
    // OUTBOUND - RSN -> Aaltra
    init = 'init',
    navigate = 'navigate',
}

// eslint-disable-next-line import/prefer-default-export
export const useIncentivesGermanyTool = (dispatch: Dispatch): void => {
    const isDE = useAppSelector(isGermany);
    // Postal handler
    useEffect(() => {
        const onIncentivesEvent: ICallback<unknown> = (data, envelope) => {
            const [, , type] = envelope.topic.split('.');
            switch (type) {
                case EVENTS.update:
                    dispatch(updateIncentivesDE(data as IncentiveEventDataTypes.Germany.Update));
                    break;
                case EVENTS.done:
                    dispatch(doneIncentivesDE(data as IncentiveEventDataTypes.Germany.Done));
                    break;
            }
        };
        if (isDE) {
            window._rsnChannel.subscribe('incentives.de.*', onIncentivesEvent);
        }
    }, [isDE, dispatch]);
};
