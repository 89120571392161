import { SizingEventDataTypes } from '../../../types/external/sizing';

export enum TOOL_SIZING_ATW {
    init = '🧰/sizing/atw/init',
    update = '🧰/sizing/atw/update',
    done = '🧰/sizing/atw/done',
}

export type InitSizingToolAtwAction = { type: TOOL_SIZING_ATW.init };
export const initSizingToolAtw = (): InitSizingToolAtwAction => ({
    type: TOOL_SIZING_ATW.init,
});

export type UpdateSizingToolAtwAction = {
    type: TOOL_SIZING_ATW.update;
    data: SizingEventDataTypes.Atw.Update;
};
export const updateSizingToolAtw = (
    data: SizingEventDataTypes.Atw.Update,
): UpdateSizingToolAtwAction => ({
    type: TOOL_SIZING_ATW.update,
    data,
});

export type DoneSizingToolAtwAction = {
    type: TOOL_SIZING_ATW.done;
    data: SizingEventDataTypes.Atw.Done;
};
export const doneSizingToolAtw = (
    data: SizingEventDataTypes.Atw.Done,
): DoneSizingToolAtwAction => ({
    type: TOOL_SIZING_ATW.done,
    data,
});

export type SizingAtwActionsType =
    | InitSizingToolAtwAction
    | UpdateSizingToolAtwAction
    | DoneSizingToolAtwAction;
