/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { breakpoint } from '@common/components/Breakpoints';
import styled, { css } from 'styled-components';
import { color, fontFamily, fontWeight, space } from '../common/Theme';
import * as ButtonStyles from './Button';
import * as CardStyles from './Card';
import { SliderControls } from './Carousel';
import * as FormStyles from './Form';
import { ModalContent, ModalWrapper } from './Modal';
import * as RatingStyles from './Rating';
import { Group, Label, Tooltip } from './Rating';

type WrapperProps = { horizontalScroll?: boolean };
export const Wrapper = styled.div<WrapperProps>`
    display: block;
    ${({ horizontalScroll }) =>
        horizontalScroll &&
        css`
            overflow-x: scroll;
        `}
`;

type SolutionsProps = {
    numItems: number;
};
export const Solutions = styled.div.attrs(() => ({}))<SolutionsProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${space[3]}px;
    margin: 0 auto;

    @media ${breakpoint.up('sm')} {
        align-items: stretch;
    }

    ${({ numItems }) =>
        numItems &&
        css`
            @media ${breakpoint.down('md')} {
                /* 218px is width of 1 regular sizes item, 73.6vw is size of isCurrent item */
                width: calc(${(numItems - 1) * 218}px + 73.6vw + 100px);
            }
        `}

    ${({ numItems }) =>
        numItems > 4 &&
        css`
            /* 218px is width of 1 regular sizes item, 73.6vw is size of isCurrent item */
            width: calc(${(numItems - 1) * 218}px + 73.6vw + 100px);

            @media ${breakpoint.up('sm')} {
                width: ${(numItems - 1) * 50}vw;
            }

            @media ${breakpoint.up('md')} {
                width: ${(numItems - 1) * 33.333}vw;
            }

            @media ${breakpoint.up('lg')} {
                max-width: 100%;
            }

            @media (min-width: 1450px) {
                max-width: 1410px;
            }
        `}
`;

export const SolutionItem = styled.div`
    position: relative;
    margin-block-start: ${space[5]}px;

    ${Group} {
        margin-block-end: ${space[2]}px;
    }

    ${Label} {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
    }

    ${Tooltip} {
        position: unset;
        height: 1rem;
    }
`;

export const SolutionItemCategory = styled.div`
    position: absolute;
    top: -${space[5]}px;
    left: ${space[2]}px;
    color: ${color.grey500};
    font-size: 0.875rem;
    font-weight: ${fontWeight.bold};
    text-transform: uppercase;

    &::after {
        content: '';
        display: block;
        transform: translateX(-20px);
        width: 20px;
        border-block-end: 20px solid ${color.grey100};
    }
`;

type SolutionProps = {
    isCurrent?: boolean;
    isHighlighted?: boolean;
    isAtw?: boolean;
};
export const Solution = styled(CardStyles.Default).attrs(() => ({
    as: 'div',
}))<SolutionProps>`
    position: relative;
    margin: 0 ${space[2]}px ${space[4]}px;
    padding: ${space[4]}px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: ${fontFamily.base};
    max-width: 218px;

    @media ${breakpoint.up('sm')} {
        min-width: 0;
        max-width: 50%;
    }

    @media ${breakpoint.up('md')} {
        max-width: 33.333%;
    }

    @media ${breakpoint.up('lg')} {
        min-width: 0;
        max-width: 218px;
        max-width: ${({ isAtw }) => (isAtw ? '24.4rem' : '218px')};
        flex-wrap: nowrap;
    }

    ${({ isCurrent }) =>
        isCurrent &&
        css`
            @media ${breakpoint.down('sm')} {
                min-width: 73.6vw;
                width: 73.6vw;
            }
        `};

    ${({ isHighlighted }) =>
        isHighlighted &&
        css`
            border: none;
            box-shadow: inset 0 0 0 2px ${color.blue300};
        `};
`;

export const Header = styled(CardStyles.Header)`
    margin-block-end: ${space[3]}px;
    padding-block-end: ${space[3]}px;
    width: 100%;
    border-block-end: 1px solid ${color.grey200};
`;

export const Body = styled(CardStyles.Body)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${space[3]}px;
    text-align: center;

    ${RatingStyles.Group} {
        margin-block-end: 0;
    }

    ${RatingStyles.LabelWrapper} {
        justify-content: center;
        margin-block-end: ${space[1]}px;

        > svg {
            color: ${color.blue300};
        }
    }

    ${ButtonStyles.Default} {
        justify-content: center;
    }
`;

export const Badge = styled.div`
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px ${space[3]}px 6px;
    font-size: 12px;
    font-weight: ${fontWeight.bold};
    line-height: 18px;
    color: ${color.grey000};
    background-color: ${color.blue300};
    border-radius: 14px;
    white-space: nowrap;
`;

export const Description = styled(CardStyles.Description)``;

export const Price = styled(CardStyles.Description)`
    margin-block: 0.25rem 1.25rem;
    color: ${color.grey800};
`;

export const IncentiveBadge = styled.div`
    margin-block-end: 1rem;
    margin-inline: auto;
    padding: 0.375rem 0.875rem;
    width: fit-content;
    background-color: ${color.green100};
    color: ${color.green400};
    border-radius: 60px;
    font-size: 0.75rem;
    font-weight: 700;
`;

export const Title = styled(CardStyles.Title)`
    margin-block-end: 0;

    & + ${Description} {
        margin-block-end: ${space[1]}px;
    }
`;

export const Highlights = styled.div``;

export const HighlightsUL = styled.ul`
    margin-block: 0.75rem 0;
    list-style-type: none;
    padding: 0;
`;
export const HighlightsLI = styled.li`
    position: relative;
    display: flex;
    align-items: baseline;
    gap: 0.5rem;

    &:not(:last-child) {
        margin-block-end: 0.5rem;
    }

    > svg {
        flex-shrink: 0;
        transform: translateY(2px);
    }

    > p {
        margin-block: 0;
        color: ${color.grey700};
        text-align: left;
    }
`;

export const Actions = styled.div`
    padding: ${space[5]}px 0;
    text-align: center;
`;

export const Action = styled(ButtonStyles.PrimaryLink)`
    display: inline-flex;
    margin: 0 auto;
    color: ${color.blue300};
`;

export const SelectBtnWrapper = styled(ButtonStyles.PrimaryOutline)`
    margin: ${space[5]}px auto 0;
    justify-content: center;
    text-transform: unset;
`;

type ImageType = { src: string };

export const Image = styled.div<ImageType>`
    margin-block-end: ${space[3]}px;
    padding-block-start: 100%;

    ${({ src }) =>
        src &&
        css`
            background-image: url('${src}');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            object-fit: contain;
        `};
`;

export const ColorPickerWrapper = styled.div`
    margin: ${space[2]}px 0px;
    min-height: 24px;
`;

export const ColorPicker = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
`;

type ColorPickerIndicatorProps = {
    isActive: boolean;
};
export const ColorPickerIndicator = styled.button<ColorPickerIndicatorProps>`
    display: flex;
    width: 24px;
    height: 24px;
    margin: 0 1px;
    padding: 0;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    ${({ isActive }) =>
        isActive &&
        css`
            border: 2px solid ${color.grey500};
        `}
`;

type ColorPickerItemProps = {
    bgColor: string;
};
export const ColorPickerItem = styled.div<ColorPickerItemProps>`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    ${({ bgColor }) => `background-color: ${bgColor};`}
`;

export const Fullscreen = styled(ButtonStyles.Action)`
    display: none;
    position: absolute;
    top: ${space[4]}px;
    right: ${space[4]}px;

    @media ${breakpoint.up('sm')} {
        display: block;
    }
`;

export const IndoorSolutionInfoModal = styled.div`
    @media ${breakpoint.down('lg')} {
        ${ModalWrapper} {
            padding-inline-start: ${space[4]}px;
            padding-inline-end: ${space[4]}px;
            margin: 0 auto;
        }

        ${ModalContent} {
            margin: 0;
        }

        ${Group} {
            margin-block-end: ${space[3]}px !important;
        }
    }

    ${SliderControls} {
        margin-inline-start: 0;
    }

    ${Label} {
        margin-block-end: ${space[2]}px;
        display: flex;
        align-items: center;
        gap: ${space[2]}px;
    }
`;

export const SolutionSpecsModal = styled.div`
    @media ${breakpoint.down('lg')} {
        ${ModalWrapper} {
            padding-inline-start: ${space[4]}px;
            padding-inline-end: ${space[4]}px;
            margin: 0 auto;
        }

        ${ModalContent} {
            margin: 0;
        }

        ${Group} {
            margin-block-end: ${space[3]}px !important;
        }
    }

    ${SliderControls} {
        margin-inline-start: 0;
    }

    ${Label} {
        margin-block-end: ${space[2]}px;
        display: flex;
        align-items: center;
        gap: ${space[2]}px;
    }
`;

export const RadiatorInfo = styled.div`
    margin-block-end: 1rem;

    @media ${breakpoint.up('md')} {
        padding: 1rem;
    }
`;

export const RadiatorTitle = styled.p`
    margin-block: 0 0.625rem;
    color: ${color.grey800};
    font-weight: 700;
`;

export const RadiatorText = styled.div`
    position: relative;
    display: block;
    margin-block: 0;
    color: ${color.grey600};

    ${FormStyles.Group} {
        margin-block-end: 0;
    }

    ${FormStyles.Label} {
        color: ${color.grey700};
        font-size: 1rem;
        font-weight: 400;
    }
`;

export const Split = styled.div`
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.25rem;
    margin-block-end: 1.5rem;

    @media ${breakpoint.up('md')} {
        flex-direction: row;
    }
`;

export const SplitList = styled.div`
    position: relative;
    margin-block-start: 1.25rem;
    padding-block-start: 1.5rem;
    border: 2px solid ${color.blue300};

    ${Solutions} {
        flex-direction: column;
        width: 100%;

        @media ${breakpoint.up('md')} {
            flex-direction: row;
        }
    }

    ${CardStyles.Default} {
        @media ${breakpoint.down('md')} {
            width: 100%;
            min-width: 100%;
        }

        @media ${breakpoint.up('md')} {
            max-width: 24.4rem;
        }
    }

    ${CardStyles.Header} {
        padding-block-end: 0;
    }
`;

export const SolutionPill = styled.h2`
    position: absolute;
    inset-block-start: -1.25rem;
    inset-inline-start: 50%;
    translate: -50%;
    margin-block: 0;
    padding: 0.5rem 1rem;
    width: max-content;
    max-width: 100%;
    background-color: ${color.blue300};
    border-radius: 60px;
    color: #fff;
    font-size: 1rem;
`;

export const Divider = styled.hr`
    margin-block-end: ${space[4]}px;
`;

export const AtaSolutionRatingWrapper = styled(Body)`
    align-items: flex-start;
    text-align: left;
    ${RatingStyles.LabelWrapper} {
        justify-content: flex-start;
    }
`;
