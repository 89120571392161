import { COLOR_VARIATIONS } from '@common/types';
import { Room } from '../../types';

export enum ROOM_BUILDER_ACTIONS {
    initRoomBuilder = 'roomBuilder/initRoomBuilder',
    addRoom = 'roomBuilder/addRoom',
    deleteRoom = 'roomBuilder/deleteRoom',
    updateRoom = 'roomBuilder/updateRoom',
    assignSolutionToRooms = 'roomBuilder/assignSolutionToRooms',
    confirmRooms = 'roomBuilder/confirmRooms',
}

type InitRoomBuilderAction = { type: ROOM_BUILDER_ACTIONS.initRoomBuilder; rooms: Array<Room> };
export const initRoomBuilder = (rooms: Array<Room>): InitRoomBuilderAction => ({
    type: ROOM_BUILDER_ACTIONS.initRoomBuilder,
    rooms,
});

type AddRoomAction = { type: ROOM_BUILDER_ACTIONS.addRoom; roomsToAdd?: number };
export const addRoom = (roomsToAdd?: number): AddRoomAction => ({
    type: ROOM_BUILDER_ACTIONS.addRoom,
    roomsToAdd,
});

type DeleteRoomAction = { type: ROOM_BUILDER_ACTIONS.deleteRoom; roomId: string };
export const deleteRoom = (roomId: string): DeleteRoomAction => ({
    type: ROOM_BUILDER_ACTIONS.deleteRoom,
    roomId,
});

type UpdateRoomAction = {
    type: ROOM_BUILDER_ACTIONS.updateRoom;
    roomId: string;
    updates: Omit<Partial<Room>, 'id' | 'solution' | 'colorVariation'>;
};
export const updateRoom = (
    roomId: string,
    updates: Omit<Partial<Room>, 'id' | 'solution' | 'colorVariation'>,
): UpdateRoomAction => ({
    type: ROOM_BUILDER_ACTIONS.updateRoom,
    roomId,
    updates,
});

type AssignSolutionToRoomsAction = {
    type: ROOM_BUILDER_ACTIONS.assignSolutionToRooms;
    roomIds: Array<string>;
    solution: string;
    colorVariation: COLOR_VARIATIONS | undefined;
};
export const assignSolutionToRooms = (
    roomIds: Array<string>,
    solution: string,
    colorVariation: COLOR_VARIATIONS | undefined,
): AssignSolutionToRoomsAction => ({
    type: ROOM_BUILDER_ACTIONS.assignSolutionToRooms,
    roomIds,
    solution,
    colorVariation,
});

export type ConfirmRoomsAction = { type: ROOM_BUILDER_ACTIONS.confirmRooms };
export const confirmRooms = (): ConfirmRoomsAction => ({
    type: ROOM_BUILDER_ACTIONS.confirmRooms,
});

export type RoomBuilderActionsType =
    | InitRoomBuilderAction
    | AddRoomAction
    | DeleteRoomAction
    | UpdateRoomAction
    | AssignSolutionToRoomsAction
    | ConfirmRoomsAction;
