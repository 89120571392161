import styled from 'styled-components';

export const Spin = styled.div`
    position: relative;
    z-index: 100;
    max-height: 626px;

    picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        object-fit: contain;
    }

    .draggable-overlay {
        top: 0;
        height: 100% !important;
    }
`;

export const ImageWrapper = styled.div`
    position: relative;
    padding-top: 100%;
`;
