import { APP_STATUS, RSN_SECTION } from './constants';
import { AppSection, AppStatus, SelSoftSolution } from './types';

export const filterValidSolutions = (
    solutions: SelSoftSolution[] | undefined,
): SelSoftSolution[] => {
    if (!solutions) return [];
    return solutions.filter((s) => s.valid.valid);
};

export const getStatusSection = (status: AppStatus): AppSection => {
    switch (status) {
        case APP_STATUS.sft_he_project_type:
        case APP_STATUS.sft_he_function_type:
        case APP_STATUS.sft_he_household_composition:
        case APP_STATUS.sft_he_emitter_type:
        case APP_STATUS.sft_he_previous_heating_system:
        case APP_STATUS.sft_he_building_type:
        case APP_STATUS.sft_he_preferred_solution:
        case APP_STATUS.sft_ra_air_to_air_type:
        case APP_STATUS.sft_ra_room_type:
        case APP_STATUS.sft_ra_number_of_rooms:
        case APP_STATUS.sft_ra_positioning_type:
        case APP_STATUS.sft_ra_room_size:
        case APP_STATUS.sft_ra_house_size:
        case APP_STATUS.sft_ra_preferred_solution:
        case APP_STATUS.sft_ra_room_builder:
        case APP_STATUS.sft_ra_unsupported_positioning_type:
            return RSN_SECTION.section_1_solution_finder_tree;
        case APP_STATUS.sp_sizing_tool:
        case APP_STATUS.sp_selsoft_result:
            return RSN_SECTION.section_2_sizing_selsoft;
        case APP_STATUS.incentives_tool:
            return RSN_SECTION.section_3_incentives;
        case APP_STATUS.sp_quotation_choice:
        case APP_STATUS.sp_quotation_tool:
            return RSN_SECTION.section_4_quotation;
        case APP_STATUS.dealer_selection:
        case APP_STATUS.dealer_selection_address_information:
            return RSN_SECTION.section_5_dealer_selection;
        case APP_STATUS.finish_lead:
            return RSN_SECTION.finished;
        case APP_STATUS.loading:
            return RSN_SECTION.loading;
        default:
            return RSN_SECTION.section_1_solution_finder_tree;
    }
};
