import { TDealer } from '@daikin/daikin-aem-react';
import { DEALER_SCHED_FACTOR } from '../../constants';
import { Dealer, Store, TimeRanges } from '../../types';

const defaultTimeRangeOrderOfPreference = [
    DEALER_SCHED_FACTOR.between_1_or_3_months,
    DEALER_SCHED_FACTOR.less_than_1_month,
    DEALER_SCHED_FACTOR.more_than_3_months,
];
export const getDefaultTimeRange = (timeRanges: TimeRanges): DEALER_SCHED_FACTOR => {
    // Loop over timeRanges in order of prerefence (as stated on confluence)
    return (
        defaultTimeRangeOrderOfPreference.find((timeRange) => {
            // Check if this timeRange has any dealers
            return timeRanges[timeRange].length > 0;
        }) || DEALER_SCHED_FACTOR.between_1_or_3_months
    );
};

const normalizeToTDealer = (dealer: Dealer): TDealer => {
    // Probably not going to work for adresses where number + street is
    const address = dealer.street.split(' ');
    const number = address.pop();
    const street = address.join(' ');
    const tDealer = {
        id: dealer.rsnInstaller,
        geocode: dealer.distance.replace(' ', ''),
        title: dealer.companyName,
        url: dealer.url,
        street,
        number,
        zip: dealer.zipCode,
        city: dealer.city,
    } as TDealer;
    return tDealer;
};

export const calculateTimeRanges = (dealers: Dealer[]): TimeRanges => {
    // Prepare placeholder object
    const timeRanges: TimeRanges = {
        [DEALER_SCHED_FACTOR.less_than_1_month]: [],
        [DEALER_SCHED_FACTOR.between_1_or_3_months]: [],
        [DEALER_SCHED_FACTOR.more_than_3_months]: [],
    };
    // Push dealers into every available timeRanges
    dealers.forEach((dealer) => {
        dealer.schedFactor.forEach((schedFactor) => {
            timeRanges[schedFactor]?.push(normalizeToTDealer(dealer));
        });
    });
    // Return result
    return timeRanges;
};

export const getSelectedDealer = (store: Store): Dealer | null => {
    const id = store.lead.dealerId;
    if (!id) return null;
    return store.appState.dealers.find((dealer) => dealer.rsnInstaller === id) || null;
};
