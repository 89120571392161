import { Settings } from '../../types';
import { ActionsType, LEAD_ACTIONS, SETTINGS_ACTIONS } from '../actions';

const initialState: Partial<Settings> = {};

const settings = (state: Settings, action: ActionsType): Settings => {
    switch (action.type) {
        case LEAD_ACTIONS.setLeadLocal:
            return {
                ...state,
                leadId: state.leadId || action.lead?.id || null,
            };
        case SETTINGS_ACTIONS.setSettings:
            return {
                ...state,
                ...action.settings,
            };
        case SETTINGS_ACTIONS.setSolutions:
            return {
                ...state,
                solutions: action.solutions,
                multiRoomSolutions: action.multiRoomSolutions || state.multiRoomSolutions,
                singleRoomSolutions: action.singleRoomSolutions || state.singleRoomSolutions,
            };
        default:
            return { ...initialState, ...state };
    }
};

export default settings;
