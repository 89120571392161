import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SCRIPT_STATE } from '../../constants';
import { useAppSelector } from '../../redux';
import { initQuotationToolAta } from '../../redux/actions/tools/quotationActions.ata';
import ToolWrapper from '../step2/ToolWrapper';

const QuotationToolAta = (): JSX.Element => {
    const dispatch = useDispatch();
    // Attempt to initialize Quotation tool
    const aaltraScriptState = useAppSelector((state) => state.appState.aaltraScriptState);

    useEffect(() => {
        if (aaltraScriptState === SCRIPT_STATE.injected) dispatch(initQuotationToolAta());
    }, [dispatch, aaltraScriptState]);

    return <ToolWrapper id="external-tool-quotation-ata" />;
};

export default QuotationToolAta;
