import i18n from '@common/i18n';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { Store } from '../../types';
import {
    APP_STATE_ACTIONS,
    FetchCitiesAction,
    setCustomerDetailsErrors,
    suggestCities,
} from '../actions';
import { getCityPostalUrl } from '../selectors';

type DepObj = { getState: () => Store; action: FetchCitiesAction };

const blockFetchCitiesPostalCodeLogic = createLogic({
    type: APP_STATE_ACTIONS.fetchCities,
    name: 'cityPostal.blockFetchCitiesLogic',
    validate({ getState, action }: DepObj, allow, reject) {
        if (getState().appState.citySuggestions[action.postal] !== undefined) {
            reject({ ...action, type: `🚫 (${action.type})` });
        } else {
            allow(action);
        }
    },
});

type FetchCitiesResponseType = {
    cities: string[] | null;
    success: boolean;
};

const fetchCitiesForPostalCodeLogic = createLogic({
    type: APP_STATE_ACTIONS.fetchCities,
    name: 'cityPostal.citiesForPostalCodeLogic',
    process({ getState, action }: DepObj, dispatch, done) {
        const url = getCityPostalUrl(getState(), action.postal);
        axios
            .get<FetchCitiesResponseType>(url)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data?.success === true && data.cities) {
                    dispatch(suggestCities(action.postal, data.cities));
                } else {
                    if (status > 299) {
                        const error = new Error(`${status} error`);
                        error.name = 'Error fetching cities for postalCode';
                        Sentry.captureException(error);
                    }
                    dispatch(suggestCities(action.postal, []));
                }
            })
            .catch((err) => {
                const errors = err.response?.data?.errors;
                if (errors && Object.keys(errors).length > 0) {
                    const translatedErrors: Array<string> = errors.postalCode.map(
                        (error: string) => {
                            return i18n(error, true, 'validators');
                        },
                    );
                    dispatch(setCustomerDetailsErrors({ postalCode: translatedErrors.join(' ') }));
                }
                err.name = 'Error fetching cities for postalCode';
                Sentry.captureException(err);
                dispatch(suggestCities(action.postal, []));
            })
            .finally(() => {
                done();
            });
    },
});

export default [blockFetchCitiesPostalCodeLogic, fetchCitiesForPostalCodeLogic];
