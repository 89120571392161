/* eslint-disable quote-props */

export { color, fontFamily, fontWeight } from '@common/styles/general';

export const spacer = 20;

export const space = [0, 5, 10, 15, 20, 30, 40, 60, 80, 120, 160];

export const iconSize = {
    w: 20,
    h: 20,
};

export const visualSize = {
    w: 80,
    h: 80,
};

export const zIndex = {
    dropdownBackdrop: 990,
    dropdown: 1000,
    fixed: 1020,
    basket: 1030,
    popover: 1060,
    tooltip: 1070,
    sticky: 1100,
    basketBackdrop: 1110,
    basketOpen: 1120,
    modalBackdrop: 1130,
    modal: 1140,
};

// Todo: set correct breakpoints
const breakpoints = [0, '576px', '768px', '992px', '1200px', '1800px'];

// Aliases
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];
breakpoints.xxl = breakpoints[5];

export { breakpoints };
