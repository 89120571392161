import i18n from '@common/i18n';
import React from 'react';
import { useDispatch } from 'react-redux';
import { MODAL_TYPE, RSN_SECTION } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { openModal } from '../../../redux/actions';
import { getCurrentSection, isAirToAirMultiRoomLead } from '../../../redux/selectors';
import * as BasketStyles from '../../../styles/components/Basket';
import * as ButtonStyles from '../../../styles/components/Button';
import * as TypographyStyles from '../../../styles/components/Typography';
import { AirToAirSingleRoomSbmSolution, AirToWaterSbmSolution } from '../../../types';

const BasketSolution = (): JSX.Element | null => {
    const dispatch = useDispatch();
    const isAtaMultiRoomLead = useAppSelector(isAirToAirMultiRoomLead);
    const solution = useAppSelector((store) => store.lead.solution);
    const isStep5 = useAppSelector(getCurrentSection) === RSN_SECTION.section_5_dealer_selection;

    if (!solution) return null;

    return (
        <BasketStyles.Header>
            <BasketStyles.HeaderContent>
                <TypographyStyles.H4 as="h3">{solution.name}</TypographyStyles.H4>
                {isStep5 && !isAtaMultiRoomLead && (
                    <ButtonStyles.PrimaryLink
                        onClick={() => dispatch(openModal(MODAL_TYPE.solutionSpecs))}
                    >
                        {i18n('view_specifications')}
                    </ButtonStyles.PrimaryLink>
                )}
            </BasketStyles.HeaderContent>
            {!isAtaMultiRoomLead && (
                <BasketStyles.HeaderImage
                    src={
                        (solution as AirToWaterSbmSolution | AirToAirSingleRoomSbmSolution)
                            .overviewImage
                    }
                    alt="solution-overview-image"
                />
            )}
        </BasketStyles.Header>
    );
};

export default BasketSolution;
