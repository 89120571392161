import styled, { css } from 'styled-components';
import { breakpoint } from '@common/components/Breakpoints';
import { color, fontWeight, space, zIndex } from '../common/Theme';
import * as ButtonStyles from './Button';
import * as BackdropStyles from './Backdrop';

export const Wrapper = styled.nav`
    position: relative;
    overflow: hidden;
    background-color: ${color.grey000};
    border-top: 1px solid ${color.grey300};

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: ${space[1]}px;
        content: '';
        background-color: ${color.grey300};
        z-index: 40;

        @media ${breakpoint.up('xl')} {
            height: ${space[2]}px;
        }
    }
`;

export const Navigation = styled.div`
    display: flex;
    width: 100%;

    @media ${breakpoint.up('xl')} {
        margin: 0 auto;
        max-width: 1390px;
    }
`;

export const Back = styled(ButtonStyles.Action)`
    padding: 0 ${space[4]}px;
    width: ${space[7]}px;
    flex-shrink: 0;
    cursor: pointer;
`;

export const StepIcon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${space[5]}px;
    height: ${space[5]}px;
`;

export const StepLabels = styled.div`
    margin-left: ${space[3]}px;
`;

export const StepName = styled.div`
    font-size: 14px;
    font-weight: ${fontWeight.bold};
    line-height: 20px;
    text-transform: uppercase;
    color: ${color.grey500};
`;

export const StepIndex = styled.span`
    display: none;
    margin-right: ${space[1]}px;

    @media ${breakpoint.up('xl')} {
        display: inline-block;
    }
`;

export const StepLabel = styled.span``;

export const StepDuration = styled.div`
    display: none;
    font-size: 13px;
    line-height: 20px;
    color: ${color.grey600};

    @media ${breakpoint.up('xl')} {
        display: block;
    }
`;

export const StepProgress = styled.div`
    @media ${breakpoint.up('xl')} {
        display: none;
    }
`;

export const Progress = styled.div`
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    @media ${breakpoint.up('xl')} {
        left: 0;
    }

    progress {
        position: relative;
        display: block;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: ${space[1]}px;
        z-index: 50;
        border: none;
        border-radius: 0;
        background-color: ${color.grey300};

        @media ${breakpoint.up('xl')} {
            height: ${space[2]}px;
            transform: translateX(${space[7]}px);
            max-width: calc(100% - ${space[7]}px);
        }

        @media ${breakpoint.up('xxl')} {
            max-width: calc(1390px - 60px);
            margin-left: calc((100% - 1390px) / 2);
        }

        &::-webkit-progress-bar {
            background-color: ${color.grey300};
        }

        &::-webkit-progress-value {
            background-color: ${color.blue300};
            border-left: 1px solid ${color.blue400};
            border-right: 4px solid ${color.blue400};
            transition: width 0.5s cubic-bezier(0.25, 1, 0.5, 1);
        }

        &::-moz-progress-bar {
            background-color: ${color.blue300};
            border-left: 1px solid ${color.blue400};
            border-right: 4px solid ${color.blue400};
            transition: width 0.5s cubic-bezier(0.25, 1, 0.5, 1);
        }
    }
`;

type StepsProps = {
    isOpen?: boolean;
};

export const Steps = styled.div<StepsProps>`
    display: none;
    width: 100%;

    ${({ isOpen }) =>
        isOpen &&
        css`
            @media ${breakpoint.down('xl')} {
                position: fixed;
                top: 150px;
                left: 0;
                right: 0;
                display: block;
                margin: 0 auto;
                max-width: 475px;
                background-color: ${color.grey000};
                border-radius: ${space[1]}px;
                z-index: ${zIndex.modal};

                ${StepLabels} {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }

                ${StepIndex} {
                    display: inline-block;
                }

                ${StepDuration} {
                    display: block;
                    margin-left: auto;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${color.grey600};
                }

                ${StepProgress} {
                    display: none;
                }
            }

            @media ${breakpoint.down('md')} {
                max-width: calc(100% - 40px);
                top: 50%;
                transform: translateY(-50%);
            }
        `};

    @media ${breakpoint.up('xl')} {
        display: flex;
        border-left: 1px solid ${color.grey300};
    }
`;

type StepProps = {
    isActive?: boolean;
    isCompleted?: boolean;
    isDisabled?: boolean;
};

export const Step = styled.div<StepProps>`
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 ${space[4]}px;
    height: 80px;
    border-right: 1px solid ${color.grey300};
    cursor: pointer;

    &:last-child {
        @media ${breakpoint.down('xxl')} {
            border-right: none;
        }
    }

    @media ${breakpoint.down('xl')} {
        border-top: 1px solid ${color.grey200};
    }

    @media ${breakpoint.up('xl')} {
        flex-basis: 100%;

        &::before,
        &::after {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 10px;
            content: '';
        }

        &::after {
            width: 1px;
            right: -1px;
            background-color: ${color.grey400};
            z-index: 60;
        }
    }

    ${({ isActive }) =>
        isActive &&
        css`
            ${StepIcon},
            ${StepName} {
                color: ${color.blue300};
            }

            ${StepDuration} {
                color: ${color.grey800};
            }
        `};

    ${({ isCompleted }) =>
        isCompleted &&
        css`
            &::after {
                background-color: ${color.blue400};
            }

            ${StepIcon} {
                flex-shrink: 0;
                color: ${color.blue400};
                background-color: ${color.blue100};
                border: 1px solid ${color.blue200};
                border-radius: 50%;
            }

            ${StepDuration} {
                color: ${color.grey800};
            }
        `};

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            cursor: default;
            ${StepIcon},
            ${StepName},
        ${StepDuration} {
                color: ${color.grey200};
            }
        `};
`;

export const MobileToggle = styled(ButtonStyles.Action)`
    padding: 0 ${space[4]}px;

    @media ${breakpoint.up('xl')} {
        display: none;
    }
`;

export const StepsMobile = styled.div`
    display: flex;
    justify-content: center;
    flex-basis: 100%;

    @media ${breakpoint.up('xl')} {
        display: none;
    }

    ${Step} {
        text-align: center;
        border: none;
    }

    ${StepIcon} {
        display: none;
    }
`;

export const HeaderTitle = styled.div`
    color: ${color.blue300};
`;

export const StepDividers = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
    height: 5px;
    z-index: 60;
`;

export const StepDivider = styled.span<StepProps>`
    position: relative;
    flex-basis: 100%;

    &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 2px;
        height: 5px;
        content: '';
        background-color: ${color.grey400};
        z-index: 70;

        ${({ isCompleted }) =>
            isCompleted &&
            css`
                background-color: ${color.blue400};
            `};
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
`;

export const Backdrop = styled.div`
    @media ${breakpoint.down('xl')} {
        ${BackdropStyles.Backdrop};
    }
`;
