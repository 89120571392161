import i18n from '@common/i18n';
import React from 'react';
import { useAppSelector } from '../../../redux';
import { getVatLabel } from '../../../redux/selectors';
import * as PricingStyles from '../../../styles/components/Pricing';
import PriceItem from './PriceItem';

type PropsType = {
    equipmentPrice: string | undefined;
    showPriceDetails: boolean;
};

const EquipmentPrice = ({ equipmentPrice, showPriceDetails }: PropsType): JSX.Element => {
    const vatLabel = useAppSelector(getVatLabel);
    return (
        <PricingStyles.Group>
            <PriceItem
                price={equipmentPrice}
                label={i18n('price_system')}
                note={vatLabel}
                mb={2}
                isImportant
                showPriceDetails={showPriceDetails}
            />
        </PricingStyles.Group>
    );
};

export default EquipmentPrice;
