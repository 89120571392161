import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { DETACHED_SCREEN_TYPE } from '../../constants';
import { closeDetachedScreen } from '../../redux/actions';
import * as DetachedStyles from '../../styles/components/Detached';
import * as PageStyles from '../../styles/components/Page';
import * as VideoStyles from '../../styles/components/Video';

type PropsType = {
    videoId: string;
};

const VideoPlayer: FC<PropsType> = ({ videoId }) => {
    const dispatch = useDispatch();
    const onCloseClicked = (): void => {
        dispatch(closeDetachedScreen(DETACHED_SCREEN_TYPE.videoPlayer));
    };
    return (
        <PageStyles.MainContent>
            <DetachedStyles.Container>
                <DetachedStyles.Header>
                    <DetachedStyles.Close as="button" type="button" onClick={onCloseClicked}>
                        <Icons.Glyph.CloseCircle />
                        {i18n('close')}
                    </DetachedStyles.Close>
                </DetachedStyles.Header>

                <DetachedStyles.Body>
                    <VideoStyles.Container>
                        <VideoStyles.Wrapper>
                            <VideoStyles.Player
                                url={`https://www.youtube.com/watch?v=${videoId}`}
                                volume={1}
                                controls
                                playing
                                light
                                width="100%"
                                height="100%"
                            />
                        </VideoStyles.Wrapper>
                    </VideoStyles.Container>
                </DetachedStyles.Body>
            </DetachedStyles.Container>
        </PageStyles.MainContent>
    );
};

export default VideoPlayer;
