import axios from 'axios';
import * as Sentry from '@sentry/react';
import { createLogic } from 'redux-logic';
import { AirToWaterLead, Lead, Store } from '../../types';
import { phasingMap } from '../../constants';
import { setLeadLocal, LEAD_ACTIONS, UpdatePhasingAction, setSelSoftData } from '../actions';
import {
    getGenericPayload,
    calcRecommendedSolution,
    getPhasing,
    selsoftResultsHaveMultiplePhases,
    isAirToWaterLead,
    getValidSelsoftResults,
} from '../selectors';

type UpdateResponseType = {
    lead: Partial<Lead>;
    success: boolean;
};

type DepObj = {
    getState: () => Store;
    action: UpdatePhasingAction;
};
// This logic sends lead-changes to the API
const apiLogic = createLogic({
    type: LEAD_ACTIONS.updatePhasing,
    name: 'phasingLogic.apiLogic',
    async process({ getState, action }: DepObj, dispatch, done) {
        const store = getState();
        const { selsoftResults } = store.appState;
        const genericPayload = await getGenericPayload(store);
        const payload: Record<string, unknown> = { ...genericPayload, phasing: action.phasing };
        if (isAirToWaterLead(store.lead) && selsoftResults) {
            payload.selSoftData = {
                ...store.lead.selSoftData,
                // Give new phasing to function so correct solution is selected and given with phasing api call
                solution: calcRecommendedSolution(store, selsoftResults, action.phasing),
            };
        }
        axios
            .post<UpdateResponseType>(getState().settings.urls.phasing, payload)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    // Update reducer w/ new lead-data
                    dispatch(setLeadLocal(data.lead, true, 'phasingLogic', action.type));
                }
            })
            .catch((err) => {
                err.name = 'Error setting phasing';
                Sentry.captureException(err);
            })
            .finally(() => {
                done();
            });
    },
});

// This logic sends lead-changes to the API
const postRecommendedLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'phasingLogic.postRecommendedLogic',
    process({ getState }: DepObj, dispatch, done) {
        const store = getState();
        const selsoftResults = getValidSelsoftResults(store);
        if (selsoftResults.length && selsoftResultsHaveMultiplePhases(selsoftResults)) {
            // Fetch store data
            const { selSoftData } = store.lead as AirToWaterLead;
            const phase = phasingMap[getPhasing(store)];
            // Check if all data is available
            if (selsoftResults && selSoftData) {
                // Check if phasing matched the recommended solution
                if (phase !== selSoftData.solution?.outdoorUnit?.powerSupply.phase) {
                    // Check if we can find a recommended solution
                    const solution = calcRecommendedSolution(store, selsoftResults);
                    if (solution) dispatch(setSelSoftData({ ...selSoftData, solution }));
                }
            }
        }
        done();
    },
});

export default [apiLogic, postRecommendedLogic];
