import React from 'react';
import { createGlobalStyle } from 'styled-components';

type Props = {
    enabled: boolean;
};

const BodyStyle = createGlobalStyle`
      body {
          overflow: hidden !important;
      }
`;

/**
 * This Helper prevent the body scrolling when a for example a modal is openened
 */

const PreventScrollHelper = ({ enabled }: Props): JSX.Element | null => {
    if (!enabled) return null;

    return <BodyStyle />;
};

export default PreventScrollHelper;
