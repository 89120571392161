import { LiteratureData } from '@common/types';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { Store } from '../../types';
import { LEAD_ACTIONS, updateLiterature } from '../actions';
import { getLiteraturePayload, getSeriesIDs, shouldFetchLiterature } from '../selectors';

type LiteratureResponseType = {
    responseData: unknown;
    success: boolean;
};

type DepObj = { getState: () => Store };

let activeRequestsIds: string[] = [];

const fetchLiteratureLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'fetchLiteratureLogic',
    process: async ({ getState }: DepObj, dispatch, done) => {
        const store = getState();
        const seriesIDs = getSeriesIDs(store);
        const prvValues = Object.values(seriesIDs).filter(Boolean);
        if (shouldFetchLiterature(store, prvValues)) {
            const payload = await getLiteraturePayload(store, prvValues);
            // There might be an active request, check if this is a duplicate
            const requestId = payload.data.join('-');
            if (!activeRequestsIds.includes(requestId)) {
                activeRequestsIds.push(requestId);
                axios
                    .post<LiteratureResponseType>(getState().settings.urls.proxyCall, payload)
                    .then((res) => {
                        const { status, data } = res;
                        if (status === 200 && data) {
                            const { documents, languages } = data.responseData as LiteratureData;
                            dispatch(updateLiterature({ documents, languages }));
                        }
                    })
                    .finally(() => {
                        activeRequestsIds = activeRequestsIds.filter((id) => id !== requestId);
                        done();
                    });
            } else {
                done();
            }
        } else {
            done();
        }
    },
});

export default [fetchLiteratureLogic];
