/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { color } from '../common/Theme';

type PreloaderProps = { colorOverride?: string };
export const Preloader = styled.div<PreloaderProps>`
    ${({ colorOverride }) =>
        css`
            color: ${colorOverride || color.blue300};
        `};

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    svg {
        display: inline-block;
        animation-name: rotation;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
    }
`;
