import React from 'react';
import { MODAL_TYPE } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { getQuotationData, getScopDetails } from '../../../redux/selectors';
import * as GridStyles from '../../../styles/components/Grid';
import * as ModalStyles from '../../../styles/components/Modal';
import * as PageStyles from '../../../styles/components/Page';
import * as SolutionStyles from '../../../styles/components/Solution';
import * as TypographyStyles from '../../../styles/components/Typography';
import { AirToAirSingleRoomSbmSolution } from '../../../types';
import BasketPrices from '../basket/BasketPrices';
import CapacityAta from '../solution/Capacity.ata';
import Description from '../solution/Description';
import EnergyLabels from '../solution/EnergyLabels';
import Highlights from '../solution/Highlights';
import ImageCarousel from '../solution/ImageCarousel';
import OptionOverview from '../solution/OptionOverview';
import RatingCard from '../solution/RatingCard';
import Scop from '../solution/Scop';
import UnitOverviewAta from '../solution/UnitOverview.ata';
import Modal from './Modal';

type PropsType = {
    solution: AirToAirSingleRoomSbmSolution;
};

const SolutionSpecsModalAta = ({ solution }: PropsType): JSX.Element => {
    const scopDetails = useAppSelector(getScopDetails);
    const hasQuotation = useAppSelector(getQuotationData) !== null;
    return (
        <SolutionStyles.SolutionSpecsModal>
            <Modal type={MODAL_TYPE.solutionSpecs} isWide>
                <ModalStyles.ModalHeader>
                    <TypographyStyles.H4 mb={2}>{solution.name}</TypographyStyles.H4>
                </ModalStyles.ModalHeader>
                <ModalStyles.ModalContent isWide>
                    <GridStyles.Row hasDivider>
                        <GridStyles.Column
                            width={{ xs: 1, lg: 1 / 2 }}
                            py={5}
                            pr={{ xs: 4, md: 5 }}
                        >
                            {solution.carousel && (
                                <ImageCarousel
                                    carousel={solution.carousel}
                                    spinners={solution.spinners}
                                    showActions
                                />
                            )}
                            <EnergyLabels solution={solution} />
                            <RatingCard
                                energy={solution.energy}
                                comfort={solution.comfort}
                                airQuality={solution.airQuality}
                                connectivity={solution.connectivity}
                                showIcons
                            />
                            <PageStyles.Divider />
                            <BasketPrices showIncentives />
                        </GridStyles.Column>

                        <GridStyles.Column
                            width={{ xs: 1, lg: 1 / 2 }}
                            py={5}
                            pl={{ xs: 4, md: 5 }}
                        >
                            {!hasQuotation && !!scopDetails && <Scop scopDetails={scopDetails} />}
                            <CapacityAta />
                            <UnitOverviewAta />
                            <OptionOverview />
                            <Highlights highlights={solution.highlights} />
                            <Description description={solution.description} />
                        </GridStyles.Column>
                    </GridStyles.Row>
                </ModalStyles.ModalContent>
            </Modal>
        </SolutionStyles.SolutionSpecsModal>
    );
};

export default SolutionSpecsModalAta;
