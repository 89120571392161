import { cleanObject } from '@tsUtils';
import { FUNCTION_TYPE, MULTI_PLUS_TYPE } from '../../constants';
import {
    AirToAirLead,
    AirToWaterLead,
    AirToWaterSbmSolution,
    SelSoftUnitType,
    Store,
} from '../../types';
import {
    isAirToAirCoolingLead,
    isAirToAirHeatingLead,
    isAirToAirMultiRoomLead,
    isAirToWaterLead,
} from './genericSelectors';
import { getTotalVolumeRequired } from './selsoftSelectors';

const getController = (state: Store): string | null => {
    return state.lead.options?.find((o) => o.isRequiredForControl)?.name || null;
};

type HeaterType = 'Combi Heater' | 'Water Heater' | 'Space Heater';

const getHeaterType = (state: Store): HeaterType => {
    const solution = state.lead.solution as AirToWaterSbmSolution;
    const operationModes = solution?.filters.operationModes || [];
    const hasSH = operationModes.includes('space-heating');
    const hasDHW = operationModes.includes('domestic-hot-water');
    if (hasSH && hasDHW) return 'Combi Heater';
    if (hasDHW) return 'Water Heater';
    return 'Space Heater';
};

const getProductNameFromUnit = (
    unit: SelSoftUnitType | undefined | null,
    isSecondary = false,
): string | null => {
    if (!unit) return null;
    const { productName } = unit;
    if (!productName) return null;
    if (!productName.includes('+') && isSecondary) return null;
    if (!productName.includes('+')) return productName.trim();
    return productName.split('+')[+isSecondary].trim();
};

const getEnergySourceType = (energySources: Array<string>): string => {
    switch (energySources.sort().join(',')) {
        // Selected Product Family
        case 'air':
            return 'Air to Water H/P';
        case 'ground':
            return 'Ground to Water H/P';
        case 'gas':
        case 'oil':
            return 'Fossil / Fuel';
        case 'water':
            return 'Water to Water H/P';
        case 'air, gas':
        case 'air, oil':
            return 'Hybrid H/P';
        default:
            return '';
    }
};

type AtwEnergyLabelParamsType = {
    selectedProductFamily: string;
    controller: string;
    heatertype: HeaterType;
    prv: string;
    indoorunit: string;
    integratedController: false;
    integratedTank: false;
    outdoorunit: string;
    hybridindoorunit?: string;
    tank?: string;
};

const getAtwEnergyLabelParams = (state: Store): AtwEnergyLabelParamsType => {
    const { selSoftData, solution, functionType } = state.lead as AirToWaterLead;
    const indoorUnit = selSoftData?.solution?.indoorUnit;
    const outdoorUnit = selSoftData?.solution?.outdoorUnit;
    const waterTank = selSoftData?.solution?.waterTank;
    const { isMonobloc = false, energySources } = solution!;

    // Result placeholder
    const result = {
        selectedProductFamily: '',
        controller: getController(state) || '',
        heatertype: getHeaterType(state),
        prv: '',
        indoorunit: '',
        integratedController: false, // Send hardcoded false as default
        integratedTank: false, // Send hardcoded false as default
        outdoorunit: '',
        hybridindoorunit: '',
        tank: '',
    };

    // Selected Product Family
    result.selectedProductFamily = getEnergySourceType(energySources);

    // Product Range Value
    if (['ground', 'gas', 'oil'].includes(energySources.sort().join(','))) {
        result.prv = indoorUnit?.productRangeValue || '';
    }

    // Set UNIT materials
    if (isMonobloc) {
        // If solution is MonoBloc, send outdoor as indoor. Original indoor is ignored.
        result.indoorunit = getProductNameFromUnit(outdoorUnit) || '';
    } else {
        result.indoorunit = getProductNameFromUnit(indoorUnit) || '';
        result.outdoorunit = getProductNameFromUnit(outdoorUnit) || '';
        result.hybridindoorunit = getProductNameFromUnit(indoorUnit, true) || '';
    }

    // Tank
    if (
        functionType === FUNCTION_TYPE.heating_cooling_dhw ||
        functionType === FUNCTION_TYPE.heating_dhw
    ) {
        result.tank = getProductNameFromUnit(waterTank) || '';
    }

    // Return clean object
    return cleanObject(result, false) as AtwEnergyLabelParamsType;
};

type AtaEnergyLabelParamsType = {
    product: string;
    locale: 'en_US'; // Fixed value
};
export const getAtaEnergyLabelsParams = (state: Store): AtaEnergyLabelParamsType => {
    let indoorUnits = '';
    let outdoorUnit = '';
    const { toolData } = state.lead;

    if (isAirToAirCoolingLead(state)) {
        indoorUnits = toolData?.sizingCooling?.solution?.indoor || '';
        outdoorUnit = toolData?.sizingCooling?.solution?.outdoor || '';
    } else if (isAirToAirHeatingLead(state)) {
        indoorUnits = toolData?.sizingWinterHeating?.solution?.indoor || '';
        outdoorUnit = toolData?.sizingWinterHeating?.solution?.outdoor || '';
    } else if (isAirToAirMultiRoomLead(state)) {
        indoorUnits =
            toolData?.sizingMultiRoom?.rooms
                ?.map((room) => room.indoor['product-name'])
                .join(' / ') || '';
        outdoorUnit = toolData?.sizingMultiRoom?.outdoor?.['product-name'] || '';
    }

    return {
        product: !!outdoorUnit ? `${indoorUnits} / ${outdoorUnit}` : indoorUnits,
        locale: 'en_US',
    };
};

type AtaMultiPlusTankEnergyLabelParamsType = {
    indoorunit: string;
    integratedController: false; // hardcoded
    integratedTank?: string;
    solarpump?: string;
    outdoorunit?: string;
    hybridindoorunit?: string;
    solarcollector?: string;
    amountofcollectors?: string;
    tank: string;
    selectedProductFamily: 'Multi+'; // hardcoded
    controller?: string;
    secondaryHeaterProduct?: string;
    locale: 'en_US'; // Fixed value
};
const getAtaMultiPlusTankEnergyLabelsParams = (
    state: Store,
): AtaMultiPlusTankEnergyLabelParamsType => {
    const { toolData } = state.lead;
    const indoorunit = toolData?.sizingMultiRoom?.outdoor?.['product-name'] || '';
    const tank =
        toolData?.sizingMultiRoom?.['solution-domestic-hot-water-tank']?.[
            'domestic-hot-water-tank'
        ]?.['product-name']!;
    return {
        indoorunit,
        integratedController: false,
        integratedTank: '',
        solarpump: '',
        outdoorunit: '',
        hybridindoorunit: '',
        solarcollector: '',
        amountofcollectors: '',
        tank,
        selectedProductFamily: 'Multi+',
        controller: '',
        secondaryHeaterProduct: '',
        locale: 'en_US',
    };
};

type AtaMultiPlusHeatPumpEnergyLabelParamsType = {
    indoorunit: string;
    heatertype: 'Water Heater'; // hardcoded
    additionalheater?: string;
    prv: string; // value from hardcoded list
    integratedController: false;
    integratedTank?: string;
    solarpump?: string;
    outdoorunit?: string;
    hybridindoorunit?: string;
    solarcollector?: string;
    amountofcollectors?: string;
    tank?: string;
    selectedProductFamily: 'Air to Water H/P'; // hardcoded
    controller?: string;
};
const getAtaMultiPlusHeatPumpEnergyLabelsParams = (
    state: Store,
): AtaMultiPlusHeatPumpEnergyLabelParamsType => {
    const { heatPump } = state.settings.multiPlusSolutions;
    const totalVolumeRequiredAtStorageTemperature = getTotalVolumeRequired(state);

    const possibleHeatPumpVolume = heatPump?.selectedVolumes?.find(
        (volume) => volume > totalVolumeRequiredAtStorageTemperature,
    );
    const indoorunit = heatPump?.solutions[`volume${possibleHeatPumpVolume!}`].productName!;
    const prv = heatPump?.solutions[`volume${possibleHeatPumpVolume!}`].prv!;

    return {
        indoorunit,
        heatertype: 'Water Heater',
        additionalheater: '',
        prv,
        integratedController: false,
        integratedTank: '',
        solarpump: '',
        outdoorunit: '',
        hybridindoorunit: '',
        solarcollector: '',
        amountofcollectors: '',
        tank: '',
        selectedProductFamily: 'Air to Water H/P',
        controller: '',
    };
};

export const getEnergyLabelParams = (
    state: Store,
): AtwEnergyLabelParamsType | AtaEnergyLabelParamsType => {
    const isAtwLead = isAirToWaterLead(state.lead);
    return isAtwLead ? getAtwEnergyLabelParams(state) : getAtaEnergyLabelsParams(state);
};

export const getMultiPlusEnergyLabelParams = (
    state: Store,
): AtaMultiPlusTankEnergyLabelParamsType | AtaMultiPlusHeatPumpEnergyLabelParamsType | null => {
    const { multiPlusType } = state.lead as AirToAirLead;
    if (!multiPlusType) return null;
    // Lead has a tank or heatPump
    const isTank = multiPlusType === MULTI_PLUS_TYPE.tank;
    return isTank
        ? getAtaMultiPlusTankEnergyLabelsParams(state)
        : getAtaMultiPlusHeatPumpEnergyLabelsParams(state);
};
