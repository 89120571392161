/* eslint-disable wrap-iife */
import boiler from '@boiler';
import i18n from '@common/i18n';
import { createLogicMiddleware } from 'redux-logic';
import App from './App';
import { SOLUTION_TYPE } from './constants';
import logic from './redux/logic';
import reducers from './redux/reducers';

const defaultLabels = {
    error_fetching_settings: 'An unknown error occurred, please try again later',
    goto_homepage: 'Go to homepage',
    start_again: 'Start again',
};

const hashRegExp = /#([\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12})/;
const queryRegExp = /lead=([\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12})/;

const mapInputToState = (_input) => {
    const targetEle = document.getElementsByClassName('rsn')[0];
    const { language, country, context, ...aemSettings } = targetEle.dataset;

    // Set default labels so the error page can still be translated if fetching the setting or another call fails
    i18n.set(defaultLabels);

    const leadIdFromHash = document.location.hash.match(hashRegExp)?.[1];
    const leadIdFromQuery = document.location.search.match(queryRegExp)?.[1];
    // Check if the url is the old format (ie.: hsn.com/#guid and convert it to hsn.com?lead=guid)
    if (!leadIdFromQuery && leadIdFromHash) {
        // Remove hashbang
        window.location.href.replace(window.location.hash, '');
        // Set as QueryString
        const params = new URLSearchParams(window.location.search);
        params.set('lead', leadIdFromHash);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    };
    const solutionType = (context === 'ra') ? SOLUTION_TYPE.room_airco : SOLUTION_TYPE.heating;

    return {
        settings: {
            leadId: leadIdFromQuery || leadIdFromHash,
            language,
            country: {
                currency: '',
                isoCode: country,
            },
            type: solutionType,
        },
        aemSettings,
    };
};

// eslint-disable-next-line func-names
(function () {
    boiler.initAppInstances(App, 'rsn', reducers, mapInputToState, [createLogicMiddleware(logic)]);
})();
