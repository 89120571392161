/* eslint-disable react/no-danger */
import React from 'react';
import { Multiline } from '@common/atoms/Multiline';
import { MODAL_TYPE } from '../../../../constants';
import * as ModalStyles from '../../../../styles/components/Modal';
import * as TypographyStyles from '../../../../styles/components/Typography';
import Modal from '../Modal';

type EvsCalculationDisclaimerProps = { disclaimerModalTitle: string; disclaimer: string };
type PropsType = {
    metaData: unknown;
};
const EvsCalculationDisclaimer = (props: PropsType): JSX.Element => {
    const { disclaimerModalTitle, disclaimer } = props.metaData as EvsCalculationDisclaimerProps;

    return (
        <Modal type={MODAL_TYPE.evsCalculationDisclaimer} isWide>
            <ModalStyles.ModalHeader>
                <TypographyStyles.H4 mb={5}>{disclaimerModalTitle}</TypographyStyles.H4>
            </ModalStyles.ModalHeader>
            <ModalStyles.ModalContent isWide width="inherit">
                <Multiline>
                    <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
                </Multiline>
            </ModalStyles.ModalContent>
        </Modal>
    );
};

export default EvsCalculationDisclaimer;
