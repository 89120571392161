/* eslint-disable react/no-danger */
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import { ALTHERMA_TYPE } from '@common/types';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { DETACHED_SCREEN_TYPE } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { openDetachedScreen, toggleAltherma } from '../../../redux/actions';
import { getCanUserToggleAltherma, getIsSmartAltherma } from '../../../redux/selectors';
import * as BasketStyles from '../../../styles/components/Basket';
import * as ButtonStyles from '../../../styles/components/Button';
import * as CardStyles from '../../../styles/components/Card';
import * as ToggleStyles from '../../../styles/components/Toggle';
import * as TypographyStyles from '../../../styles/components/Typography';
import { AirToWaterSbmSolution } from '../../../types';

const AlthermaToggle: FC = () => {
    const dispatch = useDispatch();
    const isSmart = useAppSelector(getIsSmartAltherma);
    const solution = useAppSelector((store) => store.lead.solution as AirToWaterSbmSolution);
    // UI handlers
    const onVideoClicked = (): void => {
        dispatch(openDetachedScreen(DETACHED_SCREEN_TYPE.videoPlayer, solution.smartVersionVideo));
    };
    const onSmartClicked = (): void => {
        if (!isSmart) dispatch(toggleAltherma(ALTHERMA_TYPE.smart));
    };
    const onRegularClicked = (): void => {
        if (isSmart) dispatch(toggleAltherma(ALTHERMA_TYPE.regular));
    };
    return (
        <BasketStyles.Altherma>
            <ToggleStyles.Toggle my={4}>
                <ToggleStyles.ToggleItem type="button" onClick={onSmartClicked} isActive={isSmart}>
                    {i18n('altherma_smart')}
                </ToggleStyles.ToggleItem>
                <ToggleStyles.ToggleItem
                    type="button"
                    onClick={onRegularClicked}
                    isActive={!isSmart}
                >
                    {i18n('altherma_regular')}
                </ToggleStyles.ToggleItem>
            </ToggleStyles.Toggle>
            <TypographyStyles.H4>
                {i18n(isSmart ? 'daikin_altherma_smart' : 'daikin_altherma_regular')}
            </TypographyStyles.H4>
            {isSmart && (
                <>
                    {solution.smartVersionDescription && (
                        <CardStyles.Box my={4}>
                            <TypographyStyles.Text
                                renderListAsChecks
                                dangerouslySetInnerHTML={{
                                    __html: solution.smartVersionDescription,
                                }}
                            />
                        </CardStyles.Box>
                    )}

                    <ButtonStyles.PrimaryLink as="button" mb={4} onClick={onVideoClicked}>
                        <Icons.Glyph.Video />
                        {i18n('watch_a_video')}
                    </ButtonStyles.PrimaryLink>
                </>
            )}
            <hr />
        </BasketStyles.Altherma>
    );
};

const Guard = (): JSX.Element | null => {
    const canTogglePhasing = useAppSelector(getCanUserToggleAltherma);
    return canTogglePhasing ? <AlthermaToggle /> : null;
};

export default Guard;
