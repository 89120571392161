import { breakpoint } from '@common/components/Breakpoints';
import styled, { css } from 'styled-components';
import { color, fontWeight, space, zIndex } from '../common/Theme';
import * as ButtonStyles from './Button';
import * as CardStyles from './Card';
import * as ImageStyles from './Image';

type DefaultProps = {
    isOpen?: boolean;
    height?: number;
};

export const Header = styled.div`
    display: flex;
    padding: ${space[5]}px 0;
    align-items: center;
    justify-content: space-between;

    @media ${breakpoint.down('lg')} {
        padding: ${space[4]}px;
        order: 1;
    }

    ${ImageStyles.Fluid} {
        margin-left: ${space[2]}px;
    }
`;

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${space[2]}px;

    ${ButtonStyles.PrimaryLink} {
        text-decoration: underline;
        text-underline-offset: 0.375rem;
    }
`;

export const HeaderImage = styled(ImageStyles.Fluid)`
    width: 3.75rem;
    height: 3.75rem;
    object-fit: contain;
`;

export const Phasing = styled.div`
    margin: ${space[4]}px 0;
    font-size: 16px;
    color: ${color.grey500};
    border: 1px solid ${color.grey400};
    border-radius: 3px;

    @media ${breakpoint.down('lg')} {
        margin: ${space[4]}px;
        margin-top: 0;
        order: 3;
    }
`;

export const Pricing = styled.div`
    @media ${breakpoint.down('lg')} {
        padding: 0 ${space[4]}px;
        order: 4;
    }
`;

export const Evs = styled.div`
    border: 2px solid ${color.green300};
    background-color: ${color.green100};
    border-radius: 4px;
    margin-bottom: ${space[4]}px;
    padding: ${space[3]}px;
    color: ${color.green400};
    a {
        color: ${color.green400};
        text-decoration: underline;
    }
    @media ${breakpoint.down('lg')} {
        margin: 0 ${space[4]}px;
        order: 5;
    }
`;

export const MobileContinueGroup = styled.div`
    @media ${breakpoint.down('lg')} {
        padding: 0 ${space[4]}px;
        order: 6;
    }
    @media ${breakpoint.up('lg')} {
        display: none;
    }
`;

export const ContinueButtonGroup = styled.div`
    @media ${breakpoint.down('lg')} {
        padding: 0 ${space[4]}px;
        order: 7;
    }
`;

export const CallToActions = styled.div`
    @media ${breakpoint.down('lg')} {
        order: 5;
        border-top: 1px solid ${color.grey200};
        padding: 20px 20px 0;
    }
    @media ${breakpoint.up('lg')} {
        border-top: 2px solid ${color.grey200};
        margin: 0px -30px;
        padding: 20px 30px 0;
    }
`;

export const CallToActionsTitle = styled.div`
    font-weight: bold;
`;
export const CallToActionsDescription = styled.div`
    padding: 1rem 0;
`;

export const Quotation = styled.div`
    padding-block-start: ${space[5]}px;
    border-block-start: 1px solid ${color.grey300};

    @media ${breakpoint.down('lg')} {
        padding-inline: ${space[4]}px;
    }
`;

export const Actions = styled(CardStyles.Footer)`
    display: flex;
    justify-content: space-around;
    padding: 0;
    background-color: ${color.grey100};
    border-top: 2px solid ${color.grey300};

    @media ${breakpoint.down('lg')} {
        margin-bottom: ${space[5]}px;
        order: 2;
        border-bottom: 2px solid ${color.grey300};
    }

    @media ${breakpoint.up('lg')} {
        margin: 0 -${space[5]}px;
    }

    ${ButtonStyles.Secondary} {
        padding: ${space[4]}px;
        width: 100%;
        justify-content: center;
        text-align: center;
    }

    ${ButtonStyles.Secondary} + ${ButtonStyles.Secondary} {
        border-left: 2px solid ${color.grey300};
    }
`;

export const ContinueButtonTeleportTarget = styled.div`
    button {
        width: 100%;
    }
    @media ${breakpoint.up('lg')} {
        margin-bottom: ${space[4]}px;
        &:empty {
            margin-bottom: 0px;
        }
        div::empty {
            padding: 0px;
        }
    }
    @media ${breakpoint.down('lg')} {
        padding: ${space[4]}px;
        order: 7;
        &:empty {
            padding: 0px;
        }
        div::empty {
            padding: 0px;
        }
    }
`;

export const ContactDealer = styled.div`
    margin-bottom: ${space[4]}px;
`;

export const MobileToggleButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -${space[3]}px;
    left: 50%;
    padding: 0;
    text-align: center;
    transform: translateX(-50%);
    width: ${space[5]}px;
    height: ${space[5]}px;
    background-color: ${color.grey000};
    border: 2px solid ${color.blue300};
    border-radius: 50%;
    z-index: ${zIndex.fixed};

    svg {
        flex-shrink: 0;
        color: ${color.blue300};
    }

    @media ${breakpoint.up('lg')} {
        display: none;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100vw;

    @media ${breakpoint.down('lg')} {
        // Max-height = 100vh - 20px margin-top - height Header - height ContinueButtonGroup
        max-height: calc(95vh - 20px - 100px - var(--ctaGroupHeight));
        max-height: calc(100dvh - 20px - 100px - var(--ctaGroupHeight));

        overflow-y: auto;
    }
`;

export const Basket = styled(CardStyles.Default)<DefaultProps>`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    border-radius: 10px 10px 0 0;
    z-index: ${zIndex.basket};

    ${({ height }) =>
        height &&
        css`
            --ctaGroupHeight: ${height}px;
        `};

    @media ${breakpoint.down('lg')} {
        max-width: 100vw;
        max-height: calc(95vh - ${space[4]}px); /* Fallback for dvh */
        max-height: calc(100dvh - ${space[4]}px);
        box-shadow: 0 -4px 20px 0 rgba(0, 0, 0, 0.1);
    }

    @media ${breakpoint.up('md')} {
        bottom: ${space[4]}px;
        max-width: 768px;
        margin: 0 auto;
        bottom: ${space[4]}px;
        border-radius: 10px;
    }

    @media ${breakpoint.up('lg')} {
        position: static;
        padding: ${space[5]}px ${space[5]}px 0;
        max-width: auto;
        border-radius: 0;
    }

    // Hide certain blocks below lg
    @media ${breakpoint.down('lg')} {
        ${Actions},
        ${Pricing},
        ${ContactDealer},
        ${Phasing},
        ${Quotation},
        ${CallToActions},
        ${Evs} {
            display: none;
        }
    }

    // Show mobile items on isOpen prop
    ${({ isOpen }) =>
        isOpen &&
        css`
            @media ${breakpoint.down('lg')} {
                padding-top: 100px;
                padding-bottom: var(--ctaGroupHeight);
                z-index: ${zIndex.basketOpen};

                ${Wrapper} {
                    padding-bottom: ${space[4]}px;
                }

                ${Pricing},
                ${ContactDealer},
                ${Phasing},
                ${Quotation},
                ${CallToActions},
                ${Evs} {
                    display: block;
                }

                ${Actions} {
                    display: flex;
                }

                ${MobileToggleButton} {
                    border-color: ${color.grey300};
                }

                ${Header} {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                }

                ${ContinueButtonGroup} {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding-top: ${space[5]}px;
                    background-color: ${color.grey000};
                    border-top: 1px solid ${color.grey200};
                    border-radius: 0 0 10px 10px;
                }
                ${ContinueButtonTeleportTarget} {
                    padding-bottom: ${space[4]}px;
                }
                ${ContinueButtonTeleportTarget}:empty {
                    padding: 0px;
                }
            }
        `};
`;

export const Backdrop = styled.div`
    @media ${breakpoint.down('lg')} {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${color.grey800};
        opacity: 0.7;
        z-index: ${zIndex.basketBackdrop};
    }
`;

export const Altherma = styled.div`
    margin-top: ${space[4]}px;
    font-size: 16px;
    color: ${color.grey500};

    @media ${breakpoint.down('lg')} {
        padding-inline: 20px;
    }
`;

export const GreyTextBlock = styled.div`
    background-color: ${color.grey100};
    padding: ${space[3]}px;
    margin-bottom: ${space[3]}px;
    div {
        font-size: 16px;
        color: ${color.grey500};
        font-weight: ${fontWeight.semibold};
    }
    @media ${breakpoint.down('lg')} {
        order: 2;
        margin: 0px ${space[4]}px ${space[4]}px ${space[4]}px;
    }
`;

export const BackToPersonalInfoButton = styled(ButtonStyles.Link)`
    text-decoration: underline !important;
    text-underline-offset: 0.375rem;
`;
