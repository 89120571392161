import * as Logger from '@common/Logger';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { APP_STATUS } from '../../../constants';
import { AirToAirLead, AirToWaterLead, IncentiveEventDataTypes, Store } from '../../../types';
import {
    DoneIncentivesDEActionType,
    INCENTIVES_DE,
    InitIncentivesDEActionType,
    setLeadLocal,
    UpdateIncentivesDEActionType,
} from '../../actions';
import {
    getDoneIncentivesDEToolDataPayload,
    getIncentivesGermanyPlatformJson,
    getUpdateIncentivesDEToolDataPayload,
    isGermany,
} from '../../selectors';

/* --- Types & Constants ----------------------------------------------------------------------- */

type DepObj = { getState: () => Store };

const RSN_HEADERS = {
    headers: {
        Accept: 'application/json',
    },
};

/* --- initIncentivesDELogic() ----------------------------------------------------------------- */

type InitDepObject = DepObj & { action: InitIncentivesDEActionType };
const initIncentivesDELogic = createLogic({
    type: INCENTIVES_DE.init,
    name: 'incentives.de.init',
    validate({ getState, action }: InitDepObject, allow, reject) {
        const state = getState();
        const isIncentivesPage = state.lead.status === APP_STATUS.incentives_tool;
        const isDE = isGermany(state);
        // Abort early
        if (!isIncentivesPage || !isDE) {
            return reject({ ...action, type: `🚫 (${action.type})` });
        }
        // Init incentives tool
        const toolData = state.lead.incentives?.toolData as IncentiveEventDataTypes.Germany.Update;
        const { step = '', tools = null } = toolData || {};
        Logger.logDebug({ tools });
        window._rsnChannel.publish('incentives.de.init', {
            target: 'div#external-tool-incentives-de',
            ctaTarget: 'div#cta-teleport-target',
            platform: getIncentivesGermanyPlatformJson(state),
            tools,
            step,
        });
        return allow(action);
    },
});

/* --- updateIncentivesDELogic() --------------------------------------------------------------- */

type UpdateDepObj = DepObj & { action: UpdateIncentivesDEActionType };
const updateIncentivesDELogic = createLogic({
    type: INCENTIVES_DE.update,
    name: 'incentives.de.update',
    async process({ getState }: UpdateDepObj, dispatch, done) {
        const state = getState();
        const payload = getUpdateIncentivesDEToolDataPayload(state);
        // Update drapo data on lead
        try {
            const { data } = await axios.post<{ lead: AirToWaterLead | AirToAirLead }>(
                state.settings.urls.updateIncentivesToolData,
                payload,
                RSN_HEADERS,
            );
            dispatch(
                setLeadLocal(data.lead, false, 'updateIncentivesDELogic', INCENTIVES_DE.update),
            );
            return done();
        } catch (err) {
            (err as Error).name = 'Error updating incentives for DE';
            Sentry.captureException(err);
            return done();
        }
    },
});

/* --- doneIncentivesDELogic() --------------------------------------------------------------- */

type DoneDepObj = DepObj & { action: DoneIncentivesDEActionType };
const doneIncentivesDELogic = createLogic({
    type: INCENTIVES_DE.done,
    name: 'incentives.de.update',
    async process({ getState }: DoneDepObj, dispatch, done) {
        const state = getState();
        const payload = getDoneIncentivesDEToolDataPayload(state);
        // Update drapo data on lead
        try {
            const { data } = await axios.post<{ lead: AirToWaterLead | AirToAirLead }>(
                state.settings.urls.updateIncentivesResultData,
                payload,
                RSN_HEADERS,
            );
            dispatch(setLeadLocal(data.lead, false, 'doneIncentivesDELogic', INCENTIVES_DE.done));
            return done();
        } catch (err) {
            (err as Error).name = 'Error incentives for DE tool is done';
            Sentry.captureException(err);
            return done();
        }
    },
});

/* --- Exports --------------------------------------------------------------------------------- */

export default [initIncentivesDELogic, updateIncentivesDELogic, doneIncentivesDELogic];
