import i18n from '@common/i18n';
import React, { FC } from 'react';
import type { ScopDetails } from '../../../redux/selectors';
import * as BadgeStyles from '../../../styles/components/Badge';
import * as GridStyles from '../../../styles/components/Grid';
import * as Styles from '../../../styles/components/Scop';
import * as TypographyStyles from '../../../styles/components/Typography';
import ScopGraph from './ScopGraph';

type PropsType = { scopDetails: ScopDetails; scopSvgId?: string };

const Scop: FC<PropsType> = (props) => {
    const { scopDetails, scopSvgId } = props;
    const { isHybrid, projectType, percentage, usage } = scopDetails;
    return (
        <Styles.Box>
            <Styles.Graph>
                <ScopGraph
                    scopSvgId={scopSvgId}
                    projectType={projectType}
                    renewablePercentage={percentage.renewable}
                    electricityPercentage={percentage.electricity}
                    gasPercentage={percentage.gas}
                />
            </Styles.Graph>
            <GridStyles.Row>
                <GridStyles.Column width={1 / 2}>
                    <BadgeStyles.Success mb={2}>
                        {`${(percentage.renewable * 100).toFixed(0)}%`}
                    </BadgeStyles.Success>
                    <TypographyStyles.SuccessText>{i18n('renewable')}</TypographyStyles.SuccessText>
                    <TypographyStyles.SmallText>
                        {`${usage.renewable.toFixed(0)} ${i18n(
                            'scop_renewable_annual_usage_unit',
                        )}`}
                    </TypographyStyles.SmallText>
                </GridStyles.Column>

                <GridStyles.Column width={1 / 2}>
                    <BadgeStyles.Warning mb={2}>
                        {`${((percentage.electricity + percentage.gas) * 100).toFixed(0)}%`}
                    </BadgeStyles.Warning>
                    <TypographyStyles.WarningText>
                        {i18n(isHybrid ? 'non_renewable_hybrid' : 'non_renewable')}
                    </TypographyStyles.WarningText>
                    <TypographyStyles.SmallText>
                        {`${usage.fossil.toFixed(0)} ${i18n(
                            'scop_non_renewable_annual_usage_unit',
                        )}`}
                    </TypographyStyles.SmallText>
                </GridStyles.Column>
            </GridStyles.Row>
        </Styles.Box>
    );
};

export default Scop;
