/* eslint-disable react/no-array-index-key */
import React, { FC, useCallback, useEffect, useState, useRef } from 'react';
import { indexArray, restrict } from '@tsUtils';
import * as Styles from '../../../styles/components/Carousel';

type PropsType = {
    index: number;
    itemCount: number;
    onSelect: (index: number) => void;
};

// Spacing = 8L & 8R
const MAX_W = 8 + 60 + 8;
const MIN_W = 8 + 15 + 8;

const CarouselNavigation: FC<PropsType> = ({ index, itemCount, onSelect }) => {
    // Local state
    const [itemWidth, setItemWidth] = useState(MAX_W);
    const [scrolling, setScrolling] = useState(false);

    // Cast to get it to play nice with styled-components
    const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    // Recalculate item width
    const calculateIndicatorDimensions = useCallback(() => {
        const totalWidth = containerRef.current.clientWidth;
        const width = restrict(totalWidth / itemCount, MIN_W, MAX_W) as number;
        // Check if calculated width of all items combined exceeds the available width
        setScrolling(width * itemCount > totalWidth);
        setItemWidth(width);
    }, [itemCount, containerRef]);

    // Listen for browser resize events
    useEffect(() => {
        window.addEventListener('resize', calculateIndicatorDimensions);
        return () => {
            window.removeEventListener('resize', calculateIndicatorDimensions);
        };
    }, [calculateIndicatorDimensions]);

    // Calculate for the first time
    useEffect(calculateIndicatorDimensions, []);

    // Calculate offset
    let marginLeft = '0px';
    if (scrolling) {
        let perc = index / itemCount;
        if (perc < 0.5) perc = perc * perc * 2;
        if (perc > 0.5) perc = 1 - ((1 - perc) * (1 - perc) * 2); // prettier-ignore
        const px = Math.round(containerRef.current.clientWidth * perc);
        marginLeft = `calc(${px}px - ${Math.round(100 * perc)}%`;
    }

    return (
        <Styles.SliderControls ref={containerRef} scrolling={scrolling.toString()}>
            <Styles.FadeLeft />
            <Styles.SliderCenteringDiv>
                <div style={{ marginLeft, transition: 'margin 250ms ease-in-out' }}>
                    {indexArray(itemCount).map((i) => (
                        <Styles.SliderControl
                            key={i}
                            type="button"
                            width={itemWidth}
                            onClick={() => onSelect(i)}
                            isActive={i === index}
                        >
                            &nbsp;
                        </Styles.SliderControl>
                    ))}
                </div>
            </Styles.SliderCenteringDiv>
            <Styles.FadeRight />
        </Styles.SliderControls>
    );
};

export default CarouselNavigation;
