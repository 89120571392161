import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { FC, PropsWithChildren, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useOnClickOutside } from 'usehooks-ts';
import { MODAL_TYPE } from '../../../constants';
import { closeModal } from '../../../redux/actions';
import * as Styles from '../../../styles/components/Modal';
import * as ScreenreaderStyles from '../../../styles/components/Screenreader';
import PreventScrollHelper from '../PreventScrollHelper';

/* --- Types ----------------------------------------------------------------------------------- */

export type ModalProps = {
    // isActive: boolean;
    type: MODAL_TYPE;
    isWide?: boolean;
};

/* --- <Modal/> -------------------------------------------------------------------------------- */

const Modal: FC<PropsWithChildren<ModalProps>> = (props) => {
    // Props
    const { children, type, isWide = false } = props;
    // Redux
    const dispatch = useDispatch();
    const close = (): void => {
        dispatch(closeModal(type));
    };

    // Hooks
    const modalContentRef = useRef(null);
    useOnClickOutside<HTMLDivElement>(modalContentRef, close);

    // -- Render --

    return (
        <>
            <PreventScrollHelper enabled />
            <Styles.ModalWrapper>
                <Styles.ModalDialog>
                    <Styles.ModalContent ref={modalContentRef} isWide={isWide}>
                        <Styles.ModalCloseBtn type="button" onClick={close}>
                            <Icons.Glyph.CloseCircle />
                            <ScreenreaderStyles.SrOnly>{i18n('close')}</ScreenreaderStyles.SrOnly>
                        </Styles.ModalCloseBtn>
                        <Styles.ModalScrollArea>{children}</Styles.ModalScrollArea>
                    </Styles.ModalContent>
                </Styles.ModalDialog>
                <Styles.ModalBackdrop />
            </Styles.ModalWrapper>
        </>
    );
};

/* --- Exports --------------------------------------------------------------------------------- */

export default Modal;
