import { SizingEventDataTypes } from '../../../types/external/sizing';

export enum TOOL_SIZING_ATA_HEATING {
    init = '🧰/sizing/ata/heating/init',
    update = '🧰/sizing/ata/heating/update',
    done = '🧰/sizing/ata/heating/done',
}

export type InitSizingToolAtaHeatingAction = { type: TOOL_SIZING_ATA_HEATING.init };
export const initSizingToolAtaHeating = (): InitSizingToolAtaHeatingAction => ({
    type: TOOL_SIZING_ATA_HEATING.init,
});

export type UpdateSizingToolAtaHeatingAction = {
    type: TOOL_SIZING_ATA_HEATING.update;
    data: SizingEventDataTypes.AtaHeating.Update;
};
export const updateSizingToolAtaHeating = (
    data: SizingEventDataTypes.AtaHeating.Update,
): UpdateSizingToolAtaHeatingAction => ({
    type: TOOL_SIZING_ATA_HEATING.update,
    data,
});

export type DoneSizingToolAtaHeatingAction = {
    type: TOOL_SIZING_ATA_HEATING.done;
    data: SizingEventDataTypes.AtaHeating.Done;
};
export const doneSizingToolAtaHeating = (
    data: SizingEventDataTypes.AtaHeating.Done,
): DoneSizingToolAtaHeatingAction => ({
    type: TOOL_SIZING_ATA_HEATING.done,
    data,
});

export type SizingAtaHeatingActionsType =
    | UpdateSizingToolAtaHeatingAction
    | InitSizingToolAtaHeatingAction
    | DoneSizingToolAtaHeatingAction;
