/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { color, fontWeight, space } from '../common/Theme';

type EnergyTagProps = {
    energyColor: string;
    energyText: string;
};
export const EnergyTag = styled.div<EnergyTagProps>`
    position: relative;
    display: flex;
    height: 40px;
    width: 100px;
    margin-top: ${space[2]}px;
    margin-right: ${space[4]}px;
    padding: ${space[2]}px;
    line-height: 30px;
    text-indent: -99999px;
    color: transparent;
    background-color: transparent;
    z-index: 90;
    border-radius: 2px;
    box-sizing: border-box;
    clear: both;
    ${(prop) =>
        prop.energyColor &&
        css`
            background-color: ${prop.energyColor};
        `};

    ::before {
        position: absolute;
        display: inline-block;
        left: 10px;
        text-indent: 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: ${fontWeight.regular};
        color: ${color.grey000};
        z-index: 80;
        ${(prop) =>
            prop.energyText &&
            css`
                content: '${prop.energyText}';
            `}
    }

    ::after {
        position: absolute;
        display: inline-block;
        top: 6px;
        right: -13px;
        width: 28px;
        height: 28px;
        transform: rotate(45deg);
        border-radius: 2px;
        content: '';
        z-index: 75;
        ${(prop) =>
            prop.energyColor &&
            css`
                background-color: ${prop.energyColor};
            `}
    }
`;
