import { useDeviceType } from '@common/hooks/tsHooks';
import { DEVICE_TYPE } from '@common/types';
import { useEffect, useState } from 'react';
import { EvsAtwData } from '../types';

const getTotalNavigationItems = (
    deviceType: DEVICE_TYPE,
    totalBenchmarkSolutions: number,
): number => {
    switch (deviceType) {
        case DEVICE_TYPE.mobile:
            return totalBenchmarkSolutions + 1;
        case DEVICE_TYPE.tablet:
            return totalBenchmarkSolutions;
        case DEVICE_TYPE.desktop:
        default:
            return Math.ceil(totalBenchmarkSolutions / 2);
    }
};

const useVisibleItems = (deviceType: DEVICE_TYPE): number => {
    const [visibleItems, setVisibleItems] = useState<number>(2);
    useEffect(() => {
        switch (deviceType) {
            case DEVICE_TYPE.mobile:
                setVisibleItems(0);
                break;
            case DEVICE_TYPE.tablet:
                setVisibleItems(1);
                break;
            case DEVICE_TYPE.desktop:
            default:
                setVisibleItems(2);
                break;
        }
    }, [deviceType, setVisibleItems]);
    return visibleItems;
};

export type EvsModalNavigation = {
    totalSolutions: number;
    deviceType: DEVICE_TYPE;
    itemIndex: number;
    navigationIndex: number;
    totalNavigationItems: number;
    isRightChevronDisabled: boolean;
    isLeftChevronDisabled: boolean;
    navigate: (newIndex: number) => void;
};

export type EvsAtwModalNavigation = EvsModalNavigation & {
    visibleItems: number;
};

export const useEvsAtwModalNavigation = (evsData: EvsAtwData): EvsAtwModalNavigation => {
    const { benchmarkSolutions } = evsData.priceData;

    const deviceType = useDeviceType();
    const visibleItems = useVisibleItems(deviceType);

    const [navigationIndex, setNavigationIndex] = useState<number>(0); // Navigation index

    const totalNavigationItems = getTotalNavigationItems(deviceType, benchmarkSolutions.length);
    const isRightChevronDisabled = navigationIndex === totalNavigationItems - 1;
    const isLeftChevronDisabled = navigationIndex === 0;

    return {
        totalSolutions: 1 + benchmarkSolutions.length,
        visibleItems,
        deviceType,
        itemIndex:
            deviceType === DEVICE_TYPE.mobile ? navigationIndex : navigationIndex * visibleItems,
        navigationIndex,
        totalNavigationItems,
        isRightChevronDisabled,
        isLeftChevronDisabled,
        navigate: setNavigationIndex,
    };
};

type EvsataModalNavigation = EvsModalNavigation;

export const useEvsAtaModalNavigation = (): EvsataModalNavigation => {
    const deviceType = useDeviceType();

    const [navigationIndex, setNavigationIndex] = useState<number>(0); // Navigation index
    const visibleItems = useVisibleItems(deviceType);

    const totalNavigationItems = getTotalNavigationItems(deviceType, 1);
    const isRightChevronDisabled = navigationIndex === totalNavigationItems - 1;
    const isLeftChevronDisabled = navigationIndex === 0;

    return {
        totalSolutions: 2,
        deviceType,
        itemIndex:
            deviceType === DEVICE_TYPE.mobile ? navigationIndex : navigationIndex * visibleItems,
        navigationIndex,
        totalNavigationItems,
        isRightChevronDisabled,
        isLeftChevronDisabled,
        navigate: setNavigationIndex,
    };
};
