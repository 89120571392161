import React, { FC, PropsWithChildren } from 'react';
import type * as CSS from 'csstype';
import { color, space } from '../../../../styles/common/Theme';

const blue = `2px solid ${color.blue300}`;
const white = `1px solid white`;

type CellPropsType = {
    textAlign?: CSS.Property.TextAlign;
    isBold?: boolean;
    isDaikin?: boolean;
    isFirstCell?: boolean;
    isLastCell?: boolean;
    isTitleColumn?: boolean;
    placeholder?: boolean;
    color?: string;
    show?: boolean;
    bgColor?: string;
    verticalAlign?: string;
};
const Cell: FC<PropsWithChildren<CellPropsType>> = ({
    children,
    textAlign = 'right',
    isBold = false,
    isDaikin = false,
    isFirstCell = false,
    isLastCell = false,
    isTitleColumn = false,
    placeholder = false,
    color: textColor = '',
    show = true,
    bgColor,
    verticalAlign = '',
}) => {
    if (show === false) return null;

    const getBorderStyle = (): string => {
        if (isDaikin) return blue;
        if (isTitleColumn) return white;
        if (placeholder) return white;
        return '';
    };
    const borderStyle = getBorderStyle();

    return (
        <td
            style={{
                width: '230px',
                backgroundColor: bgColor,
                padding: `${space[3]}px`,
                textAlign,
                color: textColor,
                borderLeft: borderStyle,
                borderRight: borderStyle,
                borderTop: isFirstCell || placeholder ? borderStyle : '',
                borderBottom: isLastCell || placeholder ? borderStyle : '',
                verticalAlign,
            }}
        >
            {isBold ? <strong>{children}</strong> : children}
        </td>
    );
};

export default Cell;
