import React from 'react';
import { useAppSelector } from '../../../redux';
import {
    getCommercePrices,
    getShowPrices,
    isAirToAirMultiRoomLead,
} from '../../../redux/selectors';
import * as BasketStyles from '../../../styles/components/Basket';
import PriceList from '../prices/PriceList';

type PropsType = { showIncentives?: boolean; hideSplitPrice?: boolean };

// Wrapper component that fetches price & incentive data to render a <PriceList />
const BasketPrices = ({
    showIncentives = false,
    hideSplitPrice = false,
}: PropsType): JSX.Element => {
    const prices = useAppSelector(getCommercePrices);
    const commerceError = useAppSelector((store) => store.appState.commerceError);
    return (
        <BasketStyles.Pricing>
            <PriceList
                prices={prices}
                priceError={commerceError}
                showIncentives={showIncentives}
                hideSplitPrice={hideSplitPrice}
            />
        </BasketStyles.Pricing>
    );
};

const Guard = ({
    showIncentives = false,
    hideSplitPrice = false,
}: PropsType): JSX.Element | null => {
    const showPrices = useAppSelector((state) => {
        return (
            // Check pricing strategy
            getShowPrices(state) &&
            // Don't show prices for ATA multi-room leads
            !isAirToAirMultiRoomLead(state) &&
            // A solution must be selected
            !!state.lead.solution
        );
    });
    return showPrices ? (
        <BasketPrices showIncentives={showIncentives} hideSplitPrice={hideSplitPrice} />
    ) : null;
};

export default Guard;
