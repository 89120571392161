/* eslint-disable consistent-return */
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { APP_STATUS, MODAL_TYPE } from '../../constants';
import { Store } from '../../types';
import {
    APP_STATE_ACTIONS,
    FetchEvsDataAction,
    LEAD_ACTIONS,
    RecalculateBenchmarksAction,
    SetLeadLocalAction,
    SetManualIncentivesAction,
    UserNavigationAction,
    closeModal,
    fetchEvsData,
    openEvsCompareAutomatic,
    openModal,
    setEnergyPricesErrors,
    setEvsData,
    setLeadLocal,
    userNavigation,
} from '../actions';
import { getActiveModal, getIsEvsApplicable } from '../selectors';

enum LOGIC_ORIGIN {
    fetchPrices = 'fetchInitialPrices',
    fetchLeadLogic = 'fetchLeadLogic',
    setLeadRemoteLogic = 'setLeadRemoteLogic',
    saveDrapoToolDataLogic = 'saveDrapoToolDataLogic',
    setManualIncentivesLogic = 'setManualIncentivesLogic',
    recalculateBenchmarksLogic = 'recalculateBenchmarksLogic',
}

type DepObj = { getState: () => Store };

type SetLeadDepObj = { action: SetLeadLocalAction };

const triggerFetchEvsDataLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'evsLogic.triggerFetchEvsData',
    process({ action }: SetLeadDepObj, dispatch, done) {
        const { logicOrigin, lead } = action;
        if (logicOrigin === LOGIC_ORIGIN.recalculateBenchmarksLogic) return done();
        // Fetch EVS data after fetching prices
        if (logicOrigin === LOGIC_ORIGIN.fetchPrices) dispatch(fetchEvsData());
        // Fetch EVS data after page refesh or go back to selsoft result page
        else if (lead?.status === APP_STATUS.sp_selsoft_result) {
            if (
                logicOrigin === LOGIC_ORIGIN.fetchLeadLogic ||
                logicOrigin === LOGIC_ORIGIN.setLeadRemoteLogic
            ) {
                dispatch(fetchEvsData());
            }
        }
        // Fetch EVS data after lead is been updated, page refesh or settings incentives manually
        else if (lead?.status === APP_STATUS.incentives_tool) {
            if (
                logicOrigin === LOGIC_ORIGIN.setLeadRemoteLogic ||
                logicOrigin === LOGIC_ORIGIN.fetchLeadLogic ||
                logicOrigin === LOGIC_ORIGIN.setManualIncentivesLogic ||
                logicOrigin === LOGIC_ORIGIN.saveDrapoToolDataLogic
            ) {
                dispatch(fetchEvsData());
            }
        }
        done();
    },
});

type FetchEvsDepObj = DepObj & { action: FetchEvsDataAction };

const fetchEvsDataLogic = createLogic({
    type: APP_STATE_ACTIONS.fetchEvsData,
    name: 'evsLogic.fetchEvsDataLogic',
    validate({ getState, action }: FetchEvsDepObj, allow, reject) {
        if (getIsEvsApplicable(getState())) {
            allow(action);
        } else {
            reject({ ...action, type: `🚫 (${action.type}) - EVS is not applicable` });
        }
    },
    async process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        const { id } = state.lead;
        const url = state.settings.urls.evs;
        const params: string[] = [];
        params.push(`leadId=${id}`);
        axios
            .get(`${url}?${params.join('&')}`)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    dispatch(setEvsData(data.data));
                }
            })
            .catch(() => {
                dispatch(setEvsData(null));
            })
            .finally(done);
    },
});

type SetManualIncentivesDepObj = DepObj & { action: SetManualIncentivesAction };
const setManualIncentivesLogic = createLogic({
    type: APP_STATE_ACTIONS.setManualIncentives,
    name: 'evsLogic.setManualIncentivesLogic',
    async process({ getState, action }: SetManualIncentivesDepObj, dispatch, done) {
        const store = getState();
        const { id: leadId, version, status: appStatus } = store.lead;
        const url = getState().settings.urls.evsManualIncentive;
        const payload = {
            leadId,
            manualIncentive: action.manualIncentive,
            version,
        };

        // When setting the manual incentive we get a full lead object back
        axios
            .post(url, payload)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    if (appStatus !== APP_STATUS.incentives_tool)
                        dispatch(userNavigation(APP_STATUS.incentives_tool));
                    dispatch(
                        setLeadLocal(data.lead, false, 'setManualIncentivesLogic', action.type),
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(done);
    },
});

// By updating the energy prices we need to recalculate the benchmarks
type RecalculateBenchmarksDepObj = DepObj & { action: RecalculateBenchmarksAction };
const recalculateBenchmarksLogic = createLogic({
    type: LEAD_ACTIONS.recalculateBenchmarks,
    name: 'evsLogic.recalculateBenchmarksLogic',
    async process({ getState, action }: RecalculateBenchmarksDepObj, dispatch, done) {
        const state = getState();
        const { energyPrices } = action;
        const { energyPriceUpdate } = state.settings.urls;
        const { id } = state.lead;
        const params: string[] = [];
        params.push(`leadId=${id}`);

        axios
            .post(`${energyPriceUpdate}?${params.join('&')}`, energyPrices)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    const { version, ...evsData } = data.data;
                    dispatch(setEvsData(evsData));
                    dispatch(closeModal(MODAL_TYPE.energyPrices));
                    dispatch(
                        setLeadLocal(
                            { energyPrices, version },
                            true,
                            'recalculateBenchmarksLogic',
                            action.type,
                        ),
                    );
                }
            })
            .catch((err) => {
                const errors = err.response?.data?.errors;
                dispatch(setEnergyPricesErrors(errors));
            })
            .finally(done);
    },
});

type closeEvsModalDepObj = DepObj & { action: UserNavigationAction };
const closeEvsModalLogic = createLogic({
    type: APP_STATE_ACTIONS.userNavigation,
    name: 'evsLogic.closeEvsModalLogic',
    process({ getState, action }: closeEvsModalDepObj, dispatch, done) {
        const isFr = getState().lead.country === 'FR';
        const activeModal = getActiveModal(getState());
        const isIncentivesPage = action.page === APP_STATUS.incentives_tool;
        const isActiveModalEvs = activeModal?.type === MODAL_TYPE.evsAtwCompare;
        if (isFr && isIncentivesPage && isActiveModalEvs) {
            dispatch(closeModal(MODAL_TYPE.evsAtwCompare));
            // Set value that evs overlay should open automatically because calculating incentives is triggered via button in evs overlay
            dispatch(openEvsCompareAutomatic());
        }
        done();
    },
});

let ssTo: ReturnType<typeof setTimeout>;

const openEvsModalLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'evsLogic.openEvsModalLogic',
    process({ getState, action }: DepObj & SetLeadDepObj, dispatch, done) {
        const { logicOrigin } = action;
        const isFr = getState().lead.country === 'FR';
        const openEvsCompare = getState().appState.openEvsCompareAutomatic;
        const isLogicOriginDrapo = logicOrigin === LOGIC_ORIGIN.saveDrapoToolDataLogic;
        // Check if evs overlay should opened automatically
        if (isFr && isLogicOriginDrapo && openEvsCompare) {
            clearTimeout(ssTo);
            ssTo = setTimeout(() => {
                // No evsData is sent with the openModal action because it's old data
                // See triggerFetchEvsDataLogic, there the NEW evsData was fetched
                dispatch(openModal(MODAL_TYPE.evsAtwCompare));
                done();
            }, 750);
        } else {
            done();
        }
    },
});

export default [
    triggerFetchEvsDataLogic,
    fetchEvsDataLogic,
    setManualIncentivesLogic,
    recalculateBenchmarksLogic,
    closeEvsModalLogic,
    openEvsModalLogic,
];
