/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { createLogic } from 'redux-logic';
import { APP_STATUS } from '../../../constants';
import {
    TOOL_QUOTATION_ATW,
    InitQuotationToolAtwAction,
    UpdateQuotationToolAtwAction,
    setLeadRemote,
} from '../../actions';
import { Store } from '../../../types';
import {
    getQuotationPlatformJson,
    getQuotationToolsJson,
    getQuotationUnitsJson,
} from '../../selectors';

type DepObj = { getState: () => Store };

type InitDepObj = DepObj & { action: InitQuotationToolAtwAction };
const initQuotationAtwLogic = createLogic({
    type: TOOL_QUOTATION_ATW.init,
    name: 'quotation.atw.init',
    validate({ getState, action }: InitDepObj, allow, reject) {
        const state = getState();
        if (state.lead.status === APP_STATUS.sp_quotation_tool) {
            window._rsnChannel.publish('quotation.init', {
                target: 'div#external-tool-quotation-atw',
                ctaTarget: 'div#cta-teleport-target',
                platform: getQuotationPlatformJson(state),
                tools: getQuotationToolsJson(state),
                solution: getQuotationUnitsJson(state),
                step: state.lead.toolData?.quotationHeatingPage || null,
            });
            allow(action);
        } else {
            reject({ ...action, type: `🚫 (${action.type})` });
        }
    },
});

type UpdateDepObj = DepObj & { action: UpdateQuotationToolAtwAction };
const updateQuotationAtwApiLogic = createLogic({
    type: TOOL_QUOTATION_ATW.update,
    name: 'quotation.atw.update',
    process({ action }: UpdateDepObj, dispatch, done) {
        dispatch(
            setLeadRemote(
                APP_STATUS.sp_quotation_tool,
                `${APP_STATUS.sp_quotation_tool}.${action.data.step}`,
            ),
        );
        done();
    },
});

const doneQuotationAtwApiLogic = createLogic({
    type: TOOL_QUOTATION_ATW.done,
    name: 'quotation.atw.done',
    process(_, dispatch, done) {
        dispatch(setLeadRemote(APP_STATUS.sp_selsoft_result));
        done();
    },
});

export default [initQuotationAtwLogic, updateQuotationAtwApiLogic, doneQuotationAtwApiLogic];
