import * as Sentry from '@sentry/react';
import { isAxiosError } from '@tsUtils';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { Store } from '../../types';
import {
    ConfirmRoomsAction,
    ROOM_BUILDER_ACTIONS,
    setLeadLocal,
    setRoomBuilderErrors,
} from '../actions';
import { getConfirmRoomsPayload } from '../selectors';

type DepObj = { getState: () => Store };
type ConfirmRoomsDepObj = DepObj & { action: ConfirmRoomsAction };

const confirmRoomsLogic = createLogic({
    type: ROOM_BUILDER_ACTIONS.confirmRooms,
    name: 'roomBuilder.confirmRoomsLogic',
    async process({ getState, action }: ConfirmRoomsDepObj, dispatch, done) {
        const state = getState();
        const payload = getConfirmRoomsPayload(state);
        const roomBuilderUrl = state.settings.urls.roomBuilder;
        try {
            const result = await axios.post(roomBuilderUrl, payload);
            const { data } = result;
            if (result.status === 200 && data) {
                // Update reducer w/ new lead-data
                dispatch(setLeadLocal(data.lead, true, 'confirmRoomsLogic', action.type));
            }
            done();
        } catch (err) {
            if (isAxiosError(err)) {
                const errors = err.response?.data?.errors;
                if (errors) dispatch(setRoomBuilderErrors(errors));
                err.name = 'Error saving multi rooms';
            }
            Sentry.captureException(err);
            done();
        }
    },
});

export default [confirmRoomsLogic];
