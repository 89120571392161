import althermaLogic from './althermaLogic';
import cityPostalLogic from './cityPostalLogic';
import commerceLogic from './commerceLogic';
import dealerLogic from './dealerLogic';
import energyLabelsLogic from './energyLabelsLogic';
import epackagesLogic from './epackagesLogic';
import evsLogic from './evsLogic';
import roomBuilderLogic from './roomBuilderLogic';
import geocodeLogic from './geocodeLogic';
import gigyaLogic from './gigyaLogic';
import historyLogic from './historyLogic';
import drapoLogic from './incentives/drapoLogic';
import incentivesDeLogic from './incentives/incentivesDELogic';
import leadLogic from './leadLogic';
import literatureLogic from './literatureLogic';
import modalLogic from './modalLogic';
import phasingLogic from './phasingLogic';
import selsoftLogic from './selsoftLogic';
import settingsLogic from './settingsLogic';
import sftLogic from './sftLogic';
import submitLogic from './submitLogic';
import toolsLogic from './tools';
import trackingLogic from './trackingLogic';

export default [
    ...settingsLogic,
    ...gigyaLogic,
    ...leadLogic,
    ...sftLogic,
    ...roomBuilderLogic,
    ...trackingLogic,
    ...historyLogic,
    ...epackagesLogic,
    ...geocodeLogic,
    ...selsoftLogic,
    ...phasingLogic,
    ...toolsLogic,
    ...energyLabelsLogic,
    ...althermaLogic,
    ...drapoLogic,
    ...commerceLogic,
    ...evsLogic,
    ...dealerLogic,
    ...cityPostalLogic,
    ...incentivesDeLogic,
    ...literatureLogic,
    ...submitLogic,
    ...modalLogic,
];
