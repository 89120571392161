import i18n from '@common/i18n';
import { COLOR_VARIATIONS, COLOR_VARIATION_LABELS } from '@common/types';
import { arraysEqual } from '@tsUtils';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SftColorVariationName from '../../../atoms/SftColorVariationName';
import SftSingleRoomSolutionName from '../../../atoms/SftSingleRoomSolutionName';
import { MODAL_TYPE } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { assignSolutionToRooms, closeModal } from '../../../redux/actions';
import * as ButtonStyles from '../../../styles/components/Button';
import * as ModalStyles from '../../../styles/components/Modal';
import * as RoomBuilderStyles from '../../../styles/components/RoomBuilder';
import * as TypographyStyles from '../../../styles/components/Typography';
import Modal from './Modal';

type PropsType = { metaData: unknown };

type CastedPropsType = {
    solutionName: string;
    solutionId: string;
    chosenColor: COLOR_VARIATIONS | undefined;
    setShowSolutionsOverview: () => void;
};

const SelectRoomsModal = (props: PropsType): JSX.Element => {
    const dispatch = useDispatch();
    const { solutionName, solutionId, chosenColor, setShowSolutionsOverview } =
        props.metaData as CastedPropsType;
    const rooms = useAppSelector((state) => state.roomBuilder);
    const selectedRooms = rooms
        .filter((r) => {
            if (!chosenColor) return r.solution === solutionId;
            else return r.solution === solutionId && r.colorVariation === chosenColor;
        })
        .map((r) => r.id);
    const [checkboxes, setCheckboxes] = useState<Array<string>>(selectedRooms);

    const handleCheckboxChange = (roomId: string): void => {
        if (checkboxes.includes(roomId)) {
            setCheckboxes([...checkboxes.filter((c) => c !== roomId)]);
        } else {
            setCheckboxes([...checkboxes, roomId]);
        }
    };

    const onSelectRooms = (): void => {
        dispatch(assignSolutionToRooms(checkboxes, solutionId, chosenColor));
        dispatch(closeModal(MODAL_TYPE.selectRooms));
        setShowSolutionsOverview();
    };

    return (
        <Modal type={MODAL_TYPE.selectRooms}>
            <RoomBuilderStyles.RoomSelectModalWrapper>
                <ModalStyles.ModalHeader>
                    <TypographyStyles.H4 mb={5}>
                        {`${i18n('select_rooms_for')} ${solutionName}`}
                        {chosenColor && ` ${i18n(COLOR_VARIATION_LABELS[chosenColor])}`}
                    </TypographyStyles.H4>
                </ModalStyles.ModalHeader>
                <ModalStyles.ModalContent>
                    <RoomBuilderStyles.RoomSelectList>
                        {rooms.map((room, index) => {
                            return (
                                <RoomBuilderStyles.RoomSelectItem
                                    key={room.id}
                                    onClick={() => handleCheckboxChange(room.id)}
                                >
                                    <input
                                        type="checkbox"
                                        value={`${room.id}_${index + 1}`}
                                        checked={checkboxes.includes(room.id)}
                                    />
                                    <RoomBuilderStyles.RoomSelectLabel>
                                        {room.name ?? (
                                            <span>
                                                {i18n('room')} {index + 1}
                                            </span>
                                        )}
                                        {!!room.solution && (
                                            <TypographyStyles.Small>
                                                {i18n('current_unit')}
                                                &nbsp;
                                                <SftSingleRoomSolutionName
                                                    solutionId={room.solution}
                                                />
                                                {room?.colorVariation && (
                                                    <>
                                                        &nbsp;
                                                        <SftColorVariationName
                                                            colorVariation={room.colorVariation}
                                                        />
                                                    </>
                                                )}
                                            </TypographyStyles.Small>
                                        )}
                                    </RoomBuilderStyles.RoomSelectLabel>
                                </RoomBuilderStyles.RoomSelectItem>
                            );
                        })}
                    </RoomBuilderStyles.RoomSelectList>
                </ModalStyles.ModalContent>
                <ModalStyles.ModalFooter>
                    <ButtonStyles.Primary
                        ml="auto"
                        mb={0}
                        isInline
                        onClick={onSelectRooms}
                        // Make it possible to deselect room so solution can be unassigned from room
                        disabled={arraysEqual(selectedRooms, checkboxes)}
                    >
                        {i18n('confirm')}
                    </ButtonStyles.Primary>
                </ModalStyles.ModalFooter>
            </RoomBuilderStyles.RoomSelectModalWrapper>
        </Modal>
    );
};

export default SelectRoomsModal;
