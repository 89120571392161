import { createLogic } from 'redux-logic';
import { MODAL_TYPE, RSN_SECTION } from '../../constants';
import { Store } from '../../types';
import { APP_STATE_ACTIONS, openModal, RedirectAction } from '../actions';
// import { getCurrentSection, isPardotLinked } from '../selectors';

type DepObj = { getState: () => Store };
type RedirectDepObj = DepObj & { action: RedirectAction };

const redirectLogic = createLogic({
    type: APP_STATE_ACTIONS.redirect,
    name: 'redirectLogic',
    process({ action, getState }: RedirectDepObj, _dispatch, done) {
        window.open(action.url, action.target);
        // const state = getState();
        // Don't open the pardot modal temporarily because pardot is not ready
        // // While the user is on SFT we should not be saving to Pardot yet
        // const isSFT = getCurrentSection(state) === RSN_SECTION.section_1_solution_finder_tree;
        // if (isSFT || action.target === '_blank' || action.skipPardot || isPardotLinked(state)) {
        // } else {
        //     dispatch(openModal(MODAL_TYPE.pardot, { url: action.url }));
        // }
        done();
    },
});

const saveProgressLogic = createLogic({
    type: APP_STATE_ACTIONS.saveProgress,
    name: 'redirectLogic',
    process(_: DepObj, dispatch, done) {
        dispatch(openModal(MODAL_TYPE.pardot));
        done();
    },
});

export default [redirectLogic, saveProgressLogic];
