import i18n from '@common/i18n';
import React, { FC } from 'react';
import * as ButtonStyles from '../../../styles/components/Button';

type PropsType = {
    label: string;
    icon?: JSX.Element;
    onClick?: () => void;
};

const FooterButton: FC<PropsType> = ({ label, icon, onClick }) => {
    return (
        <ButtonStyles.Secondary onClick={onClick}>
            {icon}
            <span>{i18n(label)}</span>
        </ButtonStyles.Secondary>
    );
};

export default FooterButton;
