import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { useState } from 'react';
import * as ButtonStyles from '../../../styles/components/Button';
import * as TypographyStyles from '../../../styles/components/Typography';

type PropsType = { description: string };

const Description = ({ description }: PropsType): JSX.Element | null => {
    const [showReadMore, setShowReadMore] = useState(false);
    const onToggleReadMore = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setShowReadMore(!showReadMore);
    };
    if (!description) return null;
    return (
        <>
            {showReadMore && (
                <TypographyStyles.Text
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
            )}
            <ButtonStyles.Secondary mt={showReadMore ? 0 : 4} onClick={onToggleReadMore}>
                {showReadMore ? <Icons.Glyph.CloseCircle /> : <Icons.Glyph.PlusCircle />}
                <span>{i18n(showReadMore ? 'read_less' : 'read_more')}</span>
            </ButtonStyles.Secondary>
        </>
    );
};

export default Description;
