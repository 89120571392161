import { breakpoint } from '@common/components/Breakpoints';
import styled, { css } from 'styled-components';
import { color, space } from '../common/Theme';
import * as ButtonStyles from './Button';
import * as FormStyles from './Form';

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: ${space[5]}px;
    tr,
    th,
    td {
        border: 1px solid ${color.grey200};
        padding: 5px;
    }
`;

export const TableHeader = styled.div`
    justify-content: space-between;
    display: flex;
    font-weight: bold;
    margin: ${space[2]}px;
    p {
        margin: 0px;
    }
`;

type TextGroupProps = {
    renderSideBySide: boolean;
    textColor?: string;
    reverseRow?: boolean;
};
export const TextGroup = styled.div<TextGroupProps>`
    ${({ renderSideBySide }) =>
        renderSideBySide &&
        css`
            justify-content: space-between;
            display: flex;
        `}
    ${({ textColor }) =>
        textColor &&
        css`
            div {
                color: ${textColor || ''};
            }
        `}
        ${({ reverseRow }) =>
        reverseRow &&
        css`
            flex-direction: row-reverse;
        `}
`;
export const Disclaimer = styled.div`
    color: ${color.grey400};
`;

export const Navigation = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: ${space[4]}px;
    gap: ${space[4]}px;

    div:first-child {
        padding: ${space[2]}px 0px;
    }
    div:last-child {
        gap: ${space[4]}px;
        display: flex;
        div:last-child {
            display: flex;
            gap: ${space[2]}px;
        }
    }
    @media ${breakpoint.down('lg')} {
        justify-content: space-between;
        div:last-child {
            display: contents;
        }
    }
    @media ${breakpoint.down('sm')} {
        display: block;
        div:first-child { text-align: center; };
        div:last-child {
            gap: ${space[3]}px;
            display: flex;
            div:last-child {
                display: flex;
                gap: ${space[1]}px;
            }
        }
`;

type ChevronProps = {
    bgColor: string;
};
export const Chevron = styled.button<ChevronProps>`
    padding: ${space[2]}px;
    border-radius: 50%;
    color: white;
    border: none;
    margin: auto 0;
    ${({ bgColor }) =>
        css`
            background-color: ${bgColor};
        `};
`;

type PanelPropsType = {
    isAtaHeatingLead: boolean;
};
export const Panel = styled.div<PanelPropsType>`
    display: flex;
    align-items: ${({ isAtaHeatingLead }) => (isAtaHeatingLead ? 'flex-start' : 'center')};
    svg {
        flex-shrink: 0;
        margin-right: ${space[1]}px;
    }
    ${ButtonStyles.Link} {
        margin-left: auto;
        font-size: 13px;
    }
    .compare-info-line + .compare-info-line {
        margin-top: ${space[2]}px;
    }
    .compare-info-line {
        position: relative;
        .icon {
            position: absolute;
        }
        .text {
            padding-left: ${space[5]}px;
        }
    }
`;

export const Co2AdditionalInfoTitleLabel = styled.div`
    text-align: center;
    color: ${color.grey500};
`;

export const Co2AdditionalInfoTable = styled.div`
    color: ${color.grey500};
    background-color: ${color.grey100};
    display: block;
    text-align: center;
    justify-content: space-around;
    margin: ${space[4]}px 0 ${space[6]}px 0;
    padding: ${space[4]}px;

    @media ${breakpoint.down('sm')} {
        .co2InfoItem {
            padding: ${space[4]}px;
        }
    }

    @media ${breakpoint.up('sm')} {
        display: flex;
    }

    .divider-or {
        display: inline-block;
        align-items: inherit;

        @media ${breakpoint.up('sm')} {
            display: flex;
            align-items: center;
        }

        .text {
            position: relative;
            text-align: center;
            background-color: ${color.grey100};
            z-index: 10;
            display: block;
            padding: ${space[4]}px;

            @media ${breakpoint.up('sm')} {
                display: inline-block;
                padding: ${space[2]}px;
            }
        }

        &::before {
            position: absolute;
            content: '';
            width: 72%;
            height: 1px;
            left: 14%;
            margin: 13% 0;
            background-color: ${color.grey300};

            @media ${breakpoint.up('sm')} {
                width: 1px;
                height: 13%;
                margin: 0 3%;
                left: unset;
            }

            @media ${breakpoint.up('md')} {
                margin: 0 2%;
            }
        }
    }
`;

type InlineHeaderProps = {
    textColor?: string;
};
export const InlineHeader = styled.div<InlineHeaderProps>`
    margin-bottom: ${space[2]}px;
    ${({ textColor }) =>
        textColor &&
        css`
            color: ${textColor};
        `}
`;

type BoilerRunningCostProps = { height: string; showInlineHeaders: boolean };
export const BoilerRunningCost = styled.div<BoilerRunningCostProps>`
    height: ${({ height }) => height};
    display: flex;
    flex-direction: column;
    ${({ showInlineHeaders }) =>
        showInlineHeaders
            ? css`
                  div:nth-of-type(2) {
                      margin-top: auto;
                  }
              `
            : css`
                  div {
                      margin-top: auto;
                  }
              `}
`;

export const Input = styled(FormStyles.Input)`
    width: 95px !important;
    height: 48px;
    padding: 0px 10px;
    border-radius: 0px;
    @media ${breakpoint.down('lg')} {
        width: 70px !important;
    }
    @media ${breakpoint.down('md')} {
        width: 110px !important;
    }
`;

export const EnergyPrices = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 30px;
    @media ${breakpoint.down('lg')} {
        width: inherit;
        margin-bottom: 10px;
    }
    @media ${breakpoint.down('md')} {
        flex-direction: column;
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 10px;
    }
`;

export const EnergyPrice = styled.div`
    div {
        padding-bottom: 5px;
    }
    @media ${breakpoint.down('lg')} {
        margin-bottom: 15px;
    }
`;

type InputTextProps = { display?: string };

export const InputText = styled(FormStyles.InputText)<InputTextProps>`
    // display: flex;
    justify-content: center;
    align-items: center;
    display: ${({ display }) => display ?? 'flex'};
`;
