import React, { FC } from 'react';
import { MODAL_TYPE } from '../../../constants';
import Modal from './Modal';
import * as ModalStyles from '../../../styles/components/Modal';

type PropsType = {
    metaData: unknown;
};

const InfoModal: FC<PropsType> = (props) => {
    const text = props.metaData as string;

    return (
        <Modal type={MODAL_TYPE.info}>
            <ModalStyles.ModalText>{text}</ModalStyles.ModalText>
        </Modal>
    );
};

export default InfoModal;
