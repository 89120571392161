import { COLOR_VARIATIONS } from '@common/types';
import React, { useEffect, useState } from 'react';
import { getColorVariations, getSftAirToAirOverviewImage } from '../../../redux/selectors';
import * as Styles from '../../../styles/components/Carousel';
import { AirToAirSftSolution } from '../../../types';
import ColorPicker from './ColorPicker';

type PropsType = {
    solution: AirToAirSftSolution;
    chosenColor?: COLOR_VARIATIONS;
    setChosenColor?: (color: COLOR_VARIATIONS) => void;
};

const SftImageViewer = (props: PropsType): JSX.Element => {
    // Fetch data from props
    const { solution } = props;
    // Fetch data from redux
    const colorVariations = getColorVariations(solution.indoorUnits);
    const hasColorVariations = colorVariations.length > 1;
    // Keep local state of chosenColor
    const [chosenColor, setChosenColor] = useState(props.chosenColor);
    // Notify caller.setChosenColor when a color was selected
    useEffect(() => {
        if (props.setChosenColor && chosenColor) props.setChosenColor(chosenColor);
    }, [chosenColor]);
    // Attempt to fetch image
    const image = getSftAirToAirOverviewImage(solution, true, chosenColor);

    return (
        <Styles.Carousel>
            <Styles.ImageWrapper>
                <Styles.Image role="img" aria-label={image.split('/').pop()} src={image} />
            </Styles.ImageWrapper>

            {hasColorVariations && (
                <ColorPicker
                    colors={colorVariations}
                    chosenColor={chosenColor}
                    setChosenColor={setChosenColor}
                />
            )}
        </Styles.Carousel>
    );
};

export default SftImageViewer;
