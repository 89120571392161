/* eslint-disable react/no-array-index-key */
import Spin from '@common/Spin';
import { SpinnerImages } from '@common/types';
import React, { useState } from 'react';
import { MODAL_TYPE } from '../../../constants';
import * as Styles from '../../../styles/components/Spin';
import * as ToggleStyles from '../../../styles/components/Toggle';
import * as TypographyStyles from '../../../styles/components/Typography';
import Modal from './Modal';

type PropsType = { metaData: unknown };

const SpinModal = (props: PropsType): JSX.Element => {
    const spinners = props.metaData as SpinnerImages;

    const [current, setCurrent] = useState(0);

    const spinner = spinners[current];

    return (
        <Modal type={MODAL_TYPE.spin360}>
            <>
                {spinners.length <= 1 && <TypographyStyles.H4>{spinner.name}</TypographyStyles.H4>}
                <Styles.Spin>
                    <Styles.ImageWrapper>
                        <Spin
                            images={spinner.images}
                            width={400}
                            height={600}
                            initialDirection="left"
                            initialFrames={5}
                            renderAsBackground
                        />
                    </Styles.ImageWrapper>
                </Styles.Spin>
                {spinners.length > 1 && (
                    <ToggleStyles.Toggle mt={4}>
                        {spinners.map((spin, index) => (
                            <ToggleStyles.ToggleItem
                                key={`${index}-${spin.name}`}
                                type="button"
                                onClick={() => setCurrent(index)}
                                isActive={index === current}
                            >
                                {spin.name}
                            </ToggleStyles.ToggleItem>
                        ))}
                    </ToggleStyles.Toggle>
                )}
            </>
        </Modal>
    );
};

export default SpinModal;
