import { RSN_SECTION } from '../../../constants';
import { Store } from '../../../types';
import { getCurrentSection } from '../genericSelectors';

export * from './aaltraSelectors.incentives';
export * from './aaltraSelectors.quotation';
export * from './aaltraSelectors.sizing';

export const shouldInjectAaltraScript = (store: Store): boolean => {
    return getCurrentSection(store) > RSN_SECTION.section_1_solution_finder_tree;
};
