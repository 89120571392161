/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import { DEVICE_TYPE } from '@common/types';
import { transpose } from '@tsUtils';
import React from 'react';
import { color } from '../../../../../styles/common/Theme';
import * as EvsStyles from '../../../../../styles/components/Evs';
import {
    EvsAtaHeatingCo2DataBenchmarkSol,
    EvsAtaHeatingCo2DataDaikinSol,
} from '../../../../../types';
import Cell from '../Cell';

type PropsType = {
    itemIndex: number;
    deviceType: DEVICE_TYPE;
    daikinSolution: EvsAtaHeatingCo2DataDaikinSol;
    boilerSolution: EvsAtaHeatingCo2DataBenchmarkSol;
};
const EvsAtaHeatingCo2Compare = ({
    itemIndex,
    deviceType,
    daikinSolution,
    boilerSolution,
}: PropsType): JSX.Element => {
    const isDesktop = deviceType === DEVICE_TYPE.desktop;
    const textAlign = isDesktop ? 'right' : 'left';
    const showInlineHeaders = !isDesktop;

    const titleColumn = [
        <Cell key="name" isTitleColumn isBold isFirstCell textAlign="left" />,
        <Cell key="co2-emissions-per-year" isTitleColumn isBold textAlign="left">
            <div dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_per_year') }} />
        </Cell>,
        <Cell key="total-co2-emission" isTitleColumn isBold textAlign="left">
            <div dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_in_15_years') }} />
        </Cell>,
        <Cell
            key="less-more-co2-emission"
            isTitleColumn
            isBold
            isLastCell
            textAlign="left"
            verticalAlign="top"
        >
            <div dangerouslySetInnerHTML={{ __html: i18n('co2_saved') }} />
        </Cell>,
    ];

    const daikinColumn = [
        <Cell
            key="daikin-sol-name"
            isBold
            isDaikin
            isFirstCell
            textAlign="left"
            color={color.blue400}
        >
            {i18n('daikin_solution')}
        </Cell>,
        <Cell key="daikin-co2-emissions-per-year" isDaikin textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_per_year') }} />
                </EvsStyles.InlineHeader>
            )}
            {daikinSolution.co2EmissionsPerYear}&nbsp;{i18n('kg')}
        </Cell>,
        <Cell key="daikin-total-co2-emission" isDaikin textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong
                        dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_in_15_years') }}
                    />
                </EvsStyles.InlineHeader>
            )}
            {daikinSolution.totalCo2Emissions}&nbsp;{i18n('kg')}
        </Cell>,
        <Cell
            key="daikin-less-more-co2-emission"
            isDaikin
            isLastCell
            textAlign={textAlign}
            bgColor={color.green100}
            color={color.green300}
        >
            {showInlineHeaders && (
                <EvsStyles.InlineHeader textColor={!isDesktop ? color.green300 : ''}>
                    <strong dangerouslySetInnerHTML={{ __html: i18n('co2_saved') }} />
                </EvsStyles.InlineHeader>
            )}
            <EvsStyles.TextGroup
                renderSideBySide
                reverseRow={showInlineHeaders}
                textColor={color.green300}
            >
                <Icons.Glyph.ThumbsUp size={40} />
                <div>
                    <div>
                        {i18n('emission_per_year').replace('{kg}', daikinSolution.savingsPerYear)}
                    </div>
                    <div>
                        {i18n('emission_in_15_years').replace('{kg}', daikinSolution.totalSavings)}
                    </div>
                </div>
            </EvsStyles.TextGroup>
        </Cell>,
    ];

    const boilerColumn = [
        <Cell key="boiler-name" isFirstCell isBold textAlign="left">
            {i18n('boiler_solution')}
        </Cell>,
        <Cell key="boiler-co2-emissions-per-year" textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_per_year') }} />
                </EvsStyles.InlineHeader>
            )}
            {boilerSolution.co2EmissionsPerYear}&nbsp;{i18n('kg')}
        </Cell>,
        <Cell
            key="boiler-total-co2-emission"
            textAlign={textAlign}
            bgColor={color.red100}
            color={color.red300}
        >
            {showInlineHeaders && (
                <EvsStyles.InlineHeader textColor={!isDesktop ? color.red300 : ''}>
                    <strong
                        dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_in_15_years') }}
                    />
                </EvsStyles.InlineHeader>
            )}
            {boilerSolution.totalCo2Emissions}&nbsp;{i18n('kg')}
        </Cell>,
        <Cell key="boiler-less-more-co2-emission" isLastCell textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong dangerouslySetInnerHTML={{ __html: i18n('co2_saved') }} />
                </EvsStyles.InlineHeader>
            )}
            <EvsStyles.TextGroup
                renderSideBySide
                reverseRow={showInlineHeaders}
                textColor={color.grey500}
            >
                <Icons.Glyph.ThumbsDown size={40} />
                <div
                    dangerouslySetInnerHTML={{
                        __html: i18n('more_co2_emission').replace(
                            '%d',
                            String(boilerSolution.percentageMoreCo2Emission),
                        ),
                    }}
                />
            </EvsStyles.TextGroup>
        </Cell>,
    ];

    const getVisibleSolutions = (): JSX.Element[][] => {
        switch (deviceType) {
            case DEVICE_TYPE.mobile:
                return [[daikinColumn, boilerColumn][itemIndex]];
            case DEVICE_TYPE.tablet:
                return [daikinColumn, boilerColumn];
            case DEVICE_TYPE.desktop:
                return [titleColumn, daikinColumn, boilerColumn];
            default:
                return [];
        }
    };
    const transposedArray: JSX.Element[][] = transpose(getVisibleSolutions());

    return (
        <EvsStyles.Table>
            <tbody>
                {transposedArray.map((cellArr, rowIndex) => (
                    <tr key={`row-${rowIndex}`}>{cellArr}</tr>
                ))}
            </tbody>
        </EvsStyles.Table>
    );
};

export default EvsAtaHeatingCo2Compare;
