/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { createLogic } from 'redux-logic';
import { APP_STATUS } from '../../../constants';
import { AirToWaterLead, Store } from '../../../types';
import {
    InitSizingToolAtwAction,
    LEAD_ACTIONS,
    TOOL_SIZING_ATW,
    UpdateSizingToolAtwAction,
    redirect,
    setLeadRemote,
} from '../../actions';
import { getSizingPlatformJson, shouldCallSelSoft } from '../../selectors';

type DepObj = { getState: () => Store };

type InitDepObj = DepObj & { action: InitSizingToolAtwAction };
const initSizingAtwLogic = createLogic({
    type: TOOL_SIZING_ATW.init,
    name: 'sizing.atw.init',
    validate({ getState, action }: InitDepObj, allow, reject) {
        const state = getState();
        if (state.lead.status === APP_STATUS.sp_sizing_tool) {
            window._rsnChannel.publish('sizing.heating.init', {
                target: 'div#external-tool-sizing-atw',
                ctaTarget: 'div#cta-teleport-target',
                platform: getSizingPlatformJson(state),
                tools: null,
                step: null,
            });
            allow(action);
        } else {
            reject({ ...action, type: `🚫 (${action.type})` });
        }
    },
});

type UpdateDepObj = DepObj & { action: UpdateSizingToolAtwAction };
const updateSizingAtwApiLogic = createLogic({
    type: TOOL_SIZING_ATW.update,
    name: 'sizing.atw.update',
    process({ action }: UpdateDepObj, dispatch, done) {
        dispatch(
            setLeadRemote(
                APP_STATUS.sp_sizing_tool,
                `${APP_STATUS.sp_sizing_tool}.${action.data.step}`,
            ),
        );
        done();
    },
});

const sendSelSoftResponseToSizingLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'sizing.atw.selsoft',
    process({ getState }: DepObj, _, done) {
        // Should we notify sizing tools?
        const state = getState();
        const lead = state.lead as AirToWaterLead;
        if (lead.status === APP_STATUS.sp_sizing_tool) {
            if (shouldCallSelSoft(state) && lead.selSoftData) {
                const hasValidSolution = !!lead.selSoftData?.solution;
                window._rsnChannel.publish('sizing.heating.selsoft', {
                    'has-valid-solution': hasValidSolution,
                });
            }
        }
        done();
    },
});

const doneSizingAtwApiLogic = createLogic({
    type: TOOL_SIZING_ATW.done,
    name: 'sizing.atw.done',
    process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        if (state.lead.toolData!.sizingHeating!['no-solution']) {
            // Save the no-solution data to lead but without advancing to sp_selsoft_result.
            // noSolutionLogic will trigger when setLeadRemote has finished
            // dispatch(setLeadRemote());

            // FIXME: The above should be re-enabled after the AEM no solutions page is delivered
            // For now: redirect to P5 where we will show a "No solutions found" warning
            dispatch(setLeadRemote(APP_STATUS.dealer_selection));
        } else {
            dispatch(setLeadRemote(APP_STATUS.sp_selsoft_result));
        }
        done();
    },
});

const noSolutionLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'sizing.atw.no-results',
    process({ getState }: DepObj, dispatch, done) {
        if (getState().lead.toolData?.sizingHeating?.['no-solution']) {
            dispatch(redirect(getState().aemSettings.noSolutionPage, '_self', true));
        }
        done();
    },
});

export default [
    initSizingAtwLogic,
    updateSizingAtwApiLogic,
    sendSelSoftResponseToSizingLogic,
    doneSizingAtwApiLogic,
    // noSolutionLogic,
];
