/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { color, zIndex } from '../common/Theme';

export const Backdrop = css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${color.grey800};
    opacity: 0.7;
    z-index: ${zIndex.modalBackdrop};
`;
