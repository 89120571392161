import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PHASING_TYPE } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { updatePhasing } from '../../../redux/actions';
import { getCanUserTogglePhasing, getPhasing } from '../../../redux/selectors';
import * as BasketStyles from '../../../styles/components/Basket';
import * as ButtonStyles from '../../../styles/components/Button';
import * as FormStyles from '../../../styles/components/Form';
import * as PhasingStyles from '../../../styles/components/Phasing';

const PhasingToggle: FC = () => {
    const dispatch = useDispatch();
    const storePhasing = useAppSelector(getPhasing);
    const [open, setOpen] = useState(false);
    const [chosenPhasing, setChosenPhasing] = useState(storePhasing);
    const phases = [PHASING_TYPE.single_phase, PHASING_TYPE.three_phase];

    const onApply = (): void => {
        setOpen(false);
        dispatch(updatePhasing(chosenPhasing));
    };

    if (open) {
        return (
            <BasketStyles.Phasing>
                <PhasingStyles.Opened>
                    <PhasingStyles.Header>
                        <PhasingStyles.Title>{i18n('current_phasing_title')}</PhasingStyles.Title>
                    </PhasingStyles.Header>
                    <PhasingStyles.Body>
                        <PhasingStyles.Description>
                            {i18n('current_phasing_description')}
                        </PhasingStyles.Description>
                        <PhasingStyles.Options>
                            {phases.map((phase) => (
                                <FormStyles.RadioGroup key={phase}>
                                    <FormStyles.Radio
                                        id={phase}
                                        name="phase"
                                        value={phase}
                                        checked={phase === chosenPhasing}
                                        onChange={() => setChosenPhasing(phase)}
                                    />
                                    <FormStyles.Label htmlFor={phase} key={phase}>
                                        {i18n(phase)}
                                    </FormStyles.Label>
                                </FormStyles.RadioGroup>
                            ))}
                        </PhasingStyles.Options>
                        <ButtonStyles.Primary onClick={onApply}>
                            {i18n('apply')}
                            <Icons.Glyph.ArrowRight />
                        </ButtonStyles.Primary>
                    </PhasingStyles.Body>
                </PhasingStyles.Opened>
            </BasketStyles.Phasing>
        );
    } else {
        return (
            <BasketStyles.Phasing>
                <PhasingStyles.Closed onClick={() => setOpen(true)}>
                    <PhasingStyles.Label>{i18n('your_home')}</PhasingStyles.Label>
                    <PhasingStyles.Value>{i18n(chosenPhasing)}</PhasingStyles.Value>
                    <Icons.Glyph.Pencil />
                </PhasingStyles.Closed>
            </BasketStyles.Phasing>
        );
    }
};

const Guard = (): JSX.Element | null => {
    const canTogglePhasing = useAppSelector(getCanUserTogglePhasing);
    return canTogglePhasing ? <PhasingToggle /> : null;
};

export default Guard;
