import { QuotationEventDataTypes } from '../../../types/external/quotation';

export enum TOOL_QUOTATION_ATW {
    init = '🧰/quotation/atw/init',
    update = '🧰/quotation/atw/update',
    done = '🧰/quotation/atw/done',
}

export type InitQuotationToolAtwAction = { type: TOOL_QUOTATION_ATW.init };
export const initQuotationToolAtw = (): InitQuotationToolAtwAction => ({
    type: TOOL_QUOTATION_ATW.init,
});

export type UpdateQuotationToolAtwAction = {
    type: TOOL_QUOTATION_ATW.update;
    data: QuotationEventDataTypes.Atw.Update;
};
export const updateQuotationToolAtw = (
    data: QuotationEventDataTypes.Atw.Update,
): UpdateQuotationToolAtwAction => ({
    type: TOOL_QUOTATION_ATW.update,
    data,
});

export type DoneQuotationToolAtwAction = {
    type: TOOL_QUOTATION_ATW.done;
    data: QuotationEventDataTypes.Atw.Done;
};
export const doneQuotationToolAtw = (
    data: QuotationEventDataTypes.Atw.Done,
): DoneQuotationToolAtwAction => ({ type: TOOL_QUOTATION_ATW.done, data });

export type QuotationAtwActionsType =
    | InitQuotationToolAtwAction
    | UpdateQuotationToolAtwAction
    | DoneQuotationToolAtwAction;
