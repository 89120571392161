import styled, { css } from 'styled-components';
import { color, space } from '../common/Theme';
import * as ButtonStyles from './Button';

export const Carousel = styled.div`
    position: relative;
`;

export const Actions = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

export const Action = styled(ButtonStyles.Action)`
    margin-left: ${space[4]}px;

    svg {
        color: ${color.blue300};
    }
`;

export const ImageWrapper = styled.div``;

type ImageType = { src: string };

export const Image = styled.div<ImageType>`
    padding-top: 100%;

    ${({ src }) =>
        src &&
        css`
            background-image: url('${src}');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            object-fit: contain;
        `};
`;

export const Images = styled.div``;

type SliderControls = { scrolling: string };
export const SliderControls = styled.div<SliderControls>`
    justify-content: center;
    white-space: nowrap;
    margin-top: ${space[2]}px;
    margin-left: -${space[3]}px;
    overflow: hidden;
`;

export const FadeLeft = styled.div`
    pointer-events: none;
    position: absolute;
    z-index: 1;
    left: -15px;
    width: 8px;
    height: 25px;
    background: linear-gradient(70deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
`;

export const FadeRight = styled.div`
    pointer-events: none;
    position: absolute;
    z-index: 1;
    right: 0px;
    bottom: 0px;
    width: 8px;
    height: 25px;
    background: linear-gradient(70deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
`;

export const SliderCenteringDiv = styled.div`
    width: fit-content;
    margin: auto;
`;

type SliderControlFlags = { isActive?: boolean; width: number; cursor?: string };
export const SliderControl = styled.button<SliderControlFlags>`
    border: 8px solid white;
    width: ${({ width }) => width}px;
    height: 21px;
    cursor: pointer;
    background-color: ${color.grey300};
    transition: background-color 350ms linear;

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${color.blue300};
        `};
    ${({ cursor }) =>
        cursor &&
        css`
            cursor: ${cursor};
        `};
`;

type SliderTabFlags = { isActive?: boolean };
export const SliderTab = styled.button<SliderTabFlags>`
    margin: ${space[2]}px;
    width: auto;
    height: auto;
    background-color: ${color.grey100};
    border: none;

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${color.blue300};
        `};
`;
