import { ToolData } from '../../types';
import {
    ToolActionsType,
    TOOL_SIZING_ATW,
    TOOL_SIZING_ATA_COOLING,
    TOOL_SIZING_ATA_HEATING,
    TOOL_QUOTATION_ATW,
    TOOL_QUOTATION_ATA,
    TOOL_SIZING_ATA_MULTI_ROOM,
} from '../actions';

const initialState: Partial<ToolData> = {
    sizingHeating: undefined,
    sizingCooling: undefined,
    sizingWinterHeating: undefined,
    sizingMultiRoom: undefined,
    quotationHeating: undefined,
    quotationCooling: undefined,
};

const toolData = (
    state: Partial<ToolData> | null,
    action: ToolActionsType,
): Partial<ToolData> | null => {
    state = state || initialState;
    switch (action.type) {
        /* 🔥 SIZING 🔥 */
        case TOOL_SIZING_ATW.update:
            // Merge sizing update data into sizingHeating
            return {
                ...state,
                sizingHeating: {
                    ...(state.sizingHeating || {}),
                    ...action.data.tools['heating-sizing'],
                },
                sizingHeatingPage: action.data.step,
            };
        case TOOL_SIZING_ATW.done:
            return { ...state, sizingHeating: action.data.tools['heating-sizing'] };
        /* 🧊 SIZING 🧊 */
        case TOOL_SIZING_ATA_COOLING.update:
            // Merge sizing update data into sizingCooling
            return {
                ...state,
                sizingCooling: {
                    ...(state.sizingCooling || {}),
                    ...action.data.tools['cooling-sizing'],
                },
                sizingCoolingPage: action.data.step,
            };
        case TOOL_SIZING_ATA_COOLING.done:
            return { ...state, sizingCooling: action.data.tools['cooling-sizing'] };
        /* 🧊 SIZING WH 🧊 */
        case TOOL_SIZING_ATA_HEATING.update:
            // Merge sizing update data into sizingCooling
            return {
                ...state,
                sizingWinterHeating: {
                    ...(state.sizingWinterHeating || {}),
                    ...action.data.tools['winter-heating-sizing'],
                },
                sizingWinterHeatingPage: action.data.step,
            };
        case TOOL_SIZING_ATA_HEATING.done:
            return { ...state, sizingWinterHeating: action.data.tools['winter-heating-sizing'] };
        /* 🧊 SIZING ATA multi rooms 🧊 */
        case TOOL_SIZING_ATA_MULTI_ROOM.update:
            // Merge sizing update data into sizingMultiRoom
            return {
                ...state,
                sizingMultiRoom: {
                    ...(state.sizingMultiRoom || {}),
                    ...action.data.tools['air-to-air'],
                },
                sizingMultiRoomPage: action.data.step,
            };
        case TOOL_SIZING_ATA_MULTI_ROOM.done:
            return { ...state, sizingMultiRoom: action.data.tools['air-to-air'] };
        /* 🔥 QUOTATION 🔥 */
        case TOOL_QUOTATION_ATW.update:
            // Merge quotation update data into quotationHeating
            return {
                ...state,
                quotationHeating: {
                    ...(state.quotationHeating || {}),
                    ...action.data.tools['heating-quotation'],
                },
                quotationHeatingPage: action.data.step,
            };
        case TOOL_QUOTATION_ATW.done:
            // Overwrite entire object into quotationHeating
            return {
                ...state,
                quotationHeating: action.data.tools['heating-quotation'],
            };
        /* 🧊 QUOTATION 🧊 */
        case TOOL_QUOTATION_ATA.update:
            // Merge quotation update data into quotationCooling
            return {
                ...state,
                quotationCooling: {
                    ...(state.quotationCooling || {}),
                    ...action.data.tools['cooling-quotation'],
                },
                quotationCoolingPage: action.data.step,
            };
        case TOOL_QUOTATION_ATA.done:
            // Overwrite entire object into quotationCooling
            return {
                ...state,
                quotationCooling: action.data.tools['cooling-quotation'],
            };
        default:
            return state;
    }
};

export default toolData;
