/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const SrOnly = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px; /* Fix for https://github.com/twbs/bootstrap/issues/25686 */
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
`;
