/* eslint-disable consistent-return */
/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import * as Logger from '@common/Logger';
import i18n from '@common/i18n';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { AirToWaterLead, Lead, Store } from '../../types';
import { LEAD_ACTIONS, setCommerceError, setLeadLocal } from '../actions';
import {
    getGenericPayload,
    getShowCommercePrices,
    getShowPrices,
    isAirToAirProject,
    isAirToWaterProject,
} from '../selectors';

type ResponseType = {
    lead: Partial<Lead>;
    success: boolean;
};

const logCommerce = (message: string): void => {
    Logger.log(`[Commerce] ${message}`, Logger.STYLE.SOCKET);
};

let requestInProgress = false;

type DepObj = { getState: () => Store };

const fetchInitialPrices = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'commerceLogic.initialPrices',
    async process({ getState }: DepObj, dispatch, done) {
        const store = getState();
        const { lead, appState, settings } = store;
        // Only fetch if no prices have been set yet
        if (lead.prices !== null) return done();
        // Check pricingDisplayStrategy
        if (!getShowPrices(store)) return done();
        // Check if a request is in progress
        if (requestInProgress) return done();
        // Only fetch on P3 (ss result), P4 & p5
        if (!getShowCommercePrices(store)) return done();
        // For HE, only fetch if SelSoft data was set
        if (isAirToWaterProject(store) && !(lead as AirToWaterLead).selSoftData?.solution)
            return done();
        // For RA, only fetch if Quotation data is set
        if (isAirToAirProject(store) && !lead.toolData?.quotationCooling) return done();
        // Everything is OK, fetch commerce data
        logCommerce('Fetch commerce prices');
        requestInProgress = true;
        // Clear any previous errors
        if (appState.commerceError) dispatch(setCommerceError(null));
        // Link the current lead to the user
        const url = settings.urls.requestPrices;
        const payload = await getGenericPayload(store);
        axios
            .post<ResponseType>(url, payload)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    // Update reducer w/ new lead-data
                    dispatch(
                        setLeadLocal(
                            data.lead,
                            false,
                            'fetchInitialPrices',
                            LEAD_ACTIONS.setLeadLocal,
                        ),
                    );
                } else {
                    dispatch(setCommerceError(i18n('prices_unavailable')));
                }
            })
            .catch(() => {
                dispatch(setCommerceError(i18n('prices_unavailable')));
            })
            .finally(() => {
                requestInProgress = false;
                return done();
            });
    },
});

export default [fetchInitialPrices];
