import { SizingEventDataTypes } from '../../../types/external/sizing';

export enum TOOL_SIZING_ATA_COOLING {
    init = '🧰/sizing/ata/cooling/init',
    update = '🧰/sizing/ata/cooling/update',
    done = '🧰/sizing/ata/cooling/done',
}

export type InitSizingToolAtaCoolingAction = { type: TOOL_SIZING_ATA_COOLING.init };
export const initSizingToolAtaCooling = (): InitSizingToolAtaCoolingAction => ({
    type: TOOL_SIZING_ATA_COOLING.init,
});

export type UpdateSizingToolAtaCoolingAction = {
    type: TOOL_SIZING_ATA_COOLING.update;
    data: SizingEventDataTypes.AtaCooling.Update;
};
export const updateSizingToolAtaCooling = (
    data: SizingEventDataTypes.AtaCooling.Update,
): UpdateSizingToolAtaCoolingAction => ({
    type: TOOL_SIZING_ATA_COOLING.update,
    data,
});

export type DoneSizingToolAtaCoolingAction = {
    type: TOOL_SIZING_ATA_COOLING.done;
    data: SizingEventDataTypes.AtaCooling.Done;
};
export const doneSizingToolAtaCooling = (
    data: SizingEventDataTypes.AtaCooling.Done,
): DoneSizingToolAtaCoolingAction => ({
    type: TOOL_SIZING_ATA_COOLING.done,
    data,
});

export type SizingAtaCoolingActionsType =
    | InitSizingToolAtaCoolingAction
    | UpdateSizingToolAtaCoolingAction
    | DoneSizingToolAtaCoolingAction;
