import React from 'react';
import { MODAL_TYPE } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { getActiveModal } from '../../../redux/selectors';
import ContactADealerModal from './ContactADealerModal';
import EvsAtaHeatingCompareModal from './evs/ataHeating/CompareModal';
import EvsAtwCompareModal from './evs/atw/CompareModal';
import EnergyPricesModal from './evs/atw/EnergyPricesModal';
import EvsCalculationDisclaimer from './evs/EvsCalculationDisclaimer';
import ImageCarouselModal from './ImageCarouselModal';
import IndoorSolutionInfoModal from './IndoorSolutionInfoModal';
import InfoModal from './InfoModal';
import IntrusiveModal from './IntrusiveModal';
import PardotModal from './PardotModal';
import PriceDetailsModal from './PriceDetailsModal';
import SelectRoomsModal from './SelectRoomsModal';
import SftImageViewerModal from './SftImageViewerModal';
import SolutionSpecsModal from './SolutionSpecsModal';
import SpinModal from './SpinModal';

/* --- <ModalMgmt/> ---------------------------------------------------------------------------- */

type PropsType = { asOverlay?: boolean };

const ModalMgmt = ({ asOverlay = false }: PropsType): JSX.Element | null => {
    // Redux
    // Always render last modal in list
    const activeModal = useAppSelector((state) => getActiveModal(state, asOverlay));

    // -- Render --

    switch (activeModal?.type) {
        case MODAL_TYPE.sftImageViewer:
            return <SftImageViewerModal metaData={activeModal.metaData} />;
        case MODAL_TYPE.imageGallery:
            return <ImageCarouselModal metaData={activeModal.metaData} />;
        case MODAL_TYPE.spin360:
            return <SpinModal metaData={activeModal.metaData} />;
        case MODAL_TYPE.info:
            return <InfoModal metaData={activeModal.metaData} />;
        case MODAL_TYPE.contactADealer:
            return <ContactADealerModal metaData={activeModal.metaData} />;
        case MODAL_TYPE.intrusive:
            return <IntrusiveModal metaData={activeModal.metaData} />;
        case MODAL_TYPE.evsAtwCompare:
            return <EvsAtwCompareModal />;
        case MODAL_TYPE.evsAtaHeatingCompare:
            return <EvsAtaHeatingCompareModal metaData={activeModal.metaData} />;
        case MODAL_TYPE.evsCalculationDisclaimer:
            return <EvsCalculationDisclaimer metaData={activeModal.metaData} />;
        case MODAL_TYPE.selectRooms:
            return <SelectRoomsModal metaData={activeModal.metaData} />;
        case MODAL_TYPE.indoorSolutionInfo:
            return <IndoorSolutionInfoModal metaData={activeModal.metaData} />;
        case MODAL_TYPE.energyPrices:
            return <EnergyPricesModal />;
        case MODAL_TYPE.priceDetails:
            return <PriceDetailsModal />;
        case MODAL_TYPE.solutionSpecs:
            return <SolutionSpecsModal />;
        case MODAL_TYPE.pardot:
            return <PardotModal metaData={activeModal.metaData} />;
        default:
            return null;
    }
};

/* --- Exports --------------------------------------------------------------------------------- */

export default ModalMgmt;
