import { EnergyLabels } from '@common/data/variables';
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import { DHW_ENERGY_LABELS, ENERGY_LABELS } from '@common/types';
import React from 'react';
import * as Styles from '../../styles/components/EnergyLabels';
import * as GridStyles from '../../styles/components/Grid';
import EnergyTag from './EnergyTag';

type PropsType = {
    type: keyof EnergyLabels;
    rating: ENERGY_LABELS | DHW_ENERGY_LABELS;
    hasSolar: boolean;
    isUpTo?: boolean;
};

const TypeIcon = ({ type }: { type: keyof EnergyLabels }): JSX.Element | null => {
    switch (type) {
        case 'heating':
            return <Icons.Glyph.Sun />;
        case 'cooling':
            return <Icons.Glyph.Cooling />;
        case 'dhw':
            return <Icons.Glyph.Shower />;
        default:
            return null;
    }
};

const EnergyLabel = ({ type, rating, isUpTo, hasSolar }: PropsType): JSX.Element => (
    <GridStyles.Column width={1 / 2}>
        <Styles.EnergyLabel>
            <Styles.EnergyLabelText>
                <TypeIcon type={type} />
                {isUpTo ? (
                    i18n('energy_upto')
                ) : (
                    <EnergyTag rating={rating} type={type} hasSolar={hasSolar} />
                )}
            </Styles.EnergyLabelText>
            {isUpTo && <EnergyTag rating={rating} type={type} hasSolar={hasSolar} />}
        </Styles.EnergyLabel>
    </GridStyles.Column>
);

export default EnergyLabel;
