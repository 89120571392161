import i18n from '@common/i18n';
import React from 'react';
import { useAppSelector } from '../../../redux';
import * as SpecsTableStyles from '../../../styles/components/SpecsTable';
import InfoTrigger from '../InfoTrigger';

const Capacity = (): JSX.Element | null => {
    const quotation = useAppSelector((store) => store.lead.toolData?.quotationCooling);
    if (!quotation) return null;
    return (
        <SpecsTableStyles.Group hasBorder>
            {quotation.cooling?.['nominal-capacity'] !== undefined && (
                <SpecsTableStyles.ItemRow>
                    <SpecsTableStyles.ItemLabel>
                        {i18n('cooling_capacity')}
                        <InfoTrigger label="ra_cooling_capacity_tooltip" />
                    </SpecsTableStyles.ItemLabel>
                    <SpecsTableStyles.ItemValue>
                        {`${quotation.cooling!['nominal-capacity'].toFixed(2)} kW`}
                    </SpecsTableStyles.ItemValue>
                </SpecsTableStyles.ItemRow>
            )}
            {quotation.heating?.['nominal-capacity'] !== undefined && (
                <SpecsTableStyles.ItemRow>
                    <SpecsTableStyles.ItemLabel>
                        {i18n('heating_capacity')}
                    </SpecsTableStyles.ItemLabel>
                    <SpecsTableStyles.ItemValue>
                        {`${quotation.heating!['nominal-capacity'].toFixed(2)} kW`}
                    </SpecsTableStyles.ItemValue>
                </SpecsTableStyles.ItemRow>
            )}
        </SpecsTableStyles.Group>
    );
};

export default Capacity;
