import { DHW_ENERGY_LABELS, ENERGY_LABELS } from '@common/types';

export default {
    sentryDsn: {
        hsnProfSfh:
            'https://25e65dcd0cc5805dc50192fb00ce05fd@o158987.ingest.sentry.io/4506304166690816',
        hsnShare:
            'https://c52ab99738d1d431aae90143bb73ca5e@o158987.ingest.sentry.io/4506304313688064',
        hsnRa: 'https://beea70b723ee6d3b6425a9c5f67c46d9@o158987.ingest.sentry.io/4506304287866880',
        rsn: 'https://755f78de8071485eaf073aa760292d54@o158987.ingest.sentry.io/4504564332625920',
    },
};

export const energyLabelColors = {
    'a-plus-plus-plus': '#3C8643',
    'a-plus-plus': '#69AD46',
    'a-plus': '#C9D344',
    a: '#FCEE4F',
    b: '#F2BC40',
    c: '#DC6F2D',
    d: '#D02E26',
};

export const dhwEnergyLabelColors = {
    'a-plus': '#42944A',
    a: '#69AD46',
    b: '#C9D344',
    c: '#FCEE4F',
    d: '#F2BC40',
    e: '#DC6F2D',
    f: '#D02E26',
};

export type EnergyLabels = {
    heating?: ENERGY_LABELS;
    cooling?: ENERGY_LABELS;
    dhw?: DHW_ENERGY_LABELS;
};

export const getEnergyLabelImage = (
    type: keyof EnergyLabels,
    rating: string,
    hasSolar: boolean = false,
    cdnPrefix: string,
): string => {
    switch (true) {
        case hasSolar:
            return `${cdnPrefix}/frontend/assets/img/energy-labels/a-g/${rating}.png`;
        case type === 'heating':
        case type === 'cooling':
            return `${cdnPrefix}/frontend/assets/img/energy-labels/a-d/${rating}.png`;
        case type === 'dhw':
            return `${cdnPrefix}/frontend/assets/img/energy-labels/a-f/${rating}.png`;
        default:
            return '';
    }
};

export const patterns = {
    email: /^[a-z0-9._%+-]+@[a-z0-9-]+(\.[a-z]{2,3}){1,2}$/i,
    uuid: /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/i,
    uuid_v1: /^[0-9a-f]{8}-[0-9a-f]{4}-1[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
};

export const MAX_SIMULATIONS_PER_SERIES = 10;

export const DHW_COLD_WATER_TEMP = 10;
export const DHW_STORAGE_TEMP = 48;
export const DHW_EFFICIENCY = 100;
