/* eslint-disable react/no-danger */
import i18n from '@common/i18n';
import { DEVICE_TYPE } from '@common/types';
import React, { useState } from 'react';
import { EVS_OVERVIEW_TAB, MODAL_TYPE } from '../../../../../constants';
import { useEvsAtaModalNavigation } from '../../../../../hooks/evsHooks';
import * as EvsStyles from '../../../../../styles/components/Evs';
import * as ModalStyles from '../../../../../styles/components/Modal';
import * as TypographyStyles from '../../../../../styles/components/Typography';
import { EvsAtaHeatingData, EvsOverviewTab } from '../../../../../types';
import Modal from '../../Modal';
import Co2AdditionalInfo from '../Co2AdditionalInfo';
import CostCompareToggle from '../CostCompareToggle';
import Navigation from '../Navigation';
import EvsAtaHeatingCo2Compare from './Co2Compare';
import Disclaimers from './Disclaimers';
import EvsAtaHeatingPriceCompare from './PriceCompare';

type PropsType = {
    metaData: unknown;
};

type CastedPropsType = {
    evsData: EvsAtaHeatingData;
};

const EvsAtaHeatingCompareModal = ({ metaData }: PropsType): JSX.Element => {
    const {
        evsData: { priceData, co2Data },
    } = metaData as CastedPropsType;
    const [activeTab, setActiveTab] = useState<EvsOverviewTab>(EVS_OVERVIEW_TAB.price);
    const navigation = useEvsAtaModalNavigation();
    const { itemIndex, deviceType } = navigation;
    const showNavigation = deviceType === DEVICE_TYPE.mobile;

    return (
        <Modal type={MODAL_TYPE.evsAtaHeatingCompare} isWide>
            <ModalStyles.ModalHeader>
                <TypographyStyles.H4 mb={5}>{i18n('compare_daikin_system')}</TypographyStyles.H4>
            </ModalStyles.ModalHeader>
            <ModalStyles.ModalContent isWide width="inherit">
                <CostCompareToggle activeTab={activeTab} onSetActiveTab={setActiveTab} />
                {activeTab === EVS_OVERVIEW_TAB.price ? (
                    <EvsAtaHeatingPriceCompare
                        itemIndex={itemIndex}
                        deviceType={deviceType}
                        daikinSolution={priceData.daikinSolution}
                        boilerSolution={priceData.boilerSolution}
                    />
                ) : (
                    <EvsAtaHeatingCo2Compare
                        itemIndex={itemIndex}
                        deviceType={deviceType}
                        daikinSolution={co2Data.daikinSolution}
                        boilerSolution={co2Data.boilerSolution}
                    />
                )}
                {showNavigation && <Navigation navigation={navigation} />}
                {activeTab === EVS_OVERVIEW_TAB.co2 && (
                    <Co2AdditionalInfo
                        totalSavings={co2Data.totalSavings}
                        savingsTrees={co2Data.savingsTreesComparison}
                        savingsCarTravel={co2Data.savingsCarTravelComparison}
                        savingsAirplaneTravel={co2Data.savingsAirplaneTravelComparison}
                    />
                )}
            </ModalStyles.ModalContent>
            <ModalStyles.ModalFooter>
                <EvsStyles.Disclaimer>
                    <Disclaimers activeTab={activeTab} />
                </EvsStyles.Disclaimer>
            </ModalStyles.ModalFooter>
        </Modal>
    );
};

export default EvsAtaHeatingCompareModal;
