import { createLogic } from 'redux-logic';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { injectScript, attachQueryString } from '@tsUtils';
import {
    APP_STATE_ACTIONS,
    SETTINGS_ACTIONS,
    setGigyaState,
    fetchLead,
    setLeadLocal,
    OpenDetachedScreenAction,
    setGigyaUserLeads,
    FetchCompareLeadsType,
    setcompareLeads,
    openDetachedScreen,
    clearLead,
    ClearGigyaUserAction,
    GIGYA_ACTIONS,
    SetGigyaUserAction,
    SetGigyaUidAction,
} from '../actions';
import { Store } from '../../types';
import { DETACHED_SCREEN_TYPE } from '../../constants';
import {
    getFetchCompareLeadsPayload,
    getFetchUserLeadsPayload,
    getGenericPayload,
} from '../selectors';

type DepObj = { getState: () => Store };

const injectGigyaLogic = createLogic({
    type: SETTINGS_ACTIONS.setSettings,
    name: 'gigya.injectGigyaLogic',
    process({ getState }: DepObj, dispatch, done) {
        // Fetch SDK-Url
        const { gigyaSdkUrl } = getState().settings.urls;
        // Inject & set available when callback is triggered
        injectScript(
            'gigya',
            gigyaSdkUrl,
            () => {
                dispatch(setGigyaState('injected'));
                done();
            },
            () => {
                dispatch(setGigyaState('error'));
                done();
            },
            `{
                customEventMap: {
                    eventMap: [{
                        events: '*',
                        args: [e => e],
                        method: (e) => {
                            if (e.eventName === 'afterResponse' && e.methodName === 'accounts.register') {
                                if (window._rsnChannel) {
                                    window._rsnChannel.publish('gigya.accounts.register', e.response.UID);
                                }
                            }
                        }
                    }]
                }
            }`,
        );
    },
});

const continueOnErrorLogic = createLogic({
    type: GIGYA_ACTIONS.setGigyaState,
    name: 'gigya.continueOnErrorLogic',
    process({ getState }: DepObj, dispatch, done) {
        if (getState().appState.gigyaState === 'error') dispatch(fetchLead());
        done();
    },
});

const fetchLeadForUserLogic = createLogic({
    type: GIGYA_ACTIONS.setGigyaUser,
    name: 'gigya.fetchLeadForUserLogic',
    process({ getState }: DepObj, dispatch, done) {
        // If no lead is set yet, fetch one
        if (!getState().lead?.id) dispatch(fetchLead());
        done();
    },
});

type LinkLeadDepObj = { action: SetGigyaUserAction | SetGigyaUidAction } & DepObj;
const linkLeadToUserLogic = createLogic({
    type: [GIGYA_ACTIONS.setGigyaUser, GIGYA_ACTIONS.setGigyaUid],
    name: 'gigya.linkLeadToUserLogic',
    async process({ getState, action }: LinkLeadDepObj, dispatch, done) {
        const store = getState();
        // Only perform if a lead is present
        if (!store.lead?.id) {
            done();
            return;
        }
        // Link the current lead to the user
        const url = store.settings.urls.linkLeadToCustomer;
        const payload = await getGenericPayload(store);
        axios
            .post(url, payload)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    // Update reducer w/ new lead-data
                    dispatch(setLeadLocal(data.lead, false, 'linkLeadToUserLogic', action.type));
                }
            })
            .catch((err) => {
                err.name = 'Error linking lead to user';
                Sentry.setContext('link lead response', err.response?.data);
                Sentry.captureException(err);
            })
            .finally(() => {
                done();
            });
    },
});

type FetchUserLeadsDepObj = DepObj & { action: OpenDetachedScreenAction };
const fetchUserLeadsLogic = createLogic({
    type: APP_STATE_ACTIONS.openDetachedScreen,
    name: 'gigya.fetchUserLeadsLogic',
    async process({ getState, action }: FetchUserLeadsDepObj, dispatch, done) {
        if (action.screenType === DETACHED_SCREEN_TYPE.account) {
            const store = getState();
            const payload = await getFetchUserLeadsPayload(store);
            const url = attachQueryString(store.settings.urls.listLeads, payload);
            axios
                .get(url)
                .then((res) => {
                    const { status, data } = res;
                    if (status === 200 && data.success && data.leads.length > 0) {
                        dispatch(setGigyaUserLeads(data.leads, null));
                    } else {
                        if (status > 299) {
                            const error = new Error(`${status} error`);
                            error.name = 'Error fetching user leads';
                            Sentry.captureException(error);
                        }
                        dispatch(setGigyaUserLeads([], 'gigya_user_leads_error'));
                    }
                })
                .catch((err) => {
                    err.name = 'Error fetching user leads';
                    Sentry.captureException(err);
                    dispatch(setGigyaUserLeads([], 'gigya_user_leads_error'));
                })
                .finally(done);
        } else {
            done();
        }
    },
});

export type FetchCompareLeadsDepObj = DepObj & { action: FetchCompareLeadsType };
const fetchCompareLeadsLogic = createLogic({
    type: APP_STATE_ACTIONS.fetchCompareLeads,
    name: 'fetchCompareLeads',
    async process({ getState, action }: FetchCompareLeadsDepObj, dispatch, done) {
        const { leadIds } = action;
        const store = getState();
        const payload = await getFetchCompareLeadsPayload(store, leadIds);
        const url = store.settings.urls.compareLeads;
        try {
            const { data } = await axios.post(url, payload);
            dispatch(setcompareLeads(data.leads));
            dispatch(openDetachedScreen(DETACHED_SCREEN_TYPE.leadCompare));
            done();
        } catch (err) {
            (err as Error).name = 'Error fetching compare leads';
            Sentry.captureException(err);
            done();
        }
    },
});

type GigyaLogoutDepObj = DepObj & { action: ClearGigyaUserAction };
const gigyaLogoutValidationLogic = createLogic({
    type: GIGYA_ACTIONS.clearGigyaUser,
    name: 'gigyaLogoutLogic',
    validate({ getState, action }: GigyaLogoutDepObj, allow, reject) {
        if (getState().appState.gigyaUser) {
            allow(action);
        } else {
            reject({ ...action, type: `🚫 (${action.type})` });
        }
    },
});

const gigyaLoggedOutLogic = createLogic({
    type: GIGYA_ACTIONS.clearGigyaUser,
    name: 'gigyaLogoutLogic',
    process(_, dispatch, done) {
        dispatch(openDetachedScreen(DETACHED_SCREEN_TYPE.loggedOut));
        dispatch(clearLead());
        done();
    },
});

export default [
    injectGigyaLogic,
    continueOnErrorLogic,
    fetchLeadForUserLogic,
    linkLeadToUserLogic,
    fetchUserLeadsLogic,
    fetchCompareLeadsLogic,
    gigyaLogoutValidationLogic,
    gigyaLoggedOutLogic,
];
