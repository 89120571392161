import i18n from '@common/i18n';
import React, { lazy, Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import LoggedOut from './components/common/account/LoggedOut';
import LogIn from './components/common/account/LogIn';
import UnlinkedLead from './components/common/account/UnlinkedLead';
import AemRedirectCCU from './components/common/AemRedirectCCU';
import AemRedirectMultiRoom from './components/common/AemRedirectMultiRoom';
import FullPageLoader from './components/common/FullPageLoader';
import ModalMgmt from './components/common/modals/ModalMgmt';
import StepIndicator from './components/common/StepIndicator';
import UserMenu from './components/common/UserMenu';
import VersionExpired from './components/common/VersionExpired';
import VideoPlayer from './components/common/VideoPlayer';
import { DETACHED_SCREEN_TYPE, RSN_SECTION } from './constants';
import { useAppSelector } from './redux';
import {
    getCurrentSection,
    getDetachedScreen,
    getShowNavigation,
    isAirToWaterProject,
} from './redux/selectors';
import { AppStyle } from './styles/components/App';
import * as NavigationStyles from './styles/components/Navigation';
import * as PageStyles from './styles/components/Page';
import { DetachedScreenData } from './types';

const SolutionFinderTree = lazy(
    () =>
        import(
            /* webpackChunkName: "chunks/rsn/step1-solutionFinderTree" */ './components/step1/SolutionFinderTree'
        ),
);
const SbmSolutionView = lazy(
    () =>
        import(
            /* webpackChunkName: "chunks/rsn/step2-sbmSolutionView" */ './components/step2/SbmSolutionView'
        ),
);
const SizingAndPricing = lazy(
    () =>
        import(
            /* webpackChunkName: "chunks/rsn/step3-sizingAndPricing" */ './components/step3/SizingAndPricing'
        ),
);
const Incentives = lazy(
    () =>
        import(
            /* webpackChunkName: "chunks/rsn/step4-incentives" */ './components/step4/Incentives'
        ),
);
const DealerSelection = lazy(
    () =>
        import(
            /* webpackChunkName: "chunks/rsn/step5-dealerSelection" */ './components/step5/DealerSelection'
        ),
);
const ConfirmationPage = lazy(
    () =>
        import(
            /* webpackChunkName: "chunks/rsn/step6-confirmationPage" */ './components/common/ConfirmationPage'
        ),
);
const Account = lazy(
    () =>
        import(/* webpackChunkName: "chunks/rsn/account" */ './components/common/account/Account'),
);
const LeadCompareView = lazy(
    () =>
        import(
            /* webpackChunkName: "chunks/rsn/account-leadCompareView" */ './components/common/account/compare/LeadCompareView'
        ),
);
const AlthermaChoice = lazy(
    () =>
        import(
            /* webpackChunkName: "chunks/rsn/althermaDetails" */ './components/common/altherma/AlthermaChoice'
        ),
);

const overlayTheme = {
    zIndex: {
        modalBackdrop: 1139,
        modal: 1142,
    },
};

type SectionScreenProps = { currentSection: RSN_SECTION; hasSolution: boolean };
const SectionScreen = ({ currentSection, hasSolution }: SectionScreenProps): JSX.Element | null => {
    const isNavigating = useAppSelector((state) => state.appState.isNavigating);
    if (isNavigating) return <FullPageLoader />;
    // The check for solution-data on some pages is required because:
    // when navigating back to a certain page, solution-data is reset in redux
    // before the navigation & reset-data request is sent to backend
    // So technically we are still on that step, even though data that is expected to be set is no longer present
    switch (currentSection) {
        case RSN_SECTION.loading:
            return <FullPageLoader />;
        case RSN_SECTION.section_1_solution_finder_tree:
            return <SolutionFinderTree />;
        case RSN_SECTION.section_2_solution_overview:
            return hasSolution ? <SbmSolutionView /> : <FullPageLoader />;
        case RSN_SECTION.section_3_sizing_and_pricing:
            return hasSolution ? <SizingAndPricing /> : <FullPageLoader />;
        case RSN_SECTION.section_4_incentives:
            return hasSolution ? <Incentives /> : <FullPageLoader />;
        case RSN_SECTION.section_5_dealer_selection:
            return <DealerSelection />;
        case RSN_SECTION.finished:
            return <ConfirmationPage />;
        default:
            return null;
    }
};

type DetachedScreenProps = { screenData: DetachedScreenData };
const DetachedScreen = ({ screenData }: DetachedScreenProps): JSX.Element | null => {
    switch (screenData.type) {
        case DETACHED_SCREEN_TYPE.account:
            return (
                <Suspense fallback={<FullPageLoader />}>
                    <Account />
                </Suspense>
            );
        case DETACHED_SCREEN_TYPE.leadCompare:
            return (
                <Suspense fallback={<FullPageLoader />}>
                    <LeadCompareView />
                </Suspense>
            );
        case DETACHED_SCREEN_TYPE.logIn:
            return <LogIn />;
        case DETACHED_SCREEN_TYPE.unlinkedLead:
            return <UnlinkedLead />;
        case DETACHED_SCREEN_TYPE.versionExpired:
            return <VersionExpired />;
        case DETACHED_SCREEN_TYPE.althermaChoice:
            return (
                <Suspense fallback={<FullPageLoader />}>
                    <AlthermaChoice />
                </Suspense>
            );
        case DETACHED_SCREEN_TYPE.loggedOut:
            return <LoggedOut />;
        case DETACHED_SCREEN_TYPE.videoPlayer:
            return <VideoPlayer videoId={screenData.metaData as string} />;
        case DETACHED_SCREEN_TYPE.aemRedirectCCU:
            return <AemRedirectCCU />;
        case DETACHED_SCREEN_TYPE.aemRedirectMultiRoom:
            return <AemRedirectMultiRoom />;
        default:
            return null;
    }
};

const AppRouter = (): JSX.Element => {
    const isAirToWater = useAppSelector(isAirToWaterProject);
    const currentSection = useAppSelector(getCurrentSection);
    const detachedScreen = useAppSelector(getDetachedScreen);
    const showNavigation = useAppSelector(getShowNavigation);
    const hasSolution = useAppSelector((store) => !!store.lead.solution);
    // Navigate to the correct section or detached screen & render ModalMgmt as overlay
    return (
        <AppStyle>
            <ThemeProvider theme={overlayTheme}>
                <ModalMgmt asOverlay />
            </ThemeProvider>
            <ModalMgmt />
            <NavigationStyles.Navigation>
                <NavigationStyles.Header>
                    <NavigationStyles.Title>
                        {i18n(isAirToWater ? 'heating' : 'air_to_air_heat_pump')}
                    </NavigationStyles.Title>
                    <UserMenu />
                </NavigationStyles.Header>
                {showNavigation && <StepIndicator />}
            </NavigationStyles.Navigation>
            {detachedScreen ? (
                <DetachedScreen screenData={detachedScreen} />
            ) : (
                <Suspense fallback={<FullPageLoader />}>
                    <PageStyles.MainContent>
                        <SectionScreen currentSection={currentSection} hasSolution={hasSolution} />
                    </PageStyles.MainContent>
                </Suspense>
            )}
        </AppStyle>
    );
};

export default AppRouter;
