import autobind from 'autobind-decorator';

export default class ImageLoader {

    constructor(url, index, width = null, height = null) {
        this.url = url;
        this.index = index;
        this.width = width;
        this.height = height;
        // Loader
        this.loader = new Image(width, height);
        this.loader.onerror = () => { this.loaded = false; };
        // State
        this.loaded = false;
    }

    @autobind
    startLoad(success, failure) {
        this.loader.onload = () => {
            this.loaded = true;
            if (success) success();
        };
        this.loader.onerror = () => {
            this.loaded = false;
            if (failure) failure();
        };
        this.loaded ? success() : this.loader.src = this.url;
    }

}
