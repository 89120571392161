/* eslint-disable default-case */
import { useEffect } from 'react';
import { Dispatch } from 'redux';
import {
    doneQuotationToolAta,
    updateQuotationToolAta,
} from '../redux/actions/tools/quotationActions.ata';
import {
    doneQuotationToolAtw,
    updateQuotationToolAtw,
} from '../redux/actions/tools/quotationActions.atw';
import { QuotationEventDataTypes } from '../types/external/quotation';

enum EVENTS {
    // INBOUND - Aaltra -> RSN
    update = 'update',
    done = 'done',
    // OUTBOUND - RSN -> Aaltra
    init = 'init',
    navigate = 'navigate',
}

export const useQuotationAtwTool = (dispatch: Dispatch): void => {
    // Postal handler
    useEffect(() => {
        const onQuotationAtwEvent: ICallback<unknown> = (data, envelope) => {
            const [, type] = envelope.topic.split('.');
            switch (type) {
                case EVENTS.update:
                    dispatch(updateQuotationToolAtw(data as QuotationEventDataTypes.Atw.Update));
                    break;
                case EVENTS.done:
                    dispatch(doneQuotationToolAtw(data as QuotationEventDataTypes.Atw.Done));
                    break;
            }
        };
        // Subscribe to rsnChannel events
        window._rsnChannel.subscribe('quotation.*', onQuotationAtwEvent);
    }, [dispatch]);
};

export const useQuotationAtaTool = (dispatch: Dispatch): void => {
    // Postal handler
    useEffect(() => {
        const onQuotationAtaEvent: ICallback<unknown> = (data, envelope) => {
            const [, type] = envelope.topic.split('.');
            switch (type) {
                case EVENTS.update:
                    dispatch(updateQuotationToolAta(data as QuotationEventDataTypes.Ata.Update));
                    break;
                case EVENTS.done:
                    dispatch(doneQuotationToolAta(data as QuotationEventDataTypes.Ata.Done));
                    break;
            }
        };
        // Subscribe to rsnChannel events
        window._rsnChannel.subscribe('quotation.*', onQuotationAtaEvent);
    }, [dispatch]);
};
