import i18n from '@common/i18n';
import * as Sentry from '@sentry/react';
import { dedupeCoords } from '@tsUtils';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import {
    DEALER_SCHED_FACTOR,
    DETACHED_SCREEN_TYPE,
    MODAL_TYPE,
    PROXY_TYPES,
} from '../../constants';
import { Dealer, Lead, Store } from '../../types';
import {
    APP_STATE_ACTIONS,
    LEAD_ACTIONS,
    SkipToSelectDealerAction,
    closeDetachedScreen,
    closeModal,
    setDealerId,
    setDealers,
    setGlobalError,
    setLeadLocal,
    setSchedulingFactor,
    setSkipToSelectDealerError,
} from '../actions';
import { getDefaultTimeRange, getGenericPayload, getPhasing, isFrance, isUk } from '../selectors';
import { mockDealers } from './dealerLogic.mockdata';

type RemoteDealer = {
    city: string;
    companyName: string;
    customerScore: string;
    distance: string;
    lmScore: string;
    rsnInstaller: string;
    schedFactor: string;
    street: string;
    zipCode: string;
};

type DealerResponseType = {
    responseData: RemoteDealer[];
    success: boolean;
};

type DepObj = {
    getState: () => Store;
};

const moreInfoUrl =
    '{DOMAINNAME}/content/b2c/{COUNTRYNAME}/{CULTURE}/{DEALERLOCATOR}/{INSTALLERID}.html';

const fetchDealersLogic = createLogic({
    type: APP_STATE_ACTIONS.fetchDealers,
    name: 'dealers.fetchLogic',
    async process({ getState }: DepObj, dispatch, done) {
        const {
            lead: { id },
            aemSettings: { domain },
            settings: {
                language,
                country: { isoCode },
            },
        } = getState();

        const dealerLocator = isFrance(getState())
            ? 'residential/trouver-un-installateur'
            : 'residential/dealer-locator';

        const url = moreInfoUrl
            .replace('{DOMAINNAME}', domain)
            .replace(
                '{COUNTRYNAME}',
                i18n(`rsn_dealer_map_country.${isoCode.toUpperCase()}`, true, 'rsn_country_names'),
            )
            .replace('{CULTURE}', `${language.toLocaleLowerCase()}_${isoCode.toLocaleLowerCase()}`)
            .replace('{DEALERLOCATOR}', dealerLocator);

        const parseDealers = (dealers: RemoteDealer[]): Dealer[] => {
            return dealers
                .filter((dealer) => !dealer.distance.includes('null'))
                .map((dealer) => {
                    return {
                        ...dealer,
                        url: url.replace('{INSTALLERID}', dealer.rsnInstaller),
                        // Split schedFactor-string into array
                        schedFactor: dealer.schedFactor.split(';') as DEALER_SCHED_FACTOR[],
                    };
                });
        };

        // Use mockDealers in debug mode
        if (__DEBUG) {
            dispatch(setDealers(parseDealers(mockDealers), null));
            return done();
        }

        // JWToken
        const payload = {
            leadId: id,
            type: PROXY_TYPES.dealer,
            customerToken: null,
        };

        axios
            .post<DealerResponseType>(getState().settings.urls.proxyCall, payload)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data?.success === true && data.responseData.length > 0) {
                    if (data.responseData.length === 0) {
                        dispatch(setDealers([], 'no_dealers_error'));
                    } else {
                        const dealerCoords = data.responseData.map((d) => {
                            const [latitude, longitude] = d.distance.split(', ');
                            return { latitude: +latitude, longitude: +longitude, data: d };
                        });
                        dedupeCoords<RemoteDealer>(dealerCoords, 0.0004);
                        const dealers = dealerCoords.map(
                            ({ latitude, longitude, data: dealerData }) => {
                                return {
                                    ...dealerData,
                                    distance: `${latitude}, ${longitude}`,
                                };
                            },
                        );
                        dispatch(setDealers(parseDealers(dealers), null));
                    }
                } else {
                    if (status > 299) {
                        const error = new Error(`${status} error: no_dealers_error`);
                        error.name = 'Error fetching dealers';
                        Sentry.captureException(error);
                    }
                    dispatch(setDealers([], 'no_dealers_error'));
                }
            })
            .catch((err) => {
                err.name = 'Error fetching dealers';
                Sentry.captureException(err);
                dispatch(setDealers([], 'no_dealers_error'));
            })
            .finally(() => {
                done();
            });
    },
});

const autoSelectTimeRangeLogic = createLogic({
    type: APP_STATE_ACTIONS.setDealers,
    name: 'dealers.setSchedulingFactorLogic',
    process({ getState }: DepObj, dispatch, done) {
        // Calculate & set default timeRange
        const defaultTimeRange = getDefaultTimeRange(getState().appState.timeRanges);
        dispatch(setSchedulingFactor(defaultTimeRange));
        done();
    },
});

const deselectDealerLogic = createLogic({
    type: LEAD_ACTIONS.setSchedulingFactor,
    name: 'dealers.deselectDealerLogic',
    process({ getState }: DepObj, dispatch, done) {
        // Calculate & set default timeRange
        const {
            lead: { dealerId, schedulingFactor },
            appState: { timeRanges },
        } = getState();
        if (dealerId && !timeRanges[schedulingFactor!].some((d) => d.id === dealerId)) {
            dispatch(setDealerId(null));
        }
        done();
    },
});

type SkipToSelectDealerResponseType = {
    lead: Partial<Lead>;
    success: boolean;
};

type SkipDepObj = DepObj & { action: SkipToSelectDealerAction };
const skipToSelectDealerLogic = createLogic({
    type: APP_STATE_ACTIONS.skipToSelectDealer,
    name: 'dealers.skipToSelectDealerLogic',
    process({ getState, action }: SkipDepObj, dispatch, done) {
        const store = getState();
        const genericPayload = getGenericPayload(store);
        const payload = {
            ...genericPayload,
            postalCode: isUk(store) ? action.postalCode.toLocaleUpperCase() : action.postalCode,
            phasing: getPhasing(store) || null,
        };

        axios
            .post<SkipToSelectDealerResponseType>(store.settings.urls.skipToContactDealer, payload)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data?.success === true && data.lead) {
                    dispatch(closeDetachedScreen(DETACHED_SCREEN_TYPE.aemRedirectCCU));
                    dispatch(closeModal(MODAL_TYPE.contactADealer));
                    dispatch(
                        setLeadLocal(data.lead, false, 'skipToSelectDealerLogic', action.type),
                    );
                } else {
                    if (status > 299) {
                        const error = new Error(`${status} error: error_skip_to_select_dealer`);
                        error.name = 'Error while skipping to select dealers';
                        Sentry.captureException(error);
                    }
                    dispatch(setGlobalError('error_skip_to_select_dealer'));
                }
            })
            .catch((err) => {
                const errors = err.response?.data?.errors;
                err.name = 'Error while skipping to select dealer';
                Sentry.captureException(err);
                if (errors?.postalCode && errors?.postalCode.length > 0) {
                    dispatch(
                        setSkipToSelectDealerError(i18n(errors.postalCode[0], true, 'validators')),
                    );
                } else {
                    dispatch(setGlobalError('error_skip_to_select_dealer'));
                }
            })
            .finally(done);
    },
});

export default [
    skipToSelectDealerLogic,
    fetchDealersLogic,
    autoSelectTimeRangeLogic,
    deselectDealerLogic,
];
