/* eslint-disable react/no-danger */
/* eslint-disable no-lonely-if */
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { APP_STATUS, RSN_SECTION } from '../../../constants';
import { useAppSelector } from '../../../redux';
import {
    getCurrentSection,
    getEnergyLabels,
    isAirToAirLead,
    isAirToWaterLead,
    isAirToAirMultiRoomLead,
} from '../../../redux/selectors';
import * as Styles from '../../../styles/components/EnergyLabels';
import * as GridStyles from '../../../styles/components/Grid';
import * as PreloaderStyles from '../../../styles/components/Preloader';
import { SbmSolution, SingleRoomSolution } from '../../../types';
import EnergyLabel from '../EnergyLabel';

const tooltipStyles = { maxWidth: '250px' };

type PropsType = {
    isUpTo?: boolean;
    solution: SbmSolution | SingleRoomSolution;
};

const EnergyLabels = ({ solution, isUpTo = false }: PropsType): JSX.Element => {
    const energyLabels = useAppSelector((state) => getEnergyLabels(state, solution));
    const isAtaLead = useAppSelector((state) => isAirToAirLead(state.lead));
    const currentSection = useAppSelector(getCurrentSection);
    const status = useAppSelector((state) => state.lead.status);
    const energyLabelsError = useAppSelector((state) => state.appState.energyLabelsError);

    const { cooling, heating, dhw } = energyLabels;
    const isSelsoftResult = status === APP_STATUS.sp_selsoft_result;
    const renderLoader = isAtaLead
        ? cooling === undefined && heating === undefined
        : heating === undefined && dhw === undefined;

    const renderTooltip =
        currentSection === RSN_SECTION.section_2_solution_overview ||
        currentSection === RSN_SECTION.section_3_sizing_and_pricing ||
        currentSection === RSN_SECTION.section_4_incentives;

    const getEnergyLabeltooltip = (): string => {
        switch (currentSection) {
            case RSN_SECTION.section_2_solution_overview:
                return isAtaLead
                    ? i18n('energy_labels_ata_tooltip_step_2')
                    : i18n('energy_labels_atw_tooltip_step_2');
            case RSN_SECTION.section_3_sizing_and_pricing:
                return isAtaLead
                    ? i18n('energy_labels_ata_tooltip_step_3')
                    : i18n('energy_labels_atw_tooltip_step_3');
            case RSN_SECTION.section_4_incentives:
                return isAtaLead
                    ? i18n('energy_labels_ata_tooltip_step_4')
                    : i18n('energy_labels_atw_tooltip_step_4');
            default:
                return '';
        }
    };

    return (
        <div>
            <Styles.EnergyLabelTitle as="h3" mb={3}>
                {i18n('energy_labels')}
                {renderTooltip && (
                    <>
                        <p data-tip="" data-for="tooltip_energy_labels">
                            <Icons.Glyph.InfoCircle />
                        </p>
                        <ReactTooltip
                            id="tooltip_energy_labels"
                            place="right"
                            effect="solid"
                            clickable
                        >
                            <div
                                style={tooltipStyles}
                                dangerouslySetInnerHTML={{ __html: getEnergyLabeltooltip() }}
                            />
                        </ReactTooltip>
                    </>
                )}
            </Styles.EnergyLabelTitle>
            <Styles.EnergyLabels>
                {isSelsoftResult &&
                    (energyLabelsError ? (
                        <p>{i18n(energyLabelsError)}</p>
                    ) : (
                        renderLoader && (
                            <PreloaderStyles.Preloader>
                                <Icons.Glyph.LoadingWheel />
                            </PreloaderStyles.Preloader>
                        )
                    ))}
                <GridStyles.Row>
                    {cooling && <EnergyLabel type="cooling" rating={cooling} isUpTo={isUpTo} />}
                    {heating && <EnergyLabel type="heating" rating={heating} isUpTo={isUpTo} />}
                    {dhw && <EnergyLabel type="dhw" rating={dhw} isUpTo={isUpTo} />}
                </GridStyles.Row>
            </Styles.EnergyLabels>
        </div>
    );
};

export default EnergyLabels;
