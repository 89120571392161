export const color = {
    blue100: '#D9F4FF',
    blue200: '#95CBF3',
    blue300: '#0097E0',
    blue400: '#026AB4',
    green100: '#A2EFB6',
    green200: '#5FD07C',
    green300: '#0EAE4F',
    green400: '#086D3D',
    yellow100: '#FFD7A7',
    yellow200: '#FCAB4C',
    yellow300: '#FA941A',
    yellow400: '#A03D09',
    red100: '#FFD1CD',
    red200: '#FF867C',
    red300: '#D03232',
    red400: '#A10000',
    grey000: '#FFFFFF',
    grey100: '#F5F5F5',
    grey200: '#E8EDF3',
    grey300: '#CFD7E0',
    grey400: '#9AAABC',
    grey500: '#5F748D',
    grey600: '#4D5F73',
    grey700: '#414D5A',
    grey800: '#262F38',
    grey900: '#000000',
};

export const fontFamily = {
    base: '"Open Sans",Arial,Helvetica,sans-serif',
    roboto: '"Roboto", sans-serif',
};

export const fontWeight = {
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 700,
};
