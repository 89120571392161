import { EnergyTag } from '@common/styles/EnergyTag';
import styled from 'styled-components';
import { color, space } from '../common/Theme';
import * as TypographyStyles from './Typography';

export const EnergyLabelTitle = styled(TypographyStyles.H4)`
    display: flex;
    svg {
        width: 16px;
        height: 16px;
    }
    p {
        margin: 0px;
        padding: 0px 5px;
    }
    div {
        max-width: 270px;
    }`;

export const EnergyLabels = styled.div`
    margin-bottom: ${space[5]}px;
    padding-bottom: ${space[5]}px;
    border-bottom: 1px solid ${color.grey200};
`;

export const EnergyLabel = styled.div`
    ${EnergyTag} {
        max-width: 100px;
    }
`;

export const EnergyLabelText = styled.div`
    display: flex;
    align-items: center;
    color: ${color.grey500};

    svg {
        margin-right: ${space[2]}px;
    }
`;
