import axios from 'axios';
import * as Sentry from '@sentry/react';
import { createLogic } from 'redux-logic';
import { attachQueryString } from '@tsUtils';
import { EpackageType, Store } from '../../types';
import { APP_STATE_ACTIONS, setEpackages } from '../actions';

type EpackagesResponseType = {
    epackages: EpackageType[];
    success: boolean;
};

type DepObj = {
    getState: () => Store;
};

const epackagesLogic = createLogic({
    type: APP_STATE_ACTIONS.fetchEpackages,
    name: 'epackages.fetchEpackages',
    process({ getState }: DepObj, dispatch, done) {
        const store = getState();
        const epackagesUrl = store.settings.urls.epackages;
        if (!epackagesUrl) {
            done();
            return;
        }
        const leadId = store.lead.id;
        const url = attachQueryString(epackagesUrl, { leadId });
        axios
            .get<EpackagesResponseType>(url)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data?.success === true) {
                    const { epackages } = data;
                    dispatch(setEpackages(epackages));
                } else {
                    if (status > 299) {
                        const error = new Error(
                            `${status} error: Something went wrong while fetching epackages`,
                        );
                        error.name = 'Error fetching epackages';
                        Sentry.captureException(error);
                    }
                    dispatch(setEpackages([]));
                }
            })
            .catch((err) => {
                err.name = 'Error fething epackages';
                Sentry.captureException(err);
                dispatch(setEpackages([]));
            })
            .finally(done);
    },
});

export default [epackagesLogic];
