import { SizingEventDataTypes } from '../../../types/external/sizing';

export enum TOOL_SIZING_ATA_MULTI_ROOM {
    update = '🧰/sizing/ata/multiRoom/update',
    init = '🧰/sizing/ata/multiRoom/init',
    done = '🧰/sizing/ata/multiRoom/done',
}

export type InitSizingToolAtaMultiRoomAction = { type: TOOL_SIZING_ATA_MULTI_ROOM.init };
export const initSizingToolAtaMultiRoom = (): InitSizingToolAtaMultiRoomAction => ({
    type: TOOL_SIZING_ATA_MULTI_ROOM.init,
});

export type UpdateSizingToolAtaMultiRoomAction = {
    type: TOOL_SIZING_ATA_MULTI_ROOM.update;
    data: SizingEventDataTypes.AtaMultiRoom.Update;
};
export const updateSizingToolAtaMultiRoom = (
    data: SizingEventDataTypes.AtaMultiRoom.Update,
): UpdateSizingToolAtaMultiRoomAction => ({
    type: TOOL_SIZING_ATA_MULTI_ROOM.update,
    data,
});

export type DoneSizingToolAtaMultiRoomAction = {
    type: TOOL_SIZING_ATA_MULTI_ROOM.done;
    data: SizingEventDataTypes.AtaMultiRoom.Done;
};
export const doneSizingToolAtaMultiRoom = (
    data: SizingEventDataTypes.AtaMultiRoom.Done,
): DoneSizingToolAtaMultiRoomAction => ({
    type: TOOL_SIZING_ATA_MULTI_ROOM.done,
    data,
});

export type SizingAtaMultiRoomActionsType =
    | InitSizingToolAtaMultiRoomAction
    | UpdateSizingToolAtaMultiRoomAction
    | DoneSizingToolAtaMultiRoomAction;
