import i18n from '@common/i18n';
import { attachQueryString } from '@tsUtils';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { Lead, PhasingType, RoomSizeSettingsType, Settings, Store } from '../../types';
import {
    SETTINGS_ACTIONS,
    fetchSolutions,
    setGlobalError,
    setSettings,
    setSolutions,
} from '../actions';

type DepObj = { getState: () => Store };

type FetchSettingsResponseType = Settings & {
    summerCoolingRoomSizeSettings: RoomSizeSettingsType[] | null;
    winterHeatingRoomSizeSettings: RoomSizeSettingsType[] | null;
    labels: {
        [namespace: string]: {
            [labelKey: string]: string;
        };
    };
    phasing: { phasing: PhasingType };
    lead: Partial<Lead>;
    success: boolean;
};

const fetchSettingsLogic = createLogic({
    type: SETTINGS_ACTIONS.fetchSettings,
    name: 'settingsLogic.fetchSettingsLogic',
    process({ getState }: DepObj, dispatch, done) {
        const store = getState();
        const payload = {
            country: store.settings.country.isoCode,
            language: store.settings.language,
            solutionType: store.settings.type,
            leadId: store.settings.leadId || null,
            hash: __HASH,
        };
        const origin = __API || document.location.origin.replace('rsn-demo', 'api');
        const url = attachQueryString(`${origin}/rsn/settings`, payload);
        axios
            .get<FetchSettingsResponseType>(url)
            .then((res) => {
                const { status, data } = res;

                if (status === 200 && data) {
                    i18n.setNamespace('general');
                    i18n.set(data.labels);
                    dispatch(
                        setSettings({
                            country: data.country,
                            urls: data.urls,
                            ataCoolingRoomSizeSettings: data.summerCoolingRoomSizeSettings,
                            ataHeatingRoomSizeSettings: data.winterHeatingRoomSizeSettings,
                            houseSizeSettings: data.houseSizeSettings,
                            solutionPricing: data.solutionPricing,
                            vatSettings: data.vatSettings,
                            defaultPhasing: data.phasing.phasing,
                            energyPrices: data.energyPrices,
                            isDealerRatingShown: data.isDealerRatingShown,
                            hasDefaultCooling: data.hasDefaultCooling,
                            shadings: data.shadings,
                            orientations: data.orientations,
                            multiPlusSolutions: data.multiPlusSolutions,
                        }),
                    );
                    dispatch(fetchSolutions());
                }
            })
            .catch(() => {
                dispatch(setGlobalError('error_fetching_settings'));
            })
            .finally(done);
    },
});

const fetchSolutionsLogic = createLogic({
    type: SETTINGS_ACTIONS.fetchSolutions,
    name: 'settingsLogic.fetchSolutions',
    async process({ getState }: DepObj, dispatch, done) {
        const solutionsUrl = getState().settings.urls.solutions;
        try {
            const { status, data } = await axios.get(solutionsUrl);
            if (status === 200 && data) {
                dispatch(
                    setSolutions(
                        data.solutions,
                        data.multiSplitSingleSolutions,
                        data.multiSplitMultiSolutions,
                    ),
                );
            }
            done();
        } catch (_err) {
            dispatch(setGlobalError('error_fetching_solutions'));
            done();
        }
    },
});

export default [fetchSettingsLogic, fetchSolutionsLogic];
