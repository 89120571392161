import i18n from '@common/i18n';
import { COLOR_VARIATIONS } from '@common/types';
import { attachQueryString, jsonHash, snakeToDash } from '@tsUtils';
import { APP_STATUS, DEALER_SCHED_FACTOR, MULTI_PLUS_TYPE, PROXY_TYPES } from '../../constants';
import {
    AirToAirLead,
    CustomerDetails,
    DrapoApiJsonType,
    IncentiveEventDataTypes,
    Lead,
    SelSoftRequestJson,
    Store,
} from '../../types';
import { getJWToken } from '../../utils';
import { getDrapoApiJSON, getDrapoToolData } from './leadSelectors';
import { getNextStep, getTransitionToStep } from './navigationSelectors';
import { getSelSoftRequestJson } from './selsoftSelectors';

type GenericPayload = {
    leadId: string;
    version: string;
    customerToken: string | null;
};
export const getGenericPayload = async (store: Store): Promise<GenericPayload> => {
    const customerToken = await getJWToken();
    return {
        leadId: store.lead.id,
        version: store.lead.version,
        customerToken: customerToken || store.appState.gigyaUid || null,
    };
};

type GeocodePayload = GenericPayload & {
    type: PROXY_TYPES.geolocation;
    data: { postalCode: string | undefined };
};
export const getGeocodePayload = async (store: Store): Promise<GeocodePayload> => {
    const genericPayload = await getGenericPayload(store);
    const postalCode = store.lead.toolData?.sizingHeating?.postal;
    return { ...genericPayload, type: PROXY_TYPES.geolocation, data: { postalCode } };
};

type FetchLeadPayload = {
    country: string;
    language: string;
    solutionType: 'heating' | 'room_airco';
    leadId: string | null;
    rsnConfirmationUrl: string;
    customerToken: string | null;
};
export const getFetchLeadPayload = async (store: Store): Promise<FetchLeadPayload> => {
    const customerToken = await getJWToken();
    return {
        country: store.settings.country.isoCode,
        language: store.settings.language,
        solutionType: store.settings.type,
        leadId: store.settings.leadId || null,
        rsnConfirmationUrl: store.aemSettings.confirmationPage,
        customerToken: customerToken || store.appState.gigyaUid || null,
    };
};

type SetLeadRemotePayload = {
    payload: Lead & GenericPayload & { transition: string | null; swapCooling?: boolean };
    hash: number;
};
export const getSetLeadRemotePayload = async (store: Store): Promise<SetLeadRemotePayload> => {
    const genericPayload = await getGenericPayload(store);
    const payload = { ...genericPayload, ...store.lead, transition: null };
    const hash = jsonHash({ ...payload, version: null, customerToken: null });
    return { payload, hash };
};

type SelSoftPayload = {
    payload: GenericPayload & {
        type: PROXY_TYPES.selsoft;
        data: SelSoftRequestJson;
    };
    hash: number;
};
export const getSelSoftPayload = async (store: Store): Promise<SelSoftPayload> => {
    const genericPayload = await getGenericPayload(store);
    const data = getSelSoftRequestJson(store);
    const hash = jsonHash(data);
    return {
        payload: { ...genericPayload, type: PROXY_TYPES.selsoft, data },
        hash,
    };
};

type FetchDrapoIncomeRangesPayload = GenericPayload & {
    type: PROXY_TYPES.incomeRange;
    data: {
        amountOfPeople?: number;
        postalCode?: string;
    };
};
export const getFetchDrapoIncomeRangesPayload = async (
    store: Store,
): Promise<FetchDrapoIncomeRangesPayload> => {
    const genericPayload = await getGenericPayload(store);
    const { amountOfPeople } = getDrapoToolData(store);
    const data = { amountOfPeople, postalCode: store.lead.toolData?.sizingHeating?.postal };
    return { ...genericPayload, type: PROXY_TYPES.incomeRange, data };
};

type SaveDrapoToolDataPayload = GenericPayload & {
    data: {
        incentivesFR: DrapoApiJsonType & {
            incomeRanges: number[];
        };
    };
};
export const getSaveDrapoToolDataPayload = async (
    store: Store,
): Promise<SaveDrapoToolDataPayload> => {
    const genericPayload = await getGenericPayload(store);
    const incomeRanges = getDrapoToolData(store).incomeRanges?.map(
        (incomeRange) => incomeRange.value,
    );
    const drapoApiPayload = getDrapoApiJSON(store);
    const data = { incentivesFR: { ...drapoApiPayload, incomeRanges: incomeRanges! } };
    return { ...genericPayload, data };
};

export const getCityPostalUrl = async (store: Store, postal: string): Promise<string> => {
    const customerToken = await getJWToken();
    const payload = {
        customerToken: customerToken || store.appState.gigyaUid || null,
        leadId: store.lead.id,
        postalCode: postal,
    };
    return attachQueryString(store.settings.urls.dealerSelectionPostalCode, payload);
};

type SubmitRsnRequestPayload = GenericPayload &
    CustomerDetails & {
        schedulingFactor: DEALER_SCHED_FACTOR;
        dealerId: string;
    };
export const getSubmitRsnRequestPayload = async (
    store: Store,
): Promise<SubmitRsnRequestPayload> => {
    const genericPayload = await getGenericPayload(store);
    const dealerId = store.lead.dealerId!;
    const customerDetails = store.lead.customerDetails!;
    const schedulingFactor = store.lead.schedulingFactor!;
    return { ...genericPayload, ...customerDetails, dealerId, schedulingFactor };
};

type UpdateIncentivesDEToolDataPayload = GenericPayload & {
    data: {
        incentivesDE: IncentiveEventDataTypes.Germany.Update;
    };
};
export const getUpdateIncentivesDEToolDataPayload = async (
    store: Store,
): Promise<UpdateIncentivesDEToolDataPayload> => {
    const genericPayload = await getGenericPayload(store);
    const incentivesDE = store.lead.incentives!.toolData!.incentivesDE!;
    return { ...genericPayload, data: { incentivesDE } };
};

type DoneIncentivesDEToolDataPayload = GenericPayload & {
    data: {
        incentivesDE: IncentiveEventDataTypes.Germany.Update;
    };
};
export const getDoneIncentivesDEToolDataPayload = async (
    store: Store,
): Promise<DoneIncentivesDEToolDataPayload> => {
    const genericPayload = await getGenericPayload(store);
    const { incentivesDE } = store.lead.incentives!.toolData!;
    return {
        ...genericPayload,
        data: {
            incentivesDE: incentivesDE!,
        },
    };
};

type LiteraturePayload = GenericPayload & { type: PROXY_TYPES; data: string[] };
export const getLiteraturePayload = async (
    store: Store,
    seriesIDs: string[],
): Promise<LiteraturePayload> => {
    const genericPayload = await getGenericPayload(store);
    return {
        ...genericPayload,
        type: PROXY_TYPES.literature,
        data: seriesIDs,
    };
};

type FetchUserLeadsPayload = GenericPayload & {
    country: string;
    language: string;
    solutionType: string;
};
export const getFetchUserLeadsPayload = async (store: Store): Promise<FetchUserLeadsPayload> => {
    const genericPayload = await getGenericPayload(store);
    const {
        lead: { solutionType },
        settings: {
            language,
            country: { isoCode },
        },
    } = store;
    return {
        ...genericPayload,
        country: isoCode,
        solutionType,
        language,
    };
};

type FetchCompareLeadsPayload = GenericPayload & {
    leadIds: string[];
};
export const getFetchCompareLeadsPayload = async (
    store: Store,
    leadIds: string[],
): Promise<FetchCompareLeadsPayload> => {
    const genericPayload = await getGenericPayload(store);
    return {
        ...genericPayload,
        leadIds,
    };
};

type ConfirmRoom = {
    id: string | null;
    solution: string; // solutionId
    name: string;
    surface: number;
    height: number;
    windows: number;
    shading: string;
    orientation: string;
    colorVariation: COLOR_VARIATIONS | null;
};
type ConfirmRoomsPayload = GenericPayload & {
    transition: string;
    multiPlusType: MULTI_PLUS_TYPE | null;
    rooms: Array<ConfirmRoom>;
};
export const getConfirmRoomsPayload = async (state: Store): Promise<ConfirmRoomsPayload> => {
    const genericPayload = await getGenericPayload(state);
    const targetStatus = getNextStep(state.lead) as APP_STATUS;
    const transition = getTransitionToStep(state, targetStatus)!;
    return {
        ...genericPayload,
        transition,
        multiPlusType: (state.lead as AirToAirLead).multiPlusType,
        rooms: state.roomBuilder.map((room, i) => {
            const solution = state.settings.singleRoomSolutions!.find(
                (s) => s.id === room.solution,
            )!;
            return {
                id: null,
                name:
                    room.name === '' || room.name === null ? `${i18n('room')} ${i + 1}` : room.name,
                orientation: snakeToDash(room.orientation),
                position: i + 1,
                positioningType: solution?.positioningType || null,
                height: room.height,
                shading: snakeToDash(room.shading),
                solution: room.solution,
                surface: room.surface,
                windows: room.windows,
                colorVariation: room.colorVariation,
            } as ConfirmRoom;
        }),
    };
};
