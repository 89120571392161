// Non-printable characters
export const KEYS = Object.freeze({
    backspace: 'Backspace',
    tab: 'Tab',
    enter: 'Enter',
    shift: 'Shift',
    control: 'Control',
    alt: 'Alt',
    escape: 'Escape',
    meta: 'Meta', // MacOS cmd
});

// Non-printable characters keyCode/key-map
const nonPrintableCharKeyMap = Object.freeze({
    8: KEYS.backspace,
    9: KEYS.tab,
    13: KEYS.enter,
    16: KEYS.shift,
    17: KEYS.control,
    18: KEYS.alt,
    27: KEYS.escape,
    91: KEYS.meta,
});

// FIELDS //////////////////////////////////////////////////////////////////////////////////////////////////////

const keyStates = {};
let watchedKeys = [];
let shortcuts = [];

let last6Keys = '';
let sequences = [];

// PUBLIC //////////////////////////////////////////////////////////////////////////////////////////////////////

export const removeShortcut = (handler) => {
    shortcuts = shortcuts.filter(sc => sc.handler !== handler);
};

export const removeSequence = (handler) => {
    sequences = sequences.filter(sq => sq.handler !== handler);
};

export const addShortcut = (keys, handler) => {
    // Add keys to watchedKeys array and make sure it's unique
    watchedKeys = [...new Set([...watchedKeys, ...keys])];
    // Add object to shortcuts
    shortcuts.push({
        state: false,
        handler,
        keys,
    });
};

export const addSequence = (sequence, handler) => sequences.push({ sequence, handler });

// KEYBOARD EVENT HANDLING /////////////////////////////////////////////////////////////////////////////////////

const checkShortcuts = () => {
    // Check key combinations
    shortcuts.forEach(shortcut => {
        const active = shortcut.keys.every(key => keyStates[key]);
        // If changed update state
        if (active !== shortcut.state) {
            shortcut.state = active;
            // If active, call handler
            if (active) shortcut.handler();
        }
    });
};

const checkSequences = () => {
    // Loop over sequences and check for matches
    sequences.forEach(seq => {
        if (seq.sequence === last6Keys) {
            seq.handler();
        }
    });
};

// Attempts to return the same key-value if either key or keyCode are supported by the browser
// Non-printable chars will be returned as: "Shift", "Enter", etc.
export const normalizeKeyEvent = (e) => {
    const { key, keyCode } = e;
    // Check if key exists
    if (key && key !== 'Unidentified') return key;
    // Return keyCode as close as possible to what key should have been
    return nonPrintableCharKeyMap[keyCode] // Check if it's a nonPrintableChar
        || String.fromCharCode(keyCode)?.toLowerCase() // Attempt to convert to string
        || null;
};

const keyToggle = (e, down) => {
    const key = normalizeKeyEvent(e);
    if (!key) return;
    // Shortcuts
    if (watchedKeys.includes(key)) {
        keyStates[key] = down;
        checkShortcuts();
    }
    // Sequences (excluding non-printable-characters)
    if (down && sequences.length > 0 && key.length === 1) {
        last6Keys = `${last6Keys}${key}`.slice(-6);
        checkSequences();
    }
};

window.onkeydown = e => keyToggle(e, true);
window.onkeyup = e => keyToggle(e, false);
