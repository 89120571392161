import { dhwEnergyLabelColors, energyLabelColors } from '@common/data/variables';
import { DHW_ENERGY_LABELS, ENERGY_LABELS } from '@common/types';
import { formatAsShortEnergyLabel } from '@tsUtils';
import React from 'react';
import { EnergyLabels } from '../../redux/selectors';
import * as Styles from '../../styles/components/EnergyTag';

// TypeGuard for energyLabels vs dhwEnergyLabels
const isDhwRating = (
    type: keyof EnergyLabels,
    rating: ENERGY_LABELS | DHW_ENERGY_LABELS,
): rating is DHW_ENERGY_LABELS => {
    return type === 'dhw';
};

type PropsType = {
    type: keyof EnergyLabels;
    rating: ENERGY_LABELS | DHW_ENERGY_LABELS;
};
const EnergyTag = ({ rating, type }: PropsType): JSX.Element => {
    return (
        <Styles.EnergyTag
            energyColor={
                isDhwRating(type, rating) ? dhwEnergyLabelColors[rating] : energyLabelColors[rating]
            }
            energyText={formatAsShortEnergyLabel(rating)}
        />
    );
};

export default EnergyTag;
