/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { createLogic } from 'redux-logic';
import { APP_STATUS } from '../../../constants';
import { Store } from '../../../types';
import {
    InitSizingToolAtaHeatingAction,
    LEAD_ACTIONS,
    TOOL_SIZING_ATA_HEATING,
    UpdateSizingToolAtaHeatingAction,
    redirect,
    setLeadRemote,
} from '../../actions';
import { getSizingPlatformJson, getSizingToolsJson } from '../../selectors';

type DepObj = { getState: () => Store };

type InitDepObj = DepObj & { action: InitSizingToolAtaHeatingAction };
const initSizingAtaHeatingLogic = createLogic({
    type: TOOL_SIZING_ATA_HEATING.init,
    name: 'sizing.ataHeating.init',
    validate({ getState, action }: InitDepObj, allow, reject) {
        const state = getState();
        if (state.lead.status === APP_STATUS.sp_sizing_tool) {
            window._rsnChannel.publish('sizing.winterheating.init', {
                target: 'div#external-tool-sizing-ata-heating',
                ctaTarget: 'div#cta-teleport-target',
                platform: getSizingPlatformJson(state),
                tools: null,
                step: null,
            });
            allow(action);
        } else {
            reject({ ...action, type: `🚫 (${action.type})` });
        }
    },
});

type UpdateDepObj = { action: UpdateSizingToolAtaHeatingAction };
const updateSizingAtaHeatingApiLogic = createLogic({
    type: TOOL_SIZING_ATA_HEATING.update,
    name: 'sizing.ataHeating.update',
    process({ action }: UpdateDepObj, dispatch, done) {
        dispatch(
            setLeadRemote(
                APP_STATUS.sp_sizing_tool,
                `${APP_STATUS.sp_sizing_tool}.${action.data.step}`,
            ),
        );
        done();
    },
});

const doneSizingAtaHeatingApiLogic = createLogic({
    type: TOOL_SIZING_ATA_HEATING.done,
    name: 'sizing.ataHeating.done',
    process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        if (state.lead.toolData!.sizingWinterHeating!['no-solution']) {
            // Save the no-solution data to lead but without advancing to sp_selsoft_result.
            // noSolutionLogic will trigger when setLeadRemote has finished
            // dispatch(setLeadRemote(APP_STATUS.dealer_selection));

            // FIXME: The above should be re-enabled after the AEM no solutions page is delivered
            // For now: redirect to P5 where we will show a "No solutions found" warning
            dispatch(setLeadRemote(APP_STATUS.dealer_selection));
        } else {
            dispatch(setLeadRemote(APP_STATUS.sp_selsoft_result));
        }
        done();
    },
});

const noSolutionLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'sizing.ataHeating.no-solution',
    process({ getState }: DepObj, dispatch, done) {
        if (getState().lead.toolData?.sizingWinterHeating?.['no-solution']) {
            dispatch(redirect(getState().aemSettings.noSolutionPage, '_self', true));
        }
        done();
    },
});

export default [
    initSizingAtaHeatingLogic,
    updateSizingAtaHeatingApiLogic,
    doneSizingAtaHeatingApiLogic,
    // noSolutionLogic,
];
