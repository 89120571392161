/* eslint-disable operator-linebreak */
import { createLogic } from 'redux-logic';
import sizingLogicAtw from './sizingLogic.atw';
import sizingLogicAtaCooling from './sizingLogic.ata.cooling';
import sizingLogicAtaHeating from './sizingLogic.ata.heating';
import sizingLogicAtaMultiRoom from './sizingLogic.ata.multiRoom';
import quotationLogicAtw from './quotationLogic.atw';
import quotationLogicAta from './quotationLogic.ata';
import { LEAD_ACTIONS, SetLeadLocalAction } from '../../actions';
import { Store } from '../../../types';
import { APP_STATUS } from '../../../constants';
import { isGermany, getPojectType } from '../../selectors';

type DepObj = { getState: () => Store };
type SetLeadDepObj = DepObj & { action: SetLeadLocalAction };

const unmountLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'aaltra.unmount',
    validate({ getState, action }: SetLeadDepObj, allow) {
        const store = getState();
        const targetStatus = action.lead?.status;
        const currentStatus = store.lead.status;
        if (targetStatus && currentStatus) {
            if (
                currentStatus === APP_STATUS.sp_sizing_tool &&
                targetStatus !== APP_STATUS.sp_sizing_tool
            ) {
                const projectType = getPojectType(store);
                window._rsnChannel.publish(`sizing.${projectType}.unmount`);
            } else if (
                currentStatus === APP_STATUS.sp_quotation_tool &&
                targetStatus !== APP_STATUS.sp_quotation_tool
            ) {
                window._rsnChannel.publish('quotation.unmount');
            } else if (
                currentStatus === APP_STATUS.incentives_tool &&
                targetStatus !== APP_STATUS.incentives_tool &&
                isGermany(store)
            ) {
                window._rsnChannel.publish('incentives.de.unmount');
            }
        }
        allow(action);
    },
});

export default [
    unmountLogic,
    ...sizingLogicAtw,
    ...sizingLogicAtaCooling,
    ...sizingLogicAtaHeating,
    ...sizingLogicAtaMultiRoom,
    ...quotationLogicAtw,
    ...quotationLogicAta,
];
