/* eslint-disable lines-between-class-members */
import * as Logger from '@common/Logger';
import i18n from '@common/i18n';
import { getCurrentUrl } from '@tsUtils';
import { scrollTop } from '@utils';
import { Store } from 'redux';
import { APP_STATUS } from './constants';
import { ActionsType, navigationRequest } from './redux/actions';
import { Store as State } from './types';

const SIZING = APP_STATUS.sp_sizing_tool;
const QUOTATION = APP_STATUS.sp_quotation_tool;

const log = (msg: string, ...subMessages: Array<string>): void => {
    Logger.log(`🌐 ${msg}`, Logger.STYLE.DEFAULT, ...subMessages);
};
const logError = (msg: string): void => Logger.log(`🌐 💢 ${msg}`, Logger.STYLE.ERROR);

const getAppState = (page: string): string => page.split('.')[0];

const isAaltraPage = (page: string): boolean => {
    return page.startsWith(SIZING) || page.startsWith(QUOTATION);
};

const isStepForward = (current: string, target: string): boolean => {
    return (
        Object.keys(APP_STATUS).indexOf(target.split('.')[0]) >
        Object.keys(APP_STATUS).indexOf(current.split('.')[0])
    );
};

// Checks to see if we're navigating backwards to a tool (from result/incentives/dealer)
export const isRewindingIntoTool = (current: string, target: string, tool: string): boolean =>
    getAppState(current) !== tool && getAppState(target) === tool;

// Checks to see if we're navigating backwards from the first page of a tool
const isRewindingBeyondTool = (current: string, target: string, tool: string): boolean =>
    target === tool && current.startsWith(tool);

class Navigator {
    /* Class members */

    history: Array<string>;
    store?: Store<State, ActionsType>;
    isRewindingTool: boolean;
    userWasPrompted: boolean;
    onPopState: (ev: PopStateEvent) => void;

    /* Initialisation */

    constructor() {
        this.history = [];
        this.isRewindingTool = false;
        this.userWasPrompted = false;
        this.onPopState = this.debounce(this._onPopState, 300);
    }

    init(store: Store<State, ActionsType>): void {
        this.store = store;
        window.addEventListener('popstate', this.onPopState);
    }

    // Custom debounce method for _onPopState
    private debounce(
        func: (ev: PopStateEvent) => void,
        delay: number,
    ): (ev: PopStateEvent) => void {
        let timeoutId: NodeJS.Timeout | null;
        return (ev: PopStateEvent) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(this, [ev]);
            }, delay);
        };
    }

    /* Browser pop-events */

    private _onPopState(ev: PopStateEvent): void {
        if (ev.type === 'popstate' && ev.state) {
            const { page } = ev.state;
            if (this.history.includes(page)) {
                // Store currentPage before updating history
                const currentPage = this.getCurrentPage();
                // Update history right now to stay in sync
                this.updateHistory(page);
                if (isRewindingBeyondTool(currentPage, page, SIZING) && !this.isRewindingTool) {
                    log(`onPopState ( ${page} ) | go(-1) beyond sizing-tool`);
                    window.history.go(-1);
                } else if (
                    isRewindingBeyondTool(currentPage, page, QUOTATION) &&
                    !this.isRewindingTool
                ) {
                    // eslint-disable-next-line no-alert
                    if (this.userWasPrompted || window.confirm(i18n('confirm_back'))) {
                        log(`onPopState ( ${page} ) | go(-1) beyond quotation-tool`);
                        window.history.go(-1);
                    } else {
                        /* NOTE: We're no longer pushing to the history-stack as this is flagging our domain */
                        // User does not want to go back, push state back onto the history-stack
                        // window.history.pushState(ev.state, document.title, this.getUrl());
                        // Make sure we never prompt the user again
                        this.userWasPrompted = true;
                    }
                } else if (
                    isRewindingIntoTool(currentPage, page, SIZING) &&
                    this.history.includes(SIZING) &&
                    page !== SIZING
                ) {
                    log(`onPopState ( ${page} ) | resetting sizingTool 🧹`);
                    this.isRewindingTool = true;
                    this.navigate(SIZING);
                } else if (
                    isRewindingIntoTool(currentPage, page, QUOTATION) &&
                    this.history.includes(QUOTATION) &&
                    page !== QUOTATION
                ) {
                    log(`onPopState ( ${page} ) | resetting quotationTool 🧹`);
                    this.isRewindingTool = true;
                    this.navigate(QUOTATION);
                } else {
                    // Actually request the navigation to backend
                    // This will trigger a setLeadLocal action, which will trigger this.updateHistory
                    log(`onPopState ( ${page} ) | dispatch(navigationRequest)`);
                    this.store!.dispatch(navigationRequest(page));
                    this.isRewindingTool = false;
                }
            } else {
                // This scenario should never occur but logError for debugging purposes
                logError(`onPopState ( ${page} ) | 🚫 unknown page, dispatch navigationRequest`);
                this.store?.dispatch(navigationRequest(page));
            }
        }
    }

    /* Navigate API */

    setLeadStatus(page: string): void {
        // Only update when changing pages (and ignore Aaltra subtool page changes)
        if (!this.getCurrentPage().startsWith(page)) this.updateHistory(page);
    }

    // This method has 3 purposes:
    // - keeping window.history & this.history in sync by:
    //   - adding / replacing window.history
    //   - adding / rewinding this.history
    // - updating document title
    // - updating url querystring
    private updateHistory(page: string): void {
        const _log = (msg: string): void => log(`updateHistory ( ${page} ) | ${msg}`);
        const currentPage = this.getCurrentPage();
        if (this.history.length === 0) {
            _log('app-init -> replaceHistory 🔁');
            this.replaceHistory(page);
        } else if (currentPage.startsWith(page)) {
            _log(`ignore 🚫`);
        } else if (isAaltraPage(page)) {
            if (this.history.includes(page)) {
                // Going backwards in a subtool
                _log('tool-rwd -> rewindHistory ⏪');
                this.rewindHistory(page);
            } else {
                // Going forwards in a subtool
                _log('tool-fwd -> pushHistory ⏭️');
                this.pushHistory(page);
            }
        } else if (isStepForward(currentPage, page)) {
            _log('forward -> pushHistory ⏭️');
            this.pushHistory(page);
            scrollTop();
        } else if (!this.history.includes(page)) {
            _log('unknown -> replaceHistory 🔁');
            this.replaceHistory(page);
        } else {
            _log('back -> rewindHistory ⏪');
            this.rewindHistory(page);
        }
    }

    // Navigate is called directly by UI (ie.: clicking the breadcrumbs)
    navigate(page: string): void {
        if (this.history.includes(page)) {
            const steps = 1 + this.history.indexOf(page) - this.history.length;
            log(`navigate ( ${page} ) | window.history.go(${steps})`);
            window.history.go(steps); // this will triggerNavigationRequest in onPopState
        } else {
            log(`navigate ( ${page} ) | dispatch(navigationRequest)`);
            this.store?.dispatch(navigationRequest(page));
        }
    }

    /* Utility methods */

    private getUrl(): string {
        const params = new URLSearchParams(window.location.search);
        const leadId = this.store!.getState().lead?.id;
        if (leadId) params.set('lead', leadId);
        return `${getCurrentUrl(false, false)}?${params}`;
    }

    private getCurrentPage(): string {
        return this.history[this.history.length - 1] || '';
    }

    private pushHistory(page: string): void {
        this.history.push(page);
        window.history.pushState({ page }, '', this.getUrl());
        this.syncDocumentTitle();
    }

    private replaceHistory(page: string): void {
        this.history.pop();
        this.history.push(page);
        window.history.replaceState({ page }, '', this.getUrl());
        this.syncDocumentTitle();
    }

    private rewindHistory(page: string): void {
        this.history.splice(this.history.findIndex((h) => h === page) + 1);
        this.syncDocumentTitle();
    }

    private syncDocumentTitle(): void {
        document.title = `${i18n('daikin')} - ${i18n(this.getCurrentPage())}`;
    }
}

const _singleton = new Navigator();

export default _singleton;
