import { Multiline } from '@common/atoms/Multiline';
import { patterns } from '@common/data/variables';
import { useAutoFocus } from '@common/hooks/tsHooks';
import i18n from '@common/i18n';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MODAL_TYPE } from '../../../constants';
import { closeModal, setLeadLocal } from '../../../redux/actions';
import { useAppSelector } from '../../../redux/index';
import * as ButtonStyles from '../../../styles/components/Button';
import * as FormStyles from '../../../styles/components/Form';
import * as ModalStyles from '../../../styles/components/Modal';
import * as TypographyStyles from '../../../styles/components/Typography';
import { Lead } from '../../../types';
import PrivacyPolicy, { usePrivacyPolicy } from '../PrivacyPolicy';
import Modal from './Modal';

type SaveProgressUrlResponse = {
    lead: Lead;
    success: boolean;
};

type PropsType = {
    metaData?: unknown;
};

type MetaData = {
    url: string;
};

const PardotModal = (props: PropsType): JSX.Element => {
    const metaData = props.metaData as MetaData;
    const saveProgressUrl = useAppSelector((state) => state.settings.urls.saveProgress);
    const payload = useAppSelector((state) => ({
        leadId: state.lead.id,
        version: state.lead.version,
    }));

    const dispatch = useDispatch();

    // If there is a redirect url then the exit overlay should be rendered
    // Is opened because of an AEM redirect or via the basket's save-button
    const isRedirect = metaData?.url;

    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const privacyPolicy = usePrivacyPolicy();
    const autofocusRef = useAutoFocus();

    const onSave = () => {
        if (patterns.email.test(email)) {
            setError(false);
            axios
                .post<SaveProgressUrlResponse>(saveProgressUrl, {
                    ...payload,
                    email,
                    newsletterConsent: privacyPolicy.ncAccepted,
                })
                .then((res) => {
                    const { status, data } = res;
                    if (status === 200 && data?.success === true) {
                        if (isRedirect) {
                            window.open(metaData.url, '_self');
                        } else {
                            dispatch(setLeadLocal(data.lead, true));
                            dispatch(closeModal(MODAL_TYPE.pardot));
                        }
                    }
                })
                .catch((_) => {
                    setError(true);
                });
        } else {
            setError(true);
        }
    };

    const onClose = () => {
        if (isRedirect) {
            window.open(metaData.url, '_self');
        } else {
            dispatch(closeModal(MODAL_TYPE.pardot));
        }
    };

    return (
        <Modal type={MODAL_TYPE.pardot} onClose={onClose}>
            <ModalStyles.ModalHeader>
                <TypographyStyles.H4 mb={5}>
                    {isRedirect
                        ? i18n('exit_save_your_progress_title')
                        : i18n('save_your_progress_title')}
                </TypographyStyles.H4>
            </ModalStyles.ModalHeader>

            <TypographyStyles.DefaultText mb={4}>
                <Multiline>
                    {isRedirect
                        ? i18n('exit_save_your_progress_description')
                        : i18n('save_your_progress_description')}
                </Multiline>
            </TypographyStyles.DefaultText>

            <ModalStyles.ModalForm>
                <FormStyles.Group>
                    <FormStyles.Input
                        required
                        ref={autofocusRef}
                        type="email"
                        value={email || ''}
                        placeholder={i18n('your_email_address')}
                        hasError={error}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            setEmail(e.currentTarget.value);
                        }}
                    />
                    {error && <FormStyles.Error>{i18n('email_address_error')}</FormStyles.Error>}
                </FormStyles.Group>
                <PrivacyPolicy hook={privacyPolicy} />
            </ModalStyles.ModalForm>

            <ModalStyles.ModalFooter>
                <ModalStyles.ModalFooterButtons>
                    {isRedirect && (
                        <ButtonStyles.PrimaryOutline isLarge isInline onClick={onClose}>
                            {i18n('continue_without_saving')}
                        </ButtonStyles.PrimaryOutline>
                    )}
                    <ButtonStyles.Primary
                        isLarge
                        isInline
                        onClick={onSave}
                        disabled={!privacyPolicy.ppAccepted}
                    >
                        {i18n('save_your_progress_button')}
                    </ButtonStyles.Primary>
                </ModalStyles.ModalFooterButtons>
            </ModalStyles.ModalFooter>
        </Modal>
    );
};

export default PardotModal;
