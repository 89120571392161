import styled from 'styled-components';
import * as PreloaderStyles from './Preloader';
import { color, fontWeight, space } from '../common/Theme';

export const Container = styled.div`
    position: relative;
    min-height: 180px;
`;

export const LoaderWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    z-index: 10;
`;

export const Loader = styled(PreloaderStyles.Preloader)`
    padding-bottom: ${space[4]}px;
    color: ${color.grey400};
`;

export const LoaderTitle = styled.div`
    font-size: 26px;
    font-weight: ${fontWeight.regular};
    line-height: 35px;
    color: ${color.grey500};
`;

export const ToolWrapper = styled.div`
    position: relative;
    background-color: ${color.grey100};
    z-index: 20;
`;
