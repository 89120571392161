import {
    DETACHED_SCREEN_TYPE,
    ENERGY_TYPES,
    MODAL_TYPE,
    QUOTATION_CHOICE,
    SCRIPT_STATE,
} from '../../constants';
import {
    AppStatus,
    Dealer,
    EpackageType,
    EvsAtaHeatingData,
    EvsAtwData,
    Room,
    SelSoftSolution,
} from '../../types';

export enum APP_STATE_ACTIONS {
    setGlobalError = 'appState/setGlobalError',
    openModal = 'appState/openModal',
    closeModal = 'appState/closeModal',
    openDetachedScreen = 'appState/openDetachedScreen',
    closeDetachedScreen = 'appState/closeDetachedScreen',
    navigationRequest = 'appState/navigationRequest',
    setAaltraScriptState = 'appState/setAaltraScriptState',
    userNavigation = 'appState/userNavigation',
    setSwapCooling = 'appState/setSwapCooling',
    setCommerceError = 'appState/setCommerceError',
    setQuotationChoice = 'appState/setQuotationChoice',
    fetchDealers = 'appState/fetchDealers',
    setDealers = 'appState/setDealers',
    fetchCities = 'appState/fetchCities',
    suggestCities = 'appState/suggestCities',
    setCustomerDetailsErrors = 'appState/setCustomerDetailsErrors',
    skipToSelectDealer = 'appState/skipToSelectDealer',
    setSkipToSelectDealerError = 'appState/setSkipToSelectDealerError',
    setSelSoftResults = 'appState/setSelSoftResults',
    fetchEpackages = 'appState/fetchEpackages',
    setEpackages = 'appState/setEpackages',
    fetchEvsData = 'appState/fetchEvsData',
    setEvsData = 'appState/setEvsData',
    setManualIncentives = 'appState/setManualIncentives',
    openEvsCompareAutomatic = 'appState/openEvsCompareAutomatic',
    setEnergyPricesErrors = 'appState/setEnergyPricesErrors',
    setRoomBuilderErrors = 'appState/setRoomBuilderErrors',
    setEnergyLabelsError = 'appState/setEnergyLabelsError',
    redirect = 'appState/redirect',
    saveProgress = 'appState/saveProgress',
}

type SetGlobalErrorAction = {
    type: APP_STATE_ACTIONS.setGlobalError;
    errorMessage: string;
};
export const setGlobalError = (errorMessage: string): SetGlobalErrorAction => ({
    type: APP_STATE_ACTIONS.setGlobalError,
    errorMessage,
});

type OpenModalAction = {
    type: APP_STATE_ACTIONS.openModal;
    modalType: MODAL_TYPE;
    metaData?: unknown;
    asOverlay?: boolean;
};
export const openModal = (
    modalType: MODAL_TYPE,
    metaData?: unknown,
    asOverlay: boolean = false,
): OpenModalAction => ({
    type: APP_STATE_ACTIONS.openModal,
    modalType,
    metaData,
    asOverlay,
});

export type CloseModalAction = {
    type: APP_STATE_ACTIONS.closeModal;
    modalType: MODAL_TYPE;
};
export const closeModal = (modalType: MODAL_TYPE): CloseModalAction => ({
    type: APP_STATE_ACTIONS.closeModal,
    modalType,
});

export type OpenDetachedScreenAction = {
    type: APP_STATE_ACTIONS.openDetachedScreen;
    screenType: DETACHED_SCREEN_TYPE;
    metaData?: unknown;
};
export const openDetachedScreen = (
    screenType: DETACHED_SCREEN_TYPE,
    metaData?: unknown,
): OpenDetachedScreenAction => ({
    type: APP_STATE_ACTIONS.openDetachedScreen,
    screenType,
    metaData,
});

type CloseDetachedScreenAction = {
    type: APP_STATE_ACTIONS.closeDetachedScreen;
    screenType: DETACHED_SCREEN_TYPE;
};
export const closeDetachedScreen = (
    screenType: DETACHED_SCREEN_TYPE,
): CloseDetachedScreenAction => ({
    type: APP_STATE_ACTIONS.closeDetachedScreen,
    screenType,
});

export type NavigationRequestAction = {
    type: APP_STATE_ACTIONS.navigationRequest;
    page: string;
};
export const navigationRequest = (page: string): NavigationRequestAction => ({
    type: APP_STATE_ACTIONS.navigationRequest,
    page,
});

export type SetAaltraScriptStateAction = {
    type: APP_STATE_ACTIONS.setAaltraScriptState;
    aaltraScriptState: SCRIPT_STATE;
};
export const setAaltraScriptState = (
    aaltraScriptState: SCRIPT_STATE,
): SetAaltraScriptStateAction => ({
    type: APP_STATE_ACTIONS.setAaltraScriptState,
    aaltraScriptState,
});

/*
Navigate-actions are internal requests to navigate to a certain page
They will trigger an API-call which in turn triggers an UpdateHistory-action
*/
export type UserNavigationAction = {
    type: APP_STATE_ACTIONS.userNavigation;
    page: AppStatus;
    canTrack?: boolean;
};
export const userNavigation = (page: AppStatus, canTrack = false): UserNavigationAction => ({
    type: APP_STATE_ACTIONS.userNavigation,
    page,
    canTrack,
});

type FetchEpackagesAction = { type: APP_STATE_ACTIONS.fetchEpackages };
export const fetchEpackages = (): FetchEpackagesAction => ({
    type: APP_STATE_ACTIONS.fetchEpackages,
});

type SetEpackagesAction = { type: APP_STATE_ACTIONS.setEpackages; epackages: EpackageType[] };
export const setEpackages = (epackages: EpackageType[]): SetEpackagesAction => ({
    type: APP_STATE_ACTIONS.setEpackages,
    epackages,
});

export type SetSwapCoolingAction = {
    type: APP_STATE_ACTIONS.setSwapCooling;
    swapCooling: boolean;
};
export const setSwapCooling = (swapCooling: boolean): SetSwapCoolingAction => ({
    type: APP_STATE_ACTIONS.setSwapCooling,
    swapCooling,
});

export type SetCommerceErrorAction = {
    type: APP_STATE_ACTIONS.setCommerceError;
    commerceError: string | null;
};
export const setCommerceError = (commerceError: string | null): SetCommerceErrorAction => ({
    type: APP_STATE_ACTIONS.setCommerceError,
    commerceError,
});

export type FetchEvsDataAction = { type: APP_STATE_ACTIONS.fetchEvsData };
export const fetchEvsData = (): FetchEvsDataAction => ({ type: APP_STATE_ACTIONS.fetchEvsData });

export type SetEvsDataAction = {
    type: APP_STATE_ACTIONS.setEvsData;
    evsData: EvsAtwData | EvsAtaHeatingData | null;
};
export const setEvsData = (evsData: EvsAtwData | EvsAtaHeatingData | null): SetEvsDataAction => ({
    type: APP_STATE_ACTIONS.setEvsData,
    evsData,
});

export type OpenEvsCompareAutomaticAction = { type: APP_STATE_ACTIONS.openEvsCompareAutomatic };
export const openEvsCompareAutomatic = (): OpenEvsCompareAutomaticAction => ({
    type: APP_STATE_ACTIONS.openEvsCompareAutomatic,
});

export type SetManualIncentivesAction = {
    type: APP_STATE_ACTIONS.setManualIncentives;
    manualIncentive: number;
};
export const setManualIncentives = (manualIncentive: number): SetManualIncentivesAction => ({
    type: APP_STATE_ACTIONS.setManualIncentives,
    manualIncentive,
});

export type SetEnergyPricesErrorsAction = {
    type: APP_STATE_ACTIONS.setEnergyPricesErrors;
    energyPricesErrors?: Record<ENERGY_TYPES, Array<string>>;
};
export const setEnergyPricesErrors = (
    energyPricesErrors?: Record<ENERGY_TYPES, Array<string>>,
): SetEnergyPricesErrorsAction => ({
    type: APP_STATE_ACTIONS.setEnergyPricesErrors,
    energyPricesErrors,
});

export type SetEnergyLabelsErrorAction = {
    type: APP_STATE_ACTIONS.setEnergyLabelsError;
    energyLabelsError: string;
};
export const setEnergyLabelsError = (energyLabelsError: string): SetEnergyLabelsErrorAction => ({
    type: APP_STATE_ACTIONS.setEnergyLabelsError,
    energyLabelsError,
});

export type SetQuotationChoiceAction = {
    type: APP_STATE_ACTIONS.setQuotationChoice;
    quotationChoice: QUOTATION_CHOICE;
};
export const setQuotationChoice = (
    quotationChoice: QUOTATION_CHOICE,
): SetQuotationChoiceAction => ({
    type: APP_STATE_ACTIONS.setQuotationChoice,
    quotationChoice,
});

export type FetchDealersAction = {
    type: APP_STATE_ACTIONS.fetchDealers;
};
export const fetchDealers = (): FetchDealersAction => ({
    type: APP_STATE_ACTIONS.fetchDealers,
});

export type SetDealersAction = {
    type: APP_STATE_ACTIONS.setDealers;
    dealers: Dealer[];
    errorMessage: string | null;
};
export const setDealers = (dealers: Dealer[], errorMessage: string | null): SetDealersAction => ({
    type: APP_STATE_ACTIONS.setDealers,
    dealers,
    errorMessage,
});

export type FetchCitiesAction = {
    type: APP_STATE_ACTIONS.fetchCities;
    postal: string;
};
export const fetchCities = (postal: string): FetchCitiesAction => ({
    type: APP_STATE_ACTIONS.fetchCities,
    postal,
});

type SuggestCitiesAction = {
    type: APP_STATE_ACTIONS.suggestCities;
    postal: string;
    cities: string[];
};
export const suggestCities = (postal: string, cities: string[]): SuggestCitiesAction => ({
    type: APP_STATE_ACTIONS.suggestCities,
    postal,
    cities,
});

type SetCustomerDetailsErrors = {
    type: APP_STATE_ACTIONS.setCustomerDetailsErrors;
    customerDetailsErrors: Record<string, string>;
};
export const setCustomerDetailsErrors = (
    customerDetailsErrors: Record<string, string>,
): SetCustomerDetailsErrors => ({
    type: APP_STATE_ACTIONS.setCustomerDetailsErrors,
    customerDetailsErrors,
});

export type SkipToSelectDealerAction = {
    type: APP_STATE_ACTIONS.skipToSelectDealer;
    postalCode: string;
};
export const skipToSelectDealer = (postalCode: string): SkipToSelectDealerAction => ({
    type: APP_STATE_ACTIONS.skipToSelectDealer,
    postalCode,
});

export type SetSkipToSelectDealerErrorAction = {
    type: APP_STATE_ACTIONS.setSkipToSelectDealerError;
    skipToSelectDealerError: string | null;
};
export const setSkipToSelectDealerError = (
    skipToSelectDealerError: string | null,
): SetSkipToSelectDealerErrorAction => ({
    type: APP_STATE_ACTIONS.setSkipToSelectDealerError,
    skipToSelectDealerError,
});

export type SetSelSoftResultsAction = {
    type: APP_STATE_ACTIONS.setSelSoftResults;
    solutions: SelSoftSolution[];
};
export const setSelSoftResults = (solutions: SelSoftSolution[]): SetSelSoftResultsAction => ({
    type: APP_STATE_ACTIONS.setSelSoftResults,
    solutions,
});

export type SetRoomBuilderErrorsAction = {
    type: APP_STATE_ACTIONS.setRoomBuilderErrors;
    roomBuilderErrors: Record<string, Record<keyof Room, Array<string>>>;
};
export const setRoomBuilderErrors = (
    roomBuilderErrors: Record<string, Record<keyof Room, Array<string>>>,
): SetRoomBuilderErrorsAction => ({
    type: APP_STATE_ACTIONS.setRoomBuilderErrors,
    roomBuilderErrors,
});

export type RedirectAction = {
    type: APP_STATE_ACTIONS.redirect;
    url: string;
    target: '_blank' | '_self';
    skipPardot: boolean;
    fromAem: boolean;
};
export const redirect = (
    url: string,
    target: '_blank' | '_self',
    skipPardot: boolean = false,
    fromAem: boolean = false,
): RedirectAction => ({
    type: APP_STATE_ACTIONS.redirect,
    url,
    target,
    skipPardot,
    fromAem,
});

export type SaveProgressAction = {
    type: APP_STATE_ACTIONS.saveProgress;
};
export const saveProgress = (): SaveProgressAction => ({
    type: APP_STATE_ACTIONS.saveProgress,
});

export type AppStateActionsType =
    | UserNavigationAction
    | SetGlobalErrorAction
    | OpenModalAction
    | CloseModalAction
    | OpenDetachedScreenAction
    | CloseDetachedScreenAction
    | NavigationRequestAction
    | SetAaltraScriptStateAction
    | FetchEpackagesAction
    | SetEpackagesAction
    | SetSwapCoolingAction
    | SetCommerceErrorAction
    | FetchEvsDataAction
    | SetEvsDataAction
    | OpenEvsCompareAutomaticAction
    | SetManualIncentivesAction
    | SetEnergyPricesErrorsAction
    | SetQuotationChoiceAction
    | FetchDealersAction
    | SetDealersAction
    | FetchCitiesAction
    | SuggestCitiesAction
    | SetCustomerDetailsErrors
    | SkipToSelectDealerAction
    | SetSkipToSelectDealerErrorAction
    | SetSelSoftResultsAction
    | SetRoomBuilderErrorsAction
    | SetEnergyLabelsErrorAction
    | RedirectAction
    | SaveProgressAction;
