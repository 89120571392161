import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import { preventDefault as pd } from '@utils';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DETACHED_SCREEN_TYPE } from '../../constants';
import { useGigyaUI } from '../../hooks';
import { useAppSelector } from '../../redux';
import { openDetachedScreen } from '../../redux/actions';
import * as PopoverStyles from '../../styles/components/Popover';
import * as Styles from '../../styles/components/UserMenu';

const UserMenu: FC = () => {
    const dispatch = useDispatch();

    const gigyaUser = useAppSelector((store) => store.appState.gigyaUser);

    // Local state
    const [isOpen, setIsOpen] = useState(false);

    // Don't show userMenu if gigya is unavailable
    const gigyaActions = useGigyaUI();
    if (!gigyaActions) return null;

    const onLogin = (): void => {
        setIsOpen(false);
        gigyaActions.login();
    };

    const onLogout = (): void => {
        setIsOpen(false);
        gigyaActions.logout();
    };

    const onOpenSavedSolutions = (): void => {
        setIsOpen(false);
        dispatch(openDetachedScreen(DETACHED_SCREEN_TYPE.account));
    };

    const onToggleOpen = (): void => {
        setIsOpen(!isOpen);
    };

    const onRestart = (): void => {
        window.location.href = window.location.href.replace(window.location.hash, '');
    };

    // User is not logged in, show login button
    if (!gigyaUser) {
        return (
            <Styles.UserMenu>
                <Styles.Toggle type="button" onClick={pd(onLogin)}>
                    <Styles.ToggleIcon>
                        <Icons.Glyph.UserCircle />
                    </Styles.ToggleIcon>
                    <Styles.ToggleLabel>{i18n('sign_in')}</Styles.ToggleLabel>
                    <Styles.ToggleChevron>
                        <Icons.Glyph.MenuChevron />
                    </Styles.ToggleChevron>
                </Styles.Toggle>
            </Styles.UserMenu>
        );
    }

    // User is logged in, show controls
    return (
        <Styles.UserMenu>
            <Styles.Toggle type="button" onClick={pd(onToggleOpen)}>
                <Styles.ToggleIcon>
                    <Icons.Glyph.UserCircle />
                </Styles.ToggleIcon>
                <Styles.ToggleLabel>{`${gigyaUser.firstName} ${gigyaUser.lastName}`}</Styles.ToggleLabel>
                <Styles.ToggleChevron>
                    <Icons.Glyph.MenuChevron />
                </Styles.ToggleChevron>
            </Styles.Toggle>

            <Styles.Navigation isOpen={isOpen}>
                <PopoverStyles.Header>
                    <PopoverStyles.Title>{i18n('account')}</PopoverStyles.Title>
                    <PopoverStyles.Toggle onClick={pd(onToggleOpen)}>
                        <Icons.Glyph.CloseCircle />
                    </PopoverStyles.Toggle>
                </PopoverStyles.Header>
                <Styles.NavigationItem href="" onClick={pd(onOpenSavedSolutions)}>
                    <Icons.Glyph.Stack />
                    {i18n('saved_solutions')}
                </Styles.NavigationItem>

                <Styles.NavigationItem href="" onClick={pd(onRestart)}>
                    <Icons.Glyph.FileAdd />
                    {i18n('start_a_new_project')}
                </Styles.NavigationItem>

                <Styles.NavigationItem href="" onClick={pd(onLogout)}>
                    <Icons.Glyph.Upload />
                    {i18n('sign_out')}
                </Styles.NavigationItem>
            </Styles.Navigation>
            {isOpen && <Styles.Backdrop />}
        </Styles.UserMenu>
    );
};

export default UserMenu;
