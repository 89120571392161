import { HEAT_PUMP_VOLUMES, SPLIT_PRICE_TYPE, TANK_VOLUMES } from '@common/types';
import { TsBoolean } from '@tsUtils';
import { MULTI_PLUS_TYPE } from '../../constants';
import {
    AirToAirLead,
    AtaMultiRoomUnitData,
    LeadRoom,
    SingleRoomSolution,
    SizingAtaMultiRoomRoomData,
    Store,
} from '../../types';
import { getShowPrices } from './genericSelectors';
import { getCommercePrices } from './leadSelectors';
import { getTotalVolumeRequired } from './selsoftSelectors';

export const getSingleRoomSolution = (
    state: Store,
    solutionId: string,
): SingleRoomSolution | undefined => {
    return state.settings.singleRoomSolutions?.find((s) => s.id === solutionId);
};

export const getCheapestIndoorSolutionFromPrice = (state: Store): number => {
    if (!state.settings.singleRoomSolutions) return 0;
    return Math.min(...state.settings.singleRoomSolutions.map((sol) => sol.fromPriceIndoorUnit));
};

export const getTotalMultiRoomPrice = (state: Store): number => {
    let totalPrice = 0;
    const rooms = state.roomBuilder;
    const { isDhwRequired } = state.lead as AirToAirLead;
    const roomsFromPrice = rooms.reduce((total, r) => {
        if (r.solution) {
            const solution = getSingleRoomSolution(state, r.solution);
            if (solution?.fromPriceIndoorUnit) {
                total += solution.fromPriceIndoorUnit!;
            }
        } else {
            // For room with no solution yet, the cheapest indoor solution from price is added to the total price
            totalPrice += getCheapestIndoorSolutionFromPrice(state);
        }
        return total;
    }, 0);
    const multiRoomSolution = state.settings.multiRoomSolutions?.find(
        (mss) => mss.numberOfRooms === rooms.length,
    );
    const multiPlusSolution = getMultiPlusSolution(state);

    if (isDhwRequired && !!multiPlusSolution?.type) {
        switch (multiPlusSolution.type) {
            case MULTI_PLUS_TYPE.tank: {
                // Total price + tank from price (outdoor + tank)
                totalPrice += multiPlusSolution.fromPrice || 0;
                break;
            }
            case MULTI_PLUS_TYPE.heatPump: {
                // Total price + heatPump from price
                totalPrice += multiPlusSolution.fromPrice || 0;
                // Total price + from price of number or rooms
                totalPrice += multiRoomSolution?.fromPrice || 0;
                break;
            }
        }
    } else {
        /* Total price + from price of number or rooms
        if no dhw is required or dhw is required but water volume is to high */
        totalPrice += multiRoomSolution?.fromPrice || 0;
    }
    // Total price + from prices of selected indoor units
    totalPrice += roomsFromPrice;
    totalPrice += multiRoomSolution?.smallMaterialCost || 0;
    totalPrice += multiRoomSolution?.laborCost || 0;
    return parseFloat(totalPrice.toFixed(2));
};

export const getTotalSurface = (state: Store): number => {
    return state.roomBuilder.reduce((total, room) => total + (room.surface || 0), 0);
};

export const getShowSplitPrices = (state: Store): boolean => {
    const { pricingDisplayStrategy: priceStrat } = state.settings.solutionPricing;
    if (state.appState.commerceError) return false;
    return getShowPrices(state) && priceStrat === SPLIT_PRICE_TYPE.show_split_prices;
};

export const getShowUnitPrices = (state: Store): boolean => {
    const { pricingDisplayStrategy: priceStrat } = state.settings.solutionPricing;
    if (state.appState.commerceError) return false;
    return getShowPrices(state) && priceStrat !== SPLIT_PRICE_TYPE.show_total_price;
};

export const getShowTotalPriceRoomBuilder = (state: Store): boolean => {
    const { pricingDisplayStrategy: priceStrat } = state.settings.solutionPricing;
    if (state.appState.commerceError) return false;
    return getShowPrices(state) && priceStrat !== SPLIT_PRICE_TYPE.show_equipment_only;
};

type IndoorSolutionRowData = Omit<SizingAtaMultiRoomRoomData, 'size' | 'indoor'> & {
    unit: AtaMultiRoomUnitData | null;
    price: string | null;
};

export type RoomsDataPerSolution = {
    solutionId: string | undefined;
    solution: SingleRoomSolution | null;
    data: Array<IndoorSolutionRowData>;
    showPrices: boolean;
};

export const getRoomsDataPerSolution = (state: Store): Array<RoomsDataPerSolution> => {
    // Get rooms from lead
    const { rooms } = state.lead as AirToAirLead;
    // Get sizing data from sizing tool
    const sizingMultiRoom = state.lead.toolData?.sizingMultiRoom;
    // Create a set of unique solutionIds from the rooms on the lead
    const solutionIds = Array.from(new Set<string>(rooms?.map((room) => room.solution)));
    // Get the solutions from the settings that are linked to the rooms
    const settingSolutions = solutionIds
        .map((solutionId) => getSingleRoomSolution(state, solutionId))
        .filter(TsBoolean);

    const prices = getCommercePrices(state);
    const showUnitPrices = getShowUnitPrices(state);

    // Map room indexes for each solution in object
    type RoomIndexesPerSolution = Record<string, Array<number>>;
    const roomIndexesPerSolution = rooms!.reduce((obj: RoomIndexesPerSolution, room: LeadRoom) => {
        const { solution, position } = room;
        if (obj[solution]) return { ...obj, [solution]: [...obj[solution], position] };
        return { ...obj, [solution]: [position] };
    }, {} as RoomIndexesPerSolution);

    // Loop over the setting solutions and create a object
    return settingSolutions.map((settingSolution) => {
        const roomIndexes = roomIndexesPerSolution[settingSolution.id];
        const data: Array<IndoorSolutionRowData> = [];
        // Loop over the room indexes
        roomIndexes?.forEach((roomIndex) => {
            // Filter the sizing Room data for the room with the room index
            const sizingRoomData = sizingMultiRoom?.rooms?.filter((r) => r.id === roomIndex);

            if (sizingRoomData) {
                // Loop over the sizing Room data and create a data object
                sizingRoomData.forEach((srd) => {
                    data.push({
                        name: srd.name,
                        id: srd.id,
                        unit: srd.indoor,
                        price: prices?.units?.[srd.indoor['product-name']] || null,
                    });
                });
            }
        });

        return {
            solutionId: settingSolution?.id,
            solution: settingSolution,
            data,
            showPrices: showUnitPrices,
        };
    });
};

const getMultiPlusType = (state: Store): MULTI_PLUS_TYPE | null => {
    const totalVolumeRequiredAtStorageTemperature = getTotalVolumeRequired(state);
    const numberOfRooms = state.roomBuilder.length;

    // Load is too high senario
    if (totalVolumeRequiredAtStorageTemperature >= 260) {
        return null;
    }
    // HeatPump scenario
    if (totalVolumeRequiredAtStorageTemperature > 120 || numberOfRooms > 3) {
        return MULTI_PLUS_TYPE.heatPump;
    }
    // Tank scenario
    if (totalVolumeRequiredAtStorageTemperature <= 120 && numberOfRooms <= 3) {
        return MULTI_PLUS_TYPE.tank;
    }
    return null;
};

type MultiPlusSolution = {
    type: MULTI_PLUS_TYPE | null;
    volume: TANK_VOLUMES | HEAT_PUMP_VOLUMES | null;
    image: string | null;
    fromPrice: number | null;
};
export const getMultiPlusSolution = (state: Store): MultiPlusSolution => {
    const { multiPlusSolutions } = state.settings;
    const multiPlusType = getMultiPlusType(state);
    const totalVolumeRequiredAtStorageTemperature = getTotalVolumeRequired(state);
    const data: MultiPlusSolution = { type: null, volume: null, image: null, fromPrice: null };
    const selectedTankVolumes = multiPlusSolutions.tank?.selectedVolumes;
    const selectedheatPumpVolumes = multiPlusSolutions.heatPump?.selectedVolumes;
    const possibleHeatPumpVolume = selectedheatPumpVolumes?.find(
        (volume) => volume > totalVolumeRequiredAtStorageTemperature,
    );
    const possibleTankVolume = selectedTankVolumes?.find(
        (volume) => volume > totalVolumeRequiredAtStorageTemperature,
    );

    switch (multiPlusType) {
        // Tank scenario
        case MULTI_PLUS_TYPE.tank: {
            // No tank volumes available, check smallest available heatPump
            if (selectedTankVolumes?.length === 0) {
                if (possibleHeatPumpVolume) {
                    data.type = MULTI_PLUS_TYPE.heatPump;
                    data.volume = possibleHeatPumpVolume;
                    data.image = multiPlusSolutions.heatPump!.image;
                    data.fromPrice = multiPlusSolutions.heatPump!.fromPrice;
                }
                return data;
            }

            // There are tank volumes available and one is applied
            if (possibleTankVolume) {
                return {
                    type: MULTI_PLUS_TYPE.tank,
                    volume: possibleTankVolume,
                    image: multiPlusSolutions.tank!.image,
                    fromPrice: multiPlusSolutions.tank!.fromPrice,
                };
            }
            /* There are tank volumes available but no tank is applied
             because the required volume is too heigh.
             So the smallest available heatPump is applied */
            if (!data.type) {
                if (possibleHeatPumpVolume) {
                    data.type = MULTI_PLUS_TYPE.heatPump;
                    data.volume = possibleHeatPumpVolume;
                    data.image = multiPlusSolutions.heatPump!.image;
                    data.fromPrice = multiPlusSolutions.heatPump!.fromPrice;
                }
            }
            return data;
        }
        // HeatPump scenario
        case MULTI_PLUS_TYPE.heatPump: {
            // No heatpump volumes available
            if (selectedheatPumpVolumes?.length == 0) return data;

            // There are heatPump volumes available and one is applied
            if (possibleHeatPumpVolume) {
                data.type = MULTI_PLUS_TYPE.heatPump;
                data.volume = possibleHeatPumpVolume;
                data.image = multiPlusSolutions.heatPump!.image;
                data.fromPrice = multiPlusSolutions.heatPump!.fromPrice;
            }
            return data;
        }
        default:
            return data;
    }
};
