/* eslint-disable operator-linebreak */
import { Multiline } from '@common/atoms/Multiline';
import i18n from '@common/i18n';
import { UK_INCENTIVE_TYPES } from '@common/types';
import React from 'react';
import { useAppSelector } from '../../../redux';
import { isGermany } from '../../../redux/selectors';
import { IncentiveType } from '../../../types';
import PriceItem from './PriceItem';

const generateLabel = ({ label, parameters }: IncentiveType): string => {
    let result = i18n(label, true, 'incentives');
    Object.entries(parameters).forEach(([key, value]) => {
        result = result.replace(key, value);
    });
    return result;
};

type PropsType = {
    incentives?: IncentiveType[];
    boilerUpgradeSchemeLabel: string;
};
const IncentivesPrice = ({
    incentives,
    boilerUpgradeSchemeLabel: busLabel,
}: PropsType): JSX.Element => {
    // DE incentives require specific label parsing
    if (useAppSelector(isGermany)) {
        return (
            <>
                {incentives!.map((incentive) => (
                    <Multiline key={incentive.label}>{generateLabel(incentive)}</Multiline>
                ))}
            </>
        );
    }
    // Non DE incentives
    return (
        <>
            {incentives!.map(({ label, parameters }) => {
                const note =
                    label === 'incentive_uk_boiler_upgrade_scheme'
                        ? i18n(busLabel, true, 'incentives')
                        : '';
                return (
                    <PriceItem
                        key={label}
                        label={i18n(label, true, 'incentives')}
                        price={parameters?.amount as string} // isSmall
                        type={parameters?.type as UK_INCENTIVE_TYPES}
                        link={parameters?.link as string}
                        note={note}
                        tooltip={parameters?.tooltip}
                    />
                );
            })}
        </>
    );
};

export default IncentivesPrice;
