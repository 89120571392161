import React from 'react';
import { useAppSelector } from '../../../redux';
import { isAirToAirLead } from '../../../redux/selectors';
import { isAirToAirMultiRoomLead } from '../../../redux/selectors/genericSelectors';
import { AirToAirSingleRoomSbmSolution, AirToWaterSbmSolution } from '../../../types';
import SolutionSpecsModalAta from './SolutionSpecsModal.ata';
import SolutionSpecsModalAtw from './SolutionSpecsModal.atw';

const SolutionSpecsModal = (): JSX.Element | null => {
    const solution = useAppSelector((state) => state.lead.solution);
    const isAtaMultiRoomLead = useAppSelector(isAirToAirMultiRoomLead);
    const isAtaLead = useAppSelector((state) => isAirToAirLead(state.lead));

    if (isAtaMultiRoomLead) return null;
    if (!solution) return null;

    if (isAtaLead) {
        return <SolutionSpecsModalAta solution={solution as AirToAirSingleRoomSbmSolution} />;
    } else {
        return <SolutionSpecsModalAtw solution={solution as AirToWaterSbmSolution} />;
    }
};

export default SolutionSpecsModal;
