import i18n from '@common/i18n';
import React, { FC } from 'react';
import { useAppSelector } from '../../../redux';
import { getUnitProductNames } from '../../../redux/selectors';
import * as SpecsTableStyles from '../../../styles/components/SpecsTable';
import { AirToWaterSbmSolution, QuotationAtwData } from '../../../types';
import InfoTrigger from '../InfoTrigger';
import Unit from './Unit';

const UnitOverview: FC = () => {
    const solution = useAppSelector((store) => store.lead.selSoftData?.solution);
    const unitTranslationType = useAppSelector(
        (store) => (store.lead.solution as AirToWaterSbmSolution).unitTranslationType,
    );
    const toolData = useAppSelector((store) => store.lead.toolData);
    if (!solution || !toolData) return null;
    const quotation = toolData?.quotationHeating as QuotationAtwData;

    const indoorUnits = getUnitProductNames(solution, 'indoor', unitTranslationType);
    const boilerUnits = getUnitProductNames(solution, 'boiler', unitTranslationType);
    const outdoorUnits = getUnitProductNames(solution, 'outdoor', unitTranslationType);
    const tankUnits = getUnitProductNames(solution, 'tank', unitTranslationType);

    const soundPressure = quotation?.outdoor?.['heating-sound-pressure'];
    const refrigerantType = quotation?.['refrigerant-type'];
    return (
        <>
            <Unit
                title="indoor_unit"
                productNames={indoorUnits}
                dimensions={quotation?.indoor?.dimensions}
            />
            <Unit
                title="boiler_unit"
                productNames={boilerUnits}
                dimensions={quotation?.boiler?.dimensions}
            />
            <Unit
                title="outdoor_unit"
                productNames={outdoorUnits}
                dimensions={quotation?.outdoor?.dimensions}
            >
                {!!soundPressure && (
                    <SpecsTableStyles.ItemRow>
                        <SpecsTableStyles.ItemLabel>
                            {i18n('sound_pressure')}
                            <InfoTrigger label="sound_pressure_info" />
                        </SpecsTableStyles.ItemLabel>
                        <SpecsTableStyles.ItemValue>
                            {`${soundPressure}dBA`}
                        </SpecsTableStyles.ItemValue>
                    </SpecsTableStyles.ItemRow>
                )}
                {refrigerantType && (
                    <SpecsTableStyles.ItemRow>
                        <SpecsTableStyles.ItemLabel>
                            {i18n('refrigerant_type')}
                        </SpecsTableStyles.ItemLabel>
                        <SpecsTableStyles.ItemValue>{refrigerantType}</SpecsTableStyles.ItemValue>
                    </SpecsTableStyles.ItemRow>
                )}
            </Unit>
            <Unit
                title="tank_unit"
                productNames={tankUnits}
                dimensions={quotation?.tank?.dimensions}
            />
        </>
    );
};

export default UnitOverview;
