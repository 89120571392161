/* eslint-disable default-case */
import { useEffect } from 'react';
import { Dispatch } from 'redux';
import {
    doneSizingToolAtaCooling,
    updateSizingToolAtaCooling,
    doneSizingToolAtaMultiRoom,
    updateSizingToolAtaHeating,
    doneSizingToolAtaHeating,
    doneSizingToolAtw,
    updateSizingToolAtw,
    updateSizingToolAtaMultiRoom,
} from '../redux/actions';
import { SizingEventDataTypes } from '../types/external/sizing';

enum EVENTS {
    // INBOUND - Aaltra -> RSN
    isReady = 'isReady',
    update = 'update',
    done = 'done',
    // OUTBOUND - RSN -> Aaltra
    init = 'init',
    navigate = 'navigate',
    selsoft = 'selsoft',
}

export const useSizingAtwTool = (dispatch: Dispatch): void => {
    // Postal handler
    useEffect(() => {
        const onSizingAtwEvent: ICallback<unknown> = (data, envelope) => {
            const [, , type] = envelope.topic.split('.');
            switch (type) {
                case EVENTS.update:
                    dispatch(updateSizingToolAtw(data as SizingEventDataTypes.Atw.Update));
                    break;
                case EVENTS.done:
                    dispatch(doneSizingToolAtw(data as SizingEventDataTypes.Atw.Done));
                    break;
            }
        };
        // Subscribe to rsnChannel events
        window._rsnChannel.subscribe('sizing.heating.*', onSizingAtwEvent);
    }, [dispatch]);
};

// Aaltra tool for sizing cooling (Summer Cooling)
export const applySizingAtaCoolingTool = (dispatch: Dispatch): void => {
    // Postal handler
    const onSizingAtaCoolingEvent: ICallback<unknown> = (data, envelope) => {
        const [, , type] = envelope.topic.split('.');
        switch (type) {
            case EVENTS.update:
                dispatch(
                    updateSizingToolAtaCooling(data as SizingEventDataTypes.AtaCooling.Update),
                );
                break;
            case EVENTS.done:
                dispatch(doneSizingToolAtaCooling(data as SizingEventDataTypes.AtaCooling.Done));
                break;
        }
    };
    // Subscribe to rsnChannel events
    window._rsnChannel.subscribe('sizing.cooling.*', onSizingAtaCoolingEvent);
};

// Aaltra tool for sizing cooling (Winter Heating)
export const applySizingAtaHeatingTool = (dispatch: Dispatch): void => {
    // Postal handler
    const onSizingAtaHeatingEvent: ICallback<unknown> = (data, envelope) => {
        const [, , type] = envelope.topic.split('.');
        switch (type) {
            case EVENTS.update:
                dispatch(
                    updateSizingToolAtaHeating(data as SizingEventDataTypes.AtaHeating.Update),
                );
                break;
            case EVENTS.done:
                dispatch(doneSizingToolAtaHeating(data as SizingEventDataTypes.AtaHeating.Done));
                break;
        }
    };
    // Subscribe to rsnChannel events
    window._rsnChannel.subscribe('sizing.winterheating.*', onSizingAtaHeatingEvent);
};

// Aaltra tool for sizing multi-split
export const applySizingAtaMultiRoomTool = (dispatch: Dispatch): void => {
    // Postal handler
    const onSizingAtaMultiRoomEvent: ICallback<unknown> = (data, envelope) => {
        const [, , type] = envelope.topic.split('.');
        switch (type) {
            case EVENTS.update:
                dispatch(
                    updateSizingToolAtaMultiRoom(data as SizingEventDataTypes.AtaMultiRoom.Update),
                );
                break;
            case EVENTS.done:
                dispatch(
                    doneSizingToolAtaMultiRoom(data as SizingEventDataTypes.AtaMultiRoom.Done),
                );
                break;
        }
    };
    // Subscribe to rsnChannel events
    window._rsnChannel.subscribe('sizing.airtoair.*', onSizingAtaMultiRoomEvent);
};
