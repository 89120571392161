import i18n from '@common/i18n';
import React from 'react';
import { useAppSelector } from '../../../redux';
import { getAnnualThermalEnergy, isCoolingRequired } from '../../../redux/selectors';
import * as SpecsTableStyles from '../../../styles/components/SpecsTable';
import { AirToWaterLead } from '../../../types';
import InfoTrigger from '../InfoTrigger';

const Capacity = (): JSX.Element | null => {
    const quotation = useAppSelector((store) => store.lead.toolData?.quotationHeating)!;
    const annualThermalEnergy = useAppSelector((store) => {
        return getAnnualThermalEnergy(store.lead as AirToWaterLead);
    });
    const coolingRequired = useAppSelector(isCoolingRequired);

    const coolingNominalCapacity = quotation?.['cooling-nominal-capacity'];
    const showCoolingCapacity =
        coolingRequired && coolingNominalCapacity !== undefined && coolingNominalCapacity > 0;

    if (!quotation && !showCoolingCapacity && !annualThermalEnergy) return null;

    return (
        <SpecsTableStyles.Group hasBorder>
            {quotation?.['heating-nominal-capacity'] !== undefined && (
                <SpecsTableStyles.ItemRow>
                    <SpecsTableStyles.ItemLabel>
                        {i18n('heating_capacity')}
                        <InfoTrigger label="he_heating_capacity_tooltip" />
                    </SpecsTableStyles.ItemLabel>
                    <SpecsTableStyles.ItemValue>
                        {`${quotation['heating-nominal-capacity'].toFixed(2)} kW`}
                    </SpecsTableStyles.ItemValue>
                </SpecsTableStyles.ItemRow>
            )}
            {showCoolingCapacity && (
                <SpecsTableStyles.ItemRow>
                    <SpecsTableStyles.ItemLabel>
                        {i18n('cooling_capacity')}
                        <InfoTrigger label="he_cooling_capacity_tooltip" />
                    </SpecsTableStyles.ItemLabel>
                    <SpecsTableStyles.ItemValue>
                        {`${coolingNominalCapacity.toFixed(2)} kW`}
                    </SpecsTableStyles.ItemValue>
                </SpecsTableStyles.ItemRow>
            )}
            {typeof annualThermalEnergy === 'number' && (
                <SpecsTableStyles.ItemRow>
                    <SpecsTableStyles.ItemLabel>
                        {i18n('annual_heating_energy')}
                        <InfoTrigger label="annual_heating_energy_tooltip" />
                    </SpecsTableStyles.ItemLabel>
                    <SpecsTableStyles.ItemValue>
                        {`${annualThermalEnergy.toFixed(2)} kWh`}
                    </SpecsTableStyles.ItemValue>
                </SpecsTableStyles.ItemRow>
            )}
        </SpecsTableStyles.Group>
    );
};

export default Capacity;
