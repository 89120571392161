export const num = n => typeof n === 'number' && !Number.isNaN(n);
export const px = n => (num(n) ? `${n}px` : n);

export const breakpoints = {
    xs: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

export const breakpointNext = n => {
    const keys = Object.keys(breakpoints);
    const i = keys.indexOf(n);
    return i < keys.length ? keys[i + 1] : null;
};

export const breakpoint = {
    up: n => `screen and (min-width: ${px(breakpoints[n])})`,
    down: n => `screen and (max-width: ${px(breakpoints[n]-1)})`,
    only: n => `screen and (min-width: ${px(breakpoints[n])}) and (max-width: ${px(breakpoints[breakpointNext(n)]-1)})`,
};
