import i18n from '@common/i18n';
import { COLOR_VARIATIONS } from '@common/types';
import { attachQueryString, jsonHash, snakeToDash } from '@tsUtils';
import { APP_STATUS, DEALER_SCHED_FACTOR, MULTI_PLUS_TYPE, PROXY_TYPES } from '../../constants';
import {
    AddressDetails,
    AirToAirLead,
    DrapoApiJsonType,
    IncentiveEventDataTypes,
    Lead,
    PersonalDetails,
    SelSoftRequestJson,
    Store,
} from '../../types';
import { isUk } from './genericSelectors';
import { getDrapoApiJSON, getDrapoToolData } from './leadSelectors';
import { getNextStep, getTransitionToStep } from './navigationSelectors';
import { getSelSoftRequestJson } from './selsoftSelectors';

type GenericPayload = {
    leadId: string;
    version: string;
};
export const getGenericPayload = (store: Store): GenericPayload => ({
    leadId: store.lead.id,
    version: store.lead.version,
});

type GeocodePayload = GenericPayload & {
    type: PROXY_TYPES.geolocation;
    data: { postalCode: string | undefined };
};
export const getGeocodePayload = (store: Store): GeocodePayload => {
    const genericPayload = getGenericPayload(store);
    const postalCode = store.lead.toolData?.sizingHeating?.postal;
    return { ...genericPayload, type: PROXY_TYPES.geolocation, data: { postalCode } };
};

type FetchLeadPayload = {
    country: string;
    language: string;
    solutionType: 'heating' | 'room_airco';
    leadId: string | null;
    rsnConfirmationUrl: string;
};
export const getFetchLeadPayload = (store: Store): FetchLeadPayload => {
    return {
        country: store.settings.country.isoCode,
        language: store.settings.language,
        solutionType: store.settings.type,
        leadId: store.settings.leadId || null,
        rsnConfirmationUrl: store.aemSettings.confirmationPage,
    };
};

type SetLeadRemotePayload = {
    payload: Lead & GenericPayload & { transition: string | null; swapCooling?: boolean };
    hash: number;
};
export const getSetLeadRemotePayload = (store: Store): SetLeadRemotePayload => {
    const genericPayload = getGenericPayload(store);
    const payload = { ...genericPayload, ...store.lead, transition: null };
    const hash = jsonHash({ ...payload, version: null });
    return { payload, hash };
};

type SelSoftPayload = {
    payload: GenericPayload & {
        type: PROXY_TYPES.selsoft;
        data: SelSoftRequestJson;
    };
    hash: number;
};
export const getSelSoftPayload = (store: Store): SelSoftPayload => {
    const genericPayload = getGenericPayload(store);
    const data = getSelSoftRequestJson(store);
    const hash = jsonHash(data);
    return {
        payload: { ...genericPayload, type: PROXY_TYPES.selsoft, data },
        hash,
    };
};

type FetchDrapoIncomeRangesPayload = GenericPayload & {
    type: PROXY_TYPES.incomeRange;
    data: {
        amountOfPeople?: number;
        postalCode?: string;
    };
};
export const getFetchDrapoIncomeRangesPayload = (store: Store): FetchDrapoIncomeRangesPayload => {
    const genericPayload = getGenericPayload(store);
    const { amountOfPeople } = getDrapoToolData(store);
    const data = { amountOfPeople, postalCode: store.lead.toolData?.sizingHeating?.postal };
    return { ...genericPayload, type: PROXY_TYPES.incomeRange, data };
};

type SaveDrapoToolDataPayload = GenericPayload & {
    data: {
        incentivesFR: DrapoApiJsonType & {
            incomeRanges: number[];
        };
    };
};
export const getSaveDrapoToolDataPayload = (store: Store): SaveDrapoToolDataPayload => {
    const genericPayload = getGenericPayload(store);
    const incomeRanges = getDrapoToolData(store).incomeRanges?.map(
        (incomeRange) => incomeRange.value,
    );
    const drapoApiPayload = getDrapoApiJSON(store);
    const data = { incentivesFR: { ...drapoApiPayload, incomeRanges: incomeRanges! } };
    return { ...genericPayload, data };
};

export const getCityPostalUrl = (store: Store, postal: string): string => {
    const payload = {
        leadId: store.lead.id,
        postalCode: postal,
    };
    return attachQueryString(store.settings.urls.dealerSelectionPostalCode, payload);
};

export type CustomerPersonalFormPayload = GenericPayload &
    PersonalDetails & {
        schedulingFactor: DEALER_SCHED_FACTOR;
        dealerId: string;
    };
export const getCustomerPersonalFormPayload = (store: Store): CustomerPersonalFormPayload => {
    const genericPayload = getGenericPayload(store);
    const dealerId = store.lead.dealerId!;
    const schedulingFactor = store.lead.schedulingFactor!;
    const { firstName, lastName, email, phoneNumber, comment } = store.lead.customerDetails!;
    return {
        ...genericPayload,
        dealerId,
        schedulingFactor,
        firstName,
        lastName,
        email,
        phoneNumber,
        comment,
    };
};

export type CustomerAddressFormPayload = GenericPayload &
    Omit<AddressDetails, 'privacyPolicyAccepted'>;
export const getCustomerAddressFormPayload = (store: Store): CustomerAddressFormPayload => {
    const { street, number, postalCode, city, newsletterConsent } = store.lead.customerDetails!;
    return {
        ...getGenericPayload(store),
        street,
        number,
        postalCode: isUk(store) ? postalCode.toLocaleUpperCase() : postalCode,
        city,
        newsletterConsent,
    };
};

type UpdateIncentivesDEToolDataPayload = GenericPayload & {
    data: {
        incentivesDE: IncentiveEventDataTypes.Germany.Update;
    };
};
export const getUpdateIncentivesDEToolDataPayload = (
    store: Store,
): UpdateIncentivesDEToolDataPayload => {
    const genericPayload = getGenericPayload(store);
    const incentivesDE = store.lead.incentives!.toolData!.incentivesDE!;
    return { ...genericPayload, data: { incentivesDE } };
};

type DoneIncentivesDEToolDataPayload = GenericPayload & {
    data: {
        incentivesDE: IncentiveEventDataTypes.Germany.Update;
    };
};
export const getDoneIncentivesDEToolDataPayload = (
    store: Store,
): DoneIncentivesDEToolDataPayload => {
    const genericPayload = getGenericPayload(store);
    const { incentivesDE } = store.lead.incentives!.toolData!;
    return {
        ...genericPayload,
        data: {
            incentivesDE: incentivesDE!,
        },
    };
};

type LiteraturePayload = GenericPayload & { type: PROXY_TYPES; data: string[] };
export const getLiteraturePayload = (store: Store, seriesIDs: string[]): LiteraturePayload => {
    const genericPayload = getGenericPayload(store);
    return {
        ...genericPayload,
        type: PROXY_TYPES.literature,
        data: seriesIDs,
    };
};

type ConfirmRoom = {
    id: string | null;
    solution: string; // solutionId
    name: string;
    surface: number;
    height: number;
    windows: number;
    shading: string;
    orientation: string;
    colorVariation: COLOR_VARIATIONS | null;
};
type ConfirmRoomsPayload = GenericPayload & {
    transition: string;
    multiPlusType: MULTI_PLUS_TYPE | null;
    rooms: Array<ConfirmRoom>;
};
export const getConfirmRoomsPayload = (state: Store): ConfirmRoomsPayload => {
    const genericPayload = getGenericPayload(state);
    const targetStatus = getNextStep(state.lead) as APP_STATUS;
    const transition = getTransitionToStep(state, targetStatus)!;
    return {
        ...genericPayload,
        transition,
        multiPlusType: (state.lead as AirToAirLead).multiPlusType,
        rooms: state.roomBuilder.map((room, i) => {
            const solution = state.settings.singleRoomSolutions!.find(
                (s) => s.id === room.solution,
            )!;
            return {
                id: null,
                name:
                    room.name === '' || room.name === null ? `${i18n('room')} ${i + 1}` : room.name,
                orientation: snakeToDash(room.orientation),
                position: i + 1,
                positioningType: solution?.positioningType || null,
                height: room.height,
                shading: snakeToDash(room.shading),
                solution: room.solution,
                surface: room.surface,
                windows: room.windows,
                colorVariation: room.colorVariation,
            } as ConfirmRoom;
        }),
    };
};
