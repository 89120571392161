import * as Logger from '@common/Logger';
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { MODAL_TYPE } from '../../constants';
import { useAppSelector } from '../../redux';
import { openModal } from '../../redux/actions';
import * as ButtonStyles from '../../styles/components/Button';
import * as PageStyles from '../../styles/components/Page';
import * as TypographyStyles from '../../styles/components/Typography';

const logWarning = (msg: string): void => Logger.log(`[WARNING] ${msg}`, Logger.STYLE.WARN);

const AemRedirectCCU: FC = () => {
    const dispatch = useDispatch();
    const aemUrl = useAppSelector((store) => store.aemSettings.raConcealedCeilingUnitPage);

    const onSkipToDealerClicked = (): void => {
        dispatch(openModal(MODAL_TYPE.contactADealer));
    };

    const onAemPageClicked = (): void => {
        logWarning('👋 redirecting to AEM - ConcealedCealingUnit');
        window.open(aemUrl, '_blank');
    };

    return (
        <PageStyles.MainContent>
            <PageStyles.NarrowContainer>
                <TypographyStyles.H4>{i18n('concealed_ceiling_unit_title')}</TypographyStyles.H4>
                <TypographyStyles.StateText>
                    {i18n('concealed_ceiling_unit_subtitle')}
                </TypographyStyles.StateText>
                <br />
                <br />
                <ButtonStyles.Primary
                    mb={5}
                    style={{ width: 'auto' }}
                    onClick={onSkipToDealerClicked}
                >
                    <Icons.Glyph.User />
                    &nbsp;
                    {i18n('contact_a_dealer')}
                </ButtonStyles.Primary>
                <ButtonStyles.PrimaryOutline
                    mb={5}
                    style={{ width: 'auto' }}
                    onClick={onAemPageClicked}
                >
                    {i18n('concealed_ceiling_unit_link')}
                </ButtonStyles.PrimaryOutline>
            </PageStyles.NarrowContainer>
        </PageStyles.MainContent>
    );
};

export default AemRedirectCCU;
