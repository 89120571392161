import styled, { css } from 'styled-components';
import {
    SpaceProps,
    LayoutProps,
    space as spacing,
    textAlign,
    TextAlignProps,
} from 'styled-system';
import { color, fontFamily, fontWeight, space } from '../common/Theme';

export const HeadingBase = css`
    margin: 0;
    font-family: ${fontFamily.base};
    color: ${color.blue300};
    ${spacing};
    ${textAlign};
`;

export const H1 = styled.h1<SpaceProps & TextAlignProps>`
    ${HeadingBase};
    font-size: 36px;
    font-weight: ${fontWeight.light};
    line-height: 44px;
`;

export const H2 = styled.h2<SpaceProps & TextAlignProps>`
    ${HeadingBase};
    font-size: 26px;
    font-weight: ${fontWeight.regular};
    line-height: 34px;
`;

export const H3 = styled.h3<SpaceProps & TextAlignProps>`
    ${HeadingBase};
    font-size: 22px;
    font-weight: ${fontWeight.regular};
    line-height: 30px;
`;

export const H4 = styled.h4<SpaceProps & TextAlignProps>`
    ${HeadingBase};
    font-size: 20px;
    font-weight: ${fontWeight.regular};
    line-height: 26px;
`;

export const H5 = styled.h5<SpaceProps & TextAlignProps>`
    ${HeadingBase};
    font-size: 18px;
    font-weight: ${fontWeight.semibold};
    line-height: 26px;
    color: ${color.grey800};
`;

export const DefaultText = styled.div<SpaceProps & TextAlignProps>`
    ${HeadingBase};
    font-size: 16px;
    line-height: 24px;
    color: ${color.grey500};
    ${spacing};
`;

export const SmallText = styled(DefaultText)`
    font-size: 13px;
    font-weight: ${fontWeight.semibold};
`;

export const NormalText = styled(DefaultText)`
    color: ${color.grey900};
`;

export const StrongText = styled(DefaultText)`
    font-weight: ${fontWeight.semibold};
    color: ${color.grey800};
`;

export const StateText = styled(StrongText)`
    color: ${color.grey500};
`;

export const InfoText = styled(StateText)`
    color: ${color.blue300};
`;

export const SuccessText = styled(StateText)`
    color: ${color.green400};
`;

export const WarningText = styled(StateText)`
    color: ${color.yellow400};
`;

export const ErrorText = styled(StateText)`
    color: ${color.red400};
`;

export const Small = styled.small<SpaceProps>`
    display: inline-block;
    font-size: 13px;
    line-height: 20px;
    color: ${color.grey800};
    ${spacing};
`;

type TextProps = LayoutProps &
    SpaceProps & {
        renderListAsChecks?: boolean;
    };

export const Text = styled.div<TextProps>`
    font-size: 17px;
    line-height: 27px;
    ${spacing};

    ${({ renderListAsChecks }) =>
        renderListAsChecks &&
        css`
            font-size: 16px;
            line-height: 24px;
            color: ${color.grey900};
        `};

    p {
        margin: 0;
        padding: 0 0 ${space[4]}px;
    }

    ul {
        margin: 0;
        padding: 0 0 ${space[4]}px;
        list-style: none;

        &:last-child {
            padding-bottom: 0;
        }
    }

    li {
        position: relative;
        margin-bottom: ${space[2]}px;
        padding-left: 26px;

        &::before {
            position: absolute;
            top: ${space[2]}px;
            left: 0;
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: ${color.blue300};

            ${({ renderListAsChecks }) =>
                renderListAsChecks &&
                css`
                    top: 50%;
                    margin-top: -4px;
                    width: 10px;
                    height: 8px;
                    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.544 0 4.04 5.047l-2.675-2.62L0 3.954 4.13 8 10 1.426z' fill='%23000' fill-rule='evenodd'/%3E%3C/svg%3E");
                    background-color: transparent;
                `};
        }
    }
`;
