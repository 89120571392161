// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { addSequence, addShortcut } from '@common/KeyboardListener';
import * as Logger from '@common/Logger';
import variables from '@common/data/variables';
import i18n from '@common/i18n';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { tryParseBoolean } from '@tsUtils';
import axios from 'axios'; // @ts-ignore
import React, { FC, useEffect } from 'react';
import { useStore } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import WebFont from 'webfontloader';
import AppRouter from './AppRouter';
import Navigator from './Navigator';
import FullPageError from './components/common/FullPageError';
import FullPageLoader from './components/common/FullPageLoader';
import {
    useAaltraScriptInjection,
    useGigyaSdk,
    useIncentivesGermanyTool,
    usePostal,
    useQuotationTool,
    useSizingTool,
} from './hooks';
import { useAppSelector } from './redux';
import { fetchSettings } from './redux/actions';
import * as Theme from './styles/common/Theme';

const SLOTS = {
    // Session
    DEBUG: 'daikin:rsn:debug',
    SHOW_KEYS: 'daikin:rsn:showLabelKeys',
};

const denyList = /gtm\.js|chatbot|daikin-aem-react|clarity/;
const allowList = /rsn\.js/;

// Filter out AEM, GTM, Chatbot errors
const filterErrors = (
    event: Sentry.Event,
    hint: Sentry.EventHint | undefined,
): Sentry.Event | null => {
    // Check original exception to ignore error
    if (
        hint?.originalException &&
        typeof hint.originalException !== 'string' &&
        typeof hint.originalException.stack === 'string' &&
        hint.originalException.stack.match(denyList)
    ) {
        return null;
    }
    // There should be a stacktrace
    const stack = event?.exception?.values?.[0].stacktrace?.frames;
    if (!stack?.length) return null;
    // The stacktrace should have filenames
    const filenames = stack.map(({ filename }) => filename).filter(Boolean) as string[];
    if (!filenames?.length) return null;
    // Check if any of the filenames are to be ignored
    if (filenames.some((f) => f.match(denyList))) return null;
    // Check if one of the filenames is rsn.js
    if (filenames.some((f) => f.match(allowList))) return event;
    // No hits
    return null;
};

if (!__DEBUG) {
    Sentry.init({
        dsn: variables.sentryDsn.rsn,
        integrations: [new BrowserTracing()],
        environment: __BRANCH,
        release: __COMMIT,
        ignoreErrors: ['process is not defined', "Can't find variable: process"],
        denyUrls: [
            // Chatbot
            /chatbot\//i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
        ],
        beforeSend(event, hint) {
            return filterErrors(event, hint);
        },
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5, // __BRANCH === 'master' ? 0 : 1,
    });
}
if (__DEBUG || tryParseBoolean(window.sessionStorage.getItem(SLOTS.DEBUG))) {
    Logger.setDebug(true);
    window._rsnNavigator = Navigator;
}
if (tryParseBoolean(window.sessionStorage.getItem(SLOTS.SHOW_KEYS))) i18n.setShowLabelKeys(true);

// Logger.___MUTE_NATIVE___();

const setDevMode = (): void => {
    if (tryParseBoolean(window.sessionStorage.getItem(SLOTS.DEBUG))) {
        Logger.forceLog('RSN is already running in [DEBUG]-mode', Logger.STYLE.DAIKIN);
    } else {
        Logger.forceLog(
            'RSN mode toggled to [DEBUG]',
            Logger.STYLE.DAIKIN,
            'Reloading application in 1 second',
            'Happy debugging! :)',
        );
        if (window.sessionStorage) window.sessionStorage.setItem(SLOTS.DEBUG, 'true');
        setTimeout(() => window.location.reload(), 1000);
    }
};
const setShowLabelKeys = (): void => {
    if (tryParseBoolean(window.sessionStorage.getItem(SLOTS.SHOW_KEYS))) {
        Logger.forceLog('RSN label-keys are already displayed', Logger.STYLE.DAIKIN);
    } else {
        Logger.forceLog(
            'RSN label-keys will be displayed',
            Logger.STYLE.DAIKIN,
            'Reloading application in 1 second',
        );
        if (window.sessionStorage) window.sessionStorage.setItem(SLOTS.SHOW_KEYS, 'true');
        setTimeout(() => window.location.reload(), 1000);
    }
};
// Keyboard shortcuts
addShortcut(['r', 's', 'n'], setDevMode);
addSequence('rsndev', setDevMode);
addSequence('llkk', setShowLabelKeys);
Logger.setDebug(!!tryParseBoolean(window.sessionStorage.getItem(SLOTS.DEBUG)));

const App: FC = () => {
    axios.defaults.withCredentials = true;
    const store = useStore();
    const { dispatch } = store;

    useEffect(() => {
        Navigator.init(store);
    }, [store]);
    // Fetch data from store
    const globalError = useAppSelector((state) => state.appState.globalError);
    const isLoading = useAppSelector((state) => state.appState.isLoading);
    // Custom hooks
    usePostal();
    useGigyaSdk();
    useSizingTool(dispatch);
    useQuotationTool(dispatch);
    useAaltraScriptInjection(dispatch);
    useIncentivesGermanyTool(dispatch);
    // Fetch Settings after boot
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Open Sans:400,600'],
            },
        });
        dispatch(fetchSettings());
    }, [dispatch]);
    // Error or load-state
    if (globalError) return <FullPageError message={i18n(globalError)} />;
    if (isLoading) return <FullPageLoader />;
    // Navigate to the correct section
    return (
        <Sentry.ErrorBoundary fallback={<FullPageError />}>
            <ThemeProvider theme={Theme}>
                <AppRouter />
            </ThemeProvider>
        </Sentry.ErrorBoundary>
    );
};

export default App;
