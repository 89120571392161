import * as Logger from '@common/Logger';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { PROXY_TYPES } from '../../constants';
import {
    AirToAirLead,
    AtaEnergyLabelsData,
    AtaMultiPlusEnergyLabelsData,
    AtwEnergyLabelsData,
    Store,
} from '../../types';
import {
    LEAD_ACTIONS,
    SetLeadLocalAction,
    fetchEnergyLabels,
    setEnergyLabels,
    setEnergyLabelsError,
    setMultiPlusEnergyLabels,
} from '../actions';
import {
    getEnergyLabelParams,
    getGenericPayload,
    getMultiPlusEnergyLabelParams,
    isAirToAirProject,
} from '../selectors';

type DepObj = { getState: () => Store };

type SetLeadLocalDepObj = DepObj & { action: SetLeadLocalAction };

const triggerFetchEnergyLabelsLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'energyLabelsLogic.triggerFetchEnergyLabels',
    process({ getState, action }: SetLeadLocalDepObj, dispatch, done) {
        // Only trigger fetch energy labels for atw leads
        if (isAirToAirProject(getState())) return done();
        // Trigger fetching energy labels again if phasing is changed
        if (action.logicOrigin !== 'phasingLogic') return done();
        // All checks passed, fetching energy labels
        dispatch(fetchEnergyLabels());
        return done();
    },
});

type EnergyLabelsResponseType = {
    responseData: AtwEnergyLabelsData | AtaEnergyLabelsData;
    success: boolean;
};

type MultiPlusEnergyLabelsResponseType = {
    responseData: AtaMultiPlusEnergyLabelsData;
    success: boolean;
};

const fetchEnergyLabelsLogic = createLogic({
    type: LEAD_ACTIONS.fetchEnergyLabels,
    name: 'energyLabelsLogic.fetch',
    async process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        const { proxyCall } = state.settings.urls;
        const { multiPlusType, isDhwRequired } = state.lead as AirToAirLead;
        const genericPayload = getGenericPayload(state);
        const params = getEnergyLabelParams(state);
        const multiPlusParams = getMultiPlusEnergyLabelParams(state);
        try {
            const payload = { ...genericPayload, data: params, type: PROXY_TYPES.energyLabels };
            const heatingCoolingEnergyLabelsData = await axios.post<EnergyLabelsResponseType>(
                proxyCall,
                payload,
            );
            const { data, status } = heatingCoolingEnergyLabelsData;
            if (status === 200 && data?.success === true) {
                dispatch(setEnergyLabels(data.responseData));
            } else {
                if (status > 299) {
                    const error = new Error(`${status} error`);
                    error.name = 'Error fetching energy labels';
                    Sentry.captureException(error);
                }
                dispatch(setEnergyLabelsError('no_ecolabels_yet'));
            }
            // Fetch dhw tank/heatPump energy labels if dhw is required and lead has a tank /or heatpump
            if (isDhwRequired && !!multiPlusType) {
                const payload = {
                    ...genericPayload,
                    data: { ...multiPlusParams, multiPlusType },
                    type: PROXY_TYPES.energyLabels,
                };

                const multiPlusEnergyLabelsData =
                    await axios.post<MultiPlusEnergyLabelsResponseType>(proxyCall, payload);
                const { data, status } = multiPlusEnergyLabelsData;
                if (status === 200 && data?.success === true) {
                    dispatch(setMultiPlusEnergyLabels(data.responseData));
                } else {
                    if (status > 299) {
                        const error = new Error(`${status} error`);
                        error.name = 'Error fetching dhw energy label';
                        Sentry.captureException(error);
                    }
                    dispatch(setEnergyLabelsError('no_ecolabels_yet'));
                }
            }
            done();
        } catch (reason) {
            if (reason !== 'abort') Logger.error('fetchEnergyLabels failed.', reason);
            dispatch(setEnergyLabelsError('no_ecolabels_yet'));
            done();
        }
    },
});

export default [triggerFetchEnergyLabelsLogic, fetchEnergyLabelsLogic];
