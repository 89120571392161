/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import i18n from '@common/i18n';
import { APP_STATUS, PROJECT_TYPE, TRANSITION } from '../../constants';
import { AirToWaterLead, AppStatus, CrumbType, TransitionTypes } from '../../types';

export const getNextStep = (lead: AirToWaterLead): AppStatus | null => {
    switch (lead.status) {
        case APP_STATUS.sft_he_project_type:
            return APP_STATUS.sft_he_function_type;
        case APP_STATUS.sft_he_function_type:
            return lead.functionType!.includes('dhw')
                ? APP_STATUS.sft_he_household_composition
                : APP_STATUS.sft_he_emitter_type;
        case APP_STATUS.sft_he_household_composition:
            return APP_STATUS.sft_he_emitter_type;
        case APP_STATUS.sft_he_emitter_type:
            return lead.projectType === PROJECT_TYPE.renovation
                ? APP_STATUS.sft_he_previous_heating_system
                : APP_STATUS.sft_he_building_type;
        case APP_STATUS.sft_he_previous_heating_system:
            return APP_STATUS.sft_he_building_type;
        case APP_STATUS.sft_he_building_type:
            // Stay on this page until a solution is selected
            return lead.solutionId ? APP_STATUS.sol_view_solution : APP_STATUS.sft_he_building_type;
        case APP_STATUS.sft_he_preferred_solution:
            return APP_STATUS.sol_view_solution;
        default:
            return null;
    }
};

export const getTransitionToStep = (target: AppStatus): TransitionTypes | null => {
    switch (target) {
        case APP_STATUS.sft_he_project_type:
            return TRANSITION.sft_he_choose_project_type;
        case APP_STATUS.sft_he_function_type:
            return TRANSITION.sft_he_choose_function_type;
        case APP_STATUS.sft_he_household_composition:
            return TRANSITION.sft_he_choose_household_composition;
        case APP_STATUS.sft_he_emitter_type:
            return TRANSITION.sft_he_choose_emitter_type;
        case APP_STATUS.sft_he_previous_heating_system:
            return TRANSITION.sft_he_choose_previous_heating_system;
        case APP_STATUS.sft_he_building_type:
            return TRANSITION.sft_he_choose_building_type;
        case APP_STATUS.sft_he_preferred_solution:
            return TRANSITION.sft_he_choose_preferred_solution;
        case APP_STATUS.sol_view_solution:
            return TRANSITION.sft_he_view_solution;
        default:
            return null;
    }
};

export const getSftProgress = (lead: AirToWaterLead): number => {
    const hasDhw = +!!lead.functionType?.includes('dhw');
    const isReno = +(lead.projectType === PROJECT_TYPE.renovation);
    switch (lead.status) {
        case APP_STATUS.sft_he_project_type:
            return 0;
        case APP_STATUS.sft_he_function_type:
            return 1 / (4 + isReno);
        case APP_STATUS.sft_he_household_composition:
            return 2 / (4 + isReno + hasDhw);
        case APP_STATUS.sft_he_emitter_type:
            return (2 + hasDhw) / (4 + isReno + hasDhw);
        case APP_STATUS.sft_he_previous_heating_system:
            return (2 + isReno + hasDhw) / (4 + isReno + hasDhw);
        case APP_STATUS.sft_he_building_type:
            return (3 + isReno + hasDhw) / (4 + isReno + hasDhw);
        case APP_STATUS.sft_he_preferred_solution:
            return 1;
        default:
            return 0;
    }
};

export const getBreadcrumbs = (lead: AirToWaterLead): CrumbType[] => {
    const crumbs = [];
    const { projectType, functionType, emitterType, previousHeatingSystem, buildingType } = lead;
    crumbs.push({
        label: i18n(`project_type_${projectType}`),
        page: APP_STATUS.sft_he_project_type,
    });
    crumbs.push({
        label: i18n(`function_type_${functionType}`),
        page: APP_STATUS.sft_he_function_type,
    });
    crumbs.push({
        label: i18n(`emitter_type_${emitterType}`),
        page: APP_STATUS.sft_he_emitter_type,
    });
    if (projectType === PROJECT_TYPE.renovation) {
        crumbs.push({
            label: i18n(`previous_heating_system_${previousHeatingSystem}`),
            page: APP_STATUS.sft_he_previous_heating_system,
        });
    }
    crumbs.push({
        label: i18n(`building_type_${buildingType}`),
        page: APP_STATUS.sft_he_building_type,
    });
    return crumbs;
};
