import { useEqualHeights } from '@common/hooks/tsHooks';
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { APP_STATUS, QUOTATION_CHOICE } from '../../constants';
import { useAppSelector } from '../../redux';
import { setQuotationChoice } from '../../redux/actions';
import { getShowPrices } from '../../redux/selectors';
import * as GridStyles from '../../styles/components/Grid';
import * as PageStyles from '../../styles/components/Page';
import * as QuotationChoiceStyles from '../../styles/components/QuotationChoice';
import * as TypographyStyles from '../../styles/components/Typography';
import Basket from '../common/basket/Basket';

const QuotationChoice = (): JSX.Element => {
    const dispatch = useDispatch();
    const showPrices = useAppSelector(getShowPrices);
    const commerceError = useAppSelector((state) => state.appState.commerceError);
    const canShowMoreAccuratePriceEstimate = showPrices && !commerceError;
    const quotationChoice =
        useAppSelector((state) => state.appState.quotationChoice) || QUOTATION_CHOICE.quotation;

    const [quotationChoiceHeightAddRef, quotationChoiceHeight] = useEqualHeights();
    const height = quotationChoiceHeight === 'auto' ? 'auto' : `${quotationChoiceHeight}px`;

    const onChangeQuoationChoice = (choice: QUOTATION_CHOICE): void => {
        dispatch(setQuotationChoice(choice));
    };

    return (
        <PageStyles.WideContainer>
            <GridStyles.Row>
                <GridStyles.Column width={{ xs: 1, lg: 8 / 12 }}>
                    <TypographyStyles.H4 mb={5}>
                        {i18n('installation_preference.question')}
                    </TypographyStyles.H4>
                    <GridStyles.Row>
                        <GridStyles.Column
                            width={{ xs: 1, md: 6 / 12 }}
                            ref={quotationChoiceHeightAddRef}
                        >
                            <QuotationChoiceStyles.Item
                                isHighlighted={quotationChoice === QUOTATION_CHOICE.quotation}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onChangeQuoationChoice(QUOTATION_CHOICE.quotation);
                                }}
                                style={{ height }}
                            >
                                <GridStyles.Row>
                                    <QuotationChoiceStyles.Badge
                                        isHighlighted={
                                            quotationChoice === QUOTATION_CHOICE.quotation
                                        }
                                    >
                                        {i18n('most_popular')}
                                    </QuotationChoiceStyles.Badge>
                                    <GridStyles.Column width={{ xs: 3 / 12, lg: 3 / 12 }}>
                                        <QuotationChoiceStyles.Icon>
                                            <Icons.ExtraLarge.PositioningTypeWallMounted />
                                        </QuotationChoiceStyles.Icon>
                                    </GridStyles.Column>
                                    <GridStyles.Column width={{ xs: 9 / 12, lg: 9 / 12 }}>
                                        <QuotationChoiceStyles.Content>
                                            <TypographyStyles.H4 as="h3" mb={2}>
                                                {canShowMoreAccuratePriceEstimate
                                                    ? i18n(
                                                          'installation_preference.more_accurate_price_estimate',
                                                      )
                                                    : i18n('installation_preference.specify')}
                                            </TypographyStyles.H4>
                                            {canShowMoreAccuratePriceEstimate
                                                ? i18n(
                                                      'installation_preference.more_accurate_price_estimate_info',
                                                  )
                                                : i18n('installation_preference.specify_info')}
                                        </QuotationChoiceStyles.Content>
                                    </GridStyles.Column>
                                    <QuotationChoiceStyles.Radio>
                                        <input
                                            type="radio"
                                            className="installation_preference_specify"
                                            name="installation_preference_specify"
                                            checked={quotationChoice === QUOTATION_CHOICE.quotation}
                                            readOnly
                                        />
                                    </QuotationChoiceStyles.Radio>
                                </GridStyles.Row>
                            </QuotationChoiceStyles.Item>
                        </GridStyles.Column>

                        <GridStyles.Column
                            width={{ xs: 1, md: 6 / 12 }}
                            ref={quotationChoiceHeightAddRef}
                        >
                            <QuotationChoiceStyles.Item
                                isHighlighted={quotationChoice === QUOTATION_CHOICE.skip}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onChangeQuoationChoice(QUOTATION_CHOICE.skip);
                                }}
                                style={{ height }}
                            >
                                <GridStyles.Row>
                                    <GridStyles.Column width={{ xs: 3 / 12, lg: 3 / 12 }}>
                                        <QuotationChoiceStyles.Icon>
                                            <Icons.ExtraLarge.Installer />
                                        </QuotationChoiceStyles.Icon>
                                    </GridStyles.Column>
                                    <GridStyles.Column width={{ xs: 9 / 12, lg: 9 / 12 }}>
                                        <QuotationChoiceStyles.Content>
                                            <TypographyStyles.H4 as="h3" mb={2}>
                                                {showPrices
                                                    ? i18n(
                                                          'installation_preference.contact_a_dealer',
                                                      )
                                                    : i18n(
                                                          'installation_preference.skip_and_contact_a_dealer',
                                                      )}
                                            </TypographyStyles.H4>
                                            {showPrices
                                                ? i18n(
                                                      'installation_preference.contact_a_dealer_info',
                                                  )
                                                : i18n(
                                                      'installation_preference.skip_and_contact_a_dealer_info',
                                                  )}
                                        </QuotationChoiceStyles.Content>
                                    </GridStyles.Column>
                                    <QuotationChoiceStyles.Radio>
                                        <input
                                            type="radio"
                                            className="installation_preference_contact_a_dealer"
                                            name="installation_preference_contact_a_dealer"
                                            checked={quotationChoice === QUOTATION_CHOICE.skip}
                                            readOnly
                                        />
                                    </QuotationChoiceStyles.Radio>
                                </GridStyles.Row>
                            </QuotationChoiceStyles.Item>
                        </GridStyles.Column>
                    </GridStyles.Row>
                </GridStyles.Column>
                <GridStyles.Column width={{ xs: 1, lg: 4 / 12 }}>
                    <Basket
                        showSolution
                        showPrices
                        showIncentives
                        showEvsSidePanel
                        showEpackages
                        continueButtonProps={{
                            label: 'continue',
                            target:
                                quotationChoice === QUOTATION_CHOICE.skip
                                    ? APP_STATUS.dealer_selection
                                    : APP_STATUS.sp_quotation_tool,
                            enabled: true,
                        }}
                        showSave
                    />
                </GridStyles.Column>
            </GridStyles.Row>
        </PageStyles.WideContainer>
    );
};

export default QuotationChoice;
