import styled, { css } from 'styled-components';
import {
    space as spacing,
    display,
    DisplayProps,
    width,
    LayoutProps,
    SpaceProps,
    FlexDirectionProps,
    flexDirection,
} from 'styled-system';
import { breakpoint } from '@common/components/Breakpoints';
import { color, space } from '../common/Theme';

type ColumnProps = DisplayProps & LayoutProps & SpaceProps & { hasBorder?: boolean };

export const Column = styled.div<ColumnProps>`
    padding: 0 ${space[4]}px;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;

    ${display};
    ${spacing};
    ${width};

    ${({ hasBorder }) =>
        hasBorder &&
        css`
            position: relative;

            @media ${breakpoint.up('lg')} {
                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1px;
                    height: 100%;
                    content: '';
                    background: ${color.grey200};
                }
            }
        `};
`;

type RowProps = LayoutProps & {
    hasDivider?: boolean;
    isCompact?: boolean;
    hasBorder?: boolean;
    isCenteredMobile?: boolean;
};

export const Row = styled.div<RowProps & SpaceProps>`
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    margin: 0 -${space[4]}px;
    ${spacing};

    ${({ isCompact }) =>
        isCompact &&
        css`
            margin: 0 -${space[3]}px;
            ${spacing};

            ${Column} {
                padding: 0 ${space[3]}px;
                ${spacing};
            }
        `};

    @media ${breakpoint.up('lg')} {
        ${({ hasDivider }) =>
            hasDivider &&
            css`
                & > ${Column} + ${Column} {
                    border-left: 1px solid ${color.grey200};
                }
            `};
    }

    ${({ hasBorder }) =>
        hasBorder &&
        css`
            position: relative;

            &:not(:last-child) {
                &::after {
                    position: absolute;
                    bottom: 0;
                    left: ${space[4]}px;
                    right: ${space[4]}px;
                    width: calc(100% - (${space[4]}*2) px);
                    height: 1px;
                    content: '';
                    background: ${color.grey200};
                }
            }
        `};

    @media ${breakpoint.down('md')} {
        ${({ isCenteredMobile }) =>
            isCenteredMobile &&
            css`
                display: flex;
                flex-direction: column;
                > div {
                    width: 100%;
                    max-width: 100%;
                    &:first-child {
                        padding-bottom: ${space[5]}px;
                    }
                }
            `};
    }
`;

export const Flex = styled.div<FlexDirectionProps>`
    display: flex;
    ${flexDirection}
    justify-content: space-between;
    height: 100%;

    svg {
        width: fit-content;
        margin: 0 auto;
    }

    label {
        white-space: initial;
    }
`;
