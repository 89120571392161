export enum TRACKING_ACTIONS {
    trackingSave = 'tracking/save',
}

export type TrackingSaveAction = { type: TRACKING_ACTIONS.trackingSave };
export const trackingSave = (): TrackingSaveAction => ({
    type: TRACKING_ACTIONS.trackingSave,
});

export type TrackingActionsType = TrackingSaveAction;
