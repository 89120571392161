import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import * as Theme from '../../styles/common/Theme';
import { AppStyle } from '../../styles/components/App';
import * as DetachedStyles from '../../styles/components/Detached';
import * as PageStyles from '../../styles/components/Page';

type PropsType = {
    message?: string;
};

const FullPageError: FC<PropsType> = (props) => {
    const onHomePageClicked = (): void => {
        window.location.href = window.location.origin;
    };

    const onStartAgainClicked = (): void => {
        window.location.href = window.location.origin + window.location.pathname;
    };

    return (
        <ThemeProvider theme={Theme}>
            <AppStyle>
                <PageStyles.MainContent>
                    <DetachedStyles.Container>
                        <DetachedStyles.Icon>
                            <Icons.ExtraLarge.Error />
                        </DetachedStyles.Icon>

                        <DetachedStyles.Title>
                            {props.message ?? i18n('unexpected_error')}
                        </DetachedStyles.Title>

                        <DetachedStyles.Actions>
                            <DetachedStyles.Action onClick={onHomePageClicked}>
                                <Icons.Glyph.House />
                                {i18n('goto_homepage')}
                            </DetachedStyles.Action>

                            <DetachedStyles.Action onClick={onStartAgainClicked}>
                                <Icons.Glyph.Back />
                                {i18n('start_again')}
                            </DetachedStyles.Action>
                        </DetachedStyles.Actions>
                    </DetachedStyles.Container>
                </PageStyles.MainContent>
            </AppStyle>
        </ThemeProvider>
    );
};

export default FullPageError;
