import i18n from '@common/i18n';
import React, { PropsWithChildren } from 'react';
import * as PageStyles from '../../../styles/components/Page';
import * as PricingStyles from '../../../styles/components/Pricing';
import * as TypographyStyles from '../../../styles/components/Typography';
import { IncentiveType } from '../../../types';
import IncentivesPrice from './IncentivesPrice';

// Adds a header & divider to the incentives
const IncentivesWrapper = ({ children }: PropsWithChildren): JSX.Element => (
    <>
        <TypographyStyles.H5>{i18n('incentives')}</TypographyStyles.H5>
        {children}
        <PageStyles.Divider />
    </>
);

type PropsType = {
    incentives?: IncentiveType[];
    boilerUpgradeSchemeLabel: string;
};

const Incentives = ({ incentives, boilerUpgradeSchemeLabel }: PropsType): JSX.Element => {
    if (incentives && !incentives.length) {
        return (
            <IncentivesWrapper>
                <PricingStyles.Line>
                    <PricingStyles.Label>
                        {i18n('incentives_not_eligible', true, 'incentives')}
                    </PricingStyles.Label>
                </PricingStyles.Line>
            </IncentivesWrapper>
        );
    }

    return (
        <IncentivesWrapper>
            <PricingStyles.Group>
                <IncentivesPrice
                    incentives={incentives}
                    boilerUpgradeSchemeLabel={boilerUpgradeSchemeLabel}
                />
            </PricingStyles.Group>
        </IncentivesWrapper>
    );
};

export default Incentives;
