import Linkify from '@common/atoms/Linkify';
import i18n from '@common/i18n';
import React, { useState } from 'react';
import { useAppSelector } from '../../redux';
import * as FormStyles from '../../styles/components/Form';

type PrivacyPolicyHook = {
    ppAccepted: boolean;
    togglePpAccepted: () => void;
    ncAccepted: boolean;
    toggleNcAccepted: () => void;
};
export const usePrivacyPolicy = (): PrivacyPolicyHook => {
    // Fetch store data, user might have already accepted in the past
    const privacyPolicyAccepted = useAppSelector(
        (state) => state.lead.customerDetails?.privacyPolicyAccepted,
    );
    const newsletterConsent = useAppSelector(
        (state) => state.lead.customerDetails?.newsletterConsent,
    );
    // Create local states
    const [ppAccepted, setPpAccepted] = useState(privacyPolicyAccepted || false);
    const [ncAccepted, setNcAccepted] = useState(newsletterConsent || false);
    // Create toggle functions for easy access
    const togglePpAccepted = () => setPpAccepted(!ppAccepted);
    const toggleNcAccepted = () => setNcAccepted(!ncAccepted);
    return {
        ppAccepted,
        togglePpAccepted,
        ncAccepted,
        toggleNcAccepted,
    };
};

type PropsType = { hook: PrivacyPolicyHook };
const PrivacyPolicy = ({ hook }: PropsType): JSX.Element => {
    const privacyPolicyLink = useAppSelector((state) => state.settings.urls.privacyPolicy);
    return (
        <FormStyles.Group>
            <FormStyles.CheckboxGroup>
                <FormStyles.Checkbox
                    id="accept-privacy-policy"
                    checked={hook.ppAccepted}
                    readOnly
                    onChange={hook.togglePpAccepted}
                />
                <FormStyles.Label htmlFor="accept-privacy-policy" mb={3}>
                    <Linkify
                        labelKey="privacy_policy"
                        hyperlinks={{ privacy_policy_link: privacyPolicyLink }}
                    />
                </FormStyles.Label>
            </FormStyles.CheckboxGroup>
            <FormStyles.CheckboxGroup>
                <FormStyles.Checkbox
                    id="newsletter-consent"
                    checked={hook.ncAccepted}
                    readOnly
                    onChange={hook.toggleNcAccepted}
                />
                <FormStyles.Label htmlFor="newsletter-consent" mb={3}>
                    {i18n('newsletter-consent')}
                </FormStyles.Label>
            </FormStyles.CheckboxGroup>
        </FormStyles.Group>
    );
};

export default PrivacyPolicy;
