import i18n from '@common/i18n';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ENERGY_TYPES, MODAL_TYPE } from '../../../../../constants';
import { useAppSelector } from '../../../../../redux';
import { recalculateBenchmarks } from '../../../../../redux/actions';
import * as ButtonStyles from '../../../../../styles/components/Button';
import * as EvsStyles from '../../../../../styles/components/Evs';
import * as FormStyles from '../../../../../styles/components/Form';
import * as ModalStyles from '../../../../../styles/components/Modal';
import * as TypographyStyles from '../../../../../styles/components/Typography';
import { EvsAtwData } from '../../../../../types';
import Modal from '../../Modal';

type EnergyPriceInputPropsType = {
    id: string;
    value: string;
    unit: string;
    textWithHtml?: boolean;
    errors: Array<string> | undefined;
    onChange: (value: string) => void;
};

const EnergyPriceInput = ({
    id,
    value,
    unit,
    textWithHtml = false,
    errors,
    onChange,
}: EnergyPriceInputPropsType): JSX.Element => {
    const country = useAppSelector((store) => store.settings.country);
    const { currency, isCurrencyBeforeAmount } = country;
    return (
        <EvsStyles.EnergyPrice>
            <FormStyles.Label htmlFor={id}>{i18n(id)}</FormStyles.Label>
            <FormStyles.InputGroup>
                <EvsStyles.InputText isSmall isTextBefore={isCurrencyBeforeAmount}>
                    {currency}
                </EvsStyles.InputText>
                <EvsStyles.Input
                    id={id}
                    type="text"
                    value={value}
                    hasError={!!errors}
                    onChange={(e) => onChange(e.currentTarget.value)}
                    placeholder={(0.01).toLocaleString()}
                />
                {textWithHtml ? (
                    <EvsStyles.InputText
                        isSmall
                        display="block"
                        dangerouslySetInnerHTML={{ __html: i18n(unit) }}
                    />
                ) : (
                    <EvsStyles.InputText isSmall>{i18n(unit)}</EvsStyles.InputText>
                )}
            </FormStyles.InputGroup>
            {!!errors && (
                <FormStyles.Error>
                    {errors.map((error) => (
                        <div key={`error_${id}_${error}`}>{error}</div>
                    ))}
                </FormStyles.Error>
            )}
        </EvsStyles.EnergyPrice>
    );
};

const EnergyPricesModal = (): JSX.Element => {
    const dispatch = useDispatch();
    const { benchmarkSolutions } = useAppSelector(
        (state) => state.appState.evsData as EvsAtwData,
    ).priceData;
    const energyPricesErrors = useAppSelector((state) => state.appState.energyPricesErrors);
    const energyPrices = useAppSelector((store) => store.lead.energyPrices)!;

    const hasGasBenchmarks = benchmarkSolutions.some(
        (solution) => solution.energyType === ENERGY_TYPES.gas,
    );
    const hasOilBenchmarks = benchmarkSolutions.some(
        (solution) => solution.energyType === ENERGY_TYPES.oil,
    );
    const hasLpgBenchmarks = benchmarkSolutions.some(
        (solution) => solution.energyType === ENERGY_TYPES.lpg,
    );

    const [electricity, setElectricity] = useState(energyPrices.electricity);
    const [gas, setGas] = useState(energyPrices.gas);
    const [oil, setOil] = useState(energyPrices.oil);
    const [lpg, setLpg] = useState(energyPrices.lpg);

    const onUpdateEnergyPrices = (): void => {
        dispatch(
            recalculateBenchmarks({ oil, electricity, lpg, gas, pellets: energyPrices.pellets }),
        );
    };

    return (
        <Modal type={MODAL_TYPE.energyPrices} isWide>
            <ModalStyles.ModalHeader>
                <TypographyStyles.H4 mb={5}>{i18n('change_energy_prices')}</TypographyStyles.H4>
            </ModalStyles.ModalHeader>
            <ModalStyles.ModalContent isWide width="inherit">
                <EvsStyles.EnergyPrices>
                    <EnergyPriceInput
                        id="electricity_price"
                        value={electricity}
                        unit="per_kwh"
                        onChange={setElectricity}
                        errors={energyPricesErrors?.electricity}
                    />
                    {hasGasBenchmarks && (
                        <EnergyPriceInput
                            id="gas_natural_price"
                            value={gas}
                            unit="per_m3"
                            textWithHtml
                            errors={energyPricesErrors?.gas}
                            onChange={setGas}
                        />
                    )}
                    {hasOilBenchmarks && (
                        <EnergyPriceInput
                            id="oil_price"
                            value={oil}
                            unit="per_liter"
                            errors={energyPricesErrors?.oil}
                            onChange={setOil}
                        />
                    )}
                    {hasLpgBenchmarks && (
                        <EnergyPriceInput
                            id="gas_lpg_price"
                            value={lpg}
                            unit="per_liter"
                            errors={energyPricesErrors?.lpg}
                            onChange={setLpg}
                        />
                    )}
                </EvsStyles.EnergyPrices>
            </ModalStyles.ModalContent>
            <ModalStyles.ModalFooter>
                <ButtonStyles.Primary
                    ml="auto"
                    mb={0}
                    isLarge
                    isInline
                    onClick={onUpdateEnergyPrices}
                >
                    {i18n('save_changes')}
                </ButtonStyles.Primary>
            </ModalStyles.ModalFooter>
        </Modal>
    );
};

export default EnergyPricesModal;
