/* eslint-disable react/no-danger */
import i18n from '@common/i18n';
import { SPLIT_PRICE_TYPE } from '@common/types';
import React from 'react';
import { EVS_OVERVIEW_TAB } from '../../../../../constants';
import { EvsOverviewTab } from '../../../../../types';
import CalculationDisclaimerLink from '../CalculationDisclaimerLink';

type PricingDisclaimersProps = { pricingDisplayStrategy: SPLIT_PRICE_TYPE };
type DisclaimersProps = PricingDisclaimersProps & { activeTab: EvsOverviewTab };

const PricingDisclaimers = ({
    pricingDisplayStrategy,
}: PricingDisclaimersProps): JSX.Element | null => {
    switch (pricingDisplayStrategy) {
        case SPLIT_PRICE_TYPE.show_equipment_only: {
            const disclaimer = i18n('evs_atw_system_prices_disclaimer').split(
                '{pricing_disclaimer_url}',
            );
            return (
                <div>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: disclaimer[0],
                        }}
                    />
                    <CalculationDisclaimerLink
                        label={i18n('pricing_disclaimer_url_label')}
                        disclaimerModalTitle={i18n('pricing_disclaimer_label')}
                        disclaimer={i18n('atw_system_price_disclaimer_text')}
                    />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: disclaimer[1],
                        }}
                    />
                </div>
            );
        }
        case SPLIT_PRICE_TYPE.show_total_price:
        case SPLIT_PRICE_TYPE.show_split_prices: {
            const disclaimer = i18n('evs_atw_total_prices_disclaimer').split(
                '{pricing_disclaimer_url}',
            );
            return (
                <div>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: disclaimer[0],
                        }}
                    />
                    <CalculationDisclaimerLink
                        label={i18n('pricing_disclaimer_url_label')}
                        disclaimerModalTitle={i18n('pricing_disclaimer_label')}
                        disclaimer={i18n('atw_total_price_disclaimer_text')}
                    />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: disclaimer[1],
                        }}
                    />
                </div>
            );
        }
        default:
            return null;
    }
};

const Disclaimers = ({
    activeTab,
    pricingDisplayStrategy,
}: DisclaimersProps): JSX.Element | null => {
    switch (activeTab) {
        case EVS_OVERVIEW_TAB.price: {
            const [rcDisclaimerP1, rcDisclaimerP2] = i18n('evs_atw_running_cost_disclaimer').split(
                '{calculation_disclaimer_url}',
            );
            return (
                <>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: i18n('evs_air_to_water_price_disclaimer'),
                        }}
                    />
                    <br />
                    <PricingDisclaimers pricingDisplayStrategy={pricingDisplayStrategy} />
                    <br />
                    <div>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: rcDisclaimerP1,
                            }}
                        />
                        <CalculationDisclaimerLink
                            label={i18n('calculation_disclaimer_url_label')}
                            disclaimerModalTitle={i18n('calculation_disclaimer_label')}
                            disclaimer={i18n('atw_running_cost_disclaimer_text')}
                        />
                        <span
                            dangerouslySetInnerHTML={{
                                __html: rcDisclaimerP2,
                            }}
                        />
                    </div>
                </>
            );
        }
        case EVS_OVERVIEW_TAB.co2: {
            const [co2DiclaimerP1, co2DisclaimerP2] = i18n('evs_air_to_water_co2_disclaimer').split(
                '{co2_disclaimer_url}',
            );
            return (
                <div>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: co2DiclaimerP1,
                        }}
                    />
                    <CalculationDisclaimerLink
                        label={i18n('co2_disclaimer_url_label')}
                        disclaimerModalTitle={i18n('atw_co2_calculation_disclaimer_label')}
                        disclaimer={i18n('atw_co2_calculation_disclaimer_text')}
                    />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: co2DisclaimerP2,
                        }}
                    />
                </div>
            );
        }
        default:
            return null;
    }
};

export default Disclaimers;
