/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { createLogic } from 'redux-logic';
import { AirToWaterLead, Store } from '../../../types';
import { APP_STATUS } from '../../../constants';
import {
    LEAD_ACTIONS,
    TOOL_SIZING_ATW,
    UpdateSizingToolAtwAction,
    setLeadRemote,
    InitSizingToolAtwAction,
} from '../../actions';
import {
    getAreIncentivesAllowed,
    getSizingPlatformJson,
    getSizingToolsJson,
    isFrance,
    shouldCallSelSoft,
} from '../../selectors';

type DepObj = { getState: () => Store };

type InitDepObj = DepObj & { action: InitSizingToolAtwAction };
const initSizingAtwLogic = createLogic({
    type: TOOL_SIZING_ATW.init,
    name: 'sizing.atw.init',
    validate({ getState, action }: InitDepObj, allow, reject) {
        const state = getState();
        if (state.lead.status === APP_STATUS.sp_sizing_tool) {
            window._rsnChannel.publish('sizing.heating.init', {
                target: 'div#external-tool-sizing-atw',
                ctaTarget: 'div#cta-teleport-target',
                platform: getSizingPlatformJson(state),
                tools: getSizingToolsJson(state),
                step: state.lead.toolData?.sizingHeatingPage || null,
            });
            allow(action);
        } else {
            reject({ ...action, type: `🚫 (${action.type})` });
        }
    },
});

type UpdateDepObj = DepObj & { action: UpdateSizingToolAtwAction };
const updateSizingAtwApiLogic = createLogic({
    type: TOOL_SIZING_ATW.update,
    name: 'sizing.atw.update',
    process({ action }: UpdateDepObj, dispatch, done) {
        dispatch(
            setLeadRemote(
                APP_STATUS.sp_sizing_tool,
                `${APP_STATUS.sp_sizing_tool}.${action.data.step}`,
            ),
        );
        done();
    },
});

const sendSelSoftResponseToSizingLogic = createLogic({
    type: LEAD_ACTIONS.setLeadLocal,
    name: 'sizing.aw.selsoft',
    process({ getState }: DepObj, _, done) {
        // Should we notify sizing tools?
        const state = getState();
        const lead = state.lead as AirToWaterLead;
        if (lead.status === APP_STATUS.sp_sizing_tool) {
            if (shouldCallSelSoft(state) && lead.selSoftData) {
                const hasValidSolution = !!lead.selSoftData?.solution;
                window._rsnChannel.publish('sizing.heating.selsoft', {
                    'has-valid-solution': hasValidSolution,
                });
            }
        }
        done();
    },
});

const doneSizingAtwApiLogic = createLogic({
    type: TOOL_SIZING_ATW.done,
    name: 'sizing.atw.done',
    process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        if (state.lead.toolData!.sizingHeating!['no-solution']) {
            /* If we don't have a solution (for France, for UK, etc) we can't request prices from commerce
             and can't finalize the incentives flow  */
            dispatch(setLeadRemote(APP_STATUS.dealer_selection));
        } else {
            dispatch(setLeadRemote(APP_STATUS.sp_quotation_tool));
        }
        done();
    },
});

export default [
    initSizingAtwLogic,
    updateSizingAtwApiLogic,
    sendSelSoftResponseToSizingLogic,
    doneSizingAtwApiLogic,
];
