import styled, { css } from 'styled-components';
import { breakpoint } from '@common/components/Breakpoints';
import * as BackdropStyles from './Backdrop';
import { color, fontFamily, fontWeight, space, zIndex } from '../common/Theme';

export const UserMenu = styled.nav`
    position: relative;
`;

export const Toggle = styled.button`
    display: flex;
    padding: 0 0 ${space[2]}px 0;
    font-family: ${fontFamily.base};
    font-size: 16px;
    line-height: 20px;
    color: ${color.blue300};
    background: transparent;
    border: none;
    cursor: pointer;

    @media ${breakpoint.down('xl')} {
        padding-bottom: 0;
    }
`;

export const ToggleIcon = styled.span`
    @media ${breakpoint.down('xl')} {
        svg {
            color: ${color.grey500};
        }
    }
`;

export const ToggleLabel = styled.span`
    display: none;

    @media ${breakpoint.up('xl')} {
        display: inline-block;
        padding: 0 ${space[1]}px 0 ${space[2]}px;
    }
`;

export const ToggleChevron = styled.span`
    display: none;

    @media ${breakpoint.up('xl')} {
        display: inline;
    }
`;

type NavigationProps = {
    isOpen?: boolean;
};

export const Navigation = styled.div<NavigationProps>`
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 250px;
    z-index: ${zIndex.modal};

    ${({ isOpen }) =>
        isOpen &&
        css`
            @media ${breakpoint.down('xl')} {
                position: fixed;
                top: 150px;
                left: 0;
                right: 0;
                display: block;
                margin: 0 auto;
                max-width: 475px;
                width: auto;
                background-color: ${color.grey000};
                border-radius: 5px;
                z-index: ${zIndex.modal};
            }

            @media ${breakpoint.down('md')} {
                max-width: calc(100vw - 40px);
            }

            @media ${breakpoint.up('xl')} {
                display: block;
                background-color: ${color.grey000};
                background: #ffffff;
                box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, 0.15);
                border-radius: 5px;
            }
        `};
`;

export const NavigationItem = styled.a`
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${space[4]}px;
    font-size: 16px;
    font-weight: ${fontWeight.semibold};
    line-height: 24px;
    color: ${color.grey500};
    text-decoration: none;
    border-top: 1px solid ${color.grey200};

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:first-child {
        border-top: none;
    }

    svg {
        margin-right: ${space[3]}px;
    }
`;

export const Backdrop = styled.div`
    @media ${breakpoint.down('xl')} {
        ${BackdropStyles.Backdrop};
    }
`;
