import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { APP_STATUS, RSN_SECTION } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { userNavigation } from '../../../redux/actions';
import { isAirToAirMultiRoomLead, isAirToWaterProject } from '../../../redux/selectors';
import * as Styles from '../../../styles/components/NavigationSteps';
import { AppSection } from '../../../types';
import { getStatusSection } from '../../../utils';

type Elements = {
    icon: JSX.Element;
    index: number;
    label: string;
    duration: string;
    skipped: boolean;
    navigateToStep?: APP_STATUS;
};

const getStepElements = (
    section: AppSection,
    totalSteps: number,
    isHeating: boolean,
    skipOrigin: RSN_SECTION | null,
    isAtaMultiRoomLead: boolean,
): Elements | null => {
    switch (section) {
        case RSN_SECTION.section_1_solution_finder_tree:
            return {
                icon: <Icons.Glyph.Copy />,
                index: 1,
                label: i18n('select'),
                duration: isAtaMultiRoomLead
                    ? i18n('multi_split_select_duration')
                    : i18n('select_duration'),
                skipped: false,
                navigateToStep: isHeating
                    ? APP_STATUS.sft_he_project_type
                    : APP_STATUS.sft_ra_room_type,
            };
        case RSN_SECTION.section_2_solution_overview:
            return {
                icon: <Icons.Glyph.AirConditioning />,
                index: 2,
                label: i18n('solution'),
                duration: i18n('solution_duration'),
                skipped: !!skipOrigin && skipOrigin < RSN_SECTION.section_2_solution_overview,
                navigateToStep: APP_STATUS.sol_view_solution,
            };
        case RSN_SECTION.section_3_sizing_and_pricing:
            return {
                icon: <Icons.Glyph.PowerPlug />,
                index: isAtaMultiRoomLead ? 2 : 3,
                label: i18n('sizing_and_pricing'),
                duration: isAtaMultiRoomLead
                    ? i18n('multi_split_sizing_and_pricing_duration')
                    : i18n('sizing_and_pricing_duration'),
                skipped: !!skipOrigin && skipOrigin < RSN_SECTION.section_3_sizing_and_pricing,
                navigateToStep: APP_STATUS.sp_sizing_tool,
            };
        case RSN_SECTION.section_4_incentives:
            return {
                icon: <Icons.Glyph.Calculator />,
                index: 4,
                label: i18n('incentives'),
                duration: i18n('incentives_duration'),
                skipped: !!skipOrigin && skipOrigin < RSN_SECTION.section_4_incentives,
                navigateToStep: APP_STATUS.incentives_tool,
            };
        case RSN_SECTION.section_5_dealer_selection:
            return {
                icon: <Icons.Glyph.User />,
                index: totalSteps,
                label: i18n('dealer_selection'),
                duration: isAtaMultiRoomLead
                    ? i18n('multi_split_dealer_selection_duration')
                    : i18n('dealer_selection_duration'),
                skipped: false,
            };
        default:
            return null;
    }
};

type PropsType = {
    section: AppSection;
    currentSection: AppSection;
    totalSteps: number;
    closePopover?: () => void;
};

const Step = ({
    section,
    currentSection,
    totalSteps,
    closePopover,
}: PropsType): JSX.Element | null => {
    const dispatch = useDispatch();
    const isAirToWater = useAppSelector(isAirToWaterProject);
    const isAtaMultiRoomLead = useAppSelector(isAirToAirMultiRoomLead);
    const skipOrigin = useAppSelector((store) => {
        const { skipToContactDealerFrom } = store.lead;
        if (!skipToContactDealerFrom) return null;
        return getStatusSection(skipToContactDealerFrom);
    });
    const stepElements = getStepElements(
        section,
        totalSteps,
        isAirToWater,
        skipOrigin,
        isAtaMultiRoomLead,
    );
    if (!stepElements) return null;
    // Render flags
    const isActive = currentSection === section;
    const isCompleted = !stepElements.skipped && currentSection > section;
    const isClickable = !stepElements.skipped && stepElements.navigateToStep && isCompleted;

    // UI handler
    const onStepClicked = (): void => {
        // eslint-disable-next-line no-alert
        if (window.confirm(i18n('confirm_back'))) {
            dispatch(userNavigation(stepElements!.navigateToStep!, true));
            // Close steps-popover when navigating back a step
            if (closePopover) closePopover();
        }
    };
    return (
        <Styles.Step
            isActive={isActive}
            isCompleted={isCompleted}
            isDisabled={stepElements.skipped}
            onClick={isClickable ? onStepClicked : undefined}
        >
            <Styles.StepIcon>
                {isCompleted ? <Icons.Glyph.Check /> : stepElements.icon}
            </Styles.StepIcon>
            <Styles.StepLabels>
                <Styles.StepName>
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    <Styles.StepIndex>{stepElements.index}.</Styles.StepIndex>
                    <Styles.StepLabel>{stepElements.label}</Styles.StepLabel>
                </Styles.StepName>
                <Styles.StepDuration>{stepElements.duration}</Styles.StepDuration>
                <Styles.StepProgress>
                    {`Step ${stepElements.index} of ${totalSteps}`}
                </Styles.StepProgress>
            </Styles.StepLabels>
        </Styles.Step>
    );
};

export default Step;
