/* eslint-disable operator-linebreak */
/* eslint-disable no-fallthrough */
import i18n from '@common/i18n';
import {
    ALTHERMA_TYPE,
    COLOR_VARIATIONS,
    SPLIT_PRICE_TYPE,
    UNIT_TRANSLATION_TYPE,
} from '@common/types';
import { translateIndoorUnit } from '@tsLeadUtils';
import {
    APP_STATUS,
    BOILER_TYPE,
    FUNCTION_TYPE,
    INCENTIVES_STATUS,
    PROJECT_TYPE,
    RSN_SECTION,
} from '../../constants';
import {
    AirToWaterLead,
    AppStatus,
    AtaMultiPlusTank,
    CommercePrices,
    DrapoApiJsonType,
    DrapoToolDataType,
    IncentiveType,
    Lead,
    SelSoftSolution,
    Store,
    ToolData,
} from '../../types';
import { getStatusSection } from '../../utils';
import {
    getAreIncentivesAllowed,
    getCurrentSection,
    getIsIncentivesCompleted,
    isAirToWaterLead,
    isAirToWaterProject,
    isGermany,
} from './genericSelectors';
import { getSftChoices } from './sftSelectors';

export const isCoolingRequired = (store: Store): boolean => {
    return (
        [FUNCTION_TYPE.heating_cooling_dhw, FUNCTION_TYPE.heating_cooling] as FUNCTION_TYPE[]
    ).includes((store.lead as AirToWaterLead).functionType!);
};

export const isDhwRequired = (store: Store): boolean => {
    return (
        [FUNCTION_TYPE.heating_cooling_dhw, FUNCTION_TYPE.heating_dhw] as FUNCTION_TYPE[]
    ).includes((store.lead as AirToWaterLead).functionType!);
};

const getPurgedToolData = (
    toolData: Partial<ToolData>,
    targetStatus: string,
    toolStatus: string,
): Partial<ToolData> | null => {
    const result = { ...toolData };
    // Wipe sizing-data when on root of sizing-tool
    if (targetStatus === APP_STATUS.sp_sizing_tool && !toolStatus) {
        delete result.sizingMultiRoom;
        delete result.sizingMultiRoomPage;
        delete result.sizingHeating;
        delete result.sizingHeatingPage;
        delete result.sizingWinterHeating;
        delete result.sizingWinterHeatingPage;
        delete result.sizingCooling;
        delete result.sizingCoolingPage;
    }
    if (targetStatus.startsWith(APP_STATUS.sp_sizing_tool)) {
        // Clear no-solution and required capacity value when navigating back in sizing tool
        return {
            ...result,
            sizingHeating: {
                ...result.sizingHeating,
                'no-solution': undefined,
                'required-capacity': undefined,
            },
        };
    }

    // Wipe quotation-data when on sizing-tool
    // Wipe quotation-data when on root of quotation-tool
    if (
        targetStatus === APP_STATUS.sp_sizing_tool ||
        (targetStatus === APP_STATUS.sp_quotation_tool && !toolStatus)
    ) {
        delete result.quotationHeating;
        delete result.quotationHeatingPage;
        delete result.quotationCooling;
        delete result.quotationCoolingPage;
    }
    return result;
};

// Returns a cleaned up lead object with some values omitted according to the targetStatus
// When the user goes back to a certain step, all data from later steps should be cleared
export const getPurgedLead = (
    lead: Lead,
    status: string,
    // fromStatus: AppStatus,
): Partial<Lead> => {
    const [targetStatus, toolStatus] = status.split('.');
    const {
        location,
        incentives,
        solutionId,
        toolData,
        selSoftData,
        colorVariation,
        literatureData,
        skipToContactDealerFrom,
        dealerId,
        schedulingFactor,
        options,
        prices,
        units,
        solution,
        phasing,
    } = lead;
    const json: Partial<Lead> = {
        ...getSftChoices(lead, targetStatus),
        incentives: null,
        toolData: null,
        location: null,
        selSoftData: null,
        solutionId: null,
        colorVariation: null,
        literatureData: null,
        dealerId: null,
        schedulingFactor: null,
        skipToContactDealerFrom: null,
        options: undefined,
        prices: null,
        units: null,
        solution: null,
        phasing: null,
    };
    // Add data according to current section
    switch (getStatusSection(targetStatus as AppStatus)) {
        case RSN_SECTION.section_5_dealer_selection:
            if (dealerId) json.dealerId = dealerId;
            if (schedulingFactor) json.schedulingFactor = schedulingFactor;
            if (skipToContactDealerFrom) json.skipToContactDealerFrom = skipToContactDealerFrom;
        case RSN_SECTION.section_4_incentives:
            if (incentives) json.incentives = incentives;
        case RSN_SECTION.section_3_sizing_and_pricing:
            if (toolData) json.toolData = getPurgedToolData(toolData, targetStatus, toolStatus);
            if (location) json.location = location;
            if (selSoftData && status !== APP_STATUS.sp_sizing_tool) json.selSoftData = selSoftData;
            if (literatureData) json.literatureData = literatureData;
            if (options) json.options = options;
            if (prices) json.prices = prices;
            if (phasing) json.phasing = phasing;
        case RSN_SECTION.section_2_solution_overview:
            if (solutionId) json.solutionId = solutionId;
            if (colorVariation) json.colorVariation = colorVariation;
            if (solution) json.solution = solution;
            if (units) json.units = units;
        case RSN_SECTION.section_1_solution_finder_tree:
        default:
            // Clear everything
            break;
    }
    if (
        getStatusSection(targetStatus as AppStatus) === RSN_SECTION.section_2_solution_overview ||
        getStatusSection(targetStatus as AppStatus) === RSN_SECTION.section_1_solution_finder_tree
    ) {
        json.swapCooling = false;
    }
    return json;
};

export const getAnnualThermalEnergy = (lead: AirToWaterLead): number | undefined => {
    return lead.selSoftData?.solution?.spaceHeating?.annual?.thermalEnergy;
};

const getVatRate = (store: Store): string | null => {
    const { lead } = store;
    const { vatSettings } = store.settings;
    // Return solution vat percentage if set on solution level (AFF-portal)
    if (isAirToWaterLead(lead) && lead.solution?.vatPercentage) {
        return lead.solution.vatPercentage;
    }
    // Return null if no vatSettings were set in AFF
    if (!vatSettings || vatSettings.length === 0) return null;
    // Just return rate if there's only 1 defined
    if (vatSettings.length === 1) return vatSettings[0].formattedPercentage;
    // Sort based on fromyear
    const [young, old] = vatSettings.sort((a, b) => a.fromYear - b.fromYear);
    if (isAirToWaterProject(store)) {
        // Return highest fromYear-VAT rate if projectType is renovation
        return (store.lead as AirToWaterLead).projectType === PROJECT_TYPE.renovation
            ? old.formattedPercentage
            : young.formattedPercentage;
    } else {
        // Check if sizingTool has returned data
        const isOlderBuilding = store.lead.toolData?.sizingCooling?.['older-building'];
        // If older-building is not set on sizingData, return as old as default
        if (isOlderBuilding === undefined) return old.formattedPercentage;
        return isOlderBuilding ? old.formattedPercentage : young.formattedPercentage;
    }
};

export const getVatLabel = (store: Store): string | null => {
    const vatRate = getVatRate(store);
    if (vatRate === null) return null;
    const [number, decimalSeparator, decimals] = vatRate.split(/(\.|,)/g);
    if (!decimalSeparator) return `${i18n('incl_vat')} ${number}%`;
    // Remove trailing zero's after the decimalSeparator, e.g. 20.50 -> 20.5
    const value = `${number}${decimalSeparator}${decimals?.replace(/0+$/, '')}`;
    return `${i18n('incl_vat')} ${value}%`;
};

export const getShowFromPrice = (store: Store): boolean => {
    const section = getCurrentSection(store);
    const { status } = store.lead;
    if (section === RSN_SECTION.section_2_solution_overview) return true;
    if (status === APP_STATUS.sp_sizing_tool) return true;
    if (status === APP_STATUS.sp_quotation_tool) return true;
    return false;
};

export const getShowCommercePrices = (store: Store): boolean => {
    const section = getCurrentSection(store);
    const { status } = store.lead;
    // Only show commerce prices while on P3.selsoftResult, P4 or P5
    if (status === APP_STATUS.sp_selsoft_result) return true;
    if (section === RSN_SECTION.section_4_incentives) return true;
    if (section === RSN_SECTION.section_5_dealer_selection) return true;
    return false;
};

export const getUnitProductNames = (
    solution: SelSoftSolution,
    type: 'indoor' | 'boiler' | 'option' | 'outdoor' | 'tank',
    unitTranslationType: UNIT_TRANSLATION_TYPE,
): string[] => {
    // Fetch translations
    switch (type) {
        case 'outdoor':
            if (!solution!.outdoorUnit?.productName) return [];
            return [solution!.outdoorUnit?.productName];
        case 'tank':
            if (!solution!.waterTank?.productName) return [];
            return [solution!.waterTank?.productName];
        case 'indoor':
        case 'boiler':
        case 'option': {
            const indoorName = solution!.indoorUnit?.productName;
            if (!indoorName) return [];
            return translateIndoorUnit(indoorName, unitTranslationType) // translate units if needed
                .filter((trans) => trans.type === type) // only return units of requested type
                .map((trans) => trans.name); // just return the name
        }
        default:
            return [];
    }
};

export const getColorVariation = (store: Store): COLOR_VARIATIONS | null => {
    return store.lead.colorVariation || null;
};

export const getIsSmartAltherma = (store: Store): boolean => {
    return store.lead.solutionVersion === ALTHERMA_TYPE.smart;
};

export const getBoilerUpgradeSchemeLabel = (store: Store): string => {
    if (isAirToWaterLead(store.lead)) {
        switch (store.lead.previousHeatingSystem) {
            case BOILER_TYPE.gas:
                return 'boiler_upgrade_scheme_gas';
            case BOILER_TYPE.oil:
                return 'boiler_upgrade_scheme_oil';
            case BOILER_TYPE.other:
            default:
                return '';
        }
    }
    return '';
};

const getIsHybridLead = (lead: AirToWaterLead): boolean => {
    const joined = lead.solution!.energySources.sort().join(',');
    return ['air,gas', 'air,oil'].includes(joined);
};

export type ScopDetails = {
    isHybrid: boolean;
    projectType: PROJECT_TYPE;
    percentage: Record<'renewable' | 'electricity' | 'gas', number>;
    usage: Record<'renewable' | 'fossil', number>;
};

export const getScopDetailsFromLead = (lead: AirToWaterLead): ScopDetails | null => {
    if (!lead.selSoftData?.solution) return null;
    const isHybrid = getIsHybridLead(lead);
    const {
        thermalEnergy: TE,
        energyConsumption: EC,
        heatPumpLoad: HPL,
        hybridLoad,
    } = lead.selSoftData!.solution!.spaceHeating.annual;
    // HybridLoad is always multiplied by 0.9
    const HL = (hybridLoad || 0) * 0.9;
    // Calculate percentages
    const ePerc = isHybrid ? HPL / TE : EC / TE;
    const gPerc = isHybrid ? HL / TE : 0;
    const rPerc = 1 - (ePerc + gPerc);
    // Calculate usages
    const fossilUsage = isHybrid ? HPL + HL : EC;
    const renewableUsage = TE - fossilUsage;
    // Return results
    return {
        isHybrid,
        projectType: lead.projectType!,
        percentage: {
            renewable: rPerc,
            electricity: ePerc,
            gas: gPerc,
        },
        usage: {
            renewable: renewableUsage,
            fossil: fossilUsage,
        },
    };
};

export const getScopDetails = (store: Store): ScopDetails | null => {
    if (!store.lead?.solution) return null;
    if (!isAirToWaterLead(store.lead)) return null;
    return getScopDetailsFromLead(store.lead as AirToWaterLead);
};

export const getDrapoToolData = (store: Store): Partial<DrapoToolDataType> => {
    const lead = store.lead as AirToWaterLead;
    const { adults = 0, children = 0 } = lead.householdComposition || {};
    return {
        ...store.lead.incentives?.toolData?.incentivesFR,
        amountOfPeopleFallback: adults + children,
        surfaceFallback: lead.toolData!.sizingHeating!['heated-surface'],
    };
};

export const getDrapoApiJSON = (store: Store): DrapoApiJsonType => {
    const drapoToolData = getDrapoToolData(store) || {};
    const data: DrapoApiJsonType = {
        amountOfPeople: drapoToolData.amountOfPeople ?? drapoToolData.amountOfPeopleFallback,
        ownerType: drapoToolData.ownerType, // No fallback
        income: drapoToolData.income, // No fallback
        surface: drapoToolData.surface ?? drapoToolData.surfaceFallback,
    };
    if (drapoToolData.removeFuelTank !== null) data.removeFuelTank = drapoToolData.removeFuelTank;
    if (drapoToolData.buildingAge) data.buildingAge = drapoToolData.buildingAge;

    return data;
};

export const getIsFetchIncentivesFailed = (store: Store): boolean => {
    return store.lead.incentives?.status === INCENTIVES_STATUS.failed;
};

export const getIncentives = (store: Store): IncentiveType[] => {
    return store.lead.incentives?.incentives || [];
};

export const getShowIncentivePrices = (store: Store): boolean => {
    const section = getCurrentSection(store);
    // Incentives are only ever shown on P4 and p5
    if (section === RSN_SECTION.section_1_solution_finder_tree) return false;
    if (section === RSN_SECTION.section_2_solution_overview) return false;
    if (section === RSN_SECTION.section_3_sizing_and_pricing) return false;
    // Check if incentives are allowed for the current country & solution
    if (!getAreIncentivesAllowed(store)) return false;
    // Don't show anything if incentives are still in progress
    if (!getIsIncentivesCompleted(store)) return false;
    // Don't show anything if incentives are failed
    if (getIsFetchIncentivesFailed(store)) return false;
    return true;
};

export const getShowTotalSystemPrice = (store: Store): boolean => {
    // For Germany the incentives are in percentages
    if (isGermany(store)) return false;
    // Only shown if incentives could be calculated
    if (!getShowIncentivePrices) return false;
    // Only shown if there are incentives
    if ((store.lead?.incentives?.incentives || []).length < 1) return false;
    // Only shown if the display strategy is show_quipment_price
    if (
        store.settings.solutionPricing.pricingDisplayStrategy !==
        SPLIT_PRICE_TYPE.show_equipment_only
    ) {
        return false;
    }
    // Do not show total system price on step 3
    if (getCurrentSection(store) === RSN_SECTION.section_3_sizing_and_pricing) return false;
    // incentives is an amount and total system price can be shown
    return true;
};

// Heating area should only be shown if heated-surface is not in the response from sizing tool
export const getRenderHeatingArea = (store: Store): boolean => {
    const { sizingHeating } = store.lead.toolData!;
    return !sizingHeating?.['heated-surface'];
};

export const getCommercePrices = (store: Store): CommercePrices => {
    const smallMaterialCost = store.lead.solution?.smallMaterialCost || undefined;
    const placeholderPrices: CommercePrices = {
        equipmentPrice: undefined,
        laborCost: undefined,
        smallMaterialCost,
        totalPrice: undefined,
    };
    return store.lead.prices || placeholderPrices;
};

export const getDhwUnitData = (state: Store): AtaMultiPlusTank | null => {
    const sizingData =
        state.lead.toolData?.sizingMultiRoom?.['solution-domestic-hot-water-tank']?.[
            'domestic-hot-water-tank'
        ];
    if (!sizingData) return null;
    const { depth, width, height, ...rest } = sizingData;
    return { ...rest, dimensions: { depth, width, height } } as AtaMultiPlusTank;
};
