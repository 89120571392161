import React from 'react';
import { APP_STATUS } from '../../constants';
import { useAppSelector } from '../../redux';
import QuotationChoice from './QuotationChoice';
import QuotationTool from './QuotationTool';

const QuotationStepWrapper = (): JSX.Element | null => {
    const status = useAppSelector((store) => store.lead.status);
    if (status === APP_STATUS.sp_quotation_choice) return <QuotationChoice />;
    if (status === APP_STATUS.sp_quotation_tool) return <QuotationTool />;
    return null;
};

export default QuotationStepWrapper;
