import i18n from '@common/i18n';
import React, { FC } from 'react';
import { useAppSelector } from '../../../redux';
import { isAirToAirHeatingLead } from '../../../redux/selectors';
import * as SpecsTableStyles from '../../../styles/components/SpecsTable';
import InfoTrigger from '../InfoTrigger';
import Unit from './Unit';

const UnitOverview: FC = () => {
    const sizing = useAppSelector((store) => {
        const { toolData } = store.lead;
        return isAirToAirHeatingLead(store)
            ? toolData?.sizingWinterHeating
            : toolData?.sizingCooling;
    });
    const quotation = useAppSelector((store) => store.lead.toolData?.quotationCooling);

    const indoorUnits = [sizing?.solution?.indoor || ''].filter(Boolean);
    const soundPressureIndoorSilent = quotation?.indoor?.cooling['sound-pressure-silent'];
    const soundPowerIndoor = quotation?.indoor?.cooling['sound-power'];
    const dimensionsIndoor = quotation?.indoor?.dimensions;

    const outdoorUnits = [sizing?.solution?.outdoor || ''].filter(Boolean);
    const soundPowerOutdoor = quotation?.outdoor?.cooling['sound-power'];
    const refrigerantType = quotation?.['refrigerant-type'];
    const dimensionsOutdoor = quotation?.outdoor?.dimensions;

    return (
        <>
            <Unit title="indoor_unit" productNames={indoorUnits} dimensions={dimensionsIndoor}>
                {typeof soundPressureIndoorSilent === 'number' && (
                    <SpecsTableStyles.ItemRow>
                        <SpecsTableStyles.ItemLabel>
                            {i18n('sound_pressure_cooling_silent')}
                            <InfoTrigger label="sound_pressure_cooling_silent_info" />
                        </SpecsTableStyles.ItemLabel>
                        <SpecsTableStyles.ItemValue>
                            {`${soundPressureIndoorSilent}dBA`}
                        </SpecsTableStyles.ItemValue>
                    </SpecsTableStyles.ItemRow>
                )}
                {typeof soundPowerIndoor === 'number' && (
                    <SpecsTableStyles.ItemRow>
                        <SpecsTableStyles.ItemLabel>
                            {i18n('sound_power_cooling')}
                            <InfoTrigger label="sound_power_cooling_info" />
                        </SpecsTableStyles.ItemLabel>
                        <SpecsTableStyles.ItemValue>
                            {`${soundPowerIndoor}dBA`}
                        </SpecsTableStyles.ItemValue>
                    </SpecsTableStyles.ItemRow>
                )}
            </Unit>
            <Unit title="outdoor_unit" productNames={outdoorUnits} dimensions={dimensionsOutdoor}>
                {typeof soundPowerOutdoor === 'number' && (
                    <SpecsTableStyles.ItemRow>
                        <SpecsTableStyles.ItemLabel>
                            {i18n('sound_power_cooling')}
                            <InfoTrigger label="sound_power_cooling_info" />
                        </SpecsTableStyles.ItemLabel>
                        <SpecsTableStyles.ItemValue>
                            {`${soundPowerOutdoor}dBA`}
                        </SpecsTableStyles.ItemValue>
                    </SpecsTableStyles.ItemRow>
                )}
                {refrigerantType && (
                    <SpecsTableStyles.ItemRow>
                        <SpecsTableStyles.ItemLabel>
                            {i18n('refrigerant_type')}
                        </SpecsTableStyles.ItemLabel>
                        <SpecsTableStyles.ItemValue>{refrigerantType}</SpecsTableStyles.ItemValue>
                    </SpecsTableStyles.ItemRow>
                )}
            </Unit>
        </>
    );
};

export default UnitOverview;
