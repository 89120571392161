import { ALTHERMA_TYPE, COLOR_VARIATIONS, LiteratureData } from '@common/types';
import { APP_STATUS, DEALER_SCHED_FACTOR, ENERGY_TYPES, MULTI_PLUS_TYPE } from '../../constants';
import {
    AirToAirSftSelection,
    AirToWaterSftSelection,
    AtaEnergyLabelsData,
    AtaMultiPlusEnergyLabelsData,
    AtwEnergyLabelsData,
    CustomerDetails,
    Lead,
    PhasingType,
    SelSoftData,
    SelSoftSolution,
} from '../../types';
import { APP_STATE_ACTIONS } from './appStateActions';
import { GIGYA_ACTIONS } from './gigyaActions';
import { INCENTIVES_DE, INCENTIVES_DRAPO } from './incentiveActions';
import { ROOM_BUILDER_ACTIONS } from './roomBuilderActions';
import { SETTINGS_ACTIONS } from './settingsActions';

export enum LEAD_ACTIONS {
    fetchLead = '🗂️/fetchLead',
    clearLead = '🗂️/clearLead',
    setLeadRemote = '🛫/setLead/remote',
    setLeadLocal = '🛬/setLead/local',
    updateLocation = '🗂️/update/location',
    parseSelSoftResponse = '🗂️/selsoft/parse',
    setSelSoftData = '🗂️/selsoft/setData',
    updatePhasing = '🗂️/update/phasing',
    setSftAirToWater = '🗂️/setSftAirToWater',
    setSftAirToAir = '🗂️/setSftAirToAir',
    selectSolution = '🗂️/selectSolution',
    toggleAltherma = '🗂️/toggleAltherma',
    chooseAltherma = '🗂️/chooseAltherma',
    setSchedulingFactor = '🗂️/setSchedulingFactor',
    setDealerId = '🗂️/setDealerId',
    setCustomerDetails = '🗂️/setCustomerDetails',
    submitRsnRequest = '🗂️/submitRsnRequest',
    updateLiterature = '🗂️/update/literature',
    recalculateBenchmarks = 'recalculateBenchmarks',
    fetchEnergyLabels = '🗂️/fetch/energyLabels',
    setEnergyLabels = '🗂️/set/energyLabels',
    setMultiPlusType = '🗂️/set/setMultiPlusType',
    setMultiPlusEnergyLabels = '🗂️/set/setMultiPlusEnergyLabels',
}

export type FetchLeadAction = {
    type: LEAD_ACTIONS.fetchLead;
};
export const fetchLead = (): FetchLeadAction => ({ type: LEAD_ACTIONS.fetchLead });

type SetLeadLocalActionOriginTypes =
    | APP_STATE_ACTIONS
    | SETTINGS_ACTIONS
    | LEAD_ACTIONS
    | GIGYA_ACTIONS
    | INCENTIVES_DRAPO
    | INCENTIVES_DE
    | ROOM_BUILDER_ACTIONS;

export type SetLeadLocalAction = {
    type: LEAD_ACTIONS.setLeadLocal;
    lead: Partial<Lead> | undefined;
    setAllValues: boolean;
    logicOrigin: string;
    actionOrigin: undefined | SetLeadLocalActionOriginTypes;
    toolStatus?: string;
};
export const setLeadLocal = (
    lead?: Partial<Lead>,
    setAllValues = false,
    logicOrigin = '',
    actionOrigin?: SetLeadLocalActionOriginTypes,
    toolStatus?: string,
): SetLeadLocalAction => ({
    type: LEAD_ACTIONS.setLeadLocal,
    lead,
    setAllValues,
    logicOrigin,
    actionOrigin,
    toolStatus,
});

export type ClearLeadAction = { type: LEAD_ACTIONS.clearLead };
export const clearLead = (): ClearLeadAction => ({ type: LEAD_ACTIONS.clearLead });

export type SetLeadRemoteAction = {
    type: LEAD_ACTIONS.setLeadRemote;
    targetStatus?: APP_STATUS;
    toolStatus?: string;
};
export const setLeadRemote = (
    targetStatus?: APP_STATUS,
    toolStatus?: string,
): SetLeadRemoteAction => ({
    type: LEAD_ACTIONS.setLeadRemote,
    targetStatus,
    toolStatus,
});

export type UpdateLocationAction = {
    type: LEAD_ACTIONS.updateLocation;
    lead: Partial<Lead>;
};
export const updateLocation = (lead: Partial<Lead>): UpdateLocationAction => ({
    type: LEAD_ACTIONS.updateLocation,
    lead,
});

export type ParseSelSoftResponseAction = {
    type: LEAD_ACTIONS.parseSelSoftResponse;
    selsoftData: SelSoftData;
    solutions: SelSoftSolution[];
};
export const parseSelSoftResponse = (
    selsoftData: SelSoftData,
    solutions: SelSoftSolution[],
): ParseSelSoftResponseAction => ({
    type: LEAD_ACTIONS.parseSelSoftResponse,
    selsoftData,
    solutions,
});

export type SetSelSoftDataAction = {
    type: LEAD_ACTIONS.setSelSoftData;
    selsoftData: SelSoftData;
};
export const setSelSoftData = (selsoftData: SelSoftData): SetSelSoftDataAction => ({
    type: LEAD_ACTIONS.setSelSoftData,
    selsoftData,
});

export type UpdatePhasingAction = {
    type: LEAD_ACTIONS.updatePhasing;
    phasing: PhasingType;
};
export const updatePhasing = (phasing: PhasingType): UpdatePhasingAction => ({
    type: LEAD_ACTIONS.updatePhasing,
    phasing,
});

/* AirToWater specific actions */

export type SetSftAirToWaterAction = {
    type: LEAD_ACTIONS.setSftAirToWater;
    choices: Partial<AirToWaterSftSelection>;
};
export const setSftAirToWater = (
    choices: Partial<AirToWaterSftSelection>,
): SetSftAirToWaterAction => ({
    type: LEAD_ACTIONS.setSftAirToWater,
    choices,
});

export type SelectAirToWaterSolutionAction = {
    type: LEAD_ACTIONS.selectSolution;
    solutionId: string;
};
export const selectAirToWaterSolution = (solutionId: string): SelectAirToWaterSolutionAction => ({
    type: LEAD_ACTIONS.selectSolution,
    solutionId,
});

// By updating the energy prices we need to recalculate the benchmarks
export type RecalculateBenchmarksAction = {
    type: LEAD_ACTIONS.recalculateBenchmarks;
    energyPrices: Record<ENERGY_TYPES, string>;
};
export const recalculateBenchmarks = (
    energyPrices: Record<ENERGY_TYPES, string>,
): RecalculateBenchmarksAction => ({
    type: LEAD_ACTIONS.recalculateBenchmarks,
    energyPrices,
});

export type FetchEnergyLabelsAction = { type: LEAD_ACTIONS.fetchEnergyLabels };
export const fetchEnergyLabels = (): FetchEnergyLabelsAction => ({
    type: LEAD_ACTIONS.fetchEnergyLabels,
});

export type SetEnergyLabelsAction = {
    type: LEAD_ACTIONS.setEnergyLabels;
    energyLabelsData: AtwEnergyLabelsData | AtaEnergyLabelsData | null;
};
export const setEnergyLabels = (
    energyLabelsData: AtwEnergyLabelsData | AtaEnergyLabelsData | null,
): SetEnergyLabelsAction => ({
    type: LEAD_ACTIONS.setEnergyLabels,
    energyLabelsData,
});

export type SetMultiPlusEnergyLabelsAction = {
    type: LEAD_ACTIONS.setMultiPlusEnergyLabels;
    multiPlusEnergyLabelsData: AtaMultiPlusEnergyLabelsData | null;
};
export const setMultiPlusEnergyLabels = (
    multiPlusEnergyLabelsData: AtaMultiPlusEnergyLabelsData | null,
): SetMultiPlusEnergyLabelsAction => ({
    type: LEAD_ACTIONS.setMultiPlusEnergyLabels,
    multiPlusEnergyLabelsData,
});

/* AirToAir specific actions */

export type SetSftAirToAirAction = {
    type: LEAD_ACTIONS.setSftAirToAir;
    choices: Partial<AirToAirSftSelection>;
};
export const setSftAirToAir = (choices: Partial<AirToAirSftSelection>): SetSftAirToAirAction => ({
    type: LEAD_ACTIONS.setSftAirToAir,
    choices,
});

export type SelectAirToAirSolutionAction = {
    type: LEAD_ACTIONS.selectSolution;
    solutionId: string;
    colorVariation?: COLOR_VARIATIONS;
};
export const selectAirToAirSolution = (
    solutionId: string,
    colorVariation?: COLOR_VARIATIONS,
): SelectAirToAirSolutionAction => ({
    type: LEAD_ACTIONS.selectSolution,
    solutionId,
    colorVariation,
});

export type SetMultiPlusTypeAction = {
    type: LEAD_ACTIONS.setMultiPlusType;
    multiPlusType: MULTI_PLUS_TYPE | null;
};
export const setMultiPlusType = (
    multiPlusType: MULTI_PLUS_TYPE | null,
): SetMultiPlusTypeAction => ({
    type: LEAD_ACTIONS.setMultiPlusType,
    multiPlusType,
});

/* Altherma smart / regular toggle */

export type ToggleAlthermaAction = {
    type: LEAD_ACTIONS.toggleAltherma;
    solutionVersion: ALTHERMA_TYPE;
};
export const toggleAltherma = (solutionVersion: ALTHERMA_TYPE): ToggleAlthermaAction => ({
    type: LEAD_ACTIONS.toggleAltherma,
    solutionVersion,
});

export type ChooseAlthermaAction = {
    type: LEAD_ACTIONS.chooseAltherma;
    solutionVersion: ALTHERMA_TYPE;
};
export const chooseAltherma = (solutionVersion: ALTHERMA_TYPE): ChooseAlthermaAction => ({
    type: LEAD_ACTIONS.chooseAltherma,
    solutionVersion,
});

/* Dealer selection */

export type SetSchedulingFactorAction = {
    type: LEAD_ACTIONS.setSchedulingFactor;
    schedulingFactor: DEALER_SCHED_FACTOR;
};
export const setSchedulingFactor = (
    schedulingFactor: DEALER_SCHED_FACTOR,
): SetSchedulingFactorAction => ({
    type: LEAD_ACTIONS.setSchedulingFactor,
    schedulingFactor,
});

export type SetDealerIdAction = {
    type: LEAD_ACTIONS.setDealerId;
    dealerId: string | null;
};
export const setDealerId = (dealerId: string | null): SetDealerIdAction => ({
    type: LEAD_ACTIONS.setDealerId,
    dealerId,
});

export type SetCustomerDetailsAction = {
    type: LEAD_ACTIONS.setCustomerDetails;
    customerDetails: CustomerDetails;
};
export const setCustomerDetails = (customerDetails: CustomerDetails): SetCustomerDetailsAction => ({
    type: LEAD_ACTIONS.setCustomerDetails,
    customerDetails,
});

export type SubmitRsnRequestAction = {
    type: LEAD_ACTIONS.submitRsnRequest;
};
export const submitRsnRequest = (): SubmitRsnRequestAction => ({
    type: LEAD_ACTIONS.submitRsnRequest,
});

/* Literature */

export type UpdateLiteratureAction = {
    type: LEAD_ACTIONS.updateLiterature;
    data: LiteratureData;
};
export const updateLiterature = (data: LiteratureData): UpdateLiteratureAction => ({
    type: LEAD_ACTIONS.updateLiterature,
    data,
});

/* Exports */

export type LeadActionsType =
    | FetchLeadAction
    | ClearLeadAction
    | SetLeadLocalAction
    | SetLeadRemoteAction
    | UpdateLocationAction
    | ParseSelSoftResponseAction
    | SetSelSoftDataAction
    | UpdatePhasingAction
    | SetSftAirToWaterAction
    | SetSftAirToAirAction
    | SelectAirToWaterSolutionAction
    | RecalculateBenchmarksAction
    | SelectAirToAirSolutionAction
    | SetMultiPlusTypeAction
    | ToggleAlthermaAction
    | ChooseAlthermaAction
    | SetSchedulingFactorAction
    | SetDealerIdAction
    | SetCustomerDetailsAction
    | SubmitRsnRequestAction
    | UpdateLiteratureAction
    | FetchEnergyLabelsAction
    | SetEnergyLabelsAction
    | SetMultiPlusEnergyLabelsAction;
