import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useInterval } from 'usehooks-ts';
import { useAppSelector } from '../../../redux';
import { saveProgress, trackingSave } from '../../../redux/actions';
import { isPardotLinked } from '../../../redux/selectors';
import * as BasketStyles from '../../../styles/components/Basket';
import * as PreloaderStyles from '../../../styles/components/Preloader';
import FooterButton from './FooterButton';

const getTimeLabel = (timestamp: number): string => {
    const dS = (Date.now() - timestamp) / 1000;
    if (dS >= 86400) return `${Math.round(dS / 86400)} ${i18n('days')}`;
    if (dS >= 3600) return `${Math.round(dS / 3600)} ${i18n('hours')}`;
    if (dS >= 120) return `${Math.round(dS / 60)} ${i18n('minutes')}`;
    return i18n('seconds');
};

const Save: FC = () => {
    const dispatch = useDispatch();
    const isLinked = useAppSelector(isPardotLinked);
    const isSaving = useAppSelector((store) => store.appState.isSaving);
    const lastSaved = useAppSelector((store) => store.appState.lastSaved);

    const onSaveClicked = (): void => {
        dispatch(trackingSave());
        dispatch(saveProgress());
    };

    // Update timelabel every minute
    const [time, setTime] = useState(getTimeLabel(lastSaved));
    useInterval(() => {
        setTime(getTimeLabel(lastSaved));
    }, 60000);

    // Show save-button which triggers the PardotModal
    if (!isLinked) {
        return <FooterButton onClick={onSaveClicked} label="save" icon={<Icons.Glyph.FileAdd />} />;
    }

    // Show loader when we're saving
    if (isSaving) {
        return (
            <FooterButton
                label="saving"
                icon={
                    <PreloaderStyles.Preloader colorOverride="black">
                        <Icons.Glyph.LoadingWheel />
                    </PreloaderStyles.Preloader>
                }
            />
        );
    }

    // Show how long ago RSN was saved
    const lastSavedLabel = i18n('last_saved').replace('%time%', time);
    return <FooterButton label={lastSavedLabel} />;
};

const Guard = (): JSX.Element | null => {
    // Only render save button if Pardot is enabled
    const pardotEnabled = useAppSelector((state) => state.settings.pardotEnabled);
    return pardotEnabled ? (
        <BasketStyles.Actions>
            <Save />
        </BasketStyles.Actions>
    ) : null;
};

export default Guard;
