import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { APP_STATUS } from '../../../constants';
import { userNavigation } from '../../../redux/actions';
import * as ButtonStyles from '../../../styles/components/Button';

export type ContinueButtonProps = {
    label?: string;
    target: APP_STATUS;
    enabled: boolean;
};

type PropsType = {
    continueButtonProps: ContinueButtonProps;
};
const ContinueButton = ({
    continueButtonProps: { label, target, enabled },
}: PropsType): JSX.Element => {
    const dispatch = useDispatch();
    const onClick = (): void => {
        dispatch(userNavigation(target));
    };
    return (
        <ButtonStyles.Primary
            isLarge
            mb={4}
            onClick={enabled ? onClick : undefined}
            disabled={!enabled}
        >
            {i18n(label || 'continue')}
            <Icons.Glyph.ArrowRight />
        </ButtonStyles.Primary>
    );
};

export default ContinueButton;
