import { isNumber } from '@tsUtils';

const px = (n: number): string | number => (isNumber(n) ? `${n}px` : n);

export const breakpoints = {
    xs: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};
type Breakpoint = keyof typeof breakpoints;

const getNextBreakpoint = (n: Breakpoint): Breakpoint | null => {
    const keys = Object.keys(breakpoints) as Array<Breakpoint>;
    const i = keys.indexOf(n);
    return i < keys.length ? keys[i + 1] : null;
};

export const breakpoint = {
    up: (n: Breakpoint) => `screen and (min-width: ${px(breakpoints[n])})`,
    down: (n: Breakpoint) => `screen and (max-width: ${px(breakpoints[n] - 1)})`,
    only: (n: Breakpoint) => {
        const min = breakpoints[n];
        const nextBreakpoint = getNextBreakpoint(n);
        if (!nextBreakpoint) return `screen and (min-width: ${px(min)})`;
        const max = breakpoints[nextBreakpoint];
        return `screen and (min-width: ${px(min)}) and (max-width: ${px(max)})`;
    },
};
