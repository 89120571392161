import * as AlertStyles from '@common/atoms/Alert';
import { Multiline } from '@common/atoms/Multiline';
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MODAL_TYPE } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { setSkipToSelectDealerError, skipToSelectDealer } from '../../../redux/actions';
import * as ButtonStyles from '../../../styles/components/Button';
import * as FormStyles from '../../../styles/components/Form';
import * as ModalStyles from '../../../styles/components/Modal';
import * as TypographyStyles from '../../../styles/components/Typography';
import Modal from './Modal';

type PropsType = {
    metaData?: unknown;
};

const ContactADealerModal = (props: PropsType): JSX.Element => {
    const dispatch = useDispatch();
    const sizingToolPostalCode = useAppSelector(
        (store) => store.lead.toolData?.sizingHeating?.postal,
    );
    const [postalCode, setPostalCode] = useState<string>(sizingToolPostalCode || '');
    const skipToSelectDealerError = useAppSelector(
        (store) => store.appState.skipToSelectDealerError,
    );
    const minutesToFinish = props.metaData as string | undefined;

    const onClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        if (!postalCode) {
            dispatch(setSkipToSelectDealerError(i18n('postal_code_error')));
            return;
        }
        dispatch(skipToSelectDealer(postalCode));
    };

    return (
        <Modal type={MODAL_TYPE.contactADealer}>
            <>
                <ModalStyles.ModalHeader>
                    <TypographyStyles.H4 mb={5}>
                        {i18n('contact_a_dealer_title')}
                    </TypographyStyles.H4>
                </ModalStyles.ModalHeader>
                {minutesToFinish && (
                    <AlertStyles.Info mb={4}>
                        <Icons.Glyph.InfoCircle />
                        {(i18n('minutes_to_finish_info') as string).replace('%d', minutesToFinish!)}
                    </AlertStyles.Info>
                )}

                <TypographyStyles.DefaultText mb={4}>
                    <Multiline>{i18n('contact_a_dealer_info')}</Multiline>
                </TypographyStyles.DefaultText>

                <FormStyles.Group>
                    <FormStyles.Label isSrOnly htmlFor="postal-code">
                        {i18n('your_postal_code')}
                    </FormStyles.Label>
                    <FormStyles.Input
                        required
                        id="postal-code"
                        value={postalCode || ''}
                        hasError={!!skipToSelectDealerError}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            setPostalCode(e.currentTarget.value);
                        }}
                    />
                    {skipToSelectDealerError && (
                        <FormStyles.Error>{skipToSelectDealerError}</FormStyles.Error>
                    )}
                </FormStyles.Group>

                <ModalStyles.ModalFooter>
                    <ButtonStyles.Primary ml="auto" mb={0} isLarge isInline onClick={onClick}>
                        {i18n('contact_a_dealer_modal')}
                    </ButtonStyles.Primary>
                </ModalStyles.ModalFooter>
            </>
        </Modal>
    );
};

export default ContactADealerModal;
