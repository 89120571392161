import React, { FC } from 'react';
import * as Styles from '../../../styles/components/Carousel';
import { AirToWaterSftSolution } from '../../../types';

type PropsType = {
    solution: AirToWaterSftSolution;
};

const SftImageViewer: FC<PropsType> = (props) => {
    const image = props.solution.overviewImage;
    return (
        <Styles.Carousel>
            <Styles.ImageWrapper>
                <Styles.Image role="img" aria-label={image.split('/').pop()} src={image} />
            </Styles.ImageWrapper>
        </Styles.Carousel>
    );
};

export default SftImageViewer;
