import i18n from '@common/i18n';
import React, { FC } from 'react';
import { useAppSelector } from '../../../redux';
import { getVatLabel } from '../../../redux/selectors';
import * as PricingStyles from '../../../styles/components/Pricing';
import PriceItem from './PriceItem';

type PropsType = {
    totalPrice: string | undefined;
};

const TotalPrice: FC<PropsType> = ({ totalPrice }) => {
    const vatLabel = useAppSelector(getVatLabel);
    return (
        <PricingStyles.Group>
            <PriceItem
                price={totalPrice}
                label={i18n('price_total')}
                note={vatLabel}
                mb={2}
                isImportant
                showPriceDetails
            />
        </PricingStyles.Group>
    );
};

export default TotalPrice;
