/* eslint-disable react/no-danger */
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { MODAL_TYPE } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { openModal } from '../../../redux/actions';
import {
    getHighestEvsPercentage,
    getIsEvsApplicable,
    getSavingCo2EmissionPercentage,
    getShowPrices,
    isAirToAirHeatingLead,
} from '../../../redux/selectors/genericSelectors';
import * as BasketStyles from '../../../styles/components/Basket';
import * as ButtonStyles from '../../../styles/components/Button';
import * as EvsStyles from '../../../styles/components/Evs';
import * as PreloaderStyles from '../../../styles/components/Preloader';

const EvsSidePanel = (): JSX.Element => {
    const dispatch = useDispatch();
    const percentage = useAppSelector(getHighestEvsPercentage);
    const isAtaHeatingLead = useAppSelector(isAirToAirHeatingLead);
    const savingCo2EmissionPercentage = useAppSelector(getSavingCo2EmissionPercentage);
    const evsData = useAppSelector((store) => store.appState.evsData);

    if (!percentage) {
        return (
            <PreloaderStyles.Preloader>
                <Icons.Glyph.LoadingWheel />
            </PreloaderStyles.Preloader>
        );
    }

    const onClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        // Don't dispatch action to open modal if no evs data is available
        if (evsData) {
            if (isAtaHeatingLead) {
                dispatch(openModal(MODAL_TYPE.evsAtaHeatingCompare, { evsData }));
            } else {
                dispatch(openModal(MODAL_TYPE.evsAtwCompare));
            }
        }
    };

    return (
        <BasketStyles.Evs>
            <EvsStyles.Panel isAtaHeatingLead={isAtaHeatingLead}>
                <div>
                    <div className="compare-info-line">
                        <span className="icon">
                            <Icons.Glyph.Chart size={25} />
                        </span>
                        <div
                            className="text"
                            dangerouslySetInnerHTML={{
                                __html: i18n('evs_percentage_info').replace(
                                    '{percentage}',
                                    String(percentage),
                                ),
                            }}
                        />
                    </div>
                    {/* null if benchmarks only have Pellets or LPG as an Energy source */}
                    {savingCo2EmissionPercentage && (
                        <div className="compare-info-line">
                            <span className="icon">
                                <Icons.Glyph.ThumbsUp size={25} />
                            </span>
                            <div
                                className="text"
                                dangerouslySetInnerHTML={{
                                    __html: i18n('evs_ata_heating_percentage_info').replace(
                                        '{percentage}',
                                        savingCo2EmissionPercentage,
                                    ),
                                }}
                            />
                        </div>
                    )}
                </div>
                &nbsp;
                <ButtonStyles.Link onClick={onClick}>{i18n('evs_compare')}</ButtonStyles.Link>
            </EvsStyles.Panel>
        </BasketStyles.Evs>
    );
};

const Guard = (): JSX.Element | null => {
    const showEvsSidePanel = useAppSelector((state) => {
        // Not shown when EVS is not applicable
        if (!getIsEvsApplicable(state)) return false;
        // Not shown when no prices are returned by Commerce
        if (!state.lead.prices) return false;
        // Not shown when prices are hidden by AFF
        if (!getShowPrices(state)) return false;
        // Only show if there is evsData
        if (state.appState.evsData === null) return false;
        // All checks passed
        return true;
    });
    if (!showEvsSidePanel) return null;
    return <EvsSidePanel />;
};

export default Guard;
