import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React from 'react';
import * as AaltraLoaderStyles from '../../styles/components/AaltraLoader';

type PropsType = { id: string };
const ToolWrapper = ({ id }: PropsType): JSX.Element => {
    return (
        <AaltraLoaderStyles.Container>
            <AaltraLoaderStyles.LoaderWrapper>
                <AaltraLoaderStyles.Loader>
                    <Icons.ExtraLarge.Loader />
                </AaltraLoaderStyles.Loader>
                <AaltraLoaderStyles.LoaderTitle>{i18n('loading')}</AaltraLoaderStyles.LoaderTitle>
            </AaltraLoaderStyles.LoaderWrapper>
            <AaltraLoaderStyles.ToolWrapper id={id} />
        </AaltraLoaderStyles.Container>
    );
};

export default ToolWrapper;
