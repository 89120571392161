import { IncentiveEventDataTypes } from '../../types/external/incentivesDE';
import { DrapoToolDataType, IncomeRangeType } from '../../types';

/* --- Drapo ----------------------------------------------------------------------------------- */

export enum INCENTIVES_DRAPO {
    setAmountOfPeople = '💸/drapo/set-amount-of-ppl',
    setIncomeRanges = '💸/drapo/set-income-ranges',
    setDrapoData = '💸/drapo/set-data',
    saveDrapoData = '💸/drapo/save-data',
    setDrapoToolState = '💸/drapo/set-tool-state',
}

export type SetDrapoAmountOfPeopleActionType = {
    type: INCENTIVES_DRAPO.setAmountOfPeople;
    amountOfPeople: number;
};
export const setDrapoAmountOfPeople = (
    amountOfPeople: number,
): SetDrapoAmountOfPeopleActionType => ({
    type: INCENTIVES_DRAPO.setAmountOfPeople,
    amountOfPeople,
});

export type SetDrapoIncomeRangesActionType = {
    type: INCENTIVES_DRAPO.setIncomeRanges;
    incomeRanges: IncomeRangeType[];
};
export const setDrapoIncomeRanges = (
    incomeRanges: IncomeRangeType[],
): SetDrapoIncomeRangesActionType => ({
    type: INCENTIVES_DRAPO.setIncomeRanges,
    incomeRanges,
});

export type SetDrapoDataActionType = {
    type: INCENTIVES_DRAPO.setDrapoData;
    data: Partial<DrapoToolDataType>;
};
export const setDrapoData = (data: Partial<DrapoToolDataType>): SetDrapoDataActionType => ({
    type: INCENTIVES_DRAPO.setDrapoData,
    data,
});

export type SaveDrapoDataActionType = {
    type: INCENTIVES_DRAPO.saveDrapoData;
    data: Partial<DrapoToolDataType>;
};
export const saveDrapoData = (data: Partial<DrapoToolDataType>): SaveDrapoDataActionType => ({
    type: INCENTIVES_DRAPO.saveDrapoData,
    data,
});

export type SetDrapoToolStateActionType = {
    type: INCENTIVES_DRAPO.setDrapoToolState;
    isDrapoLoading?: boolean;
    isDrapoAvailable?: boolean;
};
export const setDrapoToolState = ({
    isDrapoLoading,
    isDrapoAvailable,
}: {
    isDrapoLoading?: boolean;
    isDrapoAvailable?: boolean;
}): SetDrapoToolStateActionType => ({
    type: INCENTIVES_DRAPO.setDrapoToolState,
    isDrapoLoading,
    isDrapoAvailable,
});

/* --- Germany --------------------------------------------------------------------------------- */

export enum INCENTIVES_DE {
    init = '💸/incentives/🇩🇪/init',
    update = '💸/incentives/🇩🇪/update',
    done = '💸/incentives/🇩🇪/done',
}

export type InitIncentivesDEActionType = { type: INCENTIVES_DE.init };
export const initIncentivesDE = (): InitIncentivesDEActionType => ({
    type: INCENTIVES_DE.init,
});

export type UpdateIncentivesDEActionType = {
    type: INCENTIVES_DE.update;
    data: IncentiveEventDataTypes.Germany.Update;
};
export const updateIncentivesDE = (
    data: IncentiveEventDataTypes.Germany.Update,
): UpdateIncentivesDEActionType => ({
    type: INCENTIVES_DE.update,
    data,
});

export type DoneIncentivesDEActionType = {
    type: INCENTIVES_DE.done;
    data: IncentiveEventDataTypes.Germany.Done;
};
export const doneIncentivesDE = (
    data: IncentiveEventDataTypes.Germany.Done,
): DoneIncentivesDEActionType => ({
    type: INCENTIVES_DE.done,
    data,
});

/* --- Types ----------------------------------------------------------------------------------- */

export type IncentiveActionsType =
    | SetDrapoAmountOfPeopleActionType
    | SetDrapoIncomeRangesActionType
    | SetDrapoDataActionType
    | SaveDrapoDataActionType
    | InitIncentivesDEActionType
    | UpdateIncentivesDEActionType
    | DoneIncentivesDEActionType
    | SetDrapoToolStateActionType;
