import { DEVICE_TYPE } from '@common/types';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useDebounceValue, useWindowSize } from 'usehooks-ts';
import { breakpoints } from '../components/Breakpoints';
import { useDebounce } from './index'; // untyped hooks

// Rotates an index between 0 & count (loop back to 0)
// - calling onStartHold pauses the automatic rotate
// - calling onStopHold continues the automatic rotate
// - calling setCurrent manually override the current value and reset the timeout
type UseAutoRotateWithHoldOutput = [number, (v: number) => void, () => void, () => void];
export const useAutoRotateWithHold = (
    count: number,
    autoDelay = 5000,
    startIndex = 0,
): UseAutoRotateWithHoldOutput => {
    const [current, setCurrent] = useState(startIndex);
    const [holding, setHolding] = useState(false);
    const onStartHold = (): void => setHolding(true);
    const onStopHold = (): void => setHolding(false);
    const next = (current + 1) % count;
    useEffect(() => {
        const id = holding ? null : setTimeout(() => setCurrent(next), autoDelay);
        return () => {
            if (id) clearTimeout(id);
        };
    }, [current, count, holding]);
    return [current, setCurrent, onStartHold, onStopHold];
};

type DebouncedInput = {
    input: string;
    setInput: (value: string) => void;
    forceCallback: () => void;
};
export const useDebouncedInput = (
    initialValue: string,
    callback: (value: string) => void,
    delay = 500,
    // eslint-disable-next-line function-paren-newline
): DebouncedInput => {
    const [input, setInput] = useState(initialValue || '');
    const [query, setQuery] = useState('');

    // Debounce all input by n-ms (500)
    const debouncedInput = useDebounce(input, delay);

    // Check if debounced input is different from previous query
    const checkDiff = (): void => {
        if (debouncedInput !== query) setQuery(debouncedInput);
    };

    // Bypass debounced input to set query
    const forceCallback = (): void => {
        if (input !== query) setQuery(input);
    };

    // Fetch cities for given postalCode after a certain delay
    useEffect(checkDiff, [debouncedInput]);

    // send query to callback
    useEffect(() => callback(query), [query]);

    return { input, setInput, forceCallback };
};

export const useForceUpdate = (): (() => void) => useReducer(() => ({}), {})[1];

// AutoFocus an input element when it is mounted
export const useAutoFocus = (): React.RefObject<HTMLInputElement> => {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current) inputRef.current.focus();
    }, [inputRef]);
    return inputRef;
};

export const useDeviceType = (): DEVICE_TYPE => {
    const size = useWindowSize();
    if (size.width === 0) {
        return DEVICE_TYPE.desktop;
    } else if (size.width <= breakpoints.sm) {
        return DEVICE_TYPE.mobile;
    } else if (size.width <= breakpoints.lg) {
        return DEVICE_TYPE.tablet;
    } else {
        return DEVICE_TYPE.desktop;
    }
};

// Returns the css height value for the given element refs
export type UseEqualHeightsType = [(element: HTMLDivElement) => void, 'auto' | number];
export const useEqualHeights = (): UseEqualHeightsType => {
    const [highest, setHighest] = useState<number>(0);
    const windowSize = useWindowSize();
    const [debouncedWidth] = useDebounceValue(windowSize.width, 100);

    // Create list of refs we can push to
    const refs = useRef<HTMLDivElement[]>([]);
    const addRef = (element: HTMLDivElement): void => {
        refs.current[refs.current.length] = element;
    };

    useEffect(() => {
        setHighest(0);
    }, [windowSize.width]);

    // After render/update -> check for largest element
    useEffect(() => {
        setHighest(Math.max(0, ...refs.current.map((ele) => ele?.clientHeight ?? 0)));
    }, [refs, debouncedWidth]);
    // return refs & highest height (0 defaults to 'auto')
    return [addRef, highest || 'auto'];
};

type UsePagingType<T> = {
    items: Array<T>;
    page: number;
    pageCount: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
};
export const usePaging = <T,>(allItems: Array<T>, itemsPp: number): UsePagingType<T> => {
    const [page, setPage] = useState(0);
    const pageCount = Math.ceil(allItems.length / itemsPp);
    return {
        items: page === -1 ? allItems : allItems.slice(page * itemsPp, page * itemsPp + itemsPp),
        page,
        pageCount,
        setPage,
    };
};

type UseModal = { open: () => void; close: () => void; isOpen: boolean };
export const useModal = (): UseModal => {
    const [isOpen, setIsOpen] = useState(false);
    return {
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
        isOpen,
    };
};

export const useDebouncedForm = <F extends Record<string, unknown>>(
    storeData: F,
    debouncedCallback: (form: F) => void,
    debounceDelay: number = 1000,
): { setFormValue: (key: string, value: unknown) => void; formData: F } => {
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [formData, setFormData] = useReducer(
        (state: F, payload: { key: string; value: unknown }): F => {
            if (state[payload.key] === payload.value) return state;
            return { ...state, [payload.key]: payload.value };
        },
        storeData,
    );

    useEffect(() => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => debouncedCallback(formData), debounceDelay);
        // Clear timeout on unmount
        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, [timeoutRef, debounceDelay, formData, debouncedCallback]);

    const setFormValue = useCallback(
        (key: string, value: unknown): void => setFormData({ key, value }),
        [setFormData],
    );

    return { setFormValue, formData };
};
