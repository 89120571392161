import i18n from '@common/i18n';
import React from 'react';
import * as PricingStyles from '../../../styles/components/Pricing';
import PriceItem from './PriceItem';

type PropsType = {
    totalSystemPrice: string;
};

const TotalSystemPrice = ({ totalSystemPrice }: PropsType): JSX.Element => {
    return (
        <PricingStyles.Group>
            <PriceItem
                price={totalSystemPrice}
                label={i18n('price_system_total')}
                mb={2}
                isImportant
                showPriceDetails
            />
        </PricingStyles.Group>
    );
};

export default TotalSystemPrice;
