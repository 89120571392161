import { GigyaUser, GigyaUserLead } from '../../types';

export enum GIGYA_ACTIONS {
    setGigyaState = 'appState/🔑/setGigyaState',
    setGigyaUid = 'appState/🔑/setGigyaUid',
    setGigyaUser = 'appState/🔑/setGigyaUser',
    clearGigyaUser = 'appState/🔑/clearGigyaUser',
    setGigyaUserLeads = 'appState/🔑/setGigyaUserLeads',
    registrationFlowCompleted = 'appState/🔑/registrationFlowCompleted',
}

export type SetGigyaStateAction = {
    type: GIGYA_ACTIONS.setGigyaState;
    gigyaState: 'injected' | 'ready' | 'error';
};
export const setGigyaState = (gigyaState: 'injected' | 'ready' | 'error'): SetGigyaStateAction => ({
    type: GIGYA_ACTIONS.setGigyaState,
    gigyaState,
});

export type SetGigyaUidAction = {
    type: GIGYA_ACTIONS.setGigyaUid;
    gigyaUid: string;
};
export const setGigyaUid = (gigyaUid: string): SetGigyaUidAction => ({
    type: GIGYA_ACTIONS.setGigyaUid,
    gigyaUid,
});

export type SetGigyaUserAction = {
    type: GIGYA_ACTIONS.setGigyaUser;
    gigyaUser: GigyaUser;
};
export const setGigyaUser = (gigyaUser: GigyaUser): SetGigyaUserAction => ({
    type: GIGYA_ACTIONS.setGigyaUser,
    gigyaUser,
});

export type ClearGigyaUserAction = { type: GIGYA_ACTIONS.clearGigyaUser };
export const clearGigyaUser = (): ClearGigyaUserAction => ({
    type: GIGYA_ACTIONS.clearGigyaUser,
});

export type SetGigyaUserLeadsAction = {
    type: GIGYA_ACTIONS.setGigyaUserLeads;
    gigyaUserLeads: GigyaUserLead[];
    errorMessage: string | null;
};
export const setGigyaUserLeads = (
    gigyaUserLeads: GigyaUserLead[],
    errorMessage: string | null,
): SetGigyaUserLeadsAction => ({
    type: GIGYA_ACTIONS.setGigyaUserLeads,
    gigyaUserLeads,
    errorMessage,
});

export type GigyaRegistrationFlowCompletedAction = {
    type: GIGYA_ACTIONS.registrationFlowCompleted;
};
export const gigyaRegistrationFlowCompleted = (): GigyaRegistrationFlowCompletedAction => ({
    type: GIGYA_ACTIONS.registrationFlowCompleted,
});

export type GigyaActionsType =
    | SetGigyaStateAction
    | SetGigyaUidAction
    | SetGigyaUserAction
    | ClearGigyaUserAction
    | SetGigyaUserLeadsAction
    | GigyaRegistrationFlowCompletedAction;
