import { createLogic } from 'redux-logic';
import { APP_STATE_ACTIONS, CloseModalAction } from '../actions';
import { Store } from '../../types';

type DepObj = { getState: () => Store };
type CloseOverlayModalLogicDepObj = DepObj & { action: CloseModalAction };
const closeOverlayModalLogic = createLogic({
    type: APP_STATE_ACTIONS.closeModal,
    name: 'closeOverlayModalLogic',
    validate({ getState, action }: CloseOverlayModalLogicDepObj, allow, reject) {
        const modals = getState().appState.openModals;
        const hasOverlays = modals.some((modal) => modal.asOverlay);
        const isClosingOverlay = modals.findLast(
            (modal) => modal.type === action.modalType,
        )?.asOverlay;

        if (!hasOverlays || isClosingOverlay) {
            allow(action);
        } else {
            reject({ ...action, type: `🚫 (${action.type})` });
        }
    },
});

export default [closeOverlayModalLogic];
