import i18n from '@common/i18n';
import React, { FC } from 'react';
import { useAppSelector } from '../../../redux';
import * as SpecsTableStyles from '../../../styles/components/SpecsTable';

const OptionOverview: FC = () => {
    const options = useAppSelector((store) => store.lead.options);
    if (!options?.length) return null;

    return (
        <SpecsTableStyles.Group>
            <SpecsTableStyles.Title>{i18n('options')}</SpecsTableStyles.Title>
            {options.map((option) => (
                <SpecsTableStyles.ItemRow key={option.name}>
                    <SpecsTableStyles.ItemLabel>{option.name}</SpecsTableStyles.ItemLabel>
                    <SpecsTableStyles.ItemValue>{option.description}</SpecsTableStyles.ItemValue>
                </SpecsTableStyles.ItemRow>
            ))}
        </SpecsTableStyles.Group>
    );
};

export default OptionOverview;
