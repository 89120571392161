import i18n from '@common/i18n';
import React from 'react';
import * as TypographyStyles from '../../../styles/components/Typography';

type PropsType = {
    highlights: string;
    hideTitle?: boolean;
};

const Highlights = ({ highlights, hideTitle }: PropsType): JSX.Element | null => {
    if (!highlights) return null;
    return (
        <>
            {!hideTitle && (
                <TypographyStyles.H4 as="h3" mb={4}>
                    {i18n('highlights')}
                </TypographyStyles.H4>
            )}
            <TypographyStyles.Text dangerouslySetInnerHTML={{ __html: highlights }} />
        </>
    );
};

export default Highlights;
