import { QuotationEventDataTypes } from '../../../types/external/quotation';

export enum TOOL_QUOTATION_ATA {
    init = '🧰/quotation/ata/init',
    update = '🧰/quotation/ata/update',
    done = '🧰/quotation/ata/done',
}

export type InitQuotationToolAtaAction = { type: TOOL_QUOTATION_ATA.init };
export const initQuotationToolAta = (): InitQuotationToolAtaAction => ({
    type: TOOL_QUOTATION_ATA.init,
});

export type UpdateQuotationToolAtaAction = {
    type: TOOL_QUOTATION_ATA.update;
    data: QuotationEventDataTypes.Ata.Update;
};
export const updateQuotationToolAta = (
    data: QuotationEventDataTypes.Ata.Update,
): UpdateQuotationToolAtaAction => ({
    type: TOOL_QUOTATION_ATA.update,
    data,
});

export type DoneQuotationToolAtaAction = {
    type: TOOL_QUOTATION_ATA.done;
    data: QuotationEventDataTypes.Ata.Done;
};
export const doneQuotationToolAta = (
    data: QuotationEventDataTypes.Ata.Done,
): DoneQuotationToolAtaAction => ({ type: TOOL_QUOTATION_ATA.done, data });

export type QuotationAtaActionsType =
    | InitQuotationToolAtaAction
    | UpdateQuotationToolAtaAction
    | DoneQuotationToolAtaAction;
