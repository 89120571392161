/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import { DEVICE_TYPE, UK_INCENTIVE_TYPES } from '@common/types';
import { camelToSnake, transpose } from '@tsUtils';
import React from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { APP_STATUS, MODAL_TYPE } from '../../../../../constants';
import { useAppSelector } from '../../../../../redux';
import { openModal } from '../../../../../redux/actions';
import { getPriceTitle, getShowIncentivesRow } from '../../../../../redux/selectors';
import { color } from '../../../../../styles/common/Theme';
import * as ButtonStyles from '../../../../../styles/components/Button';
import * as EvsStyles from '../../../../../styles/components/Evs';
import * as TypographyStyles from '../../../../../styles/components/Typography';
import { EvsSolution } from '../../../../../types';
import Cell from '../Cell';
import IncentiveDaikinSolution from '../IncentiveDaikinSolution';

const getCheaperAfterLabel = (cheaperAfter: number | null): string | null => {
    if (cheaperAfter === null) return null;
    if (cheaperAfter === 1) return i18n('cheaper_after_1_year');
    return i18n('cheaper_after_n_years').replace('%d', String(cheaperAfter));
};

const EditEnergyPricesButton = (): JSX.Element => {
    const dispatch = useDispatch();
    return (
        <ButtonStyles.Link onClick={() => dispatch(openModal(MODAL_TYPE.energyPrices))}>
            <TypographyStyles.SmallText className="text-right">
                {i18n('edit_energy_prices')}
            </TypographyStyles.SmallText>
        </ButtonStyles.Link>
    );
};

type PropsType = {
    itemIndex: number;
    deviceType: DEVICE_TYPE;
    daikinSolution: EvsSolution;
    benchmarkSolutions: EvsSolution[];
    visibleItems: number;
};

const tooltipStyles = { maxWidth: '250px' };

const EvsAtwPriceCompare = ({
    itemIndex,
    deviceType,
    daikinSolution,
    benchmarkSolutions,
    visibleItems,
}: PropsType): JSX.Element => {
    const status = useAppSelector((store) => store.lead.status);
    const incentiveParams = useAppSelector(
        (store) => store.lead.incentives?.incentives?.[0]?.parameters,
    );
    const priceTitleRow = useAppSelector(getPriceTitle);
    const showIncentivesRow = useAppSelector(getShowIncentivesRow);
    const showIncentivesInput =
        incentiveParams?.type === UK_INCENTIVE_TYPES.hes ||
        incentiveParams?.type === UK_INCENTIVE_TYPES.nidirect;

    // const isMobile = deviceType === DEVICE_TYPE.mobile;
    const isDesktop = deviceType === DEVICE_TYPE.desktop;
    const textAlign = isDesktop ? 'right' : 'left';
    const showInlineHeaders = !isDesktop;

    const highlightColors = status === APP_STATUS.incentives_tool && daikinSolution.incentive;
    const cheaperAfterLabel = getCheaperAfterLabel(daikinSolution.cheaperAfter);

    const titleColumn = [
        <Cell key="name" isTitleColumn isFirstCell />,
        <Cell key="price" isBold textAlign="left" isTitleColumn>
            {i18n(camelToSnake(priceTitleRow))}
        </Cell>,
        <Cell key="running-cost" isBold textAlign="left" isTitleColumn>
            {i18n('running_cost')}
            <EditEnergyPricesButton />
        </Cell>,
        <Cell key="incentives" isBold textAlign="left" show={showIncentivesRow} isTitleColumn>
            <EvsStyles.TextGroup renderSideBySide={showIncentivesInput}>
                {i18n('incentives')}
                {showIncentivesInput && (
                    <ButtonStyles.Link
                        style={{ width: 'max-content' }}
                        href={incentiveParams?.link as string}
                        target="_blank"
                    >
                        &nbsp;
                        {i18n('more_info')}
                    </ButtonStyles.Link>
                )}
            </EvsStyles.TextGroup>
        </Cell>,
        <Cell key="total-lifecycle-cost" isBold textAlign="left" isTitleColumn>
            <div dangerouslySetInnerHTML={{ __html: i18n('total_lifecycle_cost') }} />
        </Cell>,
        <Cell key="cheaper-expensive" isTitleColumn isLastCell />,
    ];

    // Daikin solution
    const daikinColumn = [
        <Cell
            key="daikin-sol-name"
            isBold
            isDaikin
            isFirstCell
            textAlign="left"
            color={color.blue400}
        >
            {daikinSolution.name}
        </Cell>,
        <Cell key={`${camelToSnake(priceTitleRow)}`} isDaikin textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong>{i18n(camelToSnake(priceTitleRow))}</strong>
                </EvsStyles.InlineHeader>
            )}
            <div>{daikinSolution[priceTitleRow]}</div>
        </Cell>,
        <Cell key="daikin-sol-running-cost" isDaikin textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong>{i18n('running_cost')}</strong>
                    <EditEnergyPricesButton />
                </EvsStyles.InlineHeader>
            )}
            <EvsStyles.TextGroup renderSideBySide={isDesktop}>
                <TypographyStyles.SmallText className="text-left">
                    {daikinSolution.runningCostPerYear}/{i18n('year')}
                </TypographyStyles.SmallText>
                <div className="text-right">{daikinSolution.totalRunningCost}</div>
            </EvsStyles.TextGroup>
        </Cell>,
        <Cell
            key="daikin-sol-incentives"
            show={showIncentivesRow}
            bgColor={highlightColors ? color.green100 : ''}
            color={highlightColors ? color.green300 : ''}
            textAlign={textAlign}
            isDaikin
        >
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong>{i18n('incentives')}</strong>
                </EvsStyles.InlineHeader>
            )}
            <IncentiveDaikinSolution
                status={status}
                daikinSolution={daikinSolution}
                showIncentivesInput={showIncentivesInput}
            />
        </Cell>,
        <Cell key="daikin-sol-total-lifecycle-cost" isDaikin isBold textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong dangerouslySetInnerHTML={{ __html: i18n('total_lifecycle_cost') }} />
                </EvsStyles.InlineHeader>
            )}
            {daikinSolution.totalLifecycleCost}
        </Cell>,
        <Cell
            key="daikin-sol-cheaper-after"
            bgColor={color.blue100}
            isDaikin
            isLastCell
            color={color.blue400}
            textAlign={isDesktop ? 'center' : 'left'}
        >
            {cheaperAfterLabel && (
                <EvsStyles.TextGroup renderSideBySide>
                    <Icons.Glyph.ThumbsUp size={40} />
                    <div dangerouslySetInnerHTML={{ __html: cheaperAfterLabel }} />
                </EvsStyles.TextGroup>
            )}
        </Cell>,
    ];

    // Benchmark solutions
    const benchmarkColumns = benchmarkSolutions.map((benchmarkSolution, i) => {
        return [
            <Cell
                key={`name-${benchmarkSolution.name.replaceAll(/ /g, '-')}`}
                isBold
                textAlign="left"
            >
                <EvsStyles.TableHeader>
                    <div>{benchmarkSolution.name}</div>
                    <p data-tip="" data-for={`tooltip_${i}`}>
                        <Icons.Glyph.InfoCircle />
                    </p>
                    <ReactTooltip id={`tooltip_${i}`} place="top" effect="solid" clickable>
                        <div
                            style={tooltipStyles}
                            dangerouslySetInnerHTML={{ __html: benchmarkSolution.highlights }}
                        />
                    </ReactTooltip>
                </EvsStyles.TableHeader>
            </Cell>,
            <Cell
                key={`${camelToSnake(priceTitleRow)}-${benchmarkSolution.name.replaceAll(
                    / /g,
                    '-',
                )}`}
                textAlign={textAlign}
            >
                {showInlineHeaders && (
                    <EvsStyles.InlineHeader>
                        <strong>{i18n(camelToSnake(priceTitleRow))}</strong>
                    </EvsStyles.InlineHeader>
                )}
                <div>{benchmarkSolution[priceTitleRow]}</div>
            </Cell>,
            <Cell
                key={`running-cost-${benchmarkSolution.name.replaceAll(/ /g, '-')}`}
                isBold
                bgColor={benchmarkSolution.isBenchmarkRunningCostHigher ? color.red100 : ''}
                textAlign={textAlign}
            >
                {showInlineHeaders && (
                    <div
                        style={{
                            color:
                                !isDesktop && benchmarkSolution.isBenchmarkRunningCostHigher
                                    ? color.red300
                                    : '',
                        }}
                    >
                        <strong>{i18n('running_cost')}</strong>
                        <EditEnergyPricesButton />
                    </div>
                )}
                <EvsStyles.TextGroup
                    renderSideBySide={isDesktop}
                    textColor={benchmarkSolution.isBenchmarkRunningCostHigher ? color.red300 : ''}
                >
                    <TypographyStyles.SmallText className="text-left">
                        {benchmarkSolution.runningCostPerYear}/{i18n('year')}
                    </TypographyStyles.SmallText>
                    <div className="text-right">{benchmarkSolution.totalRunningCost}</div>
                </EvsStyles.TextGroup>
            </Cell>,
            <Cell
                key={`incentives-${benchmarkSolution.name.replaceAll(/ /g, '-')}`}
                show={showIncentivesRow}
                color={color.grey400}
                textAlign={textAlign}
            >
                {showInlineHeaders && (
                    <EvsStyles.InlineHeader textColor="black">
                        <strong>{i18n('incentives')}</strong>
                    </EvsStyles.InlineHeader>
                )}
                <div>{i18n('no_incentives')}</div>
            </Cell>,
            <Cell
                key={`total-lifecycle-cost-${benchmarkSolution.name.replaceAll(/ /g, '-')}`}
                isBold
                textAlign={textAlign}
            >
                {showInlineHeaders && (
                    <EvsStyles.InlineHeader>
                        <strong>
                            <div
                                dangerouslySetInnerHTML={{ __html: i18n('total_lifecycle_cost') }}
                            />
                        </strong>
                    </EvsStyles.InlineHeader>
                )}
                {benchmarkSolution.totalLifecycleCost}
            </Cell>,
            <Cell
                key={`percentage-more-expensive-${benchmarkSolution.name.replaceAll(/ /g, '-')}`}
                color={color.grey400}
                textAlign="center"
            >
                <EvsStyles.TextGroup renderSideBySide>
                    <Icons.Glyph.ThumbsDown size={40} />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: i18n('more_expensive').replace(
                                '%d',
                                String(benchmarkSolution.percentageMoreExpensive),
                            ),
                        }}
                    />
                </EvsStyles.TextGroup>
            </Cell>,
        ];
    });

    const emptyPlaceholderColumn = [
        <Cell key="name-placeholder" placeholder />,
        <Cell key="price-placeholder" placeholder />,
        <Cell key="running-cost-placeholder" placeholder />,
        <Cell key="incentives-placeholder" placeholder show={showIncentivesRow} />,
        <Cell key="total-lifecycle-cos-placeholder" placeholder />,
        <Cell key="percentage-more-expensive-placeholder" placeholder />,
    ];

    if (deviceType === DEVICE_TYPE.desktop && benchmarkSolutions.length % 2 !== 0) {
        benchmarkColumns.splice(benchmarkColumns.length, 0, [...emptyPlaceholderColumn]);
    }

    const getVisibleSolutions = (): JSX.Element[][] => {
        switch (deviceType) {
            case DEVICE_TYPE.mobile:
                return [[daikinColumn, ...benchmarkColumns][itemIndex]];
            case DEVICE_TYPE.tablet:
                return [
                    daikinColumn,
                    ...benchmarkColumns.slice(itemIndex, itemIndex + visibleItems),
                ];
            case DEVICE_TYPE.desktop:
                return [
                    titleColumn,
                    daikinColumn,
                    ...benchmarkColumns.slice(itemIndex, itemIndex + visibleItems),
                ];
            default:
                return [];
        }
    };

    const transposedArray: JSX.Element[][] = transpose(getVisibleSolutions());

    return (
        <EvsStyles.Table>
            <tbody>
                {transposedArray.map((cellArr, rowIndex) => (
                    <tr key={`row-${rowIndex}`}>{cellArr}</tr>
                ))}
            </tbody>
        </EvsStyles.Table>
    );
};

export default EvsAtwPriceCompare;
