import i18n from '@common/i18n';
import { Dimensions } from '@common/types';
import React, { FC } from 'react';
import * as SpecsTableStyles from '../../../styles/components/SpecsTable';

type UnitPropsType = {
    title: string;
    productNames: string[];
    dimensions: Dimensions | undefined;
    children?: React.ReactNode;
};
const Unit: FC<UnitPropsType> = ({ title, productNames, dimensions, children }) => {
    // Don't render if there are no productNames
    if (!productNames.length) return null;
    if (!dimensions) return null;
    // Generate dimension strings
    const { height, width, depth } = dimensions;
    return (
        <SpecsTableStyles.Group>
            <SpecsTableStyles.Title>{i18n(title)}</SpecsTableStyles.Title>
            <SpecsTableStyles.ItemRow>
                <SpecsTableStyles.ItemLabel>{i18n('model')}</SpecsTableStyles.ItemLabel>
                <SpecsTableStyles.ItemValue>{productNames.join(' + ')}</SpecsTableStyles.ItemValue>
            </SpecsTableStyles.ItemRow>
            <SpecsTableStyles.ItemRow>
                <SpecsTableStyles.ItemLabel>
                    {i18n('dimensions')}
                    <SpecsTableStyles.ItemNote isInline>
                        {i18n('dimensions_format')}
                    </SpecsTableStyles.ItemNote>
                </SpecsTableStyles.ItemLabel>
                <SpecsTableStyles.ItemValue>
                    {`(${[height, width, depth].join('x')})mm`}
                </SpecsTableStyles.ItemValue>
            </SpecsTableStyles.ItemRow>
            {children}
        </SpecsTableStyles.Group>
    );
};

export default Unit;
