import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SCRIPT_STATE } from '../../constants';
import { useAppSelector } from '../../redux';
import { initQuotationToolAtw } from '../../redux/actions/tools/quotationActions.atw';
import ToolWrapper from '../step2/ToolWrapper';

const QuotationToolAtw = (): JSX.Element => {
    // Attempt to initialize quotation tool
    const dispatch = useDispatch();
    const aaltraScriptState = useAppSelector((state) => state.appState.aaltraScriptState);

    useEffect(() => {
        if (aaltraScriptState === SCRIPT_STATE.injected) dispatch(initQuotationToolAtw());
    }, [dispatch, aaltraScriptState]);

    return <ToolWrapper id="external-tool-quotation-atw" />;
};

export default QuotationToolAtw;
