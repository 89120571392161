/* eslint-disable react/no-danger */
import i18n from '@common/i18n';
import React from 'react';
import { EVS_OVERVIEW_TAB } from '../../../../../constants';
import { useAppSelector } from '../../../../../redux';
import { EvsAtaHeatingData, EvsOverviewTab } from '../../../../../types';
import CalculationDisclaimerLink from '../CalculationDisclaimerLink';

type DisclaimersProps = { activeTab: EvsOverviewTab };
const Disclaimers = ({ activeTab }: DisclaimersProps): JSX.Element | null => {
    const savingsPercentage = useAppSelector(
        (store) => store.lead.toolData?.sizingWinterHeating?.['savings-percentage'],
    );
    const calcDisclaimer = useAppSelector(
        (store) => (store.appState.evsData as EvsAtaHeatingData).priceData.calculationDisclaimerUrl,
    );

    switch (activeTab) {
        case EVS_OVERVIEW_TAB.price: {
            const firstDisclaimer = i18n('evs_air_to_air_cost_assumption_disclaimer').split(
                '{calculation_disclaimer_url}',
            );
            const disclaimers = [
                i18n('evs_air_to_air_running_cost_savings_disclaimer').replace(
                    '%d',
                    savingsPercentage ? savingsPercentage.toFixed(0) : '0',
                ),
                i18n('evs_air_to_air_cost_space_heating_disclaimer'),
            ];
            return (
                <ol>
                    <li key={`disclaimer_${0}`}>
                        <div>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: firstDisclaimer[0],
                                }}
                            />
                            <CalculationDisclaimerLink
                                label={i18n('calculation_disclaimer_url_label')}
                                disclaimerModalTitle={i18n('calculation_disclaimer_label')}
                                disclaimer={calcDisclaimer}
                            />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: firstDisclaimer[1],
                                }}
                            />
                        </div>
                    </li>
                    {disclaimers.map((disclaimer, i) => {
                        return (
                            <li key={`disclaimer_${++i}`}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: disclaimer,
                                    }}
                                />
                            </li>
                        );
                    })}
                </ol>
            );
        }
        case EVS_OVERVIEW_TAB.co2: {
            const co2Disclaimer = i18n('evs_air_to_air_co2_disclaimer').split(
                '{calculation_disclaimer_url}',
            );
            return (
                <div>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: co2Disclaimer[0],
                        }}
                    />
                    <CalculationDisclaimerLink
                        label={i18n('calculation_disclaimer_url_label')}
                        disclaimerModalTitle={i18n('calculation_disclaimer_label')}
                        disclaimer={calcDisclaimer}
                    />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: co2Disclaimer[1],
                        }}
                    />
                </div>
            );
        }
        default:
            return null;
    }
};

export default Disclaimers;
