import { LayoutProps } from 'styled-system';
import styled, { css } from 'styled-components';
import { breakpoint } from '@common/components/Breakpoints';
import { color, space, zIndex } from '../common/Theme';
import * as ButtonStyles from './Button';
import * as FormStyles from './Form';

type ModalWrapperProps = LayoutProps & {
    isActive?: boolean;
    maxWidth?: string;
};

export const ModalWrapper = styled.div<ModalWrapperProps>`
    position: fixed;
    display: block;
    inset: 0;
    z-index: ${(props) => props.theme.zIndex.modal};

    ${({ maxWidth }) =>
        maxWidth &&
        css`
            max-width: calc(100% - ${space[6]}px);

            @media ${breakpoint.up('md')} {
                margin: 0 auto;
            }

            @media ${breakpoint.up('lg')} {
                max-width: unset;
            }
        `}
`;

export const ModalBackdrop = styled.div`
    position: fixed;
    inset: 0;
    background: ${color.grey800};
    opacity: 0.7;
    z-index: ${(props) => props.theme.zIndex.ModalBackdrop};
`;

export const ModalDialog = styled.div`
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    margin-top: ${space[4]}px;
    min-height: calc(100vh - ${space[6]}px);
`;

export const ModalHeader = styled.div`
    margin: 0 -${space[5]}px ${space[5]}px;
    padding: 0 ${space[5]}px;
    border-bottom: 1px solid ${color.grey300};
`;

type ModalContentProps = {
    isWide?: boolean;
    width?: string;
};

export const ModalContent = styled.div<ModalContentProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 ${space[4]}px;
    background-color: ${color.grey000};
    border-radius: ${space[1]}px;
    z-index: ${zIndex.modal};

    @media ${breakpoint.up('md')} {
        margin: 0 auto;
        ${({ isWide }) =>
            isWide
                ? css`
                      max-width: 960px;
                  `
                : css`
                      max-width: 686px;
                  `}
    }
    @media ${breakpoint.down('md')} {
        ${({ width }) =>
            width &&
            css`
                width: ${width};
            `}
    }
`;

export const ModalText = styled.div`
    padding-right: 24px;
`;

export const ModalCloseBtn = styled(ButtonStyles.Action)`
    position: absolute;
    top: ${space[5]}px;
    right: ${space[5]}px;
    z-index: 1150;

    svg {
        color: ${color.blue300};
    }
`;

export const ModalScrollArea = styled.div`
    position: relative;
    padding: ${space[5]}px;
    max-height: calc(100vh - ${space[6]}px);
    overflow-y: auto;
`;

export const ModalFooter = styled.div`
    margin: 0 -${space[5]}px -${space[5]}px;
    padding: ${space[5]}px;
    background-color: ${color.grey100};
    border-top: 1px solid ${color.grey200};
    border-radius: 0 0 ${space[1]}px ${space[1]}px;
`;

export const ModalFooterButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: ${space[2]}px;
`;

export const ModalForm = styled.div`
    ${FormStyles.Input} {
        &::placeholder {
            color: ${color.grey800} !important;
        }
    }
`;
