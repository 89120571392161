/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import styled, { css } from 'styled-components';
import {
    space as spacing,
    textAlign,
    LayoutProps,
    SpaceProps,
    TextAlignProps,
} from 'styled-system';
import { color, fontFamily, fontWeight, space } from '../common/Theme';

type CardProps = SpaceProps & {
    isHighlighted?: boolean;
};

export const Default = styled.div<CardProps>`
    display: block;
    width: 100%;
    padding: ${space[5]}px;
    font-family: ${fontFamily.base};
    background-color: ${color.grey000};
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    border: none;

    ${spacing};

    ${({ isHighlighted }) =>
        isHighlighted &&
        css`
            position: relative;
            border: 2px solid ${color.blue300};
        `};
`;

export const Box = styled.div<LayoutProps | SpaceProps>`
    padding: ${space[4]}px;
    background-color: ${color.grey100};
    ${spacing};
`;

type HeaderProps = LayoutProps &
    TextAlignProps &
    SpaceProps & {
        hasBorder?: boolean;
    };
export const Header = styled.header<HeaderProps>`
    text-align: center;

    ${spacing};
    ${textAlign};

    ${({ hasBorder }) =>
        hasBorder &&
        css`
            border-bottom: 1px solid ${color.grey200};
        `};
`;

export const Body = styled.div<LayoutProps | SpaceProps>`
    ${spacing};
`;

export const Title = styled.h3<LayoutProps & SpaceProps>`
    margin: 0 0 ${space[1]}px;
    font-size: 20px;
    font-weight: ${fontWeight.regular};
    line-height: 26px;
    color: ${color.blue300};
    ${spacing};
`;

export const Flexible = styled.div`
    flex-grow: 1;
`;

export const Description = styled.p<LayoutProps>`
    margin-bottom: ${space[5]}px;
    font-size: 16px;
    line-height: 24px;
    color: ${color.grey500};
`;

export const Footer = styled.footer``;
