import { indexArray } from '@tsUtils';
import { v4 as uuidv4 } from 'uuid';
import { Room } from '../../types';
import {
    LEAD_ACTIONS,
    LeadActionsType,
    ROOM_BUILDER_ACTIONS,
    RoomBuilderActionsType,
} from '../actions';

const createRoom = (): Room => ({
    id: uuidv4(),
    position: null,
    solution: null,
    name: null,
    surface: null,
    height: 250,
    windows: 1.5,
    shading: 'no-shading',
    orientation: 'north',
    colorVariation: null,
});

const initialRoomBuilder: Array<Room> = [];

const roomBuilder = (
    state: Array<Room>,
    action: LeadActionsType | RoomBuilderActionsType,
): Array<Room> => {
    switch (action.type) {
        case LEAD_ACTIONS.setSftAirToAir: {
            const { numberOfRooms } = action.choices;
            if (typeof numberOfRooms === 'number') {
                return [...(state || []), ...indexArray(numberOfRooms).map(createRoom)].slice(
                    0,
                    numberOfRooms,
                );
            } else {
                return state;
            }
        }
        case ROOM_BUILDER_ACTIONS.initRoomBuilder: {
            return action.rooms;
        }
        case ROOM_BUILDER_ACTIONS.addRoom: {
            if (action.roomsToAdd) {
                return indexArray(action.roomsToAdd).map(createRoom);
            } else {
                return [...state, createRoom()];
            }
        }
        case ROOM_BUILDER_ACTIONS.deleteRoom:
            return state.filter((r) => r.id !== action.roomId);
        case ROOM_BUILDER_ACTIONS.updateRoom:
            return state.map((room) => {
                return action.roomId === room.id ? { ...room, ...action.updates } : room;
            });
        case ROOM_BUILDER_ACTIONS.assignSolutionToRooms:
            return state.map((room) => {
                // Assign solution to selected rooms
                if (action.roomIds.includes(room.id)) {
                    return {
                        ...room,
                        solution: action.solution,
                        colorVariation: action.colorVariation || null,
                    };
                }
                // Room is not selected
                // unassign solution if solutionId (and, if available, colorVariation) match
                if (
                    room.solution === action.solution &&
                    (!action.colorVariation || room?.colorVariation === action.colorVariation)
                ) {
                    return { ...room, solution: null, colorVariation: null };
                }
                // Room is unaffected
                return room;
            });
        default:
            return state || initialRoomBuilder;
    }
};

export default roomBuilder;
