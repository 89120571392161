import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import { indexArray } from '@tsUtils';
import React from 'react';
import { EvsModalNavigation } from '../../../../hooks/evsHooks';
import { color } from '../../../../styles/common/Theme';
import * as Styles from '../../../../styles/components/Carousel';
import * as EvsStyles from '../../../../styles/components/Evs';

type PropsType = {
    navigation: EvsModalNavigation;
};
const Navigation = ({ navigation }: PropsType): JSX.Element => {
    const {
        totalSolutions,
        totalNavigationItems,
        navigationIndex,
        isRightChevronDisabled,
        isLeftChevronDisabled,
        navigate,
    } = navigation;

    return (
        <EvsStyles.Navigation>
            <div>{`${totalSolutions} ${i18n('solutions')}`}</div>
            <div>
                <div>
                    {indexArray(totalNavigationItems).map((i) => {
                        return (
                            <Styles.SliderControl
                                key={i}
                                type="button"
                                width={46}
                                cursor="default"
                                isActive={i === navigationIndex}
                            >
                                &nbsp;
                            </Styles.SliderControl>
                        );
                    })}
                </div>
                <div>
                    <EvsStyles.Chevron
                        onClick={() => {
                            navigate(navigationIndex - 1);
                        }}
                        bgColor={isLeftChevronDisabled ? color.grey300 : color.blue300}
                        disabled={isLeftChevronDisabled}
                    >
                        <Icons.Glyph.ChevronLeft />
                    </EvsStyles.Chevron>
                    <EvsStyles.Chevron
                        onClick={() => {
                            navigate(navigationIndex + 1);
                        }}
                        bgColor={isRightChevronDisabled ? color.grey300 : color.blue300}
                        disabled={isRightChevronDisabled}
                    >
                        <Icons.Glyph.ChevronRight />
                    </EvsStyles.Chevron>
                </div>
            </div>
        </EvsStyles.Navigation>
    );
};

export default Navigation;
