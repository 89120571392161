import { DEALER_SCHED_FACTOR, DETACHED_SCREEN_TYPE } from '../../constants';
import { AppState } from '../../types';
import {
    ActionsType,
    APP_STATE_ACTIONS,
    GIGYA_ACTIONS,
    INCENTIVES_DRAPO,
    LEAD_ACTIONS,
    ROOM_BUILDER_ACTIONS,
} from '../actions';
import { calculateTimeRanges } from '../selectors';

const initialState: AppState = {
    globalError: null,
    isNavigating: false,
    isLoading: true,
    isSaving: false,
    lastSaved: Date.now(),
    gigyaState: '',
    gigyaUid: null,
    gigyaUser: null,
    gigyaUserLeads: [],
    gigyaUserLeadsError: null,
    openModals: [],
    openDetachedScreens: [],
    compareLeads: [],
    isFetchingDealers: false,
    dealers: [],
    dealersError: null,
    customerDetailsErrors: {},
    commerceError: null,
    timeRanges: {
        [DEALER_SCHED_FACTOR.less_than_1_month]: [],
        [DEALER_SCHED_FACTOR.between_1_or_3_months]: [],
        [DEALER_SCHED_FACTOR.more_than_3_months]: [],
    },
    citySuggestions: {},
    submitPressedTime: null,
    registrationCompleteTime: null,
    swapCooling: false,
    evsData: null,
    skipToSelectDealerError: null,
    openEvsCompareAutomatic: null,
    epackages: [],
    energyPricesErrors: null,
    aaltraScriptState: null,
    roomBuilderErrors: null,
    energyLabelsError: null,
};

const appState = (state: AppState, action: ActionsType): AppState => {
    switch (action.type) {
        case APP_STATE_ACTIONS.navigationRequest:
            // Set isNavigating when not navigating within tool
            return {
                ...state,
                isNavigating: !action.page.split('.')[1],
            };
        case APP_STATE_ACTIONS.setGlobalError:
            return {
                ...state,
                globalError: action.errorMessage,
                isLoading: false,
                isSaving: false,
            };
        case APP_STATE_ACTIONS.openModal: {
            const { modalType: type, metaData, asOverlay } = action;
            return {
                ...state,
                openModals: [...state.openModals, { type, metaData, asOverlay }],
            };
        }
        case APP_STATE_ACTIONS.closeModal: {
            const { modalType: type } = action;
            const lastIndex = state.openModals.findLastIndex((modal) => modal.type === type);
            // Make a copy because splice is destructive on the active state
            const openModals = [...state.openModals];
            openModals.splice(lastIndex, 1);
            return {
                ...state,
                openModals,
                openEvsCompareAutomatic: false,
                energyPricesErrors: null,
            };
        }
        case APP_STATE_ACTIONS.openDetachedScreen: {
            const { screenType: type, metaData } = action;
            return {
                ...state,
                openDetachedScreens: [...state.openDetachedScreens, { type, metaData }],
            };
        }
        case APP_STATE_ACTIONS.setSkipToSelectDealerError:
            return {
                ...state,
                skipToSelectDealerError: action.skipToSelectDealerError,
            };
        case APP_STATE_ACTIONS.closeDetachedScreen:
            return {
                ...state,
                openDetachedScreens: state.openDetachedScreens.filter(
                    (modal) => modal.type !== action.screenType,
                ),
            };
        case APP_STATE_ACTIONS.setCompareLeads:
            return {
                ...state,
                compareLeads: action.leads,
            };
        case APP_STATE_ACTIONS.fetchDealers:
            return {
                ...state,
                isFetchingDealers: true,
                dealersError: null,
            };
        case APP_STATE_ACTIONS.setDealers:
            return {
                ...state,
                isFetchingDealers: false,
                dealers: action.dealers,
                timeRanges: calculateTimeRanges(action.dealers),
                dealersError: action.errorMessage,
            };
        case APP_STATE_ACTIONS.setAaltraScriptState:
            return { ...state, aaltraScriptState: action.aaltraScriptState };
        case APP_STATE_ACTIONS.setRoomBuilderErrors:
            return { ...state, roomBuilderErrors: action.roomBuilderErrors };
        case ROOM_BUILDER_ACTIONS.confirmRooms:
            return { ...state, roomBuilderErrors: null };
        case APP_STATE_ACTIONS.setEnergyLabelsError:
            return { ...state, energyLabelsError: action.energyLabelsError };
        case GIGYA_ACTIONS.setGigyaState:
            return {
                ...state,
                gigyaState: action.gigyaState,
            };
        case GIGYA_ACTIONS.setGigyaUid:
            return {
                ...state,
                gigyaUid: action.gigyaUid,
            };
        case GIGYA_ACTIONS.setGigyaUser:
            return {
                ...state,
                gigyaUser: action.gigyaUser,
            };
        case GIGYA_ACTIONS.clearGigyaUser:
            return {
                ...state,
                gigyaUid: null,
                gigyaUser: null,
                gigyaUserLeads: [],
                gigyaUserLeadsError: null,
            };
        case GIGYA_ACTIONS.setGigyaUserLeads:
            return {
                ...state,
                gigyaUserLeads: action.gigyaUserLeads,
                gigyaUserLeadsError: action.errorMessage,
            };
        case APP_STATE_ACTIONS.setCommerceError:
            return {
                ...state,
                commerceError: action.commerceError,
            };
        case APP_STATE_ACTIONS.fetchCities:
            return {
                ...state,
                customerDetailsErrors: { ...state.customerDetailsErrors, postalCode: '', city: '' },
                citySuggestions: { ...state.citySuggestions, [action.postal]: null },
            };
        case APP_STATE_ACTIONS.suggestCities:
            return {
                ...state,
                citySuggestions: { [action.postal]: action.cities },
            };
        case APP_STATE_ACTIONS.setCustomerDetailsErrors:
            return {
                ...state,
                customerDetailsErrors: action.customerDetailsErrors,
            };
        case APP_STATE_ACTIONS.setSelSoftResults:
            return {
                ...state,
                selsoftResults: action.solutions,
            };
        case APP_STATE_ACTIONS.setSwapCooling:
            return {
                ...state,
                swapCooling: action.swapCooling,
            };
        case APP_STATE_ACTIONS.setEpackages:
            return {
                ...state,
                epackages: action.epackages,
            };
        case APP_STATE_ACTIONS.setEvsData:
            return {
                ...state,
                evsData: action.evsData,
            };
        case APP_STATE_ACTIONS.openEvsCompareAutomatic:
            return {
                ...state,
                openEvsCompareAutomatic: true,
            };
        /* Side-effect action-handling */
        case LEAD_ACTIONS.chooseAltherma:
            return {
                ...state,
                isNavigating: true,
                openDetachedScreens: state.openDetachedScreens.filter((screen) => {
                    return screen.type !== DETACHED_SCREEN_TYPE.althermaChoice;
                }),
            };
        case LEAD_ACTIONS.toggleAltherma:
        case LEAD_ACTIONS.updatePhasing:
        case LEAD_ACTIONS.setLeadRemote:
            return {
                ...state,
                isSaving: true,
                lastSaved: Date.now(),
            };
        case LEAD_ACTIONS.setLeadLocal:
            return {
                ...state,
                isLoading: false, // this will only update the very first time setLeadLocal is called
                isSaving: false,
                isNavigating:
                    action.logicOrigin !== 'althermaChoiceLogic' ? false : state.isNavigating,
                skipToSelectDealerError: null,
                // Close loggedOut screen if it was open
                openDetachedScreens: state.openDetachedScreens.filter((screen) => {
                    return screen.type !== DETACHED_SCREEN_TYPE.loggedOut;
                }),
            };
        case LEAD_ACTIONS.fetchEnergyLabels:
            return { ...state, energyLabelsError: null };
        case INCENTIVES_DRAPO.setAmountOfPeople:
            return { ...state, isDrapoLoading: true };
        case INCENTIVES_DRAPO.setIncomeRanges:
            return { ...state, isDrapoLoading: false };
        case INCENTIVES_DRAPO.setDrapoToolState:
            return {
                ...state,
                isDrapoLoading: action.isDrapoLoading ?? state.isDrapoLoading,
                isDrapoAvailable: action.isDrapoAvailable ?? state.isDrapoAvailable,
            };
        case APP_STATE_ACTIONS.submitPressed:
            return { ...state, submitPressedTime: Date.now() };
        case GIGYA_ACTIONS.registrationFlowCompleted:
            return { ...state, registrationCompleteTime: Date.now() };
        case APP_STATE_ACTIONS.setEnergyPricesErrors:
            return {
                ...state,
                energyPricesErrors: action.energyPricesErrors || null,
            };
        default:
            return { ...initialState, ...state };
    }
};

export default appState;
