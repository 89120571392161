import styled, { css } from 'styled-components';
import { space as spacing, SpaceProps } from 'styled-system';
import { breakpoint } from '@common/components/Breakpoints';
import { color, fontFamily, fontWeight, space } from '../common/Theme';

export const Title = styled.h4`
    margin-bottom: ${space[5]}px;
    font-size: 18px;
    font-weight: ${fontWeight.semibold};
    line-height: 26px;
    text-align: center;
    color: ${color.grey800};
`;

type GroupProps = {
    isCentered?: boolean;
};
export const Group = styled.div<GroupProps & SpaceProps>`
    margin-bottom: ${space[5]}px;
    ${spacing};

    ${({ isCentered }) =>
        isCentered &&
        css`
            text-align: center;
        `};
`;

type LabelProps = {
    isBold?: boolean;
    isSrOnly?: boolean;
};
export const Label = styled.label<LabelProps & SpaceProps>`
    display: block;
    margin-bottom: ${space[2]}px;
    font-size: 16px;
    font-weight: ${fontWeight.semibold};
    line-height: 20px;
    color: ${color.grey800};
    ${spacing};

    ${({ isBold }) =>
        isBold &&
        css`
            font-weight: ${fontWeight.semibold};
        `};

    ${({ isSrOnly }) =>
        isSrOnly &&
        css`
            font-weight: ${fontWeight.semibold};
        `};
`;

export const Error = styled.label`
    display: block;
    margin-bottom: ${space[4]}px;
    font-size: 16px;
    font-weight: ${fontWeight.regular};
    line-height: 20px;
    color: ${color.red400};

    svg {
        float: left;
    }
`;

type InputProps = {
    hasError?: boolean;
    hideNumberArrows?: boolean;
    isSmall?: boolean;
    isTextBefore?: boolean;
};
export const Input = styled.input<InputProps>`
    display: block;
    width: 100%;
    height: 50px;
    padding: 11px 0 15px 15px;
    font-family: ${fontFamily.base};
    font-size: 16px;
    line-height: 24px;
    color: ${color.grey900};
    border: 1px solid ${color.grey400};
    outline: none;

    &::placeholder {
        color: ${color.grey500};
    }

    &:focus {
        border-color: ${color.blue300};
    }

    &:disabled,
    &[readonly] {
        background-color: ${color.grey100};
        border-color: ${color.grey300};
        opacity: 1;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: auto;
        margin-right: 10px;
    }

    &:focus::placeholder {
        color: transparent;
    }

    ${({ hasError }) =>
        hasError &&
        css`
            border-color: ${color.red400};
            background-color: ${color.red100};
        `};

    ${({ hideNumberArrows }) =>
        hideNumberArrows &&
        css`
            &[type='number'] {
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    margin: 0;
                    -webkit-appearance: none;
                    -moz-appearance: textfield;
                }
            }
        `};

    ${({ isSmall }) =>
        isSmall &&
        css`
            height: auto;
            padding: 0px 10px;
            border-radius: 4px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        `};

    ${({ isTextBefore }) =>
        isTextBefore &&
        css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        `};
`;

export const TextArea = styled(Input).attrs((_props) => ({
    as: 'textarea',
}))`
    height: 80px;
    resize: vertical;
`;
type InputGroupProps = {
    isTextBefore?: boolean;
};
export const InputGroup = styled.div<InputGroupProps>`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    ${Input} {
        position: relative; /* For focus states z-index */
        flex: 1 1 auto;
        /* Add width 1% and flex-basis auto to ensure that button will not wrap out */
        /* the column. Applies to IE Edge+ and Firefox. Chrome does not require this. */
        width: 1%;
        margin-bottom: 0;
    }
    ${({ isTextBefore }) =>
        isTextBefore &&
        css`
            flex-direction: row-reverse;
        `}
`;

type InpuTextProps = {
    isSmall?: boolean;
    isTextBefore?: boolean;
};
export const InputText = styled.div<InpuTextProps>`
    margin-left: -1px;
    padding: 11px ${space[4]}px 13px;
    font-size: 16px;
    font-weight: ${fontWeight.semibold};
    line-height: 24px;
    color: ${color.grey500};
    background-color: ${color.grey100};
    border: 1px solid ${color.grey300};
    z-index: 2;

    ${({ isSmall }) =>
        isSmall &&
        css`
            padding: 5px 10px;
            border-radius: 4px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        `};

    ${({ isTextBefore }) =>
        isTextBefore &&
        css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        `};
`;

export const InputTextAlt = styled(InputText)`
    margin-left: unset;
    padding: unset;
    line-height: unset;
    border: none;
    background-color: transparent;
    color: ${color.grey400};
    font-weight: unset;
`;

export const Help = styled.div`
    margin-top: ${space[2]}px;
    font-size: 13px;
    line-height: 20px;
    color: ${color.grey600};
`;

export const Append = styled.div`
    display: flex;
`;

export const AppendAlt = styled(Append)`
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
`;

export const CustomInputGroup = styled.div`
    position: relative;
    padding-left: 35px;

    ${Label} {
        font-weight: ${fontWeight.regular};
    }
`;

export const RadioGroup = styled(CustomInputGroup)``;

export const Radio = styled.input.attrs(() => ({
    type: 'radio',
}))`
    position: absolute;
    opacity: 0;
    z-index: -1;

    & ~ ${Label} {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: ${space[4]}px;
            height: ${space[4]}px;
            background-color: ${color.grey000};
            border: 1px solid ${color.grey600};
            border-radius: 50%;
            box-sizing: border-box;
        }
    }

    &:checked ~ ${Label} {
        &::before {
            background-color: ${color.blue300};
            border-color: ${color.blue300};
        }

        &::after {
            position: absolute;
            top: ${space[1]}px;
            left: ${space[1]}px;
            content: '';
            width: ${space[2]}px;
            height: ${space[2]}px;
            background-color: ${color.grey000};
            border-radius: 50%;
        }
    }

    &:disabled ~ ${Label} {
        color: ${color.grey500};

        &::before {
            background-color: ${color.grey100};
            border-color: ${color.grey300};
        }
    }
`;

export const CheckboxGroup = styled(CustomInputGroup)``;

export const Checkbox = styled.input.attrs(() => ({
    type: 'checkbox',
}))`
    position: absolute;
    opacity: 0;
    z-index: -1;

    & ~ ${Label} {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: ${space[4]}px;
            height: ${space[4]}px;
            background-color: ${color.grey000};
            border: 1px solid ${color.grey600};
            box-sizing: border-box;
        }
    }

    &:checked ~ ${Label} {
        &::before {
            background-color: ${color.blue300};
            border-color: ${color.blue300};
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: ${space[4]}px;
            height: ${space[4]}px;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjU0NCA2IDkuMDQgMTEuMDQ3bC0yLjY3NS0yLjYyTDUgOS45NTQgOS4xMyAxNCAxNSA3LjQyNnoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
            background-repeat: no-repeat;
            background-position: 0 0;
        }
    }

    &:disabled ~ ${Label} {
        color: ${color.grey500};

        &::before {
            background-color: ${color.grey100};
            border-color: ${color.grey300};
        }
    }
`;

export const FlexWrapper = styled.div<SpaceProps>`
    ${spacing};

    @media ${breakpoint.up('md')} {
        display: flex;
        flex-wrap: wrap;
    }

    ${RadioGroup} {
        margin-right: ${space[5]}px;
    }
`;

// @Davy - This Select is basically just a copy-paste of Input ^
type SelectProps = {
    hasError?: boolean;
};
export const Select = styled.select<SelectProps>`
    display: block;
    width: 100%;
    height: 50px;
    padding: 11px 0 15px 15px;
    font-family: ${fontFamily.base};
    font-size: 16px;
    line-height: 24px;
    color: ${color.grey900};
    border: 1px solid ${color.grey400};
    outline: none;

    &::placeholder {
        color: ${color.grey500};
    }

    &:focus {
        border-color: ${color.blue300};
    }

    &:disabled,
    &[readonly] {
        background-color: ${color.grey100};
        border-color: ${color.grey300};
        opacity: 1;
    }

    ${({ hasError }) =>
        hasError &&
        css`
            border-color: ${color.red400};
            background-color: ${color.red100};
        `};
`;
