import { COLOR_VARIATIONS } from '@common/types';
import React, { FC } from 'react';
import { MODAL_TYPE } from '../../../constants';
import { useAppSelector } from '../../../redux';
import { isAirToWaterProject } from '../../../redux/selectors';
import { AirToAirSftSolution, AirToWaterSftSolution } from '../../../types';
import SftImageViewerAta from '../solution/SftImageViewer.ata';
import SftImageViewerAtw from '../solution/SftImageViewer.atw';
import Modal from './Modal';

type PropsType = {
    metaData: unknown;
};

type CastedPropsType = {
    solution: AirToAirSftSolution | AirToWaterSftSolution;
    chosenColor?: COLOR_VARIATIONS;
    setChosenColor?: (color: COLOR_VARIATIONS) => void;
};

const SftImageViewerModal: FC<PropsType> = (props) => {
    const { solution, chosenColor, setChosenColor } = props.metaData as CastedPropsType;
    const isAirToWater = useAppSelector(isAirToWaterProject);
    return (
        <Modal type={MODAL_TYPE.sftImageViewer}>
            {isAirToWater ? (
                <SftImageViewerAtw solution={solution as AirToWaterSftSolution} />
            ) : (
                <SftImageViewerAta
                    solution={solution as AirToAirSftSolution}
                    chosenColor={chosenColor}
                    setChosenColor={setChosenColor}
                />
            )}
        </Modal>
    );
};

export default SftImageViewerModal;
