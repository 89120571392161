/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import styled, { css } from 'styled-components';
import { breakpoint } from '@common/components/Breakpoints';
import { color, fontFamily, fontWeight, space } from '../common/Theme';
import * as ButtonStyles from './Button';
import * as CardStyles from './Card';
import { SliderControls } from './Carousel';
import { ModalContent, ModalWrapper } from './Modal';
import { Group, Label, Tooltip } from './Rating';

export const Wrapper = styled.div`
    display: block;
    overflow-x: scroll;
`;

type SolutionsProps = {
    numItems: number;
};
export const Solutions = styled.div.attrs(() => ({}))<SolutionsProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${space[3]}px;
    margin: 0 auto;

    @media ${breakpoint.up('sm')} {
        align-items: stretch;
    }

    ${({ numItems }) =>
        numItems &&
        css`
            @media ${breakpoint.down('md')} {
                /* 218px is width of 1 regular sizes item, 73.6vw is size of isCurrent item */
                width: calc(${(numItems - 1) * 218}px + 73.6vw + 100px);
            }
        `}

    ${({ numItems }) =>
        numItems > 4 &&
        css`
            /* 218px is width of 1 regular sizes item, 73.6vw is size of isCurrent item */
            width: calc(${(numItems - 1) * 218}px + 73.6vw + 100px);

            @media ${breakpoint.up('sm')} {
                width: ${(numItems - 1) * 50}vw;
            }

            @media ${breakpoint.up('md')} {
                width: ${(numItems - 1) * 33.333}vw;
            }

            @media ${breakpoint.up('lg')} {
                max-width: 100%;
            }

            @media (min-width: 1450px) {
                max-width: 1410px;
            }
        `}
`;

export const SolutionItem = styled.div`
    position: relative;
    margin-top: ${space[5]}px;

    ${Group} {
        margin-bottom: ${space[2]}px;
    }

    ${Label} {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
    }

    ${Tooltip} {
        position: unset;
        height: 1rem;
    }
`;

export const SolutionItemCategory = styled.div`
    position: absolute;
    top: -${space[5]}px;
    left: ${space[2]}px;
    color: ${color.grey500};
    font-size: 0.875rem;
    font-weight: ${fontWeight.bold};
    text-transform: uppercase;

    &::after {
        content: '';
        display: block;
        transform: translateX(-20px);
        width: 20px;
        border-bottom: 20px solid ${color.grey100};
    }
`;

type SolutionProps = {
    isCurrent?: boolean;
    isHighlighted?: boolean;
};
export const Solution = styled(CardStyles.Default).attrs(() => ({
    type: 'button',
    as: 'button',
}))<SolutionProps>`
    position: relative;
    margin: 0 ${space[2]}px ${space[4]}px;
    padding: ${space[4]}px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: ${fontFamily.base};
    max-width: 218px;

    &:hover {
        cursor: pointer;
    }

    @media ${breakpoint.up('sm')} {
        min-width: 0;
        max-width: 50%;
    }

    @media ${breakpoint.up('md')} {
        max-width: 33.333%;
    }

    @media ${breakpoint.up('lg')} {
        min-width: 0;
        max-width: 218px;
        flex-wrap: nowrap;
    }

    ${({ isCurrent }) =>
        isCurrent &&
        css`
            @media ${breakpoint.down('sm')} {
                min-width: 73.6vw;
                width: 73.6vw;
            }
        `};

    ${({ isHighlighted }) =>
        isHighlighted &&
        css`
            border: none;
            box-shadow: inset 0 0 0 2px ${color.blue300};
        `};
`;

export const Header = styled(CardStyles.Header)`
    margin-bottom: ${space[3]}px;
    padding-bottom: ${space[3]}px;
    width: 100%;
    border-bottom: 1px solid ${color.grey200};
`;

export const Body = styled(CardStyles.Body)`
    width: 100%;
`;

export const Badge = styled.div`
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px ${space[3]}px 6px;
    font-size: 12px;
    font-weight: ${fontWeight.bold};
    line-height: 18px;
    color: ${color.grey000};
    background-color: ${color.blue300};
    border-radius: 14px;
    white-space: nowrap;
`;

export const Description = styled(CardStyles.Description)``;

export const Title = styled(CardStyles.Title)`
    margin-bottom: 0;

    & + ${Description} {
        margin-bottom: ${space[1]}px;
    }
`;

export const Actions = styled.div`
    padding: ${space[5]}px 0;
    text-align: center;
`;

export const Action = styled(ButtonStyles.PrimaryLink)`
    display: inline-flex;
    margin: 0 auto;
    color: ${color.blue300};
`;

export const SelectBtnWrapper = styled(ButtonStyles.PrimaryOutline)`
    margin: ${space[5]}px auto 0;
    justify-content: center;
    text-transform: unset;
`;

type ImageType = { src: string };

export const Image = styled.div<ImageType>`
    margin-bottom: ${space[2]}px;
    padding-top: 100%;

    ${({ src }) =>
        src &&
        css`
            background-image: url('${src}');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            object-fit: contain;
        `};
`;

export const ColorPickerWrapper = styled.div`
    margin: ${space[2]}px 0px;
    min-height: 24px;
`;

export const ColorPicker = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
`;

type ColorPickerIndicatorProps = {
    isActive: boolean;
};
export const ColorPickerIndicator = styled.button<ColorPickerIndicatorProps>`
    display: flex;
    width: 24px;
    height: 24px;
    margin: 0 1px;
    padding: 0;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    ${({ isActive }) =>
        isActive &&
        css`
            border: 2px solid ${color.grey500};
        `}
`;

type ColorPickerItemProps = {
    bgColor: string;
};
export const ColorPickerItem = styled.div<ColorPickerItemProps>`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    ${({ bgColor }) => `background-color: ${bgColor};`}
`;

export const Fullscreen = styled(ButtonStyles.Action)`
    display: none;
    position: absolute;
    top: ${space[4]}px;
    right: ${space[4]}px;

    @media ${breakpoint.up('sm')} {
        display: block;
    }
`;

export const IndoorSolutionInfoModal = styled.div`
    @media ${breakpoint.down('lg')} {
        ${ModalWrapper} {
            padding-left: ${space[4]}px;
            padding-right: ${space[4]}px;
            margin: 0 auto;
        }

        ${ModalContent} {
            margin: 0;
        }

        ${Group} {
            margin-bottom: ${space[3]}px !important;
        }
    }

    ${SliderControls} {
        margin-left: 0;
    }

    ${Label} {
        margin-bottom: ${space[2]}px;
        display: flex;
        align-items: center;
        gap: ${space[2]}px;
    }
`;
