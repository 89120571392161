import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../redux';
import {
    clearGigyaUser,
    fetchLead,
    setGigyaState,
    setGigyaUid,
    setGigyaUser,
} from '../redux/actions';
import { getJWToken } from '../utils';

// Blocks a single scroll event
const lockSingleScroll = (): void => {
    const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;
    const resetScroll = (): void => {
        window.removeEventListener('scroll', resetScroll);
        window.scrollTo(0, scrollPos);
    };
    window.addEventListener('scroll', resetScroll);
};

export const useGigyaSdk = (): void => {
    // Fetch redux-state
    const dispatch = useDispatch();
    const gigyaState = useAppSelector((store) => store.appState.gigyaState);
    // Add event handlers if gigya is ready
    useEffect(() => {
        // Only init if window.gigya is injected but not ready yet
        // This check makes sure this useEffect is only ran once
        if (gigyaState !== 'injected') return;
        // Attach event handlers for login/logout
        window.gigya.accounts.addEventHandlers({
            onLogin: (response) => {
                dispatch(setGigyaUser(response.profile));
            },
            onLogout: () => {
                dispatch(clearGigyaUser());
            },
        });
        // Fetch user-info for the first time
        window.gigya.accounts.getAccountInfo({
            callback: (response) => {
                if (response.errorCode === 0 && response.profile) {
                    // User found, set in redux
                    dispatch(setGigyaUser(response.profile));
                } else {
                    // Gigya failed or user is not logged in yet, fetch lead without JWToken
                    dispatch(fetchLead());
                }
            },
        });
        // Subscribe to register event
        const onRegisterEvent: ICallback<unknown> = async (data) => {
            // If email-validation is turned off in the gigya-api then JWToken will be set
            // No need to set UID then -> this will trigger 2 linklead & submit-calls
            const customerToken = await getJWToken();
            if (!customerToken) dispatch(setGigyaUid(data as string));
        };
        window._rsnChannel.subscribe('gigya.accounts.register', onRegisterEvent);
        // Set gigya ready
        dispatch(setGigyaState('ready'));
        // setEnabled(true);
    }, [gigyaState]);
};

type GigyaUiHookType = {
    login: () => void;
    logout: () => void;
};

export const useGigyaUI = (): null | GigyaUiHookType => {
    // Fetch value from globalState
    const gigyaState = useAppSelector((s) => s.appState.gigyaState);
    const lang = useAppSelector((s) => s.settings.language).toLowerCase();
    // Only return actions of everything is loaded & listening
    if (gigyaState !== 'ready') return null;
    // Return login/logout actions
    return {
        login: () => {
            lockSingleScroll();
            window.gigya.accounts.showScreenSet({
                screenSet: 'Default-RegistrationLogin',
                startScreen: 'gigya-login-screen',
                lang,
            });
        },
        logout: () => {
            window.gigya.accounts.logout();
        },
    };
};

// Opens the registration-screen and invokes the given callback if registration or login was
export const gigyaRegister = (language: string, callback: (complete: boolean) => void): void => {
    lockSingleScroll();
    window.gigya.accounts.showScreenSet({
        screenSet: 'Default-RegistrationLogin',
        startScreen: 'gigya-register-screen',
        lang: language,
        context: { callback },
    });
};
