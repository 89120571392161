import styled from 'styled-components';
import { breakpoint } from '@common/components/Breakpoints';
import { color, space } from '../common/Theme';
import * as ButtonStyles from './Button';

export const Toggle = styled(ButtonStyles.Action)`
    padding: 0 ${space[4]}px;
`;

export const Header = styled.div`
    display: flex;
    padding: ${space[4]}px 0 ${space[4]}px ${space[4]}px;
    align-items: center;
    font-size: 20px;
    line-height: 26px;
    color: ${color.blue300};

    ${Toggle} {
        display: flex;
        margin-left: auto;
    }

    @media ${breakpoint.up('xl')} {
        display: none;
    }
`;

export const Title = styled.span``;
