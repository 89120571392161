/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { createLogic } from 'redux-logic';
import * as Sentry from '@sentry/react';
import * as Logger from '@common/Logger';
import { APP_STATUS } from '../../constants';
import { Store } from '../../types';
import {
    setGlobalError,
    LEAD_ACTIONS,
    setLeadRemote,
    selectAirToWaterSolution,
    selectAirToAirSolution,
} from '../actions';
import { getNextStep, isFinalSftStep, getSftResults, isAirToWaterLead } from '../selectors';

type DepObj = { getState: () => Store };

const log = (msg: string): void => Logger.log(`[SFT] ${msg}`, Logger.STYLE.FILLER);

// Check if we've reached the end of SFT-tree
// - select solution if there is only 1
// - navigate to preferred_solution-screen if there are multiple
const sftLogic = createLogic({
    type: [LEAD_ACTIONS.setSftAirToWater, LEAD_ACTIONS.setSftAirToAir],
    name: 'sft.sftLogic',
    process({ getState }: DepObj, dispatch, done) {
        const store = getState();
        if (isFinalSftStep(store)) {
            // Final SFT step, check if we can autoselect a solution
            const solutions = getSftResults(store);
            if (solutions.length === 0) {
                dispatch(setGlobalError('no_solution_found'));
                Sentry.captureException(new Error('no_solution_found'));
            } else if (isAirToWaterLead(store.lead)) {
                /* AirToWater */
                if (solutions.length === 1) {
                    // Auto-select the only available solution
                    log('Only 1 solution available -> auto-select solution');
                    dispatch(selectAirToWaterSolution(solutions[0].id));
                } else if (solutions.length > 1) {
                    // Redirect to the preferred-solution-screen
                    log('Multiple solutions available -> navigate to preferredSolutionScreen');
                    dispatch(setLeadRemote(APP_STATUS.sft_he_preferred_solution));
                }
            } else {
                /* AirToAir */
                // eslint-disable-next-line no-lonely-if
                if (solutions.length === 1) {
                    // Auto-select the only available solution
                    log('Only 1 solution available -> auto-select solution');
                    dispatch(selectAirToAirSolution(solutions[0].id));
                } else if (solutions.length > 1) {
                    // Redirect to the preferred-solution-screen
                    log('Multiple solutions available -> navigate to preferredSolutionScreen');
                    dispatch(setLeadRemote(APP_STATUS.sft_ra_preferred_solution));
                }
            }
        } else {
            // SFT not done yet, navigate to next SFT step
            const nextStep = getNextStep(store.lead) as APP_STATUS;
            dispatch(setLeadRemote(nextStep));
        }
        done();
    },
});

// Determine next page to navigate to
const solutionSelectedLogic = createLogic({
    type: LEAD_ACTIONS.selectSolution,
    name: 'sft.solutionSelectedLogic',
    process({ getState }: DepObj, dispatch, done) {
        const store = getState();
        const nextStep = getNextStep(store.lead) as APP_STATUS;
        dispatch(setLeadRemote(nextStep));
        done();
    },
});

export default [sftLogic, solutionSelectedLogic];
