/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { APP_STATUS, POSITIONING_TYPE, ROOM_TYPE, TRANSITION } from '../../constants';
import { AirToAirLead, AppStatus, TransitionTypes } from '../../types';

export const getNextStep = (lead: AirToAirLead): AppStatus | null => {
    switch (lead.status) {
        case APP_STATUS.sft_ra_room_type:
            return lead.roomType === ROOM_TYPE.multi
                ? APP_STATUS.sft_ra_number_of_rooms
                : APP_STATUS.sft_ra_air_to_air_type;
        case APP_STATUS.sft_ra_number_of_rooms:
            return APP_STATUS.sft_ra_room_builder;
        case APP_STATUS.sft_ra_air_to_air_type:
            return APP_STATUS.sft_ra_positioning_type;
        case APP_STATUS.sft_ra_positioning_type:
            return lead.positioningType === POSITIONING_TYPE.concealed_ceiling_unit
                ? APP_STATUS.sft_ra_unsupported_positioning_type // AEM redirect
                : APP_STATUS.sft_ra_room_size;
        case APP_STATUS.sft_ra_room_size:
            return lead.solutionId
                ? APP_STATUS.sp_sizing_tool
                : APP_STATUS.sft_ra_preferred_solution;
        case APP_STATUS.sft_ra_unsupported_positioning_type:
            return APP_STATUS.sft_ra_unsupported_positioning_type; // Stay put -> AEM redirect inc
        case APP_STATUS.sft_ra_preferred_solution:
        case APP_STATUS.sft_ra_room_builder:
            return APP_STATUS.sp_sizing_tool;
        default:
            return null;
    }
};

export const getTransitionToStep = (target: AppStatus): TransitionTypes | null => {
    switch (target) {
        case APP_STATUS.sft_ra_air_to_air_type:
            return TRANSITION.sft_ra_choose_air_to_air_type;
        case APP_STATUS.sft_ra_number_of_rooms:
            return TRANSITION.sft_ra_choose_number_of_rooms;
        case APP_STATUS.sft_ra_positioning_type:
            return TRANSITION.sft_ra_choose_positioning_type;
        case APP_STATUS.sft_ra_room_type:
            return TRANSITION.sft_ra_choose_room_type;
        case APP_STATUS.sft_ra_unsupported_positioning_type:
            return TRANSITION.sft_ra_choose_unsupported_positioning_type;
        case APP_STATUS.sft_ra_room_size:
            return TRANSITION.sft_ra_choose_room_size;
        case APP_STATUS.sft_ra_preferred_solution:
            return TRANSITION.sft_ra_choose_preferred_solution;
        case APP_STATUS.sft_ra_room_builder:
            return TRANSITION.sft_ra_room_builder;
        default:
            return null;
    }
};

export const getSftProgress = (lead: AirToAirLead): number => {
    switch (lead.status) {
        case APP_STATUS.sft_ra_room_type:
            return 0;
        case APP_STATUS.sft_ra_number_of_rooms:
            return 0.3;
        case APP_STATUS.sft_ra_room_builder:
            return 0.6;
        case APP_STATUS.sft_ra_air_to_air_type:
            return 0.2;
        case APP_STATUS.sft_ra_positioning_type:
            return 0.4;
        case APP_STATUS.sft_ra_room_size:
            return 0.6;
        case APP_STATUS.sft_ra_preferred_solution:
            return 0.8;
        case APP_STATUS.sft_ra_unsupported_positioning_type:
            return 1;
        default:
            return 0;
    }
};
