import React, { FC } from 'react';
import { roundToStep } from '@tsUtils';
import * as Icons from '@common/icons';
import * as RatingStyles from '../styles/components/Rating';

enum starRating {
    empty = 0,
    half = 0.5,
    full = 1,
}

// Returns 0 | 0.5 | 1 for a certain star
const getFiveStarValue = (value: number, index: number): starRating => {
    return Math.min(1, Math.max(0, roundToStep(value, 0.5) - index)) as starRating;
};

const Star: FC<{ value: starRating }> = ({ value }) => {
    switch (value) {
        case starRating.full:
            return (
                <RatingStyles.Star isFull>
                    <Icons.Glyph.StarFull />
                </RatingStyles.Star>
            );
        case starRating.half:
            return (
                <RatingStyles.Star isHalf>
                    <Icons.Glyph.StarHalf />
                </RatingStyles.Star>
            );
        case starRating.empty:
        default:
            return (
                <RatingStyles.Star isEmpty>
                    <Icons.Glyph.StarEmpty />
                </RatingStyles.Star>
            );
    }
};

type PropsType = { rating: number };

const StarRating: FC<PropsType> = ({ rating }) => (
    <>
        <Star value={getFiveStarValue(rating, 0)} />
        <Star value={getFiveStarValue(rating, 1)} />
        <Star value={getFiveStarValue(rating, 2)} />
        <Star value={getFiveStarValue(rating, 3)} />
        <Star value={getFiveStarValue(rating, 4)} />
    </>
);

export default StarRating;
