/* eslint-disable react/no-danger */
import i18n from '@common/i18n';
import React from 'react';
import { EVS_OVERVIEW_TAB } from '../../../../constants';
import * as ToggleStyles from '../../../../styles/components/Toggle';
import { EvsOverviewTab } from '../../../../types';

type PropsType = {
    activeTab: EvsOverviewTab;
    onSetActiveTab: (activeTab: EvsOverviewTab) => void;
};
const CostCompareToggle = ({ activeTab, onSetActiveTab }: PropsType): JSX.Element => {
    return (
        <ToggleStyles.Toggle my={4}>
            <ToggleStyles.ToggleItem
                type="button"
                isActive={activeTab === EVS_OVERVIEW_TAB.price}
                onClick={() => onSetActiveTab(EVS_OVERVIEW_TAB.price)}
            >
                {i18n('price_toggle_label')}
            </ToggleStyles.ToggleItem>
            <ToggleStyles.ToggleItem
                type="button"
                isActive={activeTab === EVS_OVERVIEW_TAB.co2}
                onClick={() => onSetActiveTab(EVS_OVERVIEW_TAB.co2)}
                dangerouslySetInnerHTML={{ __html: i18n('co2_toggle_label') }}
            />
        </ToggleStyles.Toggle>
    );
};

export default CostCompareToggle;
