import styled, { css } from 'styled-components';
import { color, fontWeight, space } from '../common/Theme';
import * as TypographyStyles from './Typography';

type ItemGroupFlags = { hasBorder?: boolean };
export const Group = styled.div<ItemGroupFlags>`
    padding-bottom: ${space[3]}px;

    ${({ hasBorder }) =>
        hasBorder &&
        css`
            margin-bottom: ${space[5]}px;
            border-bottom: 1px solid ${color.grey200};
        `};
`;

export const Title = styled(TypographyStyles.H4)`
    margin-bottom: ${space[3]}px;
`;

export const ItemRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: ${space[3]}px;
    font-size: 16px;
    font-weight: ${fontWeight.semibold};
    color: ${color.grey600};
    line-height: 24px;
`;

export const ItemLabel = styled.div``;

export const ItemValue = styled.div`
    padding-left: ${space[4]}px;
    text-align: right;
`;

type ItemNoteFlags = { isInline?: boolean };
export const ItemNote = styled.span<ItemNoteFlags>`
    display: block;
    font-size: 13px;
    font-weight: ${fontWeight.regular};
    line-height: 20px;

    ${({ isInline }) =>
        isInline &&
        css`
            display: inline-block;
            margin-left: ${space[1]}px;
        `};
`;
