/* eslint-disable react/no-danger */
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React from 'react';
import * as EvsStyles from '../../../../styles/components/Evs';
import * as TypographyStyles from '../../../../styles/components/Typography';

type PropsType = {
    totalSavings: string;
    savingsTrees: string;
    savingsCarTravel: string;
    savingsAirplaneTravel: string;
};
const Co2AdditionalInfo = ({
    totalSavings,
    savingsTrees,
    savingsCarTravel,
    savingsAirplaneTravel,
}: PropsType): JSX.Element => {
    return (
        <>
            <EvsStyles.Co2AdditionalInfoTitleLabel
                dangerouslySetInnerHTML={{
                    __html: i18n('co2_savings_is_equal_to').replace('{kg}', totalSavings),
                }}
            />
            <EvsStyles.Co2AdditionalInfoTable>
                <div className="co2InfoItem">
                    <Icons.Glyph.Plant />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: i18n('co2_absorption_of'),
                        }}
                    />
                    <TypographyStyles.StrongText>
                        {savingsTrees}
                        &nbsp;
                        {i18n('trees')}
                    </TypographyStyles.StrongText>
                </div>
                <span className="divider-or">
                    <span className="text">{i18n('or')}</span>
                </span>
                <div className="co2InfoItem">
                    <Icons.Glyph.Car />
                    <div>{i18n('distance_travelled_by_car')}</div>
                    <TypographyStyles.StrongText>
                        {savingsCarTravel}
                        &nbsp;
                        {i18n('km')}
                    </TypographyStyles.StrongText>
                </div>
                <span className="divider-or">
                    <span className="text">{i18n('or')}</span>
                </span>
                <div className="co2InfoItem">
                    <Icons.Glyph.Plane />
                    <div>{i18n('flight_travel_of')}</div>
                    <TypographyStyles.StrongText>
                        {savingsAirplaneTravel}
                        &nbsp;
                        {i18n('km')}
                    </TypographyStyles.StrongText>
                </div>
            </EvsStyles.Co2AdditionalInfoTable>
        </>
    );
};

export default Co2AdditionalInfo;
