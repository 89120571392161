import styled, { css } from 'styled-components';

import { breakpoint } from '@common/components/Breakpoints';
import { ErrorText, Small } from './Typography';
import { color, fontWeight, space } from '../common/Theme';
import * as FormStyles from './Form';
import * as ButtonStyles from './Button';
import { Body, Default } from './Card';
import { PrimaryLink } from './Button';
import { ModalFooter, ModalHeader } from './Modal';
import { WideContainer } from './Page';
import { Label } from './Pricing';

export const AddRoomCardBtn = styled.button`
    padding: ${space[0]};
    min-width: 12.5rem;
    background-color: ${color.grey200};
    border: 1px solid ${color.blue300};
    border-radius: 4px;
    cursor: pointer;

    @media ${breakpoint.up('lg')} {
        min-height: 40rem;
    }
`;

export const AddRoomCardWrapper = styled.div`
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${space[2]}px;
    color: ${color.blue300};
    font-size: 0.875rem;
    text-align: center;

    > svg {
        margin-right: ${space[0]};
        border: 2px solid ${color.blue300};
        border-radius: 50%;
    }
`;

export const AddRoomCardLabel = styled.span`
    text-transform: uppercase;
    font-weight: ${fontWeight.bold};
`;

export const AddRoomCardDescription = styled.p`
    color: ${color.grey500};
    line-height: 1.5;
`;

export const SolutionsOverview = styled.div`
    margin: ${space[1]}px;
`;

export const SolutionsOverviewHeader = styled.div<{ display?: string; padding?: string }>`
    text-align: center;
    border-bottom: 1px solid ${color.blue200};
    margin-bottom: ${space[3]}px;
    ${({ display }) =>
        display &&
        css`
            display: ${display};
        `}
    ${({ padding }) =>
        padding &&
        css`
            padding: ${padding};
        `}
    button {
        width: fit-content;
    }
    div {
        margin: auto;
    }
`;

export const SolutionsList = styled.div`
    display: flex;
`;

export const SolutionDetailInput = styled(FormStyles.Group)`
    margin-bottom: ${space[3]}px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;

    &:last-child {
        margin-bottom: ${space[0]};
    }

    @media ${breakpoint.up('md')} {
        grid-template-columns: repeat(2, 1fr);
        gap: ${space[3]}px;

        ${FormStyles.Label} {
            margin-bottom: ${space[0]};
        }
    }

    @media ${breakpoint.up('lg')} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media ${breakpoint.up('xxl')} {
        ${FormStyles.InputGroup},
        ${FormStyles.Select} {
            width: 60%;
        }
    }

    ${FormStyles.Select} {
        background-color: transparent;
    }

    span {
        @media ${breakpoint.up('lg')} {
            display: none;
        }
    }

    label:last-child {
        @media ${breakpoint.down('md')} {
            margin-top: 20px;
        }

        @media ${breakpoint.up('xxl')} {
            width: 100%;
            text-align: end;
        }
    }
`;

export const Cards = styled.div`
    margin-bottom: ${space[6]}px;
    display: grid;
    grid-template-columns: 1fr;
    gap: ${space[6]}px;

    @media ${breakpoint.up('md')} {
        margin-bottom: unset;
        gap: ${space[5]}px;
    }

    @media ${breakpoint.up('lg')} {
        grid-template-columns: repeat(3, 1fr);
        gap: ${space[4]}px;
    }

    @media ${breakpoint.up('xl')} {
        display: flex;
        flex-wrap: nowrap;
    }

    @media ${breakpoint.up('xxl')} {
        &:has(> :nth-child(5)) {
            ${SolutionDetailInput} {
                ${FormStyles.InputGroup} {
                    width: 100%;
                }

                ${FormStyles.Select} {
                    width: 100%;
                }
            }
        }
    }

    ${Default} {
        padding: ${space[0]};

        ${Body} {
            padding: ${space[5]}px ${space[4]}px;
        }
    }
`;

export const Footer = styled.div`
    padding: 25px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: #fff;
    border-top: 1px solid lightgrey;
    box-shadow: 0px -4px 20px 0px #0000001a;

    ${WideContainer} {
        padding-bottom: ${space[0]};

        @media ${breakpoint.down('xxl')} {
            padding-left: ${space[0]};
            padding-right: ${space[0]};
        }
    }
`;

type FooterWrapperProps = { display: string };
export const FooterWrapper = styled.div<FooterWrapperProps>`
    display: ${({ display }) => display};
    flex-direction: column;
    gap: ${space[2]}px;

    @media ${breakpoint.up('md')} {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        gap: ${space[3]}px;
    }
`;

export const FooterPrice = styled.div`
    display: flex;
    align-items: center;
    gap: ${space[2]}px;

    ${Label} {
        margin-bottom: ${space[1]}px;
        color: ${color.grey800};
        font-size: 1.125rem;
        font-weight: ${fontWeight.semibold};

        @media ${breakpoint.up('md')} {
            font-weight: ${fontWeight.regular};
        }
    }

    @media ${breakpoint.up('md')} {
        flex-basis: 50%;
        display: block;
    }

    @media ${breakpoint.up('xxl')} {
        flex-basis: 40%;
    }

    > small {
        display: none;

        @media ${breakpoint.up('md')} {
            display: initial;
        }
    }
`;

export const FooterTooltip = styled.div`
    display: inline;
    color: ${color.grey500};

    @media ${breakpoint.up('md')} {
        display: none;
    }
`;

export const FooterNextMobileBtn = styled.div`
    margin-left: auto;

    @media ${breakpoint.up('md')} {
        display: none;
    }
`;

type FooterNextProps = { displayBtn: string };
export const FooterNext = styled.div<FooterNextProps>`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    gap: ${space[2]}px;

    @media ${breakpoint.up('xs')} {
        flex-basis: 50%;
        align-items: flex-end;
        text-align: right;
    }

    @media ${breakpoint.up('xxl')} {
        flex-basis: 40%;
        align-items: center;
        flex-direction: row;
    }

    > button {
        display: none;
        margin-left: unset;

        @media ${breakpoint.down('md')} {
            display: ${({ displayBtn }) => displayBtn};
        }

        @media ${breakpoint.up('md')} {
            display: flex;
        }

        @media ${breakpoint.up('xxl')} {
            order: 1;
        }
    }

    ${Small} {
        margin: ${space[0]};

        ${ErrorText} {
            font-size: inherit;
            font-weight: inherit;
            line-height: 1.2;
        }
    }
`;

export const Header = styled.div`
    padding: ${space[6]}px ${space[4]}px;
    background-color: ${color.grey200};
`;

export const HeaderTop = styled.div`
    margin-bottom: ${space[2]}px;
    display: flex;
    justify-content: space-between;

    svg {
        color: ${color.blue300};
    }
`;

export const RoomName = styled.p`
    margin-top: ${space[0]};
    margin-bottom: ${space[0]};
    font-weight: ${fontWeight.bold};
`;

export const SolutionInfo = styled.div`
    margin-bottom: ${space[6]}px;
    min-height: 14.5rem;
    text-align: center;

    ${PrimaryLink} {
        padding-top: 6.5rem;
        padding-bottom: 6.5rem;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: ${space[2]}px;
        width: 100%;
        border: 2px dashed ${color.blue300};
        border-radius: 10px;
        cursor: pointer;

        > svg {
            margin-right: ${space[0]};
        }
    }
`;

export const SolutionInfoRatings = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: ${space[3]}px;
    color: ${color.blue300};

    > div {
        display: flex;
        gap: ${space[2]}px;
    }
`;

export const SolutionInfoRating = styled.div`
    padding: ${space[2]}px ${space[3]}px;
    display: flex;
    align-items: center;
    gap: ${space[1]}px;
    color: ${color.yellow300};
    font-size: 0.875rem;
    font-weight: ${fontWeight.bold};
    border: 1px solid ${color.yellow300};
    border-radius: 2rem;

    > span {
        margin-right: ${space[0]};

        > svg {
            width: 0.75rem;
            height: 0.75rem;
        }
    }
`;

export const RoomSelectModalWrapper = styled.div`
    ${ModalHeader} {
        border-bottom: ${space[0]};
    }

    ${ModalFooter} {
        background-color: transparent;
    }
`;

export const RoomSelectList = styled.div`
    margin-bottom: ${space[6]}px;
`;

export const RoomSelectItem = styled.div`
    width: fit-content;
    display: flex;
    align-items: baseline;
    gap: 0.5rem;

    &:not(:last-child) {
        margin-bottom: ${space[4]}px;
    }
`;

export const RoomSelectLabel = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${space[1]}px;
    cursor: default;

    ${Small} {
        font-weight: ${fontWeight.bold};
    }
`;

export const Carousel = styled.div`
    .indoor-solutions-list {
        width: 100%;
    }
    .slick-slider {
        width: 100%;
    }
    .slick-track {
        margin-top: 15px;
    }
    .slick-dots {
        bottom: auto;
        li {
            height: auto;
        }
    }
    .slick-slider .slick-dots .slick-active {
        background-color: ${color.grey500};
    }
    .slick-slider .slick-dots ul {
        padding: 0px;
        display: flex;
        justify-content: center;
    }
    .slick-slider .slick-dots li {
        background-color: ${color.grey300};
        border-radius: 5px;
        height: 5px;
        width: 40px;
        cursor: default;
    }
    .solution-list {
        border-top: 3px ${color.grey300} solid;

        > button {
            margin-top: ${space[4]}px;
            max-width: calc(100% - ${space[4]}px);
            min-width: calc(100% - ${space[4]}px);
            width: calc(100% - ${space[4]}px);
        }
    }
`;

export const BackToRoomsBtn = styled(ButtonStyles.PrimaryLink)`
    width: fit-content;
    font-size: 0.875rem;
    text-transform: uppercase;
`;

export const SolutionInfoProduct = styled.div`
    position: relative;

    > a {
        position: absolute;
        top: -0.75rem;
        right: 0;
        padding: ${space[2]}px ${space[3]}px;
        color: ${color.grey500};
        background-color: #ffffffcc;
        border-radius: 1rem;
    }

    > div[role='img'] {
        padding-top: 20%;
        height: 110px;
    }
`;

export const SolutionInfoTitle = styled.div`
    margin-bottom: ${space[6]}px;
    display: flex;
    justify-content: center;
    gap: ${space[1]}px;
    color: ${color.grey800};
    font-size: 1.25rem;

    > p {
        margin: ${space[0]};
    }

    > span {
        cursor: pointer;
    }
`;
