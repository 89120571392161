/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import { DEVICE_TYPE } from '@common/types';
import { transpose } from '@tsUtils';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { color } from '../../../../../styles/common/Theme';
import * as EvsStyles from '../../../../../styles/components/Evs';
import { EvsAtwCo2DataBenchmarkSol, EvsAtwCo2DataDaikinSol } from '../../../../../types';
import Cell from '../Cell';

const tooltipStyles = { maxWidth: '250px' };

type PropsType = {
    itemIndex: number;
    deviceType: DEVICE_TYPE;
    daikinSolution: EvsAtwCo2DataDaikinSol;
    benchmarkSolutions: EvsAtwCo2DataBenchmarkSol[];
    visibleItems: number;
};

const EvsAtwCo2Compare = ({
    itemIndex,
    deviceType,
    daikinSolution,
    benchmarkSolutions,
    visibleItems,
}: PropsType): JSX.Element => {
    const isDesktop = deviceType === DEVICE_TYPE.desktop;
    const textAlign = isDesktop ? 'right' : 'left';
    const showInlineHeaders = !isDesktop;

    const titleColumn = [
        <Cell key="name" isTitleColumn isBold isFirstCell textAlign="left" />,
        <Cell key="co2-emissions-per-year" isTitleColumn isBold textAlign="left">
            <div dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_per_year') }} />
        </Cell>,
        <Cell key="total-co2-emission" isTitleColumn isBold textAlign="left">
            <div dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_in_15_years') }} />
        </Cell>,
        <Cell
            key="less-more-co2-emission"
            isTitleColumn
            isBold
            isLastCell
            textAlign="left"
            verticalAlign="top"
        >
            <div dangerouslySetInnerHTML={{ __html: i18n('co2_saved') }} />
        </Cell>,
    ];

    const daikinColumn = [
        <Cell
            key="daikin-sol-name"
            isBold
            isDaikin
            isFirstCell
            textAlign="left"
            color={color.blue400}
        >
            {daikinSolution.name}
        </Cell>,
        <Cell key="daikin-co2-emissions-per-year" isDaikin textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_per_year') }} />
                </EvsStyles.InlineHeader>
            )}
            {daikinSolution.co2EmissionsPerYear}&nbsp;{i18n('kg')}
        </Cell>,
        <Cell key="daikin-total-co2-emission" isDaikin textAlign={textAlign}>
            {showInlineHeaders && (
                <EvsStyles.InlineHeader>
                    <strong
                        dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_in_15_years') }}
                    />
                </EvsStyles.InlineHeader>
            )}
            {daikinSolution.totalCo2Emissions}&nbsp;{i18n('kg')}
        </Cell>,
        <Cell
            key="daikin-less-more-co2-emission"
            isDaikin
            isLastCell
            textAlign={textAlign}
            bgColor={color.green100}
            color={color.green300}
        >
            {showInlineHeaders && (
                <EvsStyles.InlineHeader textColor={!isDesktop ? color.green300 : ''}>
                    <strong dangerouslySetInnerHTML={{ __html: i18n('co2_saved') }} />
                </EvsStyles.InlineHeader>
            )}
            <EvsStyles.TextGroup
                renderSideBySide
                reverseRow={showInlineHeaders}
                textColor={color.green300}
            >
                <Icons.Glyph.ThumbsUp size={40} />
                <div>
                    <div>
                        {i18n('emission_per_year').replace('{kg}', daikinSolution.savingsPerYear)}
                    </div>
                    <div>
                        {i18n('emission_in_15_years').replace('{kg}', daikinSolution.totalSavings)}
                    </div>
                </div>
            </EvsStyles.TextGroup>
        </Cell>,
    ];

    const benchmarkColumns = benchmarkSolutions.map((benchmarkSolution, i) => {
        return [
            <Cell
                key={`name-${benchmarkSolution.name.replaceAll(/ /g, '-')}`}
                isFirstCell
                isBold
                textAlign="left"
            >
                <EvsStyles.TableHeader>
                    <div>{benchmarkSolution.name}</div>
                    <p data-tip="" data-for={`tooltip_${i}`}>
                        <Icons.Glyph.InfoCircle />
                    </p>
                    <ReactTooltip id={`tooltip_${i}`} place="top" effect="solid" clickable>
                        <div
                            style={tooltipStyles}
                            dangerouslySetInnerHTML={{ __html: benchmarkSolution.highlights }}
                        />
                    </ReactTooltip>
                </EvsStyles.TableHeader>
            </Cell>,
            <Cell
                key={`co2-emissions-per-year-${benchmarkSolution.name.replaceAll(/ /g, '-')}`}
                textAlign={textAlign}
            >
                {showInlineHeaders && (
                    <EvsStyles.InlineHeader>
                        <strong
                            dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_per_year') }}
                        />
                    </EvsStyles.InlineHeader>
                )}
                {benchmarkSolution.co2EmissionsPerYear}&nbsp;{i18n('kg')}
            </Cell>,
            <Cell
                key={`total-co2-emission-${benchmarkSolution.name.replaceAll(/ /g, '-')}`}
                textAlign={textAlign}
                bgColor={color.red100}
                color={color.red300}
            >
                {showInlineHeaders && (
                    <EvsStyles.InlineHeader textColor={!isDesktop ? color.red300 : ''}>
                        <strong
                            dangerouslySetInnerHTML={{ __html: i18n('co2_emissions_in_15_years') }}
                        />
                    </EvsStyles.InlineHeader>
                )}
                {benchmarkSolution.totalCo2Emissions}&nbsp;{i18n('kg')}
            </Cell>,
            <Cell
                key={`less-more-co2-emission-${benchmarkSolution.name.replaceAll(/ /g, '-')}`}
                isLastCell
                textAlign={textAlign}
            >
                {showInlineHeaders && (
                    <EvsStyles.InlineHeader>
                        <strong dangerouslySetInnerHTML={{ __html: i18n('co2_saved') }} />
                    </EvsStyles.InlineHeader>
                )}
                <EvsStyles.TextGroup
                    renderSideBySide
                    reverseRow={showInlineHeaders}
                    textColor={color.grey500}
                >
                    <Icons.Glyph.ThumbsDown size={40} />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: i18n('more_co2_emission').replace(
                                '%d',
                                String(benchmarkSolution.percentageMoreCo2Emission),
                            ),
                        }}
                    />
                </EvsStyles.TextGroup>
            </Cell>,
        ];
    });

    const emptyPlaceholderColumn = [
        <Cell key="name-placeholder" placeholder />,
        <Cell key="benchmark-co2-emissions-per-year-placeholder" placeholder />,
        <Cell key="benchmark-total-co2-emission-placeholder" placeholder />,
        <Cell key="benchmark-less-more-co2-emission-placeholder" placeholder />,
    ];

    if (deviceType === DEVICE_TYPE.desktop && benchmarkSolutions.length % 2 !== 0) {
        benchmarkColumns.splice(benchmarkColumns.length, 0, [...emptyPlaceholderColumn]);
    }

    const getVisibleSolutions = (): JSX.Element[][] => {
        switch (deviceType) {
            case DEVICE_TYPE.mobile:
                return [[daikinColumn, ...benchmarkColumns][itemIndex]];
            case DEVICE_TYPE.tablet:
                return [
                    daikinColumn,
                    ...benchmarkColumns.slice(itemIndex, itemIndex + visibleItems),
                ];
            case DEVICE_TYPE.desktop:
                return [
                    titleColumn,
                    daikinColumn,
                    ...benchmarkColumns.slice(itemIndex, itemIndex + visibleItems),
                ];
            default:
                return [];
        }
    };
    const transposedArray: JSX.Element[][] = transpose(getVisibleSolutions());

    return (
        <EvsStyles.Table>
            <tbody>
                {transposedArray.map((cellArr, rowIndex) => (
                    <tr key={`row-${rowIndex}`}>{cellArr}</tr>
                ))}
            </tbody>
        </EvsStyles.Table>
    );
};

export default EvsAtwCo2Compare;
