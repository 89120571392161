import i18n from '@common/i18n';
import React from 'react';
import { useAppSelector } from '../../../redux';
import {
    getBoilerUpgradeSchemeLabel,
    getIncentives,
    getIsSmartAltherma,
    getShowEquipmentPrice,
    getShowIncentivePrices,
    getShowPriceError,
    getShowSplitPrice,
    getShowTotalPrice,
    getShowTotalSystemPrice,
    isAirToWaterProject,
} from '../../../redux/selectors';
import * as PageStyles from '../../../styles/components/Page';
import * as PricingStyles from '../../../styles/components/Pricing';
import * as TypographyStyles from '../../../styles/components/Typography';
import { AirToWaterLead, CommercePrices } from '../../../types';
import EquipmentPrice from './EquipmentPrice';
import Incentives from './Incentives';
import PriceItem from './PriceItem';
import TotalPrice from './TotalPrice';
import TotalSystemPrice from './TotalSystemPrice';

type PropsType = {
    prices: CommercePrices;
    priceError?: string | null;
    showIncentives?: boolean; // Incentives are not shown in certain scenarios (compare, altherma, ...)
    isSmart?: boolean;
    hideSplitPrice?: boolean;
};

const PriceList = ({
    prices,
    priceError = null,
    showIncentives = false,
    isSmart = undefined,
    hideSplitPrice = false,
}: PropsType): JSX.Element | null => {
    // Redux
    const lead = useAppSelector((store) => store.lead);
    const isAtwProject = useAppSelector(isAirToWaterProject);
    const skipToContactDealerForm = useAppSelector((store) => store.lead.skipToContactDealerFrom);
    const isBinding = useAppSelector((store) => store.settings.solutionPricing.quotationIsBinding);
    const boilerUpgradeSchemeLabel = useAppSelector(getBoilerUpgradeSchemeLabel);
    const showSmartDisclaimer = useAppSelector((store) => isSmart ?? getIsSmartAltherma(store));
    // Incentives prices should not be shown on step 5
    const showIncentivePrices = useAppSelector(
        (store) => showIncentives && getShowIncentivePrices(store),
    );
    const incentives = useAppSelector(getIncentives);
    const showTotalSystemPrice = useAppSelector(getShowTotalSystemPrice);
    const showEquipmentPrice = useAppSelector(getShowEquipmentPrice);
    const showTotalPrice = useAppSelector(getShowTotalPrice);
    const showSplitPrice = useAppSelector(getShowSplitPrice(hideSplitPrice));
    const showPriceError = useAppSelector(getShowPriceError);

    // Don't render if skip to contact dealer was done
    if (skipToContactDealerForm) return null;

    // Pricing error
    if (priceError) {
        return (
            <>
                {showPriceError && (
                    <TypographyStyles.InfoText mb={4}>{priceError}</TypographyStyles.InfoText>
                )}
                {showIncentivePrices && (
                    <Incentives
                        incentives={incentives}
                        boilerUpgradeSchemeLabel={boilerUpgradeSchemeLabel}
                    />
                )}
            </>
        );
    }
    // Don't render if no prices were found
    if (prices === null) return null;

    // For air to water, only show prices if SelSoft data was set
    if (isAtwProject && !(lead as AirToWaterLead).selSoftData?.solution) return null;

    return (
        <>
            {/* Split prices, should not be shown on step 5 in the basket
            but should be on shown step 5 in the solution specs modal */}
            {showSplitPrice && (
                <>
                    <PricingStyles.Group>
                        <PriceItem label={i18n('price_system')} price={prices.equipmentPrice} />
                        <PriceItem label={i18n('price_labour_cost')} price={prices.laborCost} />
                        <PriceItem
                            label={i18n('price_small_material')}
                            price={prices.smallMaterialCost}
                        />
                    </PricingStyles.Group>
                    <PageStyles.Divider />
                </>
            )}
            {showEquipmentPrice && (
                <>
                    {/* If total system price is rendered, don't render the price details link under the system price,
                        otherwise this will be rendered twice */}
                    <EquipmentPrice
                        equipmentPrice={prices.equipmentPrice}
                        showPriceDetails={!showTotalSystemPrice}
                    />
                    <PageStyles.Divider />
                </>
            )}
            {/* Render IncentivePrices or IncentiveError */}
            {showIncentivePrices && (
                <Incentives
                    incentives={incentives}
                    boilerUpgradeSchemeLabel={boilerUpgradeSchemeLabel}
                />
            )}
            {/* Total Price */}
            {showTotalPrice && <TotalPrice totalPrice={prices.totalPrice} />}
            {/* Total System Price */}
            {showTotalSystemPrice && (
                <TotalSystemPrice totalSystemPrice={prices.totalSystemPrice!} />
            )}
            {/* Smart altherma disclaimer */}
            {showSmartDisclaimer && (
                <PricingStyles.Group>
                    <TypographyStyles.Small mb={4}>
                        {i18n('smart_altherma_disclaimer')}
                    </TypographyStyles.Small>
                </PricingStyles.Group>
            )}
            {/* Price binding & disclaimer */}
            <PricingStyles.Group>
                <TypographyStyles.Small mb={4}>
                    {i18n(isBinding ? 'price_binding' : 'price_non_binding')}
                </TypographyStyles.Small>
            </PricingStyles.Group>
            <PricingStyles.Group>
                <TypographyStyles.Small mb={4}>{i18n('price_disclaimer')}</TypographyStyles.Small>
            </PricingStyles.Group>
        </>
    );
};

export default PriceList;
