/* eslint-disable no-fallthrough */
/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import {
    APP_STATUS,
    EMITTER_TYPE,
    KEEP_THE_RADIATORS,
    PROJECT_TYPE,
    ROOM_SIZE_GROUP,
    ROOM_TYPE,
} from '../../constants';
import {
    AirToAirLead,
    AirToAirSftSelection,
    AirToAirSftSolution,
    AirToWaterLead,
    AirToWaterSftSelection,
    AirToWaterSftSolution,
    AtwState,
    Lead,
    SingleRoomSolution,
    Store,
} from '../../types';
import {
    getRoomSizeSettings,
    isAirToAirMultiRoomLead,
    isAirToAirProject,
    isAirToWaterLead,
    isAirToWaterProject,
} from './genericSelectors';

// Build partial object of AirToWaterSftSelection currently set
const getAirToWaterSftSelection = (
    lead: AirToWaterLead,
    targetStatus: string,
): Partial<AirToWaterSftSelection> => {
    const choices: Partial<AirToWaterSftSelection> = {
        buildingType: null,
        previousHeatingSystem: null,
        emitterType: null,
        householdComposition: null,
        functionType: null,
        projectType: null,
    };
    const {
        buildingType,
        emitterType,
        functionType,
        projectType,
        householdComposition,
        previousHeatingSystem,
    } = lead;
    // This switch-statement works with fallthroughs
    // cases are listed in a reverse chronological order
    // every case hit also incorporates data from all earlier pages (later cases)
    switch (targetStatus) {
        case APP_STATUS.dealer_selection_address_information:
        case APP_STATUS.dealer_selection:
        case APP_STATUS.sp_quotation_tool:
        case APP_STATUS.sp_quotation_choice:
        case APP_STATUS.incentives_tool:
        case APP_STATUS.sp_selsoft_result:
        case APP_STATUS.sp_sizing_tool:
        case APP_STATUS.sft_he_preferred_solution:
            if (buildingType) choices.buildingType = buildingType;
        case APP_STATUS.sft_he_building_type:
            if (previousHeatingSystem) choices.previousHeatingSystem = previousHeatingSystem;
        case APP_STATUS.sft_he_previous_heating_system:
            if (emitterType) choices.emitterType = emitterType;
        case APP_STATUS.sft_he_emitter_type:
        case APP_STATUS.sft_he_household_composition:
            // houseHoldComposition must be prefilled even when going back
            if (householdComposition) choices.householdComposition = householdComposition;
            if (functionType) choices.functionType = functionType;
        case APP_STATUS.sft_he_function_type:
            if (projectType) choices.projectType = projectType;
        case APP_STATUS.sft_he_project_type:
        default:
            // Going back to first SFT step, clear all
            break;
    }
    return choices;
};

// TODO: check if correct
// Build partial object of AirToAirSftSelection currently set
const getAirToAirSftSelection = (
    lead: AirToAirLead,
    targetStatus: string,
): Partial<AirToAirSftSelection> => {
    const choices: Partial<AirToAirSftSelection> = {
        roomSize: null,
        positioningType: null,
        numberOfRooms: null,
        roomType: null,
        houseSize: null,
        houseSurface: null,
        airToAirType: null,
    };
    const {
        airToAirType,
        roomType,
        numberOfRooms,
        positioningType,
        roomSize,
        houseSize,
        houseSurface,
    } = lead;
    // This switch-statement works with fallthroughs
    // cases are listed in a reverse chronological order
    // every case hit also incorporates data from all earlier pages (later cases)
    switch (targetStatus) {
        case APP_STATUS.dealer_selection_address_information:
        case APP_STATUS.dealer_selection:
        case APP_STATUS.sp_quotation_tool:
        case APP_STATUS.sp_quotation_choice:
        case APP_STATUS.incentives_tool:
        case APP_STATUS.sp_selsoft_result:
        case APP_STATUS.sp_sizing_tool:
        case APP_STATUS.sft_ra_preferred_solution:
        case APP_STATUS.sft_ra_unsupported_positioning_type:
        case APP_STATUS.sft_ra_room_size:
            // roomSize must be prefilled even when going back
            if (roomSize) choices.roomSize = roomSize;
            if (positioningType) choices.positioningType = positioningType;
            if (houseSize) choices.houseSize = houseSize;
        case APP_STATUS.sft_ra_positioning_type:
            if (numberOfRooms) choices.numberOfRooms = numberOfRooms;
        case APP_STATUS.sft_ra_room_builder:
            if (numberOfRooms) choices.numberOfRooms = numberOfRooms;
            if (airToAirType) choices.airToAirType = airToAirType;
            choices.roomType = roomType ?? 'multi';
            if (houseSurface) choices.houseSurface = houseSurface;
        case APP_STATUS.sft_ra_number_of_rooms:
            if (roomType) choices.roomType = roomType;
            if (houseSurface) choices.houseSurface = houseSurface;
        case APP_STATUS.sft_ra_air_to_air_type:
            if (airToAirType) choices.airToAirType = airToAirType;
        case APP_STATUS.sft_ra_room_type:
        default:
            // Going back to first SFT step, clear all
            break;
    }
    return choices;
};

export const getSftChoices = (
    lead: Lead,
    targetStatus: string,
): Partial<AirToWaterSftSelection | AirToAirSftSelection> => {
    return isAirToWaterLead(lead)
        ? getAirToWaterSftSelection(lead, targetStatus)
        : getAirToAirSftSelection(lead as AirToAirLead, targetStatus);
};

// Get all solutions matching the SFT-choices
const getSftAirToWaterResults = (
    store: Store,
    choices: Partial<AirToWaterSftSelection>,
): AirToWaterSftSolution[] => {
    const solutions = store.settings.solutions as AirToWaterSftSolution[];
    const { projectType, emitterType, buildingType, functionType, previousHeatingSystem } = choices;
    return solutions.filter((solution) => {
        return (
            solution.projectType === projectType &&
            solution.emitterType === emitterType &&
            solution.buildingType === buildingType &&
            solution.functionType === functionType &&
            (!previousHeatingSystem || solution.boilerType === previousHeatingSystem)
        );
    });
};

const getSftAirToAirResults = (
    store: Store,
    choices: Partial<AirToAirSftSelection>,
): AirToAirSftSolution[] => {
    const solutions = store.settings.solutions as AirToAirSftSolution[];
    const roomSizeSettings = getRoomSizeSettings(store);
    const { roomType, numberOfRooms, positioningType, roomSize, airToAirType } = choices;
    const roomSizeGroup =
        roomSize && roomSize > roomSizeSettings!.upToSize
            ? ROOM_SIZE_GROUP.max
            : ROOM_SIZE_GROUP.up_to;
    return solutions.filter((solution) => {
        if (!solution.isMultiRoomSolution) {
            // 1-room solutions
            if (roomType !== ROOM_TYPE.single) return false;
            if (solution.positioningType !== positioningType) return false;
            if (solution.roomSizeGroup !== roomSizeGroup) return false;
            if (solution.airToAirType !== airToAirType) return false;
        } else {
            // multi-room solutions
            if (roomType !== ROOM_TYPE.multi) return false;
            if (solution.numberOfRooms !== numberOfRooms) return false;
            if (solution.airToAirType !== airToAirType) return false;
        }
        return true;
    });
};

export const getSftResults = (
    store: Store,
    additionalChoices?: Partial<AirToWaterSftSelection | AirToAirSftSelection>,
): Array<AirToWaterSftSolution | AirToAirSftSolution | SingleRoomSolution> => {
    if (isAirToWaterProject(store)) {
        const choices = getAirToWaterSftSelection(
            store.lead as AirToWaterLead,
            APP_STATUS.sft_he_preferred_solution,
        );
        if (additionalChoices) Object.assign(choices, additionalChoices);
        return getSftAirToWaterResults(store, choices);
    } else if (isAirToAirProject(store)) {
        if (isAirToAirMultiRoomLead(store)) {
            return store.settings.singleRoomSolutions || [];
        }
        const choices = getAirToAirSftSelection(
            store.lead as AirToAirLead,
            APP_STATUS.sft_ra_preferred_solution,
        );
        if (additionalChoices) Object.assign(choices, additionalChoices);
        return getSftAirToAirResults(store, choices);
    }
    return [];
};

export const hasAtLeastOneMultiPlusSolutionEnabled = (state: Store) => {
    const { multiPlusSolutions } = state.settings;
    // No tank and no heatPump are enabled in AFF portal
    if (!multiPlusSolutions.tank && !multiPlusSolutions.heatPump) return false;
    // At least one tank volume is enabled
    if (multiPlusSolutions.tank && multiPlusSolutions.tank.selectedVolumes?.length > 0) return true;
    // At least one heatPump volume is enabled
    if (multiPlusSolutions.heatPump && multiPlusSolutions.heatPump.selectedVolumes.length > 0) {
        return true;
    }
};

export const getHouseholdComposition = (state: Store): number | null => {
    const { householdComposition } = state.lead;
    if (!householdComposition) return null;
    return householdComposition.adults + householdComposition.children;
};

type SplitSolutions = {
    split: true;
    keepRad: AirToWaterSftSolution[];
    yeetRad: AirToWaterSftSolution[];
};
type AllSolutions = { split: false; solutions: AirToWaterSftSolution[] };
export const getRadiatorSplitup = (state: AtwState): SplitSolutions | AllSolutions => {
    const solutions = getSftResults(state) as Array<AirToWaterSftSolution>;
    const { emitterType, projectType } = state.lead;
    const allResults: AllSolutions = { split: false, solutions };
    if (projectType !== PROJECT_TYPE.renovation) return allResults;
    if (emitterType !== EMITTER_TYPE.rad && emitterType !== EMITTER_TYPE.ufh_rad) return allResults;
    const keepRad = solutions.filter((s) => s.keepTheRadiators === KEEP_THE_RADIATORS.yes);
    const yeetRad = solutions.filter((s) => s.keepTheRadiators !== KEEP_THE_RADIATORS.yes);
    if (keepRad.length === 0 || yeetRad.length === 0) return allResults;
    return { split: true, keepRad, yeetRad };
};
