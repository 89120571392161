import { UNIT_TRANSLATION_TYPE } from '@common/types';

type TranslatedUnit = { name: string; type: 'indoor' | 'boiler' | 'option' };
// eslint-disable-next-line import/prefer-default-export
export const translateIndoorUnit = (
    indoorProductName: string,
    unitTranslationType: UNIT_TRANSLATION_TYPE,
): TranslatedUnit[] => {
    const [p1, p2] = indoorProductName.split('+').map((p) => p.trim());
    let result: TranslatedUnit[] = [];
    // eslint-disable-next-line default-case
    switch (unitTranslationType) {
        case UNIT_TRANSLATION_TYPE.DONT_TRANSLATE:
            result = [
                { name: p1, type: 'indoor' },
                { name: p2, type: 'indoor' },
            ];
            break;
        case UNIT_TRANSLATION_TYPE.FIRST_INDOOR_SECOND_BOILER:
            result = [
                { name: p1, type: 'indoor' },
                { name: p2, type: 'boiler' },
            ];
            break;
        case UNIT_TRANSLATION_TYPE.FIRST_INDOOR_SECOND_OPTION:
            result = [
                { name: p1, type: 'indoor' },
                { name: p2, type: 'option' },
            ];
            break;
        case UNIT_TRANSLATION_TYPE.INDOOR_TO_BOILER:
            result = [
                { name: p1, type: 'boiler' },
                { name: p2, type: 'boiler' },
            ];
            break;
        case UNIT_TRANSLATION_TYPE.ALL_TO_OPTION:
            result = [
                { name: p1, type: 'option' },
                { name: p2, type: 'option' },
            ];
            break;
    }
    if (!p2) return result.slice(0, 1);
    return result;
};
