import styled, { css } from 'styled-components';
import { LayoutProps, space as spacing, SpaceProps } from 'styled-system';
import { breakpoint } from '@common/components/Breakpoints';
import { color, fontWeight, space } from '../common/Theme';

export const MainContent = styled.div<{ isLight?: boolean } & LayoutProps & SpaceProps>`
    padding: 60px 0 160px;
    ${spacing};

    background-color: ${color.grey100};
    overflow-x: clip;
    min-height: 0;

    ${({ isLight }) =>
        isLight &&
        css`
            background-color: ${color.grey000};
        `};
`;

export const Container = styled.div`
    width: 100%;

    @media ${breakpoint.up('lg')} {
        padding: ${space[4]}px;
    }
`;

export const WideContainer = styled(Container)`
    margin: 0 auto;
    padding: 0 ${space[4]}px ${space[4]}px;

    @media ${breakpoint.up('xl')} {
        max-width: 1410px;
    }
`;

export const DetailContainer = styled(WideContainer)`
    margin: 0 auto;
    padding: 0 0 ${space[4]}px;

    @media ${breakpoint.up('lg')} {
        padding: 0 ${space[4]}px ${space[4]}px;
        max-width: 1410px;
    }
`;

export const NarrowContainer = styled(Container)`
    overflow-x: visible;
    margin: 0 auto;
    width: auto;

    @media ${breakpoint.up('md')} {
        max-width: 83.333%;
    }

    @media ${breakpoint.up('xl')} {
        max-width: 41.667%;
    }
`;

export const Title = styled.h2`
    margin-bottom: ${space[4]}px;
    font-size: 20px;
    font-weight: ${fontWeight.regular};
    line-height: 28px;
    text-align: center;
    color: ${color.grey600};
`;

export const Footer = styled.span`
    display: block;
    margin-bottom: ${space[4]}px;
    font-size: 12px;
    font-weight: ${fontWeight.light};
    line-height: 18px;
    text-align: center;
    color: ${color.grey400};

    span {
        display: block;
    }
`;

export const Divider = styled.hr<SpaceProps>`
    margin: ${space[4]}px 0;
    ${spacing};
`;
