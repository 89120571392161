import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { MODAL_TYPE } from '../../constants';
import { openModal } from '../../redux/actions';
import * as RatingStyles from '../../styles/components/Rating';

type PropsType = {
    label: string;
    namepace?: string;
};

const InfoTrigger = (props: PropsType): JSX.Element => {
    const dispatch = useDispatch();

    const onClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(openModal(MODAL_TYPE.info, i18n(props.label, true, props.namepace)));
    };

    return (
        <RatingStyles.Tooltip onClick={onClick}>
            <Icons.Glyph.InfoCircle />
        </RatingStyles.Tooltip>
    );
};

export default InfoTrigger;
