import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { FC } from 'react';
import * as DetachedStyles from '../../styles/components/Detached';
import * as PageStyles from '../../styles/components/Page';

const VersionExpired: FC = () => {
    const onHomePageClicked = (): void => {
        window.location.href = window.location.origin;
    };

    const onStartAgainClicked = (): void => {
        window.location.href = window.location.origin + window.location.pathname;
    };

    return (
        <PageStyles.MainContent>
            <DetachedStyles.Container>
                <DetachedStyles.Icon>
                    <Icons.ExtraLarge.Locked />
                </DetachedStyles.Icon>

                <DetachedStyles.Title>
                    {i18n('version_token_expired', true, 'validators')}
                </DetachedStyles.Title>

                <DetachedStyles.Actions>
                    <DetachedStyles.Action onClick={onHomePageClicked}>
                        <Icons.Glyph.House />
                        {i18n('goto_homepage')}
                    </DetachedStyles.Action>

                    <DetachedStyles.Action onClick={onStartAgainClicked}>
                        <Icons.Glyph.Back />
                        {i18n('start_again')}
                    </DetachedStyles.Action>
                </DetachedStyles.Actions>
            </DetachedStyles.Container>
        </PageStyles.MainContent>
    );
};

export default VersionExpired;
