import styled, { css } from 'styled-components';
import { SpaceProps, space as spacing } from 'styled-system';
import { breakpoint } from '@common/components/Breakpoints';
import { color, fontWeight, space } from '../common/Theme';
import * as ButtonStyles from './Button';
import * as CardStyles from './Card';
import * as PageStyles from './Page';
import * as PreloaderStyles from './Preloader';

export const Container = styled(PageStyles.WideContainer)<SpaceProps>`
    text-align: center;
    ${spacing};
`;

export const Header = styled.div<SpaceProps>`
    display: flex;
    padding-bottom: ${space[4]}px;
    ${spacing};
`;

export const Buttons = styled.div`
    display: flex;
    margin-left: auto;
`;

export const Body = styled.div``;

export const Close = styled(ButtonStyles.Link)`
    margin-left: auto;
`;

export const CloseLabel = styled.span`
    display: none;

    @media ${breakpoint.up('md')} {
        display: inline-block;
    }
`;

export const Title = styled.h1<SpaceProps>`
    font-size: 26px;
    font-weight: ${fontWeight.regular};
    line-height: 35px;
    color: ${color.grey500};
    ${spacing};
`;

const IconStyles = css`
    padding-bottom: ${space[4]}px;
    color: ${color.grey400};
`;

export const Icon = styled.div`
    ${IconStyles};
`;

export const Loader = styled(PreloaderStyles.Preloader)`
    ${IconStyles};
`;

export const Actions = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    padding-top: ${space[7]}px;
`;

export const Action = styled(CardStyles.Default)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 ${space[4]}px ${space[6]}px;
    font-size: 16px;
    font-weight: ${fontWeight.semibold};
    line-height: 20px;
    color: ${color.blue300};
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }

    @media ${breakpoint.up('md')} {
        flex-basis: 262px;
        flex-shrink: 0;
    }

    svg {
        margin-bottom: ${space[3]}px;
    }
`;
