import axios from 'axios';
import * as Sentry from '@sentry/react';
import { createLogic } from 'redux-logic';
import { Store, AirToWaterLead, IncomeRangeType } from '../../../types';
import {
    INCENTIVES_DRAPO,
    setDrapoData,
    setLeadLocal,
    IncentiveActionsType,
    SetDrapoAmountOfPeopleActionType,
    SetDrapoDataActionType,
    setDrapoIncomeRanges,
    setDrapoToolState,
} from '../../actions';
import { getFetchDrapoIncomeRangesPayload, getSaveDrapoToolDataPayload } from '../../selectors';

/* --- Types & Constants ----------------------------------------------------------------------- */

type DepObj = {
    getState: () => Store;
    action: IncentiveActionsType;
};

const RSN_HEADERS = {
    headers: {
        Accept: 'application/json',
    },
};

/* --- drapoLogic.validateFetchIncomeRangesLogic ----------------------------------------------- */

// Check if we should fetch new incomeRanges
const validateFetchIncomeRangesLogic = createLogic({
    type: INCENTIVES_DRAPO.setAmountOfPeople,
    name: 'drapoLogic.validateFetchIncomeRangesLogic',
    validate({ getState, action }: DepObj, allow, reject) {
        const store = getState();
        const { amountOfPeople } = action as SetDrapoAmountOfPeopleActionType;
        const drapoToolData = store.lead.incentives?.toolData?.incentivesFR || {};
        // Do abort when already fetching income ranges from drapo
        const isAlreadyLoading = store.appState.isDrapoLoading;
        if (isAlreadyLoading) return reject({ ...action, type: `🔴/${action.type}` });
        // Do abort when no change in amount of people was detected (& incomeRanges are already known)
        const incomeRanges = drapoToolData?.incomeRanges;
        const isUnchanged = drapoToolData.amountOfPeople === amountOfPeople;
        if (isUnchanged && incomeRanges?.length) {
            return reject({ ...action, type: `🔴/${action.type}` });
        }
        // Allow change in amountOfPeople & trigger income range fetch in process block
        return allow(action);
    },
});

/* --- drapoLogic.fetchDrapoIncomeRangesLogic -------------------------------------------------- */

// Fetch income ranges when number of amountOfPeople changes
const fetchDrapoIncomeRangesLogic = createLogic({
    type: INCENTIVES_DRAPO.setAmountOfPeople,
    name: 'drapoLogic.fetchDrapoIncomeRangesLogic',
    async process({ getState }: DepObj, dispatch, done) {
        const store = getState();
        const payload = await getFetchDrapoIncomeRangesPayload(store);
        try {
            const {
                data: { responseData: incomeRanges },
            } = await axios.post<{ responseData: IncomeRangeType[] }>(
                store.settings.urls.proxyCall,
                payload,
                RSN_HEADERS,
            );
            dispatch(setDrapoIncomeRanges(incomeRanges));
            return done();
        } catch (err) {
            (err as Error).name = 'Error fetching Drapo income ranges';
            Sentry.captureException(err);
            dispatch(setDrapoToolState({ isDrapoAvailable: false, isDrapoLoading: false }));
            dispatch(setDrapoData({ incomeRanges: null }));
            return done();
        }
    },
});

/* --- drapoLogic.saveDrapoToolDataLogic ------------------------------------------------------- */

// Save drapo incentive results to lead
const saveDrapoToolDataLogic = createLogic({
    type: INCENTIVES_DRAPO.saveDrapoData,
    name: 'drapoLogic.saveDrapoToolDataLogic',
    async process(
        { getState, action }: DepObj & { action: SetDrapoDataActionType },
        dispatch,
        done,
    ) {
        // Store
        const store = getState();
        const payload = await getSaveDrapoToolDataPayload(store);
        const { income, ownerType } = payload.data.incentivesFR;
        // Abandon remote update when no relevant data was sent
        const shouldUpdate = Object.keys(action.data).some((dataKey) => {
            return ['income', 'ownerType', 'surface'].includes(dataKey);
        });
        if (!shouldUpdate) return done();
        // Abandon remote update when not all necessary data is known
        if (!income || !ownerType) return done();
        // Update loading state
        dispatch(setDrapoToolState({ isDrapoLoading: true }));
        // Update drapo data on lead
        try {
            const { data } = await axios.post<{ lead: AirToWaterLead }>(
                store.settings.urls.updateIncentivesToolData,
                payload,
                RSN_HEADERS,
            );
            dispatch(setDrapoToolState({ isDrapoAvailable: true, isDrapoLoading: false }));
            dispatch(setLeadLocal(data.lead, false, 'saveDrapoToolDataLogic', action.type));
            return done();
        } catch (err) {
            (err as Error).name = 'Error saving Drapo incentives results';
            Sentry.captureException(err);
            dispatch(setDrapoToolState({ isDrapoAvailable: false, isDrapoLoading: false }));
            return done();
        }
    },
});

/* --- Exports --------------------------------------------------------------------------------- */

export default [
    validateFetchIncomeRangesLogic,
    fetchDrapoIncomeRangesLogic,
    saveDrapoToolDataLogic,
];
