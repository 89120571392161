import React from 'react';
import autobind from 'autobind-decorator';
import { arraysEqual } from '@tsUtils';
import ImageLoader from './ImageLoader';

const draggableCss = {
    position: 'absolute',
    userSelect: 'none',
    cursor: 'pointer',
    zIndex: 10000,
};

const imgContainerCss = Object.freeze({
    userDrag: 'none',
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserDrag: 'none',
    WebkitUserSelect: 'none',
    MsUserSelect: 'none',
    pointerEvents: 'none',
});

const STATUS = Object.freeze({
    PRELOAD: 'PRELOAD',
    WAITING: 'WAITING',
    LOADING: 'LOADING',
    COMPLETE: 'COMPLETE',
});

export default class Spin extends React.Component {

    constructor(props) {
        super(props);
        // Initial data
        this.state = { index: null };
        this.loaders = [];
        this.status = STATUS.PRELOAD;
        this.nextFrames = [];
        this.restFrames = [];
        this.dragging = false;
        // Refs
        this.draggable = React.createRef();
        // Parse props
        this.organizeLoaders(props, false);
    }

    componentDidMount() {
        this.startInitialLoad();
    }

    componentDidUpdate(prevProps) {
        // Only update loaders when new images-array has changed
        if (!arraysEqual(prevProps.images, this.props.images)) this.organizeLoaders(this.props, true);
    }

    @autobind
    organizeLoaders({ width, height, initialDirection, initialFrames, images }, startLoading = false) {
        // Create new or reuse existing loaders
        const loadersArr = [];
        images.forEach((img, i) => {
            const loader = this.loaders.find(l => l.url === img) || new ImageLoader(img, i, width, height);
            loader.index = i;
            loadersArr.push(loader);
        });
        this.loaders = loadersArr;
        // Start at middle index
        this.status = STATUS.PRELOAD;
        this.startFrame = images.length >> 1;
        // Define initial load order
        const dir = (initialDirection === 'right') ? -1 : 1;
        if (initialFrames) {
            // Create list of defined number of images
            this.nextFrames = [...new Array(initialFrames - 1)]
                .map((v, i) => this.startFrame + (dir * (1 + i)))
                .filter(v => ((v > 0) && (v < images.length)));
            // Define full load order
            this.restFrames = images
                .map((img, i) => i)
                .filter(i => !(i === this.startFrame || this.nextFrames.includes(i)))
                .sort((a, b) => (Math.abs(this.startFrame - a) - Math.abs(this.startFrame - b)));
        } else {
            // List all images in correct order
            this.nextFrames = images
                .map((img, i) => i)
                .sort((a, b) => (Math.abs(this.startFrame - a) - Math.abs(this.startFrame - b)));
            // No restFrames
            this.restFrames = [];
        }
        // Load first image
        if (startLoading) this.startInitialLoad();
    }

    @autobind
    startInitialLoad() {
        // Start load process
        this.loadNext([this.startFrame], () => {
            // First image loaded. Show initial frame before advancing
            this.setState({ index: this.startFrame });
            // Load subsequent 4 images
            this.loadNext([...this.nextFrames], () => {
                if (this.props.initialFrames) {
                    // All images loaded. Start animation in half a second
                    setTimeout(this.animateToNext, 100, [...this.nextFrames], () => {
                        // Animation complete. Attach drag-handlers
                        this.attachHandlers();
                    });
                } else {
                    // No initialFrames defined. Just attach handlers without animation
                    this.attachHandlers();
                }
            });
        });
    }

    // Preload a sequence of images
    @autobind
    loadNext(sequence, completeHandler) {
        if (sequence.length) {
            this.loaders[sequence.shift()].startLoad(() => {
                this.loadNext(sequence, completeHandler);
            });
        } else {
            completeHandler();
        }
    }

    // Animate over a sequence of images
    @autobind
    animateToNext(sequence, completeHandler) {
        if (sequence.length) {
            this.setState({ index: sequence.shift() });
            setTimeout(this.animateToNext, 50, sequence, completeHandler);
        } else {
            completeHandler();
        }
    }

    @autobind
    loadFullSpin() {
        if (this.status === STATUS.WAITING) {
            // Notify watchers
            if (this.props.onStartedInteraction) this.props.onStartedInteraction();
            // Start loading
            this.status = STATUS.LOADING;
            this.loadNext([...this.restFrames], () => {
                this.status = STATUS.COMPLETE;
            });
        }
    }

    @autobind
    attachHandlers() {
        // Only attach once when refs are set
        if (!this.hasHandlers && this.draggable.current) {
            this.hasHandlers = true;
            // Fields
            const step = this.props.width >> 5;
            let x = null;
            // Utility
            const getX = e => {
                if (e.clientX !== undefined) return e.clientX;
                if (e.touches && e.touches[0]) return e.touches[0].pageX;
                return 0;
            };
            // UI Handlers
            const onStart = e => {
                x = getX(e);
                this.dragging = true;
                this.loadFullSpin();
                if (this.props.onChangeAngle) this.props.onChangeAngle(this.state.index);
            };
            const onMove = e => {
                const clientX = getX(e);
                // Only update if dragging & movement is larger than 'step'
                if (this.dragging && (Math.abs(clientX - x) > step)) {
                    // Delta = x-diff rounded down to 'step'. e.g.: x-diff = 43, step = 20 -> delta = 2
                    const delta = ((clientX - x) - ((clientX - x) % step));
                    // Add n-number of angles to the current index
                    this.showAngle(this.state.index - (delta / step));
                    // Add delta value to x (instead of settings clientX so we keep distance lost by '% step')
                    x += delta;
                }
            };
            const onEnd = () => {
                x = null;
                this.dragging = false;
                if (this.props.onChangeAngle) this.props.onChangeAngle(null);
            };
            // Start dragging
            this.draggable.current.addEventListener('mousedown', onStart);
            this.draggable.current.addEventListener('touchstart', onStart, false);
            // Move dragging
            window.addEventListener('mousemove', onMove);
            window.addEventListener('touchmove', onMove, false);
            // Stop dragging
            window.addEventListener('mouseup', onEnd);
            window.addEventListener('touchend', onEnd, false);
            window.addEventListener('touchcancel', onEnd, false);
            // Notify watchers
            if (this.props.onDoneLoading) this.props.onDoneLoading();
        }
        // Update
        this.status = STATUS.WAITING;
    }

    @autobind
    showAngle(requested) {
        // Find closest loaded img
        const closestIndex = this.loaders
            .filter(loader => loader.loaded)
            .sort((a, b) => Math.abs(a.index - requested) - Math.abs(b.index - requested))[0].index;
        // Check if state changed
        if (this.state.index !== closestIndex) {
            // Update state
            this.setState({ index: closestIndex });
            // Notify parent
            if (this.dragging && this.props.onChangeAngle) {
                this.props.onChangeAngle(closestIndex);
            }
        }
    }

    render() {
        const { height, renderAsBackground = false } = this.props;
        const { index } = this.state;

        let positionCss = {};

        if (!renderAsBackground) {
            positionCss = { position: 'relative' };
        }

        return (
            <div style={positionCss}>
                {/* Loader - TODO - Replace with icon */}
                {(index === null) && <div>Loading...</div>}
                {/* UI handler */}
                <div
                    className="draggable-overlay"
                    style={{ width: '100%', height, ...draggableCss }}
                    ref={this.draggable}
                />
                {/* Images */}
                <div style={imgContainerCss}>
                    {this.loaders.map(loader => (loader.loaded && (loader.index === index) && (
                        renderAsBackground ? (
                            <picture
                                key={loader.url}
                                style={{ 'backgroundImage': `url(${loader.url})` }}
                                draggable={false}
                                width='auto'
                                height={height}
                            />
                        ) : (
                            <img
                                key={loader.url}
                                src={loader.url}
                                alt=''
                                draggable={false}
                                width='auto'
                                height={height}
                            />
                        )
                    )))}
                </div>
            </div>
        );
    }

}
