import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../redux';
import { redirect } from '../../../redux/actions';
import * as BasketStyles from '../../../styles/components/Basket';
import * as ButtonStyles from '../../../styles/components/Button';
import { Cta } from '../../../types';

const CallToActions = (): JSX.Element | null => {
    const dispatch = useDispatch();
    const callToActions = useAppSelector((store) => store.settings.callToActions);

    if (!callToActions?.length) return null;

    const onClickCta = (cta: Cta): void => {
        dispatch(redirect(cta.url, cta.target, true));
    };

    return (
        <BasketStyles.CallToActions>
            <BasketStyles.CallToActionsTitle>
                {i18n('need_advice_title')}
            </BasketStyles.CallToActionsTitle>
            <BasketStyles.CallToActionsDescription>
                {i18n('need_advice_description')}
            </BasketStyles.CallToActionsDescription>
            {callToActions.map((cta, i) => (
                <ButtonStyles.PrimaryOutline
                    isLarge
                    mb={4}
                    onClick={() => onClickCta(cta)}
                    key={`cta_${i}`}
                >
                    {cta.title}
                    <Icons.Glyph.Redirect />
                </ButtonStyles.PrimaryOutline>
            ))}
        </BasketStyles.CallToActions>
    );
};

export default CallToActions;
