import {
    SingleRoomSolution,
    MultiRoomSolution,
    Settings,
    AirToWaterSftSolution,
    AirToAirSftSolution,
} from '../../types';

export enum SETTINGS_ACTIONS {
    fetchSettings = 'settings/fetchSettings',
    setSettings = 'settings/setSettings',
    fetchSolutions = 'settings/fetchSolutions',
    setSolutions = 'settings/setSolutions',
}

type FetchSettingsAction = { type: SETTINGS_ACTIONS.fetchSettings };
export const fetchSettings = (): FetchSettingsAction => ({
    type: SETTINGS_ACTIONS.fetchSettings,
});

export type SetSettingsAction = {
    type: SETTINGS_ACTIONS.setSettings;
    settings: Partial<Settings>;
};
export const setSettings = (settings: Partial<Settings>): SetSettingsAction => ({
    type: SETTINGS_ACTIONS.setSettings,
    settings,
});

export type FetchSolutionsAction = { type: SETTINGS_ACTIONS.fetchSolutions };
export const fetchSolutions = (): FetchSolutionsAction => ({
    type: SETTINGS_ACTIONS.fetchSolutions,
});

export type SetSolutionsAction = {
    type: SETTINGS_ACTIONS.setSolutions;
    solutions: Array<AirToWaterSftSolution | AirToAirSftSolution>;
    singleRoomSolutions?: Array<SingleRoomSolution>;
    multiRoomSolutions?: Array<MultiRoomSolution>;
};
export const setSolutions = (
    solutions: Array<AirToWaterSftSolution | AirToAirSftSolution>,
    singleRoomSolutions?: Array<SingleRoomSolution>,
    multiRoomSolutions?: Array<MultiRoomSolution>,
): SetSolutionsAction => ({
    type: SETTINGS_ACTIONS.setSolutions,
    solutions,
    singleRoomSolutions,
    multiRoomSolutions,
});

export type SettingsActionsType =
    | FetchSettingsAction
    | SetSettingsAction
    | FetchSolutionsAction
    | SetSolutionsAction;
