import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useDebounceValue, useWindowSize } from 'usehooks-ts';
import * as BasketStyles from '../../../styles/components/Basket';
import PreventScrollHelper from '../PreventScrollHelper';
import AlthermaToggle from './AlthermaToggle';
import BasketPrices from './BasketPrices';
import BasketSolution from './BasketSolution';
import CallToActions from './CallToActions';
import ContactDealerButton from './ContactDealerButton';
import ContinueButton, { ContinueButtonProps } from './ContinueButton';
import Epackages from './Epackages';
import EvsSidePanel from './EvsSidePanel';
import PhasingToggle from './PhasingToggle';
import Save from './Save';
import ServicePartnerInfo from './ServicePartnerInfo';

type PropsType = {
    showPhaseToggle?: boolean;
    showAlthermaToggle?: boolean;
    showSolution?: boolean;
    showPrices?: boolean;
    showIncentives?: boolean;
    showEvsSidePanel?: boolean;
    showServicePartnerInfo?: boolean;
    showAaltraPortal?: boolean;
    showContactADealer?: boolean;
    showCallToActions?: boolean;
    showEpackages?: boolean;
    continueButtonProps?: ContinueButtonProps;
    showSave?: boolean;
    hideSplitPrice?: boolean;
    children?: React.ReactNode;
};

const Basket = ({
    showPhaseToggle = false,
    showAlthermaToggle = false,
    showSolution = false,
    showPrices = false,
    showIncentives = false,
    showEvsSidePanel = false,
    showServicePartnerInfo = false,
    showAaltraPortal = false,
    showContactADealer = false,
    showCallToActions = false,
    showEpackages = false,
    showSave = false,
    hideSplitPrice = false,
    continueButtonProps,
    children,
}: PropsType): JSX.Element => {
    // (Mobile) open state
    const [isOpen, setIsOpen] = useState(false);
    const onToggleIsOpen = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    // Set height of panel-title in CSS variable
    const [height, setHeight] = useState(0);
    const elementRef = useRef<HTMLDivElement>(null);

    const windowSize = useWindowSize();
    const windowSizeStr = useDebounceValue<string>(JSON.stringify(windowSize), 250);

    useEffect(() => {
        setHeight(elementRef?.current?.clientHeight ?? 110);
    }, [isOpen, setHeight, elementRef, windowSizeStr]);

    return (
        <>
            <PreventScrollHelper enabled={isOpen} />
            {isOpen && <BasketStyles.Backdrop />}
            <BasketStyles.Basket isOpen={isOpen} pb={0} height={height}>
                <BasketStyles.Wrapper>
                    <BasketStyles.MobileToggleButton type="button" onClick={onToggleIsOpen}>
                        {isOpen ? <Icons.Glyph.Close /> : <Icons.Glyph.Plus />}
                    </BasketStyles.MobileToggleButton>
                    {showSolution && <BasketSolution />}
                    {showPhaseToggle && <PhasingToggle />}
                    {showAlthermaToggle && <AlthermaToggle />}
                    {/* TODO - MOBILE CONTINUE BUTTON */}
                    {showPrices && (
                        <BasketPrices
                            showIncentives={showIncentives}
                            hideSplitPrice={hideSplitPrice}
                        />
                    )}
                    {showEvsSidePanel && <EvsSidePanel />}
                    {showServicePartnerInfo && <ServicePartnerInfo />}
                    {children}
                    {showAaltraPortal && (
                        <BasketStyles.ContinueButtonTeleportTarget
                            key="teleport-target"
                            id="cta-teleport-target"
                        />
                    )}
                    {(showContactADealer || continueButtonProps) && (
                        <BasketStyles.ContinueButtonGroup ref={elementRef}>
                            {continueButtonProps && (
                                <ContinueButton continueButtonProps={continueButtonProps} />
                            )}
                            {showContactADealer && (
                                <BasketStyles.ContactDealer>
                                    <ContactDealerButton />
                                </BasketStyles.ContactDealer>
                            )}
                        </BasketStyles.ContinueButtonGroup>
                    )}
                    {showCallToActions && <CallToActions />}
                    {/* temporarily hide save button, pardot not ready */}
                    {/* {showSave && (
                        <BasketStyles.Actions>
                            <Save />
                        </BasketStyles.Actions>
                    )} */}
                </BasketStyles.Wrapper>
            </BasketStyles.Basket>
            {showEpackages && <Epackages />}
        </>
    );
};

export default Basket;
