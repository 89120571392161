import i18n from '@common/i18n';
import * as Icons from '@common/icons';
import React from 'react';
import StarRating from '../../../atoms/StarRating';
import * as GridStyles from '../../../styles/components/Grid';
import * as RatingStyles from '../../../styles/components/Rating';
import { ConnectivityType } from '../../../types';

type PropsType = {
    energy: number;
    comfort: number;
    airQuality: number;
    connectivity: ConnectivityType;
    showIcons?: boolean;
};

const RatingCard = ({
    energy,
    comfort,
    airQuality,
    connectivity,
    showIcons = false,
}: PropsType): JSX.Element => {
    return (
        <RatingStyles.Box mt={5}>
            <GridStyles.Row>
                <GridStyles.Column width={{ xs: 1, md: 1 / 2 }}>
                    <RatingStyles.Group>
                        <RatingStyles.Label>
                            {showIcons && (
                                <>
                                    <Icons.Glyph.Zap />
                                    &nbsp;
                                </>
                            )}
                            {i18n('energy_efficiency')}
                        </RatingStyles.Label>
                        <RatingStyles.Rating>
                            <StarRating rating={energy} />
                        </RatingStyles.Rating>
                    </RatingStyles.Group>

                    <RatingStyles.Group>
                        <RatingStyles.Label>
                            {showIcons && (
                                <>
                                    <Icons.Glyph.Sun />
                                    &nbsp;
                                </>
                            )}
                            {i18n('comfort')}
                        </RatingStyles.Label>
                        <RatingStyles.Rating>
                            <StarRating rating={comfort} />
                        </RatingStyles.Rating>
                    </RatingStyles.Group>
                </GridStyles.Column>

                <GridStyles.Column width={{ xs: 1, md: 1 / 2 }}>
                    <RatingStyles.Group>
                        <RatingStyles.Label>
                            {showIcons && (
                                <>
                                    <Icons.Glyph.Wind />
                                    &nbsp;
                                </>
                            )}
                            {i18n('indoor_air_quality')}
                        </RatingStyles.Label>
                        <RatingStyles.Rating>
                            <StarRating rating={airQuality} />
                        </RatingStyles.Rating>
                    </RatingStyles.Group>

                    <RatingStyles.Group>
                        <RatingStyles.Label>{i18n('connectivity')}</RatingStyles.Label>
                        <RatingStyles.Value>{i18n(connectivity)}</RatingStyles.Value>
                    </RatingStyles.Group>
                </GridStyles.Column>
            </GridStyles.Row>
        </RatingStyles.Box>
    );
};

export default RatingCard;
