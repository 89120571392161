import styled from 'styled-components';
import { SpaceProps, space as spacing } from 'styled-system';
import { color, fontWeight } from '../styles/general';

export const space = [0, 5, 10, 15, 20, 30, 40, 60, 80, 120, 160];

export const Default = styled.div<SpaceProps>`
    display: flex;
    margin: 0 0 ${space[5]}px;
    padding: ${space[4]}px;
    font-size: 16px;
    font-weight: ${fontWeight.semibold};
    line-height: 20px;
    ${spacing};

    svg {
        flex-shrink: 0;
        margin-right: ${space[4]}px;
    }
`;

export const Info = styled(Default)`
    color: ${color.blue400};
    background-color: ${color.blue100};
`;

export const Success = styled(Default)`
    color: ${color.green400};
    background-color: ${color.green100};
`;

export const Warning = styled(Default)`
    color: ${color.yellow400};
    background-color: ${color.yellow100};
`;

export const Error = styled(Default)`
    color: ${color.red400};
    background-color: ${color.red100};
`;
