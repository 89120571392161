import styled from 'styled-components';
import { space as spacing, SpaceProps } from 'styled-system';
import { color, fontWeight } from '../common/Theme';

export const Default = styled.div<SpaceProps>`
    display: inline-flex;
    padding: 4px 15px 6px;
    font-size: 12px;
    font-weight: ${fontWeight.semibold};
    line-height: 18px;
    text-align: center;
    height: 28px;
    min-width: 55px;
    border-radius: 14px;
    ${spacing};
`;

export const Info = styled(Default)`
    color: ${color.blue400};
    background-color: ${color.blue100};
`;

export const Success = styled(Default)`
    color: ${color.green400};
    background-color: ${color.green100};
`;

export const Warning = styled(Default)`
    color: ${color.yellow400};
    background-color: ${color.yellow100};
`;

export const Error = styled(Default)`
    color: ${color.red400};
    background-color: ${color.red100};
`;
