import styled, { css } from 'styled-components';
import { space as spacing, SpaceProps } from 'styled-system';
import { color, fontWeight, space } from '../common/Theme';
import * as CardStyles from './Card';

export const Group = styled.div`
    margin-bottom: ${space[1]}px;
`;

export const Label = styled.span`
    position: relative;
    display: block;
    margin-bottom: ${space[1]}px;
    font-size: 13px;
    color: ${color.grey600};

    > svg {
        color: ${color.blue300};
    }
`;

export const Value = styled.span`
    display: block;
    font-size: 13px;
    color: ${color.grey900};
`;

export const Tooltip = styled.div`
    display: inline-block;
    position: absolute;
    margin-left: 5px;
    margin-top: 2px;

    svg {
        width: 16px;
        height: 16px;
        color: ${color.grey600};
    }
`;

export const Rating = styled.span`
    display: block;
    padding-left: ${space[1]}px;
`;

type StarProps = {
    isFull?: boolean;
    isHalf?: boolean;
    isEmpty?: boolean;
};
export const Star = styled.span<StarProps>`
    margin-right: ${space[1]}px;

    svg {
        ${({ isFull }) =>
            isFull &&
            css`
                color: ${color.yellow300};
            `};

        ${({ isHalf }) =>
            isHalf &&
            css`
                color: ${color.yellow300};
            `};

        ${({ isEmpty }) =>
            isEmpty &&
            css`
                color: ${color.grey300};
            `};
    }
`;

export const Box = styled(CardStyles.Box)<SpaceProps>`
    ${spacing};

    ${Group} {
        margin-bottom: ${space[1]}px;
    }

    ${Group}:not(:last-child) {
        margin-bottom: ${space[5]}px;
    }

    ${Label} {
        font-size: 16px;
        font-weight: ${fontWeight.semibold};
        line-height: 20px;
        color: ${color.grey800};
    }

    ${Value} {
        font-size: 16px;
        line-height: 24px;
        color: ${color.grey500};
    }
`;
