/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { APP_STATUS, DETACHED_SCREEN_TYPE, RSN_SECTION, TRANSITION } from '../../constants';
import {
    AirToAirLead,
    AirToWaterLead,
    AppStatus,
    DetachedScreenData,
    Lead,
    ModalData,
    Store,
    TransitionTypes,
} from '../../types';
import {
    getAreIncentivesAllowed,
    getCurrentSection,
    isAirToAirLead,
    isAirToAirMultiRoomLead,
    isAirToWaterLead,
} from './genericSelectors';
import * as ataNavigationSelectors from './navigationSelectors.ata';
import * as atwNavigationSelectors from './navigationSelectors.atw';

export const getNextStep = (lead: Lead): AppStatus | null => {
    if (isAirToWaterLead(lead)) return atwNavigationSelectors.getNextStep(lead);
    if (isAirToAirLead(lead)) return ataNavigationSelectors.getNextStep(lead as AirToAirLead);
    return null;
};

export const getTotalSteps = (state: Store): number => {
    if (isAirToAirMultiRoomLead(state)) return 3;
    if (getAreIncentivesAllowed(state)) return 5;
    return 4;
};

export const isFinalSftStep = (store: Store): boolean => {
    switch (store.lead.status) {
        case APP_STATUS.sft_he_building_type:
        case APP_STATUS.sft_ra_room_size:
        case APP_STATUS.sft_ra_room_builder:
            return true;
        default:
            return false;
    }
};

export const getTransitionToStep = (store: Store, target: string): TransitionTypes | null => {
    // Is transition to sizing tool substep?
    if (target.startsWith(`${APP_STATUS.sp_sizing_tool}.`)) {
        return TRANSITION.view_sizing_tool;
    }
    if (target.startsWith(`${APP_STATUS.sp_quotation_tool}.`)) {
        return TRANSITION.view_quotation_tool;
    }
    // Common steps
    // eslint-disable-next-line default-case
    switch (target) {
        case APP_STATUS.sp_sizing_tool:
            return TRANSITION.view_sizing_tool;
        case APP_STATUS.sp_quotation_choice:
            return TRANSITION.view_quotation_choice;
        case APP_STATUS.sp_quotation_tool:
            return TRANSITION.view_quotation_tool;
        case APP_STATUS.incentives_tool:
            return TRANSITION.view_incentives_tool;
        case APP_STATUS.sp_selsoft_result:
            return TRANSITION.view_selsoft_result;
        case APP_STATUS.dealer_selection:
            return TRANSITION.view_dealer_selection;
        case APP_STATUS.dealer_selection_address_information:
            return TRANSITION.view_dealer_selection_address_information;
    }
    // SFT related transitions
    if (isAirToWaterLead(store.lead)) {
        return atwNavigationSelectors.getTransitionToStep(target as APP_STATUS);
    }
    if (isAirToAirLead(store.lead)) {
        return ataNavigationSelectors.getTransitionToStep(target as APP_STATUS);
    }
    // Nothing found
    return null;
};

export const isRsnPage = (page: string): boolean => {
    return Object.keys(APP_STATUS).includes(page);
};

export const isStepForward = (current: AppStatus, target: AppStatus): boolean => {
    return Object.keys(APP_STATUS).indexOf(target) > Object.keys(APP_STATUS).indexOf(current);
};

export const isFinished = (store: Store): boolean => store.lead.status === APP_STATUS.finish_lead;

export const getActiveModal = (store: Store, asOverlay: boolean = false): ModalData | undefined => {
    const filtered = store.appState.openModals.filter((modal) => modal.asOverlay === asOverlay);
    return filtered[filtered.length - 1];
};

export const getDetachedScreen = (store: Store): DetachedScreenData => {
    return store.appState.openDetachedScreens[store.appState.openDetachedScreens.length - 1];
};

export const getShowNavigation = (store: Store): boolean => {
    if (isFinished(store)) return false;
    const detached = getDetachedScreen(store);
    if (!detached) return true;
    switch (detached.type) {
        case DETACHED_SCREEN_TYPE.althermaChoice:
            return true;
        case DETACHED_SCREEN_TYPE.unlinkedLead:
        case DETACHED_SCREEN_TYPE.aemRedirectCCU:
            return false;
        default:
            return true;
    }
};

export const getProgress = (store: Store): number => {
    const divisor = getTotalSteps(store);
    switch (getCurrentSection(store)) {
        case RSN_SECTION.section_1_solution_finder_tree: {
            const sftProgress = isAirToWaterLead(store.lead)
                ? atwNavigationSelectors.getSftProgress(store.lead as AirToWaterLead)
                : ataNavigationSelectors.getSftProgress(store.lead as AirToAirLead);
            return sftProgress / divisor;
        }
        case RSN_SECTION.section_2_sizing_selsoft:
            if (store.lead.status === APP_STATUS.sp_sizing_tool) return 1.5 / divisor;
            if (store.lead.status === APP_STATUS.sp_selsoft_result) return 2 / divisor;
        case RSN_SECTION.section_3_incentives:
            return (divisor - 2.5) / divisor;
        case RSN_SECTION.section_4_quotation:
            if (isAirToAirMultiRoomLead(store)) return 2 / divisor;
            return (divisor - 1.5) / divisor;
        case RSN_SECTION.section_5_dealer_selection:
            return (divisor - 0.5) / divisor;
        default:
            return 0;
    }
};

export const hasBasketSelector = (store: Store): boolean => {
    return getCurrentSection(store) !== RSN_SECTION.section_1_solution_finder_tree;
};
