import React from 'react';
import { useAppSelector } from '../redux';

type PropsType = { solutionId: string };

const SftSingleRoomSolutionName = ({ solutionId }: PropsType): JSX.Element => {
    const solutionName = useAppSelector(
        (state) => state.settings.singleRoomSolutions!.find((s) => s.id === solutionId)!.name,
    );
    return <span>{solutionName}</span>;
};

export default SftSingleRoomSolutionName;
