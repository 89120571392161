import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const cachedRequests: Record<string, string[]> = {};

type CityPostalResponse = {
    setPostal: Dispatch<SetStateAction<string | null | undefined>>;
    cities: string[] | null;
    loading: boolean;
};
// eslint-disable-next-line import/prefer-default-export
export const useCityPostal = (
    urlGenerator: (postalCode: string) => Promise<string>,
    postalCode: string | undefined,
): CityPostalResponse => {
    const [postal, setPostal] = useState<null | string | undefined>(postalCode);
    const [cities, setCities] = useState<null | string[]>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getCities = async () => {
            if (postal) {
                if (cachedRequests[postal]) {
                    setCities(cachedRequests[postal]);
                } else {
                    setLoading(true);
                    const url = await urlGenerator(postal);
                    axios
                        .get(url)
                        .then((res) => {
                            const { data, status } = res;
                            if (status === 200 && data.success && data.cities) {
                                cachedRequests[postal] = data.cities;
                                setCities(data.cities);
                                setLoading(false);
                            } else {
                                setLoading(false);
                            }
                        })
                        .catch((err) => {
                            err.name = 'Error fetching cities';
                            Sentry.captureException(err);
                            setLoading(false);
                        });
                }
            }
        };
        getCities();
    }, [postal]);
    return { setPostal, cities, loading };
};
