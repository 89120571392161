/* eslint-disable default-case */
/* eslint-disable operator-linebreak */ // https://github.com/prettier/prettier/issues/3806
import { EnergyLabels } from '@common/data/variables';
import i18n from '@common/i18n';
import {
    AIR_TO_AIR_TYPES,
    COLOR_VARIATIONS,
    DHW_ENERGY_LABELS,
    ENERGY_LABELS,
    SPLIT_PRICE_TYPE,
} from '@common/types';
import { formatAsLongEnergyLabel, formatPrice, TsBoolean } from '@tsUtils';
import {
    APP_STATUS,
    FUNCTION_TYPE,
    INCENTIVES_STATUS,
    PHASING_TYPE,
    PROJECT_TYPE,
    RSN_SECTION,
    SOLUTION_TYPE,
} from '../../constants';
import {
    AirToAirLead,
    AirToAirSftSolution,
    AirToAirSingleRoomSbmSolution,
    AirToWaterLead,
    AirToWaterSbmSolution,
    AppSection,
    AtaEnergyLabelsData,
    AtaMultiPlusEnergyLabelsData,
    AtwEnergyLabelsData,
    EpackageType,
    EvsAtaHeatingData,
    EvsAtwData,
    EvsSolution,
    Lead,
    PhasingType,
    RoomSizeSettingsType,
    SbmSolution,
    SelSoftSolution,
    SingleRoomSolution,
    Store,
} from '../../types';
import { filterValidSolutions, getStatusSection } from '../../utils';
import { getSelectedDealer } from './dealerSelectors';

export const isAirToWaterProject = (store: Store): boolean => store.settings.type === 'heating';
export const isAirToAirProject = (store: Store): boolean => store.settings.type === 'room_airco';

export const isAirToWaterLead = (lead: Lead): lead is AirToWaterLead => {
    return lead.solutionType === SOLUTION_TYPE.heating;
};
export const isAirToAirLead = (lead: Lead): boolean => {
    return lead.solutionType === SOLUTION_TYPE.room_airco;
};

export const isAirToAirHeatingLead = (store: Store): boolean => {
    return (store.lead as AirToAirLead).airToAirType === AIR_TO_AIR_TYPES.winter_heating;
};
export const isAirToAirCoolingLead = (store: Store): boolean => {
    return (store.lead as AirToAirLead).airToAirType === AIR_TO_AIR_TYPES.summer_cooling;
};

export const isAirToAirMultiRoomLead = (state: Store): boolean => {
    return (state.lead as AirToAirLead).roomType === 'multi';
};

export const getPojectType = (store: Store): string => {
    if (isAirToWaterProject(store)) return 'heating'; // AirToWaterProject (HE)
    if (isAirToAirMultiRoomLead(store)) return 'airtoair'; // AirToAirProject (multi-split flow)
    if (isAirToAirHeatingLead(store)) return 'winterheating'; // AirToAirProject (RA)
    return 'cooling'; // AirToAirProject (RA)
};

export const getRoomSizeSettings = (store: Store): RoomSizeSettingsType | null => {
    const { positioningType } = store.lead as AirToAirLead;
    if (!positioningType) return null;
    const roomSizeSettings = isAirToAirHeatingLead(store)
        ? store.settings.ataHeatingRoomSizeSettings
        : store.settings.ataCoolingRoomSizeSettings;
    return roomSizeSettings?.find((size) => size.positioningType === positioningType) || null;
};

const getAreIncentivesAllowedUK = (state: Store): boolean => {
    // Cooling projects in UK don't have incentives
    if (!isAirToWaterProject(state)) return false;
    // Incentives are only shown if project type renovation
    if ((state.lead as AirToWaterLead).projectType !== PROJECT_TYPE.renovation) return false;
    return !state.lead.toolData?.sizingHeating?.['no-solution'];
};

const getAreIncentivesAllowedFR = (state: Store): boolean => {
    // FR incentives are impossible if commerce prices fails
    if (state.appState.commerceError) return false;
    // Cooling projects in FR don't have incentives
    if (isAirToAirProject(state)) return false;
    // Incentives are only shown if project type renovation
    if ((state.lead as AirToWaterLead).projectType !== PROJECT_TYPE.renovation) return false;
    return true;
};

export const getAreIncentivesAllowed = (store: Store): boolean => {
    if (
        !(store.lead.solution as AirToWaterSbmSolution | AirToAirSingleRoomSbmSolution)
            ?.allowIncentiveCalculation
    )
        return false;
    if (isAirToAirMultiRoomLead(store)) return false;
    switch (store.settings.country.isoCode) {
        case 'UK':
        case 'GB':
            return getAreIncentivesAllowedUK(store);
        case 'FR':
            return getAreIncentivesAllowedFR(store);
        case 'DE':
            return true;
        default:
            return false;
    }
};

export const getRemainingDuration = (store: Store): string => {
    const currentSection = getCurrentSection(store);
    const incentiveDuration = getAreIncentivesAllowed(store) ? 2 : 0;
    // 'Contact a dealer' CTA is actually only shown on sp_sizing_tool so only the first case is relevant.
    // Keeping this code intact because the CTA's are not clearly defined on confluence for RSN2.0
    switch (currentSection) {
        case RSN_SECTION.section_2_sizing_selsoft:
            return `${4 + incentiveDuration} - ${4 + incentiveDuration + 1}`;
        case RSN_SECTION.section_3_incentives:
            return `${incentiveDuration} - ${incentiveDuration + 1}`;
        case RSN_SECTION.section_4_quotation:
            return i18n('a_few');
        default:
            return '';
    }
};

export const getShowPrices = (store: Store): boolean => {
    return store.settings.solutionPricing?.pricingDisplayStrategy !== SPLIT_PRICE_TYPE.dont_show;
};

export const getPriceFormatter = (state: Store) => (price: number) => {
    return formatPrice(price, {
        decimalCount: state.settings.country.decimalCount,
        currencySymbol: state.settings.country.currency,
        currencyBeforeAmount: state.settings.country.isCurrencyBeforeAmount,
        decimalSeparator: state.settings.country.decimalSeparator,
        groupingSeparator: state.settings.country.thousandSeparator,
    });
};

export const getPriceTitle = (store: Store): keyof EvsSolution => {
    const priceStrat = store.settings.solutionPricing.pricingDisplayStrategy;
    switch (priceStrat) {
        case SPLIT_PRICE_TYPE.show_equipment_only:
            return 'systemPrice';
        case SPLIT_PRICE_TYPE.show_total_price:
        case SPLIT_PRICE_TYPE.show_split_prices:
        default:
            return 'totalPrice';
    }
};

export const getIsEvsApplicable = (store: Store): boolean => {
    // The panel is not shown for A2A leads with summer cooling as airToAirType
    if (isAirToAirProject(store) && isAirToAirCoolingLead(store)) return false;
    // The panel is not rendered if the Winter Heating Sizing Tool returns "usage-known" : false
    if (isAirToAirProject(store) && !store.lead.toolData?.sizingWinterHeating?.['usage-known']) {
        return false;
    }
    // All checks passed
    return true;
};

// Hide incentives row for Ata heating (Cfr.: https://jira.hosted-tools.com/browse/DAI001-8155)
export const getShowIncentivesRowEvsAtaHeating = (_store: Store): boolean => {
    return false;
    // if (!store.lead.solution?.allowIncentiveCalculation) return false;
    // // No incentives shown for Germany because the incentives return a percentage
    // if (isGermany(store)) return false;
    // // No incentives shown for France because only available for Atw (HE)
    // if (isFrance(store)) return false;
    // // No incentives shown for UK because only available for Atw (HE)
    // if (isUk(store)) return false;
    // // No incentives allowed for the other countries because they don't have an incentives tool
    // return false;
};

export const getShowIncentivesRow = (store: Store): boolean => {
    if (
        !(store.lead.solution as AirToWaterSbmSolution | AirToAirSingleRoomSbmSolution)
            ?.allowIncentiveCalculation
    )
        return false;
    // No incentives shown for Germany
    if (isGermany(store)) return false;
    // UK exceptions
    if (isUk(store)) {
        // UK incentives are only available for renovation-projects
        if ((store.lead as AirToWaterLead).projectType !== PROJECT_TYPE.renovation) return false;
        // UK incentives are pre-calculated by BE so incentives?.status is completed by default
        // Render incentives row if the user is on the selsoft result or incentives page
        return (
            store.lead.status === APP_STATUS.sp_selsoft_result ||
            store.lead.status === APP_STATUS.incentives_tool
        );
    }
    // FR incentives are only available for renovation-projects
    if (isFrance(store) && (store.lead as AirToWaterLead).projectType !== PROJECT_TYPE.renovation) {
        return false;
    }
    // No incentives shown when status is completed but no incentives are available
    if (
        store.lead.incentives?.status === INCENTIVES_STATUS.completed &&
        !store.lead.incentives?.incentives?.length
    ) {
        return false;
    }
    // All checks passed, show incentives
    return true;
};

export const isUk = (store: Store): boolean => {
    return store.settings.country.isoCode.toUpperCase() === 'GB';
};
export const isGermany = (store: Store): boolean => {
    return store.settings.country.isoCode.toUpperCase() === 'DE';
};
export const isFrance = (store: Store): boolean => {
    return store.settings.country.isoCode.toUpperCase() === 'FR';
};
export const isPortugal = (store: Store): boolean => {
    return store.settings.country.isoCode.toUpperCase() === 'PT';
};

export const getPhasing = (store: Store): PhasingType => {
    return store.lead.phasing || store.settings.defaultPhasing;
};

export const getCanUserTogglePhasing = (store: Store): boolean => {
    // Fetch selsoftResults from store or use override if available
    const selsoftResults = getValidSelsoftResults(store);
    // Can only render if SS results are present
    if (!selsoftResults) return false;
    // Check phasing of the results
    return selsoftResultsHaveMultiplePhases(selsoftResults);
};

export const selsoftResultsHaveMultiplePhases = (
    solutions: SelSoftSolution[] | undefined,
): boolean => {
    if (!solutions) return false;
    // Fetch the outdoor units
    const outdoorSolutions = solutions.filter((ssR) => ssR.outdoorUnit);
    // If the solution does not have an outdoor unit, phasing is irrelevant
    if (!outdoorSolutions.length) return false;
    // Get a unique list of all phase-types
    const phases = [...new Set(outdoorSolutions.map((ssR) => ssR.outdoorUnit!.powerSupply.phase))];
    // Return if single & multi-phase are both present or just one of them
    return phases.length > 1;
};

export const getCanUserToggleAltherma = (store: Store): boolean => {
    // No altherma toggle when multi-split flow is selected
    if (isAirToAirMultiRoomLead(store)) return false;
    // Check the backend requirements
    if (!store.lead.allowsSolutionVersions) return false;
    // If phasing-toggle is not available, neither is altherma
    if (!getCanUserTogglePhasing(store)) return false;
    // Only show is toggleable phasing is set to 1-phase
    if (getPhasing(store) !== PHASING_TYPE.single_phase) return false;
    // All tests passed
    return true;
};

export const getHasDhw = (lead: Lead): boolean => {
    if (isAirToAirLead(lead)) return false;
    if ((lead as AirToWaterLead).functionType === FUNCTION_TYPE.heating_cooling_dhw) return true;
    if ((lead as AirToWaterLead).functionType === FUNCTION_TYPE.heating_dhw) return true;
    return false;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const isUnauthorizedError = (error: any): boolean => {
    if (error.response?.data?.errors?.leadId?.includes('linked_to_other_customer')) return true;
    if (error.response?.data?.errors?.leadId?.includes('customer_token_required')) return true;
    return false;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const isSyncError = (error: any): boolean => {
    return error.response?.data?.errors?.version?.includes('version_token_expired');
};

export const shouldFetchLiterature = (store: Store, seriesIDs: string[]): boolean => {
    // There need to be some seriesIDs present
    if (seriesIDs.length === 0) return false;
    // SelSoft data is required for HE
    if (isAirToWaterProject(store) && !(store.lead as AirToWaterLead).selSoftData?.solution) {
        return false;
    }
    // solution.productNamePrv is required for RA
    const coolingSolution = (store.lead as AirToAirLead).solution;
    const sizingSolution = store.lead.toolData?.sizingCooling?.solution;
    if (
        isAirToAirProject(store) &&
        !((coolingSolution as AirToAirSingleRoomSbmSolution)?.productNamePrv && sizingSolution)
    ) {
        return false;
    }
    // Only check for changes if existing data is present
    if (
        store.lead.literatureData?.documents &&
        Object.keys(store.lead.literatureData?.documents).length > 0
    ) {
        // Check if there's a difference in these materialCodes
        const literatureCodes = Object.keys(store.lead.literatureData.documents);
        return literatureCodes.some((code) => !seriesIDs.includes(code));
    }
    // No data yet, fetch it
    return true;
};

// Color variations are indicated as last character of indoorUnit product name
export const getColorVariations = (indoorUnits: string[]): COLOR_VARIATIONS[] => {
    return (
        Array.from(new Set(indoorUnits.map(getColorCode)))
            // Filter out undefined values
            .filter(TsBoolean)
            // Always render in same order
            .sort((a, b) => 'WBST'.indexOf(a) - 'WBST'.indexOf(b)) as COLOR_VARIATIONS[]
    );
};

type ColorCode = 'W' | 'B' | 'S' | 'T';
const isColorCode = (color: string): color is ColorCode => {
    switch (color) {
        case 'W':
        case 'B':
        case 'S':
        case 'T':
            return true;
        default:
            return false;
    }
};
export const getColorCode = (productName: string): ColorCode | undefined => {
    const color = productName
        .toUpperCase()
        .replaceAll(/[^A-Z]+/g, '')
        .slice(-1);
    return isColorCode(color) ? color : undefined;
};

// When changing colorVariation give back the correct image for the chosen color
export const getSftAirToAirOverviewImage = (
    solution: AirToAirSftSolution,
    isLarge: boolean,
    chosenColor?: COLOR_VARIATIONS,
): string => {
    // Fetch images and check if any are set
    const images = isLarge ? solution.largeOverviewImages : solution.overviewImages;
    if (!images?.length) return '';
    // Return either chosenColor or first item
    if (chosenColor) {
        return images.find((img) => img.colorVariation === chosenColor)?.path || '';
    } else {
        return images[0]?.path || '';
    }
};

export const getRenderSubmitForm = (store: Store): boolean => {
    const { dealers, isFetchingDealers } = store.appState;
    const currentSection = getCurrentSection(store);
    const selectedDealer = getSelectedDealer(store);

    if (currentSection !== RSN_SECTION.section_5_dealer_selection) return false;
    if (isFetchingDealers) return false;
    if (selectedDealer) return true;
    if (dealers.length <= 0) return true;
    return false;
};

export const getEnergyLabels = (
    state: Store,
    solutionOverride?: SbmSolution | SingleRoomSolution,
): EnergyLabels => {
    const { lead } = state;
    const isAtwLead = isAirToWaterLead(lead);
    const isAtaMultiRoomLead = isAirToAirMultiRoomLead(state);
    // Create placeholder
    const energyLabels: EnergyLabels = {};
    /* On step 2 (selsoft result), step 4 (incentives) and step 5 (dealer selection)
     return the energy labels we got from energy labels api (DAI001-8643, DAI001-8516, DAI001-8827) */
    if (
        lead.status === APP_STATUS.sp_selsoft_result ||
        lead.status === APP_STATUS.incentives_tool ||
        lead.status === APP_STATUS.dealer_selection ||
        lead.status === APP_STATUS.dealer_selection_address_information
    ) {
        if (isAtwLead) {
            const energyLabelsData = lead?.energyLabelsData as AtwEnergyLabelsData;
            return {
                heating: formatAsLongEnergyLabel(
                    energyLabelsData?.packageSpaceHeatingLabel,
                ) as ENERGY_LABELS,
                dhw: formatAsLongEnergyLabel(
                    energyLabelsData?.packageWaterHeatingLabel,
                ) as DHW_ENERGY_LABELS,
            };
        } else if (isAirToAirCoolingLead(state) || isAirToAirHeatingLead(state)) {
            const energyLabelsData = lead?.energyLabelsData as AtaEnergyLabelsData;
            return {
                heating: formatAsLongEnergyLabel(
                    energyLabelsData?.product?.energyHeatingClass,
                ) as ENERGY_LABELS,
                cooling: formatAsLongEnergyLabel(
                    energyLabelsData?.product?.energyClass,
                ) as ENERGY_LABELS,
            };
        } else if (isAtaMultiRoomLead) {
            const energyLabelsData = lead?.energyLabelsData as AtaEnergyLabelsData;
            const multiPlusEnergyLabelsData =
                lead?.multiPlusEnergyLabelsData as AtaMultiPlusEnergyLabelsData;
            return {
                heating: formatAsLongEnergyLabel(
                    energyLabelsData?.product.energyHeatingClass,
                ) as ENERGY_LABELS,
                cooling: formatAsLongEnergyLabel(
                    energyLabelsData?.product.energyClass,
                ) as ENERGY_LABELS,
                dhw: formatAsLongEnergyLabel(
                    multiPlusEnergyLabelsData?.packageWaterHeatingLabel,
                ) as DHW_ENERGY_LABELS,
            };
        }
        return energyLabels;
    } else {
        // Fetch derived data from store
        const { solution, toolData } = lead;
        const hasDhw = getHasDhw(lead);
        const isAirToAir = isAirToAirLead(lead);
        // Fetch energyLabel data
        if (solutionOverride) {
            energyLabels.heating = (
                solutionOverride as AirToWaterSbmSolution | AirToAirSingleRoomSbmSolution
            ).heatingEnergyLabel;
            if (isAirToAir) {
                energyLabels.cooling = (
                    solutionOverride as AirToAirSingleRoomSbmSolution
                ).coolingEnergyLabel;
            } else if (hasDhw) {
                energyLabels.dhw = (
                    solutionOverride as AirToWaterSbmSolution
                ).domesticHotWaterEnergyLabel;
            }
        } else if (toolData?.quotationCooling) {
            const quotationData = toolData.quotationCooling;
            energyLabels.heating = formatAsLongEnergyLabel(
                quotationData.heating?.['energy-label'],
            ) as ENERGY_LABELS;
            energyLabels.cooling = formatAsLongEnergyLabel(
                quotationData.cooling?.['energy-label'],
            ) as ENERGY_LABELS;
        } else if (toolData?.quotationHeating) {
            const quotationData = toolData.quotationHeating;
            energyLabels.heating = formatAsLongEnergyLabel(
                quotationData['energy-label'],
            ) as ENERGY_LABELS;
            if (hasDhw)
                energyLabels.dhw = formatAsLongEnergyLabel(
                    quotationData['dhw-label'],
                ) as DHW_ENERGY_LABELS;
        }
        // Use lead.solution as a fallback
        // sometimes quotation.heating might be set but dhw/cooling is missing, fall back to solution for these values
        if (solution) {
            energyLabels.heating ||= (
                solution as AirToWaterSbmSolution | AirToAirSingleRoomSbmSolution
            ).heatingEnergyLabel;
            if (isAirToAir) {
                energyLabels.cooling ||= (
                    solution as AirToAirSingleRoomSbmSolution
                ).coolingEnergyLabel;
            } else if (hasDhw) {
                energyLabels.dhw ||= (
                    solution as AirToWaterSbmSolution
                ).domesticHotWaterEnergyLabel;
            }
        }
        return energyLabels;
    }
};

export const getEpackages = (store: Store): EpackageType[] => {
    return store.appState.epackages || [];
};

// Check if external tool is open
export const isExternelToolOpen = (store: Store): boolean => {
    const { status } = store.lead;
    const section = getStatusSection(status);
    const isIncentivesComplete = getIsIncentivesCompleted(store);
    const isDE = isGermany(store);
    // The sizing or quotation tool is open (step 2)
    if (section === RSN_SECTION.section_2_sizing_selsoft && status !== APP_STATUS.sp_selsoft_result)
        return true;
    // The incentives tool for Germany is open (step 3)
    if (isDE && section === RSN_SECTION.section_3_incentives && !isIncentivesComplete) return true;
    return false;
};

export const getHighestEvsPercentage = (store: Store): number | null => {
    const { evsData } = store.appState;
    if (!evsData) return null;
    // AirToAir (RA) Winter Heating
    if (isAirToAirHeatingLead(store)) {
        return (evsData as EvsAtaHeatingData).priceData?.boilerSolution.percentageMoreExpensive;
    }
    // AirToWater (HE)
    return Math.max(
        ...((evsData as EvsAtwData).priceData.benchmarkSolutions.map(
            ({ percentageMoreExpensive }) => percentageMoreExpensive,
        ) as number[]),
    );
};

// Co2 emission percentage saving value shown in side panel
// For ATW: null when benchmarks only have Pellets or LPG as an Energy source
// For ATA HE: if side panel is rendered then the energy consumption is known
export const getSavingCo2EmissionPercentage = (state: Store): string | null => {
    return state.appState.evsData?.co2Data?.co2EmissionSavings ?? null;
};

export const getIsIncentivesCompleted = (store: Store): boolean => {
    return store.lead.incentives?.status === INCENTIVES_STATUS.completed;
};

export const getValidSelsoftResults = (store: Store): SelSoftSolution[] => {
    return filterValidSolutions(store.appState.selsoftResults);
};

export const getCurrentSection = (store: Store): AppSection => getStatusSection(store.lead.status);
