import i18n from '@common/i18n';
import { COLOR_VARIATIONS, COLOR_VARIATION_LABELS } from '@common/types';
import React from 'react';

type PropsType = { colorVariation: COLOR_VARIATIONS };

const SftColorVariationName = ({ colorVariation }: PropsType): JSX.Element => {
    return <span>{i18n(COLOR_VARIATION_LABELS[colorVariation])}</span>;
};

export default SftColorVariationName;
