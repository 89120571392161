import { limitPercentageToMinimums } from '@tsUtils';
import React, { FC, useEffect } from 'react';
import SVG from 'svg.js';
import { PROJECT_TYPE } from '../../../constants';

type Coordinates = [x: number, y: number];

const COLORS = {
    green: '#12AE4F',
    orange: '#FA951B',
    gray: '#5E738A',
};

const PATHS = {
    new: `<path fill="${COLORS.gray}" d="M14.6085794,9.45788266 C13.4334381,9.09882894 13.8343133,7.42758357 14.9282809,7.49671288 L15.0438861,7.51019758 L15.1642189,7.53684972 L79.2677346,25.0422208 C79.6631025,25.1501878 79.948352,25.4865191 79.996948,25.8854927 L80.0043007,26.0068983 L80.0043007,77.0068983 C80.0043007,78.6045792 78.7553807,79.9105591 77.1805735,80.0018056 L77.0043007,80.0068983 L26.0043007,80.0068983 C25.9648518,80.0068983 25.9259364,80.004614 25.8876796,80.0001705 L3,80 C1.34314575,80 0,78.6568542 0,77 L0,52.9867045 C0,51.2152376 1.16516754,49.6547652 2.86364668,49.1515121 L25.004,42.5906289 L25.0047851,12.6345272 L14.6085794,9.45788266 Z M27.0047851,12.8435272 L27.0047851,78.0075272 L42.0000122,78.0075272 L42,50 C42,48.8954305 42.8954305,48 44,48 L62,48 C63.1045695,48 64,48.8954305 64,50 L64,78.0066289 L77.0043007,78.0075272 C77.5171365,78.0075272 77.9402255,77.6209447 77.9980503,77.1235286 L78.0047851,77.0068983 L78.0047851,26.7715272 L27.0047851,12.8435272 Z M25,44.678 L3.43182334,51.0691083 C2.63566124,51.3050082 2.0738646,52.0055064 2.00675002,52.8219691 L2,52.9867045 L2,77 C2,77.5128358 2.38604019,77.9355072 2.88337887,77.9932723 L3,78 L25,78 L25,44.678 Z M62,50 L44,50 L44,78 L62,78 L62,50 Z M50,63 C50.5522847,63 51,63.4477153 51,64 C51,64.5522847 50.5522847,65 50,65 L48,65 C47.4477153,65 47,64.5522847 47,64 C47,63.4477153 47.4477153,63 48,63 L50,63 Z" />`,
    reno: `<path fill="${COLORS.gray}" d="M40.5236162,5.75391617 L40.6270904,5.82698241 L56,18.201957 L56,11.999957 L55.0000349,12 C54.4477502,12 54.0000349,11.5522847 54.0000349,11 C54.0000349,10.4477153 54.4477502,10 55.0000349,10 L68.0000349,10 C68.5523197,10 69.0000349,10.4477153 69.0000349,11 C69.0000349,11.5522847 68.5523197,12 68.0000349,12 L67,11.999957 L67,27.056957 L79.6270904,37.2210254 C80.0573062,37.5673385 80.1253227,38.1968397 79.7790095,38.6270555 C79.4593358,39.0241777 78.8983659,39.1126805 78.475578,38.8515618 L78.3729795,38.7789746 L73,34.453957 L73.0000349,77 C73.0000349,78.5976809 71.751115,79.9036609 70.1763077,79.9949073 L70.0000349,80 L10.0000349,80 C8.40235404,80 7.09637404,78.75108 7.00512761,77.1762728 L7.00003492,77 L7,34.453957 L1.62709037,38.7789746 C1.22996809,39.0986483 0.663040584,39.0652837 0.305786121,38.7198827 L0.221060299,38.6270555 C-0.0986133912,38.2299332 -0.065248764,37.6630057 0.280152196,37.3057512 L0.372979465,37.2210254 L39.3729795,5.82698241 C39.7057965,5.55907286 40.167386,5.53471745 40.5236162,5.75391617 Z M40.0000349,7.889 L9,32.843957 L9,77 C9,77.5128358 9.38607511,77.9355072 9.88341379,77.9932723 L10.0000349,78 L29.0000349,78 L29.0000349,50 C29.0000349,48.8954305 29.8954654,48 31.0000349,48 L49.0000349,48 C50.1046044,48 51.0000349,48.8954305 51.0000349,50 L51.0000349,78 L70.0000349,78 C70.5128708,78 70.9355421,77.6139598 70.9933072,77.1166211 L71.0000349,77 L71.0000349,32.843957 L40.0000349,7.889 Z M49.0000349,50 L31.0000349,50 L31.0000349,78 L49.0000349,78 L49.0000349,50 Z M37.0000349,63 C37.5523197,63 38.0000349,63.4477153 38.0000349,64 C38.0000349,64.5522847 37.5523197,65 37.0000349,65 L35.0000349,65 C34.4477502,65 34.0000349,64.5522847 34.0000349,64 C34.0000349,63.4477153 34.4477502,63 35.0000349,63 L37.0000349,63 Z M65,11.999957 L58,11.999957 L58,19.811957 L65,25.446957 L65,11.999957 Z" />`,
    plant: '<path fill="#FFF" d="M4,1 C7.49,1 10.383,3.554 10.913,6.895 C12.088,5.724 13.71,5 15.5,5 L20,5 L20,7.5 C20,11.09 17.09,14 13.5,14 L11,14 L11,19 L9,19 L9,11 L7,11 C3.134,11 0,7.866 0,4 L0,1 L4,1 Z M18,7 L15.5,7 C13.015,7 11,9.015 11,11.5 L11,12 L13.5,12 C15.985,12 18,9.985 18,7.5 L18,7 Z M4,3 L2,3 L2,4 C2,6.761 4.239,9 7,9 L9,9 L9,8 C9,5.239 6.761,3 4,3 Z" />',
    plug: '<path fill="#FFF" d="M11,16 L11,20 L9,20 L9,16 L6,16 C3.790861,16 2,14.209139 2,12 L2,5 C2,4.44771525 2.44771525,4 3,4 L6,4 L6,0 L8,0 L8,4 L12,4 L12,0 L14,0 L14,4 L17,4 C17.5522847,4 18,4.44771525 18,5 L18,12 C18,14.209139 16.209139,16 14,16 L11,16 Z M6,14 L14,14 C15.1045695,14 16,13.1045695 16,12 L16,10 L4,10 L4,12 C4,13.1045695 4.8954305,14 6,14 Z M16,6 L4,6 L4,8 L16,8 L16,6 Z" />',
    gas: '<path fill="#FFF" d="M10,0 C15.3333333,5.00044071 18,9.1953125 18,12.5846154 C18,17.6685697 14.418278,20 10,20 C5.581722,20 2,17.6685697 2,12.5846154 C2,9.1953125 4.66666667,5.00044071 10,0 Z M10,2.784 L9.73945921,3.0472098 C5.87608536,6.97886021 4,10.2066002 4,12.5846154 C4,15.8135759 5.82531377,17.6451872 9.00005256,17.9533712 L9,14 C9,13.4477153 9.44771525,13 10,13 C10.5522847,13 11,13.4477153 11,14 L11.0009385,17.9533712 C14.1750661,17.644806 16,15.8132399 16,12.5846154 C16,10.2066002 14.1239146,6.97886021 10.2605408,3.0472098 L10,2.784 Z" />',
};

const getHousePath = (projectType: PROJECT_TYPE): string => {
    switch (projectType) {
        case PROJECT_TYPE.new:
            return PATHS.new;
        case PROJECT_TYPE.renovation:
            return PATHS.reno;
        default:
            return '';
    }
};

const drawIcon = (container: SVG.Nested, color: string, iconType: keyof typeof PATHS): void => {
    container.circle(40).fill(color).move(-20, -20);
    const iconContainer = container.nested();
    iconContainer.svg(PATHS[iconType]).move(-10, -10);
};

const getPercentageCoords = (perc: number, radius = 100): Coordinates => [
    100 + Math.round(radius * Math.cos(Math.PI * 2 * (perc + 0.75))),
    100 + Math.round(radius * Math.sin(Math.PI * 2 * (perc + 0.75))),
];

const calculateCurvePath = (startPerc: number, endPerc: number): string => {
    const center = [100, 100];
    const start = getPercentageCoords(startPerc);
    const end = getPercentageCoords(endPerc);
    const relativeEnd = [end[0] - start[0], end[1] - start[1]];
    return `M ${start.join(',')} a ${center.join(',')} 0 0,1 ${relativeEnd.join(',')}`;
};

const drawSection = (
    container: SVG.Nested,
    color: string,
    iconType: keyof typeof PATHS,
    from: number,
    to: number,
    fixedCenter?: number | undefined,
): void => {
    const center = (from + to) / 2;
    // Draw arc
    // Arcs can only span 180° -> if from-to exceeds 50% (180°) we split the arc in two parts
    if (to - from > 0.5) {
        container.svg(`
            <path
                fill="none"
                stroke-width="7"
                stroke="${color}"
                d="${calculateCurvePath(from, center)}"
            />
            <path
                fill="none"
                stroke-width="7"
                stroke="${color}"
                d="${calculateCurvePath(center, to)}"
            />
        `);
    } else {
        container.svg(`
            <path
                fill="none"
                stroke-width="7"
                stroke="${color}"
                d="${calculateCurvePath(from, to)}"
            />
        `);
    }
    // Draw icon
    const icon = container.nested();
    drawIcon(icon, color, iconType);
    const [eX, eY] = getPercentageCoords(fixedCenter ?? center);
    icon.move(eX, eY);
};

type PropsType = {
    scopSvgId?: string;
    projectType: PROJECT_TYPE;
    renewablePercentage: number; // renewable percentage
    electricityPercentage: number; // electricity percentage
    gasPercentage: number; // gas percentage
};

const ScopGraph: FC<PropsType> = (props) => {
    // Make a list of percentages sorted in ascending order
    const percentages = limitPercentageToMinimums(
        [
            { id: 'renewable', value: props.renewablePercentage || 0 },
            { id: 'electricity', value: props.electricityPercentage || 0 },
            { id: 'gas', value: props.gasPercentage || 0 },
        ],
        0.09,
        true,
    );
    const rPerc = percentages.find((p) => p.id === 'renewable')!.value;
    const ePerc = percentages.find((p) => p.id === 'electricity')!.value;
    const gPerc = percentages.find((p) => p.id === 'gas')!.value;
    // House icon
    const { projectType, scopSvgId = 'scop-svg' } = props;

    useEffect(() => {
        // Create SVG object w/ white background
        const svg = SVG(scopSvgId).size(250, 250);
        svg.viewbox(0, 0, 250, 250);
        svg.rect(250, 250).fill('#FFF');
        const graph = svg.nested().move(25, 25);
        // Electricity
        if (ePerc) drawSection(graph, COLORS.orange, 'plug', 0, ePerc);
        // Gas
        if (gPerc) drawSection(graph, COLORS.orange, 'gas', ePerc, ePerc + gPerc);
        // Renewable
        const fixedCenter = ePerc + gPerc < 0.4 ? 0.5 : undefined;
        if (rPerc) drawSection(graph, COLORS.green, 'plant', ePerc + gPerc, 1, fixedCenter);
        // Whitespacing between slices
        let perc = 0;
        const strokes = [getPercentageCoords(perc, 106)];
        if (ePerc) strokes.push(getPercentageCoords((perc += ePerc), 106));
        if (gPerc) strokes.push(getPercentageCoords((perc += gPerc), 106));
        strokes.forEach((stroke) => {
            graph.line(100, 100, ...stroke).stroke({ color: '#fff', width: 4 });
        });
        // House icon
        svg.nested().svg(getHousePath(projectType)).move?.(85, 80);
    }, []);

    return <div id={scopSvgId} />;
};

export default ScopGraph;
